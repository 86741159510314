import { request, parseErrorResponse } from "./request";

const createEvent = (req) =>
  new Promise((resolve, reject) => {
    request(req.options)
      .post(`/events`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getEventById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/events/${req.params.id}`,)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getEventByUserId = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/events/my`,)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getEvents = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/events`,)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateEventById = (req) =>
  new Promise((resolve, reject) => {
    request(req.options)
      .put(`/events/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateSurveyEvent = (req) =>
  new Promise((resolve, reject) => {
    request(req.options)
      .put(`/events/survey/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateInteractiveEvent = (req) =>
  new Promise((resolve, reject) => {
    request(req.options)
      .put(`/events/interactive/${req.body.type}/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const registerProductEvent = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/events/register-product/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changeStatusRegisterItem = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/events/change-status-register/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const addEventManagers = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/events/event-managers/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changeStatusEventById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/events/status/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const donateEvent = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/events/donate`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getDonateHistoriesEvent = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/events/history-donate/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  createEvent,
  getEventById,
  getEvents,
  getEventByUserId,
  updateEventById,
  updateSurveyEvent,
  updateInteractiveEvent,
  registerProductEvent,
  changeStatusRegisterItem,
  addEventManagers,
  changeStatusEventById,
  donateEvent,
  getDonateHistoriesEvent
};

export default APIs;
