/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "components/loading";
import { useEffect, useRef, useState } from "react";
import { Ratio } from "./styles";

const VUploadFile = (props: any) => {
  const {
    ratio,
    typeUpload,
    id,
    sectionName,
    fileId,
    file,
    setFile,
    title,
    src,
    loading,
    radius,
    children,
    style,
  } = props;

  const fileRef = useRef<any>();
  const imgRef = useRef<any>();
  // const [errUpload, setErrorUpload] = useState<boolean>(false);
  const [srcVideo, setSrcVideo] = useState("");

  const isVideo = (filename: any) => {
    const type = filename?.type?.split("/")[1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
      case "quicktime":
        // etc
        return true;
    }
    return false;
  };

  console.log({ file });

  const srcIsVideo = (src: any) => {
    const type = src.split(".")[src.split(".").length - 1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
        // etc
        return true;
    }
    return false;
  };

  const onUpload = async () => {
    const [f] = fileRef.current.files;

    setFile(f, id, sectionName, fileId);
    imgRef.current.src = URL.createObjectURL(f);
    if (isVideo(f)) {
      setSrcVideo(URL.createObjectURL(f));
    } else {
    }
  };

  useEffect(() => {
    if (src) {
      if (srcIsVideo(src)) {
        setSrcVideo(src);
      } else {
        imgRef.current.src = src;
      }
    }
  }, [src]);

  useEffect(() => {
    if (
      sectionName &&
      file &&
      typeUpload !== "video" &&
      typeUpload !== "application/pdf"
    ) {
      imgRef.current.src = URL.createObjectURL(file);
    }
  }, [sectionName, file]);

  return (
    <>
      <div
        className={`relative flex justify-center items-center w-full h-full border-1 border-dashed rounded-${radius} `}
        style={style ? { ...style } : {}}
      >
        <label
          className={`flex justify-center items-center w-full h-full cursor-pointer`}
          htmlFor={id}
        >
          {!Boolean(file || srcVideo) ? (
            <div className="" hidden={src}>
              {title ? `Upload ${title}` : children}

              <Ratio>{ratio}</Ratio>
            </div>
          ) : (
            ""
          )}
          {srcVideo ? (
            <video src={srcVideo} width="auto" height="100%" autoPlay loop>
              Your browser does not support HTML video.
            </video>
          ) : typeUpload === "application/pdf" && file ? (
            file.name
          ) : src ? (
            <img
              className={`w-full h-full object-cover border rounded-${radius}`}
              ref={imgRef}
              alt={id}
            />
          ) : (
            <img
              className={`w-full h-full object-cover border rounded-${radius}`}
              hidden={!Boolean(file)}
              ref={imgRef}
              alt={id}
            />
          )}
        </label>
      </div>
      <input
        hidden={true}
        id={id}
        ref={fileRef}
        type="file"
        className="custom-file-input"
        accept={`${typeUpload || ""}/*`}
        required
        onChange={onUpload}
      />

      {loading && (
        <div className="absolute-center">
          <Loading type="sm" />
        </div>
      )}
    </>
  );
};

export default VUploadFile;
