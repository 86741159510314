import VModal from "components/v-core/v-modal";
import VTable from "components/v-core/v-table";
import React, { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import Revenues from "./Revenues";
import { useHistory } from "react-router-dom";
import { renderStatus } from "utils/render";
import TaskService from "api/tasks";
import { formatDate } from "utils/common";

const CampaignHistories = () => {
  const history = useHistory();
  const [isShowModalRevenues, setIsShowModalRevenues] = useState(false);
  const [historyLevel, setHistoryLevel] = useState({
    docs: [],
  });

  const columns = [
    {
      title: "Campaign",
      dataIndex: "campaign",
      key: "campaign",
      render: (data: any) => <span>{data?.campaign?.info?.name}</span>,
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => <span>{formatDate(data?.createdAt)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => renderStatus(data?.status),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (data: any) =>
        renderLevel(data?.level_id, data?.campaign?.levels),
    },
    {
      title: "Revenues",
      dataIndex: "revenues",
      key: "revenues",
      render: (data: any) => (
        <span
          className="cursor-pointer hover:opacity-80"
          onClick={() => openModalRevenues(data)}
        >
          <CgProfile size={17} />
        </span>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const renderLevel = (levelId, levels) => {
    try {
      const level = levels.find((l) => l.id === levelId);
      if (level) return level.name;
      return "";
    } catch (error) {
      return "";
    }
  };

  const getData = async () => {
    const res: any = await TaskService.getHistoryLevelsByUserId({
      query: {
        limit: 100,
        page: 1,
      },
    });
    if (res && res.docs) setHistoryLevel(res);
  };

  const closeModel = (type: string) => {
    setIsShowModalRevenues(false);
  };

  const openModalRevenues = (data: any) => {
    setIsShowModalRevenues(true);
    history.push(`?tab=history&id=${data.id}`);
  };

  return (
    <div>
      <VTable data={historyLevel.docs} columns={columns} />
      <VModal
        title="Revenues"
        isOpen={isShowModalRevenues}
        onCancel={() => closeModel("revenues")}
        centered={true}
      >
        <Revenues />
      </VModal>
    </div>
  );
};

export default CampaignHistories;
