import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import VUploadFile from "components/v-upload";
import Icons from "helper/icons";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIs from "api";
import VBanner from "components/v-banner";
import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";
import AdsBanner from "./AdsBanner";

type IProps = {
  data?: any;
  setData?: (e: any) => void;
  isEdit?: boolean;
  style?: any;
};

const Banner = (props: IProps) => {
  const { data, setData, isEdit, style } = props;
  const params: any = useParams();
  const [bannerType, setBannerType] = useState(data.banner?.type || "slide");
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [slide, setSlide] = useState([]);
  const [videos, setVideos] = useState(null);
  const [link, setLink] = useState({
    banner: "",
    facebook: "",
    adsLeft: [],
    adsRight: [],
  });

  useEffect(() => {
    if (data.banner.slide) setSlide(data.banner.slide);
  }, [data.banner.slide]);

  const onSubmitModal = async () => {
    setIsLoading(true);
    if (bannerType === "slide") {
      if (slide?.length > 0 && slide?.filter((it) => it.file)?.length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `landing-page/${params.symbol}`);

        const statesNew = slide
          .filter((st) => st.isNew)
          .map((stNew) => {
            formData.append("files", stNew.file);
            return stNew;
          });
        const listImg: any = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
        listImg.forEach((el) => {
          statesNew[el.id] = {
            ...statesNew[el.id],
            src: el?.filePath,
          };
        });

        const slideUpload = slide.map((st) => {
          const newStFound = statesNew.find((c) => c.id === st.id);
          if (newStFound) {
            return {
              ...st,
              ...newStFound,
            };
          }
          return st;
        });
        setData((prev) => ({
          ...prev,
          banner: {
            ...prev.banner,
            type: "slide",
            slide: slideUpload,
          },
        }));
      } else {
        setData((prev) => ({
          ...prev,
          banner: {
            ...prev.banner,
            type: "slide",
            slide: slide,
          },
        }));
      }
    } else if (bannerType === "video") {
      if (link.banner) {
        setData((prev) => ({
          ...prev,
          banner: {
            ...prev.banner,
            type: "video",
            link: link.banner,
          },
        }));
      } else {
        const formData = new FormData();
        formData.append("folderPath", `landing-page/${params.symbol}`);
        formData.append("files", videos?.banner);

        const listFile: any = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
        listFile.forEach((el) =>
          setData((prev) => ({
            ...prev,
            banner: {
              ...prev.banner,
              type: "video",
              link: el.filePath,
            },
          }))
        );
      }
    } else {
      setData((prev) => ({
        ...prev,
        banner: {
          ...prev.banner,
          type: "facebook",
          link: link.facebook,
        },
      }));
    }
    setIsLoading(false);
    setOpenModal(false);
  };

  const handleChangeType = (type: string) => {
    setBannerType(type);
  };

  // Slide
  const handleAddSlide = () => {
    setSlide((prev) =>
      prev.concat({
        id: uuidv4(),
        src: "",
      })
    );
  };

  const setFile = (f: any, id: string, key: string) => {
    if (key === "video") {
      setVideos((pre) => ({
        ...pre,
        [id]: f,
      }));
    } else {
      setSlide((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
                ...el,
                file: f,
                isNew: true,
              }
            : el
        )
      );
    }
  };

  // Video
  const onChangeLink = (value: string, name: string) => {
    setLink((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderUploadImage = () => {
    return (
      <div className="relative w-full h-full rounded-md">
        <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
          <Icons iconName="add_image" />
          <span className="text-sm">Upload image</span>
        </div>
      </div>
    );
  };

  const renderUploadVideo = () => {
    return (
      <div className="relative w-full h-full rounded border-1 border-dashed">
        <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
          <Icons iconName="add_video" />
          <span className="text-sm">Upload video</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="w-full min-h-[75vh] max-sm:min-h-[50vh] flex max-lg:flex-col gap-x-5 gap-y-2 items-center"
        style={style}
      >
        <div className="w-[20%] *:max-h-[calc(100%/3)] h-full max-lg:hidden flex flex-col items-center gap-y-2 overflow-y-auto">
          <AdsBanner
            data={data}
            setData={setData}
            isEdit={isEdit}
            field="left"
          />
        </div>
        <div
          className={`${
            isEdit && "border-1 border-dashed"
          } relative w-[60%] h-full max-lg:h-[80%] max-sm:h-[300px] max-lg:w-full`}
        >
          <VBanner data={data.banner} isFull={true} classVideoName="h-full" />
          <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
            {isEdit && (
              <div className="flex items-center gap-x-2">
                <VButton
                  radius="full"
                  iconLeft={<Icons iconName="upload" />}
                  onClick={() => setOpenModal(true)}
                >
                  Upload
                </VButton>
              </div>
            )}
          </div>
        </div>
        <div className="w-[20%] *:max-h-[calc(100%/3)] h-full max-lg:hidden flex flex-col items-center gap-y-2 overflow-y-auto">
          <AdsBanner
            data={data}
            setData={setData}
            isEdit={isEdit}
            field="right"
          />
        </div>
        <div className="max-w-full lg:hidden flex gap-x-2 overflow-x-auto no-scrollbar">
          <AdsBanner
            data={data}
            setData={setData}
            isEdit={isEdit}
            field="left"
          />
          <AdsBanner
            data={data}
            setData={setData}
            isEdit={isEdit}
            field="right"
          />
        </div>
      </div>
      <VModal
        title={`Upload`}
        isOpen={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={onSubmitModal}
        loading={isLoading}
      >
        <div className="flex flex-col items-center min-h-64 gap-y-5">
          <div className="w-full">
            <VDropdown
              options={[
                {
                  label: "Slide",
                  value: "slide",
                },
                {
                  label: "Video",
                  value: "video",
                },
                {
                  label: "Facebook",
                  value: "facebook",
                },
              ]}
              value={bannerType}
              onChange={handleChangeType}
            />
          </div>
          {bannerType === "slide" && (
            <div className="flex flex-wrap items-center gap-4 min-h-48">
              {slide.map((item) => (
                <div className="w-48 h-48">
                  <VUploadFile
                    id={item.id}
                    file={item.file}
                    src={item.src}
                    setFile={setFile}
                    children={renderUploadImage()}
                    radius="md"
                  />
                </div>
              ))}
              <VButton
                className="w-10 h-10"
                radius="full"
                onClick={handleAddSlide}
              >
                +
              </VButton>
            </div>
          )}
          {bannerType === "video" && (
            <div className="w-full flex justify-center">
              <div className="w-48 flex flex-col gap-y-3">
                <div className="h-48 w-full">
                  <VUploadFile
                    id={"banner"}
                    sectionName={"video"}
                    file={videos?.banner?.file}
                    src={link.banner || data.banner?.link}
                    setFile={setFile}
                    children={renderUploadVideo()}
                    radius="md"
                  />
                </div>
                <div className="w-full">
                  <VInput
                    value={link.banner}
                    onChange={(e) => onChangeLink(e.target.value, "banner")}
                  />
                </div>
              </div>
            </div>
          )}
          {bannerType === "facebook" && (
            <div className="w-full">
              <VInput
                placeholder="Link..."
                value={link.facebook}
                onChange={(e) => onChangeLink(e.target.value, "facebook")}
              />
            </div>
          )}
        </div>
      </VModal>
    </>
  );
};

export default Banner;
