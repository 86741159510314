import { ThemeContext } from "context/ThemeContext";
import { useContext, useEffect, useRef, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import VDropdown from "components/v-core/v-dropdown";
import SettingService from "api/settings";
import VButton from "components/v-core/v-button";
import BankService from "api/banks";
import { toast } from "react-toastify";
import { MasterContext } from "context/MasterContext";
import CardItem from "./components/CardItem";
import Icons from "helper/icons";
import VModal from "components/v-core/v-modal";
import VDownLoad from "components/v-download";
import { exportComponentAsPNG } from "react-component-export-image";

const BankCard = () => {
  const { themesSetting } = useContext(ThemeContext);
  const { auth } = useContext(MasterContext);

  const [listBanks, setListBanks] = useState(null);
  const [cardsData, setCardsData] = useState<any>([]);
  const [listCards, setListCards] = useState<any>([]);
  const [bankName, setBankName] = useState("");
  const [bankSelected, setBankSelected] = useState(null);
  const [cardSelected, setCardSelected] = useState(null);
  const [isEdit, setIsEdit] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const imgFrontRef = useRef();
  const imgBackRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [cardDownload, setCardDownload] = useState(null);

  const bankDefault = {
    bankName: bankName,
    front: {
      background: bankSelected ? bankSelected.frontBackground : "",
      inputList: [],
    },
    back: {
      background: bankSelected ? bankSelected.backBackground : "",
      inputList: [],
    },
    logo: {
      src: bankSelected ? bankSelected.logo : "",
      position: {
        x: 20,
        y: 20,
      },
    },
  };

  useEffect(() => {
    getBankCard();
    getCardsData();
  }, []);

  useEffect(() => {
    if (listBanks) {
      setBankName(listBanks[0].name);
    }
  }, [listBanks]);

  useEffect(() => {
    if (bankName && listBanks) {
      const _selected = listBanks?.find((el) => el.name === bankName);
      setBankSelected(_selected);
    }
  }, [bankName, listBanks]);

  useEffect(() => {
    if (bankSelected && cardsData) {
      const _newList = cardsData?.filter(
        (el) => el.bankName === bankSelected?.name
      );
      if (_newList) {
        setListCards(_newList);
      } else {
        setListCards([]);
      }
    }
  }, [bankSelected, cardsData]);

  // Get list banks
  const getBankCard = async () => {
    const res: any = await SettingService.getSettingByName("bank_cards");
    setListBanks(res?.setting?.value);
  };

  // Get data bank
  const getCardsData = async () => {
    const res: any = await BankService.getBanks();
    if (res) {
      setCardsData(res);
    } else {
      setCardsData([]);
    }
  };

  const handleChooseBank = (value) => {
    setBankName(value);
  };
  const handleAddCard = async () => {
    try {
      if (bankName !== "") {
        await BankService.createBank({
          body: {
            ...bankDefault,
            user_id: auth._id,
          },
        });
        getCardsData();
      } else {
        toast.error("Please choose a bank name");
      }
    } catch (error) {}
  };

  const handleEditCard = (card: any) => {
    setIsEdit(card?._id);
    setCardSelected(card);
  };

  const handleDeleteCard = async (bankId: string) => {
    try {
      await BankService.deleteBank({
        params: { id: bankId },
      });
      getCardsData();
    } catch (error) {}
  };

  const handleSaveCard = async () => {
    try {
      setIsLoading(true);
      await BankService.updateBank({
        params: { id: cardSelected._id },
        body: cardSelected,
      });
      await getCardsData();
      toast.success("Update success!");
      setIsEdit("");
      setIsLoading(false);
    } catch (error) {
      console.error("Error saving cards:", error);
    }
  };

  const onOpenModal = (card: any) => {
    setCardDownload(card);
    setOpenModal(true);
  };

  const downloadImage = async () => {
    setIsLoading(true);
    const cardRefs = [imgFrontRef, imgBackRef];

    const fileNames = [
      `front_card_${new Date().getTime()}` || "vzonex",
      `back_card_${new Date().getTime()}` || "vzonex",
    ];

    for (let i = 0; i < cardRefs.length; i++) {
      await exportComponentAsPNG(cardRefs[i], { fileName: fileNames[i] });
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full flex flex-col gap-y-6 m-auto">
      <div className="flex flex-col gap-y-2 z-50">
        <label className="px-2">Choose bank</label>
        <div className="flex gap-2">
          <VDropdown
            customClassName="w-[200px]"
            value={bankName}
            options={
              listBanks?.map((item: any) => ({
                value: item.name,
                label: item.name,
              })) || []
            }
            onChange={handleChooseBank}
          />
          <VButton
            className="w-8 h-8"
            radius="full"
            onClick={handleAddCard}
            iconLeft={<IoMdAdd />}
          />
        </div>
      </div>
      {listCards?.map((card) => (
        <div
          className="relative w-full flex flex-col gap-y-3 p-4 rounded"
          style={themesSetting.card}
          key={card?._id}
        >
          <div className=" flex max-2xl:flex-col items-center gap-2 ">
            <div className="w-1/2 max-2xl:w-full text-center z-30">
              <span className="font-bold text-lg">Front</span>
              <CardItem
                data={cardSelected?._id === card?._id ? cardSelected : card}
                setData={setCardSelected}
                isEdit={isEdit === card?._id ? true : false}
                type="front"
              />
            </div>
            <div className="w-1/2 max-2xl:w-full text-center z-30">
              <span className="font-bold text-lg">Back</span>
              <CardItem
                data={cardSelected?._id === card?._id ? cardSelected : card}
                setData={setCardSelected}
                isEdit={isEdit === card?._id ? true : false}
                type="back"
              />
            </div>
          </div>
          {isEdit === card?._id && (
            <div className="absolute top-0 right-0">
              <VButton
                className="w-6 h-6"
                onClick={() => handleDeleteCard(card?._id)}
              >
                <span className="text-xl font-bolt text-red-500">-</span>
              </VButton>
            </div>
          )}
          <div className="flex justify-center w-full mt-4 gap-x-2 -z-1">
            {isEdit !== card?._id ? (
              <>
                <VButton
                  iconLeft={<Icons iconName="edit" />}
                  onClick={() => handleEditCard(card)}
                >
                  Edit
                </VButton>
                <VButton onClick={() => onOpenModal(card)}>Download</VButton>
              </>
            ) : (
              <VButton
                onClick={handleSaveCard}
                iconLeft={<Icons iconName="save" />}
                children="Save"
                loading={isLoading}
              />
            )}
          </div>
        </div>
      ))}
      <VModal
        isOpen={openModal}
        fullscreen={true}
        showImage={true}
        onCancel={() => setOpenModal(false)}
      >
        <VDownLoad
          download={downloadImage}
          closeModal={setOpenModal}
          loading={isLoading}
        >
          <div className="w-full" ref={imgFrontRef}>
            <CardItem data={cardDownload} type="front" />
          </div>
          <div className="w-full" ref={imgBackRef}>
            <CardItem data={cardDownload} type="back" />
          </div>
        </VDownLoad>
      </VModal>
    </div>
  );
};

export default BankCard;
