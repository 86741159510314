export const filterAddress = (
  key: string,
  data: any,
  code: number,
  setState: any
) => {
  const newData = data.find((el) => el.code === code);

  if (key === "provices") {
    setState(newData.districts);
  } else if (key === "districts") {
    setState(newData.wards);
  } else {
    // console.log();
  }
};

export const checkUserInInvestLocation = (
  userAddress: any,
  investAddress: any
) => {
  if (!investAddress) return true;
  if (!userAddress) return false;

  const { country, province, district, ward } = userAddress;
  const {
    country: countryInvest,
    province: provinceInvest,
    district: districtInvest,
    ward: wardInvest,
  } = investAddress;

  const checkSameField = (userField: string, investField: string) => {
    if (!investField) return true;
    if (!userField) return false;
    if (String(userField) === String(investField)) return true;
    return false;
  };

  const sameCountry = checkSameField(country, countryInvest);

  if (!sameCountry) return false;
  const sameProvince = checkSameField(province, provinceInvest);

  if (!sameProvince) return false;
  const sameDistrict = checkSameField(district, districtInvest);

  if (!sameDistrict) return false;
  const sameWard = checkSameField(ward, wardInvest);

  if (!sameWard) return false;

  if (sameCountry && sameProvince && sameDistrict && sameWard) {
    return true;
  }

  return false;
};
