/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse } from "./request";

export const createTrade = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/trades/create", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const makeTrading = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/trades/make-trading/${req.params.id}/`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getUserInfor = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/trades/user-info/${req.params.id}/`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getAllTrades = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/trades/")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export default {
  createTrade,
  getAllTrades,
  makeTrading,
  getUserInfor
};
