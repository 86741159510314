export const SOCIAL_IMG = {
  Telegram: "/assets/images/social/telegram.png",
  Twitter: "/assets/images/social/twitter.png",
  Facebook: "/assets/images/social/mlogo.png",
  Document: "/assets/images/social/doc.png",
  Youtobe: "/assets/images/social/mlogo.png",
};


export const REGIONS = [
  {
    code: 1,
    name: 'Tây Bắc Bộ',
    provices: [
      'Điện Biên',
      'Hoà Bình',
      'Lai Châu',
      'Lào Cai',
      'Sơn La',
      'Yên Bái'
    ]
  },
  {
    code: 2,
    name: 'Đông Bắc Bộ',
    provices: [
      'Bắc Giang',
      'Bắc Kạn',
      'Cao Bằng',
      'Hà Giang',
      'Lạng Sơn',
      'Phú Thọ',
      'Quảng Ninh',
      'Thái Nguyên',
      'Tuyên Quang'
    ]
  },
  {
    code: 3,
    name: 'Đồng bằng sông Hồng',
    provices: [
      'Bắc Ninh',
      'Hà Nam',
      'Hà Nội',
      'Hải Dương',
      'Hải Phòng',
      'Hưng Yên',
      'Nam Định',
      'Ninh Bình',
      'Thái Bình',
      'Vĩnh Phúc'
    ]
  },
  {
    code: 4,
    name: 'Bắc Trung Bộ',
    provices: [
      'Thanh Hóa',
      'Nghệ An',
      'Hà Tĩnh',
      'Quảng Bình',
      'Quảng Trị',
      'Thừa Thiên Huế'
    ]
  },
  {
    code: 5,
    name: 'Nam Trung Bộ',
    provices: [
      'Đà Nẵng',
      'Quảng Nam',
      'Quảng Ngãi',
      'Bình Định',
      'Phú Yên',
      'Khánh Hòa',
      'Ninh Thuận',
      'Bình Thuận'
    ]
  },
  {
    code: 6,
    name: 'Tây Nguyên',
    provices: ['Kon Tum', 'Gia Lai', 'Đắk Lắk', 'Đắk Nông', 'Lâm Đồng']
  },
  {
    code: 7,
    name: 'Đông Nam Bộ',
    provices: [
      'Bình Phước',
      'Bình Dương',
      'Đồng Nai',
      'Tây Ninh',
      'Vũng Tàu',
      'Thành phố Hồ Chí Minh'
    ]
  },
  {
    code: 8,
    name: 'Tây Nam Bộ',
    provices: [
      'Long An',
      'Đồng Tháp',
      'Tiền Giang',
      'An Giang',
      'Bến Tre',
      'Vĩnh Long',
      'Trà Vinh',
      'Hậu Giang',
      'Kiên Giang',
      'Sóc Trăng',
      'Bạc Liêu',
      'Cà Mau',
      'Cần Thơ'
    ]
  }
];