import { Controller } from "react-hook-form";

import { FormItem } from "../../styles";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import { useContext, useState } from "react";

const Step2 = (props) => {
  const { theme, getThemeByKey } = useContext(ThemeContext);
  const { control, errors, query } = props;
  const [hoverField, setHoverField] = useState(null);

  const inputTheme = getThemeByKey("auth.input");
  return (
    <>
      <FormItem>
        <Controller
          name="fullName"
          control={control}
          render={({ field }) => (
            <VInput
              required
              label="Full Name"
              placeholder="Full Name"
              hasError={Boolean(errors.fullName)}
              wrapperClassName={"bg-transparent gap-y-1 px-0"}
              labelClassName={`text-xs font-bold ${
                theme === "dark" ? "text-white" : "text-gray-6"
              }`}
              inputWrapperClassName={`w-full border-[1px] rounded`}
              className={`w-full bg-transparent p-2`}
              onMouseEnter={() => setHoverField("email")}
              onMouseLeave={() => setHoverField(null)}
              inputStyle={{
                ...inputTheme,
                borderColor:
                  hoverField === "email"
                    ? inputTheme?.hover?.borderColor
                    : inputTheme?.borderColor,
              }}
              {...field}
            />
          )}
        />
      </FormItem>
      <FormItem>
        <Controller
          name="refer_code"
          control={control}
          defaultValue={query.refer_code}
          render={({ field }) => (
            <VInput
              label="Referral ID (Optional)"
              placeholder="Referral ID"
              hasError={Boolean(errors.refer_code)}
              wrapperClassName={"bg-transparent gap-y-1 px-0"}
              labelClassName={`text-xs font-bold ${
                theme === "dark" ? "text-white" : "text-gray-6"
              }`}
              inputWrapperClassName={`w-full border-[1px] ${
                theme === "dark" ? "border-gray-3" : "border-gray-5"
              } hover:border-blue rounded`}
              className="w-full bg-transparent p-2"
              {...field}
            />
          )}
        />
      </FormItem>
    </>
  );
};

export default Step2;
