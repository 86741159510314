import AdsBannerService from "api/adsBanner";
import { MasterContext } from "context/MasterContext";
import VBanner from "components/v-banner";
import React, { useState, useEffect, useContext, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
import { ThemeContext } from "context/ThemeContext";

const VAds = () => {
  const { themesSetting } = useContext(ThemeContext);
  const location = useLocation();
  const { allMenus } = useContext(MasterContext);
  const [adsData, setAdsData] = useState(null);
  const menuSelected = allMenus.find((el) => el.url === location.pathname);
  const [menuAds, setMenuAds] = useState(null);
  const [open, setOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0); // Set initial time (in seconds)

  useEffect(() => {
    if (menuSelected) {
      getAdsData();
    }
  }, [menuSelected]);

  useEffect(() => {
    if (adsData) {
      setTimeout(() => {
        setMenuAds(adsData);
        setOpen(true);
      }, adsData?.time_start_show * 1000);
    } else {
      setMenuAds(null);
      setOpen(false);
    }
  }, [adsData]);

  const getAdsData = async () => {
    try {
      const res: any = await AdsBannerService.getByMenuId({
        query: {
          menu_id: menuSelected?._id,
        },
      });
      if (res) {
        setAdsData(res);
      } else {
        setAdsData(null);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (menuAds && menuAds.is_loop && !open) {
      setTimeout(() => {
        setOpen(true);
      }, menuAds?.time_loop * 1000);
    }
  }, [menuAds, open]);

  useEffect(() => {
    if (open && menuAds) {
      if (menuAds.time_end_show > 0) {
        setTimeout(() => {
          setOpen(false);
        }, menuAds.time_end_show * 1000);
      }
      setTimeLeft(
        menuAds.enable_close ? menuAds.time_enable_close : menuAds.time_end_show
      );
    }
  }, [open, menuAds]);

  useEffect(() => {
    if (open && menuAds && timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval); // Clean up the interval
    }
  }, [open, menuAds, timeLeft]);

  const closeAds = () => {
    if (menuAds.enable_close && timeLeft === 0) {
      setOpen(false);
    }
  };

  return (
    <>
      {menuAds && (
        <Transition.Root show={open}>
          <Dialog
            className={`${
              menuAds?.position === "top"
                ? "items-start"
                : menuAds?.position === "bottom"
                ? "items-end"
                : "items-center"
            } z-[999999] absolute top-0 w-full h-full flex justify-center`}
            onClose={closeAds}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black-2 bg-opacity-80 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={`${
                menuAds?.position === "top"
                  ? "-translate-y-full"
                  : menuAds?.position === "bottom"
                  ? "translate-y-full"
                  : "opacity-0 scale-95"
              }`}
              enterTo={`${
                menuAds?.position === "top"
                  ? "translate-y-0"
                  : menuAds?.position === "bottom"
                  ? "translate-y-full"
                  : "opacity-100 scale-100"
              }`}
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`${
                  menuAds?.position === "center" ? "w-1/2" : " w-full h-[10vh]"
                } ${menuAds?.banner?.type === "video" && "h-1/2"} text-center`}
                style={
                  menuAds?.position !== "center"
                    ? {
                        backgroundColor: themesSetting.card.backgroundColor,
                      }
                    : {}
                }
              >
                <Dialog.Panel className="relative w-full h-full flex items-end transform overflow-hidden rounded-lg transition-all p-0 z-50">
                  <div
                    className="absolute right-1 top-1 flex justify-center items-center w-6 h-6 rounded-full opacity-70 z-50"
                    style={themesSetting.card}
                  >
                    {menuAds && timeLeft === 0 ? (
                      <VButton
                        type="outline"
                        radius="full"
                        className="w-3 h-3 !border-none"
                        iconLeft={<Icons iconName="close" />}
                        onClick={closeAds}
                      />
                    ) : (
                      <span className="text-xs opacity-70">{timeLeft}</span>
                    )}
                  </div>
                  <div className="w-full h-full">
                    <VBanner data={menuAds.banner} />
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default VAds;
