/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { ButtonPreview, ChannelWrapper, Header, RowItem } from "./styles";
import { Container, Row } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { TitleWrapper } from "./styles";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MasterContext } from "context/MasterContext";
import APIs from "api";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import VInput from "components/v-core/v-input";

const View = () => {
  const { auth, getUser } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);

  const [data, setData] = useState([]);

  const handleAdd = () => {
    setData((data) => [
      ...data,
      {
        id: "",
        prioritized: false,
      },
    ]);
  };

  const onInputCheckChange = (e, index) => {
    const { checked } = e.target || false;
    const _data = [...data].map((item, i) =>
      i === index
        ? {
            ...item,
            prioritized: checked,
          }
        : item
    );

    setData(_data);
  };

  const onChangeHandler = (value, index) => {
    const arr = data.map((item, i) =>
      i === index
        ? {
            ...item,
            id: value,
          }
        : item
    );
    setData(arr);
  };

  const handleDeleteIndex = (index) => {
    const newState = data.filter((item, i) => i !== index);
    setData(newState);
  };

  const handleSubmit = async () => {
    const user: any = await APIs.USER.editUser({
      params: {
        id: auth?._id,
      },
      body: {
        channels: data,
      },
    });
    if (user) {
      const data: any = await getUser();
      setData(data?.channels);
      toast.success("Update Success");
    }
  };

  useEffect(() => {
    if (auth) {
      const { channels } = auth;
      if (channels?.length) {
        setData(channels);
      } else {
        setData([
          {
            id: "",
            prioritized: false,
          },
        ]);
      }
    }
  }, [auth]);

  return (
    <ChannelWrapper
      className="mt-10 p-4 rounded-md"
      style={themesSetting?.card}
    >
      <Header className="pr-4">
        <p className="heading !p-0">Setting My Channel</p>
        <ButtonPreview>
          <Link to={`/my/preview-channel/${auth?._id}`}>
            <VButton>Preview</VButton>
          </Link>
        </ButtonPreview>
      </Header>
      <>
        <Container>
          <Row>
            <TitleWrapper>
              <div className="id">ID</div>
              <div className="prioritized">Prioritized</div>
            </TitleWrapper>
          </Row>
          <Row>
            {data.map((item, index) => (
              <RowItem key={index}>
                <div className="input-id">
                  <VInput
                    transparent={true}
                    className="!bg-transparent"
                    placeholder="ID"
                    value={item.id}
                    onChange={(e) => onChangeHandler(e.target.value, index)}
                  />
                </div>
                <div className="input-prior">
                  <VInput
                    type="checkbox"
                    transparent={true}
                    className="!bg-transparent"
                    checked={item.prioritized}
                    onChange={(e) => onInputCheckChange(e, index)}
                  />
                </div>
                <div className="btn-delete">
                  <button onClick={() => handleDeleteIndex(index)}>
                    <BiTrash />
                  </button>
                </div>
              </RowItem>
            ))}
          </Row>
          <Row>
            <RowItem>
              <VButton onClick={handleAdd}>Add Channel</VButton>
              <VButton onClick={handleSubmit}>Submit</VButton>
            </RowItem>
          </Row>
        </Container>
      </>
    </ChannelWrapper>
  );
};

export default View;
