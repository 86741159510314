import { request, parseErrorResponse } from "./request";

const getKyc = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/kycs/getByUser`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createKyc = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/kycs/`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const KycService = {
  getKyc,
  createKyc,
};

export default KycService;
