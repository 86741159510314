/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import NavBottom from "layouts/MainLayout/navbar-bottom";
import Bg from "./iframeVideo";

import Step1 from "./step1";
import Step2 from "./step2";

import Header from "layouts/MainLayout/header";
import Footer from "layouts/MainLayout/footer";
import { ThemeContext } from "context/ThemeContext";
import VButton from "components/v-core/v-button";
import { toast } from "react-toastify";
import Step3 from "./step3";
import YourStockService from "api/yourEstock";
interface IProps {
  [key: string]: any;
}

const defaultStateProject = {
  name: "",
  symbol: "",
  supply: 0,
  info: {},
  cal: {},
  is_holding_balance: true,
  status: "init",
  category_id: null,
};

const defaultStateDetail = {
  object: "",
  status: "pending",
};

export default function View(props: IProps) {
  const history = useHistory();
  const { symbol } = useParams();

  const { themesSetting, logos } = useContext(ThemeContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [projectInfo, setProjectInfo] = useState(defaultStateProject);
  const [detail, setDetails] = useState(defaultStateDetail);

  useEffect(() => {
    if (symbol) getYourEstock();
  }, [symbol]);

  const getYourEstock = async () => {
    try {
      const yourEstock: any = await YourStockService.getYourEstock(symbol);
      if (yourEstock) history.push(`/project/${symbol}`);
    } catch (error) {}
  };

  const handleChangeDetail = (name, value, parent?: string) => {
    if (parent)
      setDetails((prev) => ({
        ...prev,
        [parent]: {
          ...(prev[parent] || {}),
          [name]: value,
        },
      }));
    else
      setDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
  };

  const handleChangeProject = (name, value, parent?: string) => {
    if (parent)
      setProjectInfo((prev) => ({
        ...prev,
        [parent]: {
          ...(prev[parent] || {}),
          [name]: value,
        },
      }));
    else
      setProjectInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
  };

  const handleSubmit = async () => {
    try {
      if (step < 3) {
        setStep((prev) => prev + 1);
      } else {
        const yourStock: any = await YourStockService.createYourEstock({
          ...detail,
          eStockData: {
            infoEstock: {},
            infoUser: {},
          },
          projectInfo,
        });
        console.log({
          yourStock,
        });
        if (yourStock) {
          history.push(
            `/project/${projectInfo.symbol}?isEdit=true&token=${yourStock.token}`
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      setStep(3);
      setLoading(false);
      toast.error(error?.errors);
    }
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <div className="h-[100vh] max-[768px]:overflow-hidden ">
      <div className="" style={themesSetting?.layout}>
        <Header main={true} />
      </div>
      {
        <div className="relative mt-[108px] w-[100vw] h-[calc(100vh-150px)] [max-1100px]:mt-[86px] [max-1100px]:h-[calc(100vh-142px)] overflow-y-auto no-scrollbar">
          {logos?.backgroundLogin ? (
            <div className="fixed w-full h-full z-0 top-0 right-0 overflow-hidden">
              <video
                autoPlay
                loop
                muted
                className="min-w-full h-full pointer-events-none "
                src={logos?.backgroundLogin}
              ></video>
            </div>
          ) : (
            <Bg />
          )}
          <div className="relative flex items-center px-[15px] py-[30px] min-h-[70vh]">
            <div
              className="relative w-full max-w-[900px] m-auto p-5 opacity-90 rounded-lg"
              style={themesSetting.card}
            >
              <h1 className="text-xl md:text-[42px] mb-[30px] text-center">
                Create, share idea Project
              </h1>
              {step === 1 && (
                <Step1
                  handleChangeDetail={handleChangeDetail}
                  setStep={setStep}
                />
              )}

              {step === 2 && (
                <Step2
                  detail={detail}
                  projectInfo={projectInfo}
                  handleChangeDetail={handleChangeDetail}
                  setStep={setStep}
                  handleChangeProject={handleChangeProject}
                />
              )}

              {step === 3 && (
                <Step3
                  projectInfo={projectInfo}
                  setStep={setStep}
                  handleChangeProject={handleChangeProject}
                />
              )}

              {step !== 1 && (
                <div className="my-[12px] flex  justify-center items-center">
                  <br />
                  <div className="mr-2 w-20">
                    <VButton
                      loading={loading}
                      className="w-full text-[16px] font-bold"
                      type={"outline"}
                      onClick={handleBack}
                    >
                      Back
                    </VButton>
                  </div>
                  <div className="w-20">
                    <VButton
                      loading={loading}
                      className="w-full text-[16px] font-bold"
                      onClick={handleSubmit}
                    >
                      Next
                    </VButton>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="relative w-[100vw] pb-5" style={themesSetting.layout}>
            <Footer main={true} />
          </div>
        </div>
      }
      <div className="hidden [max-1100px]:block">
        <NavBottom />
      </div>
    </div>
  );
}
