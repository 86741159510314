import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";
import { MasterContext } from "context/MasterContext";
import { useContext, useEffect } from "react";
interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { projectInfo, handleChangeProject } = props;

  const { categories, getCategories } = useContext(MasterContext);

  useEffect(() => {
    getCategories({
      params: { group: "category_project" },
    });
  }, []);

  return (
    <div className="flex items-center justify-center w-full">
      <form className="max-w-[600px] w-full max-[456px]:w-full max-[456px]:p-0">
        <>
          <p className="text-[14px] md:text-[16px] text-center">
            Project information
          </p>
          {/* category */}
          <div className="my-[12px]">
            <VDropdown
              label="Project category"
              options={categories.map((el) => ({
                label: el.name,
                value: el.key,
              }))}
              value={projectInfo?.categories}
              onChange={(value) => handleChangeProject("categories", value)}
            />
          </div>
          <div className="my-[12px]">
            <VInput
              className="w-full py-2 px-2 border rounded-md bg-transparent"
              label="Project Name"
              value={projectInfo.name}
              placeholder="name..."
              onChange={(e) => handleChangeProject("name", e.target.value)}
            />
          </div>
          <div className="my-[12px]">
            <VInput
              type="textarea"
              value={projectInfo.info.description}
              className="w-full py-2 px-2 border rounded-md bg-transparent"
              label="Description"
              placeholder="description..."
              onChange={(e) =>
                handleChangeProject("description", e.target.value, "info")
              }
            />
          </div>

          <hr />
          <div className="my-[12px]">
            <VInput
              className="w-full py-2 px-2 border rounded-md bg-transparent"
              label="Token Name"
              value={projectInfo.token_name}
              placeholder="nft.."
              onChange={(e) =>
                handleChangeProject("token_name", e.target.value)
              }
            />
          </div>
          <div className="my-[12px]">
            <VInput
              className="w-full py-2 px-2 border rounded-md bg-transparent"
              label="Symbol"
              value={projectInfo.symbol}
              placeholder="symbol"
              onChange={(e) => handleChangeProject("symbol", e.target.value)}
            />
          </div>
          <div className="my-[12px]">
            <VInput
              className="w-full py-2 px-2 border rounded-md bg-transparent"
              type="text"
              placeholder="1,000,000"
              label="Total Supply"
              value={projectInfo.total_supply}
              isCurrency={true}
              allowNegativeValue={false}
              defaultValue={projectInfo.total_supply}
              onValueChange={(e) => handleChangeProject("total_supply", e)}
            />
          </div>
        </>

        {/* {step === 3 && (
          <Step3
            control={control}
            errors={errors}
            errorField={errorField}
            fieldTokenomics={fieldTokenomics}
            dataDetail={dataDetail}
            totalSupply={totalSupply}
            setTotalSupply={setTotalSupply}
            setDataICO={setDataICO}
            setDataDetail={setDataDetail}
            setFieldTokenomics={setFieldTokenomics}
          />
        )}

        {step === 4 ? (
          <>
            <p className="text-[14px] md:text-[16px] text-center">
              {"Project profit distribution"}
            </p>
            <FromProfit isCreate={true} />
          </>
        ) : (
          <div className="my-[12px]">
            <br />
            <div className="text-center">
              <VButton
                loading={loading}
                radius="full"
                className="w-full max-w-[300px] text-[16px] font-bold"
                onClick={handleSubmit(onSubmit)}
              >
                Next
              </VButton>
            </div>
          </div>
        )} */}
      </form>
    </div>
  );
}
