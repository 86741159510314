import React from "react";
import Wrapper from "./styles";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  return (
    <Wrapper>
      <h3>You cannot use this feature</h3>
      <p>Please contact us to use it!</p>
    </Wrapper>
  );
}
