import React, {} from 'react';

import Wrapper from "./styles";

interface IProps {
    [key: string]: any
}

export default function View(props: IProps) {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}