import { useEffect, useState } from "react";
import ProjectService from "api/projects";

export default function useProjects() {
  const [projects, setProjects] = useState([]);
  const [projectsByType, setProjectsByType] = useState({
    upcoming: [],
    opening: [],
    closed: [],
  });
  const [projectSelected, setProjectSelected] = useState<any>({});

  const getProjects = async () => {
    try {
      const res: any = await ProjectService.getProjects();
      if (res && res?.projects) {
        setProjects(res?.projects);
      }
    } catch (error) {}
  };

  const getProjectById = async (projectId: string, callback?: any) => {
    try {
      const res: any = await ProjectService.getProjectById({
        params: {
          id: projectId,
        },
      });
      if (res && res.project) setProjectSelected(res.project);
    } catch (error) {
      callback && callback();
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (projects) {
      setProjectsByType({
        opening: projects.filter((p: any) => p.status === "opening"),
        closed: projects.filter((p: any) => p.status === "closed"),
        upcoming: projects.filter((p: any) => p.status === "upcoming"),
      });
    }
  }, [projects]);

  return {
    projectsByType,
    projects,
    projectSelected,
    getProjects,
    getProjectById,
  };
}
