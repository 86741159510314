/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import {
  DetailRightView,
  Title,
  SpanColor,
  MarginTop,
  SubmitWithdraw,
  RightViewContent,
  Warning,
} from "../../styles";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { formatNumber } from "utils/common";
import Verify from "../verify";
import APIs from "api";
import { useParams } from "react-router-dom";
import { MasterContext } from "context/MasterContext";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";

const EmailWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <RightViewContent>
      <MarginTop mt="20px" />
      <VInput
        className="!bg-transparent"
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={<VButton onClick={setMaxAmount}>MAX</VButton>}
        suffixClassName="!-right-3"
        onValueChange={(e) => handleChange(e)}
      />

      <MarginTop mt="20px" />
      <p>
        Maximum {stockName} Available:{" "}
        <SpanColor color="red100">{userBalance[tokenName]}</SpanColor>{" "}
        {stockName}
      </p>
      <p>
        You can <SpanColor color="green100">buy</SpanColor> or{" "}
        <SpanColor color="orange100">deposit</SpanColor> {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <VInput
              type={"textarea"}
              className="!bg-transparent h-[100px]"
              transparent={true}
              label="Message"
              placeholder={`Welcome to ${stockName} Stock`}
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />

      <MarginTop mt="20px" />
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          Please update the correct VZONEXID to the correct profile at
          <a href="https://qrmart.vn/" target={"_blank"}>
            {" "}
            https://qrmart.vn/
          </a>
        </p>
      </Warning>
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>Estimated time to complete: less than a minute</p>
      </Warning>
    </RightViewContent>
  );
};

const View = () => {
  const { stocks, balances } = useContext(MasterContext);

  const { stock: stockParam } = useParams();
  const location: any = useLocation();
  const history = useHistory();
  const address = queryString.parse(location.search)?.address;
  const amountStock = queryString.parse(location.search)?.amountStock;

  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(null);
  const [showVerify, setShowVerify] = useState(false);
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });

  const [amount, setAmount] = useState<number>(null);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    setAmount(e);
  };

  const handleSubmitWithdraw = async (data) => {
    const body = {
      note: data.note,
      to: data.to,
      stock: stockParam,
      amount: Number(amount),
      network: "vzonex",
      verify,
    };
    try {
      if (amount <= 0 || !amount) {
        setErrorAmount(true);
      } else {
        if (!showVerify) {
          setShowVerify(true);
          return;
        }
        setLoading(true);
        await APIs.TRANSACTION.withdrawQRMart({ body });
        setLoading(false);
        setShowVerify(false);

        history.push(`/my/wallets/${stockParam}/withdraw/`);
        toast.success("Withdraw successful!");
        setVerify({
          email_code: "",
          google_code: "",
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.errors || "Withdraw failed!");
    }
  };

  const setMaxAmount = () => {
    setAmount(balances[stockParam]);
  };

  const feeTransfer =
    stock && stock.fee_transfer
      ? stock.fee_transfer
      : { stock: stockParam, percent: 0 };
  // const amount = watch("amount");
  const amountFee = formatNumber(((+amount || 0) * feeTransfer.percent) / 100);

  const formProps = {
    userBalance: balances,
    control,
    errors,
    tokenName: stockParam,
    fee: {
      amount: amountFee,
      stock: feeTransfer.stock,
    },
    setMaxAmount,
  };

  useEffect(() => {
    if (stocks) setStock(stocks.find((c) => c.symbol === stockParam));
  }, [stocks, stockParam]);

  useEffect(() => {
    if (Number(amountStock) > 0) {
      setAmount(Number(amountStock));
    }
    if (address) {
      setValue("to", address);
    }
  }, []);

  return (
    <DetailRightView>
      <Title bgColor={"grey200"} just="space-between">
        <p>Transfer Stock ({String(stockParam).toUpperCase()})</p>
      </Title>

      <form onSubmit={handleSubmit(handleSubmitWithdraw)}>
        {showVerify ? (
          <Verify verify={verify} setVerify={setVerify} />
        ) : (
          <EmailWithdraw
            {...formProps}
            amount={amount}
            errorAmount={errorAmount}
            handleChange={handleChange}
          />
        )}
        <MarginTop mt="40px" />
        <SubmitWithdraw className="text-center p-[15px] mb-[15px]">
          <VButton
            radius="full"
            className="w-[300px]"
            loading={loading}
            onClick={handleSubmit(handleSubmitWithdraw)}
          >
            Submit
          </VButton>
        </SubmitWithdraw>
      </form>
    </DetailRightView>
  );
};

export default View;
