/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import Countdown from "components/v-countdown";
import APIs from "api";
import { useParams, useHistory } from "react-router-dom";
import Icons from "helper/icons";
import { compareDateWithCur } from "utils/times";
import { renderStatus } from "utils/render";
import ProgressCircles from "./Progress";
import VButton from "components/v-core/v-button";
import VBanner from "components/v-banner";
import VModal from "components/v-core/v-modal";
import LevelHistory from "./LevelHistories";

export default function DetailCampaign() {
  const { campaignId } = useParams();

  const history = useHistory();

  const [isShowModal, setIsShowModal] = useState(false);

  const [campaignSelected, setCampaignSelected] = useState(null);
  const [level, setLevel] = useState({
    data: {
      total_f1: 0,
      total_user: 0,
      total_my_invest: 0,
      total_user_invest: 0,
      amount_user_invest: 0,
    },
    level_id: "",
    images: [],
    videos: [],
    attachments: [],
    status_upload: "pending",
    images_uploaded: [],
    videos_uploaded: [],
    attachments_uploaded: [],
    enable_amount_image: false,
    enable_amount_video: false,
    enable_amount_attachment: false,
    createdAt: "",
  });

  useEffect(() => {
    if (campaignId) {
      getLevel();
    }
  }, [campaignId]);

  const getLevel = async () => {
    const res: any = await APIs.TASK.getLevelUserCampaign({
      params: {
        id: campaignId,
      },
    });
    if (res.campaign) {
      setCampaignSelected(res.campaign);
    }
    if (res.level) {
      setLevel(res.level);
    }
  };

  const handleBack = () => {
    history.push("/my/campaign/?tab=campaign");
  };

  const renderCountDown = (p) => {
    let title = "";
    let time = 0;
    if (p.start_date && compareDateWithCur(p.start_date) > 0) {
      title = "upcoming";
      time = new Date().getTime() + compareDateWithCur(p.start_date);
    } else {
      title = "happening";
      time = new Date().getTime() + compareDateWithCur(p.end_date);
    }

    return (
      <div className="flex flex-col gap-y-2">
        <span className="w-full text-center">{renderStatus(title)}</span>
        <Countdown
          date={new Date(time).toLocaleDateString()}
          className="!text-base !w-[40px] !h-[45px]"
        />
      </div>
    );
  };

  return (
    <>
      {campaignSelected && (
        <div className="w-full h-full flex flex-col gap-y-3 p-2 overflow-x-hidden">
          <VButton
            type="outline"
            className="w-6 h-6 !border-none"
            iconLeft={<Icons iconName="back" />}
            onClick={handleBack}
          />
          <div className="relative w-full h-[45%] overflow-hidden">
            <VBanner data={campaignSelected.banner} isWidthFull={true} />
            <div className="absolute right-0 bottom-0 flex justify-center">
              {renderCountDown(campaignSelected)}
            </div>
          </div>
          <div className="w-full flex justify-end">
            <VButton onClick={() => setIsShowModal(true)}>
              List users joined
            </VButton>
          </div>
          <div className="w-full">
            <ProgressCircles
              levels={campaignSelected.levels}
              level={level}
              campaignId={campaignSelected._id}
            />
          </div>
          <VModal
            isOpen={isShowModal}
            children={<LevelHistory campaignId={campaignSelected?._id} />}
            onCancel={() => setIsShowModal(false)}
            centered={true}
          />
        </div>
      )}
    </>
  );
}
