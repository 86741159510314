import Countdown from "components/v-countdown";
import ToolTip from "components/v-tooltip";
import React from "react";
import { BsFillGiftFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { compareDateWithCur } from "utils/times";
import {
  Campaign,
  Content,
  Image,
  Item,
  ContainItem,
  CounterdownWrapper,
  TooltipWrapper,
} from "./styles";
import { labelColors } from "./utils";

const Tasks = ({ listTasks = [], type = "new" }) => {
  const history = useHistory();

  const renderCountdown = (task) => {
    let title = "";
    let time = 0;
    const { frame } = task.time;
    if (task.time.start_date && compareDateWithCur(task.time.start_date) > 0) {
      title = "Upcoming";
      time = new Date().getTime() + compareDateWithCur(task.time.start_date);
    } else {
      title = "Happenning";
      time = new Date().getTime() + compareDateWithCur(task.time.end_date);
    }
    if (frame && frame.length > 0) {
      const hourCur = new Date().getHours();
      let distance = null;
      title = "Upcoming";

      for (let index = 0; index < frame.length; index++) {
        const element = frame[index];
        if (hourCur === element) {
          distance = 1;
          title = "Happenning";
          break;
        }
        if (hourCur < element) {
          distance = element - hourCur;
          break;
        }
      }
      if (!distance) distance = frame[0] + 24 - hourCur;
      time =
        new Date().getTime() +
        distance * 3600000 -
        new Date().getMinutes() * 60000;
    }
    return (
      <CounterdownWrapper>
        <span className={`title ${title} text-center flex justify-center my-2`}>
          {title}
        </span>
        <Countdown date={time} />
      </CounterdownWrapper>
    );
  };

  const renderReward = (task) => {
    return (
      <TooltipWrapper>
        <label>Stocks :</label>
        {task.reward["stocks"].map((s) => (
          <div>
            <span>
              +{s.amount} ({String(s.symbol).toUpperCase()})
            </span>
          </div>
        ))}
        <hr />
        <label>Vouchers :</label>
        {task.reward["vouchers"].map((s) => (
          <div>
            <span>
              +{s.amount} ({s.id})
            </span>
          </div>
        ))}
        <hr />
        <label>Tickets :</label>
        {task.reward["tickets"].map((s) => (
          <div>
            <span>
              +{s.amount} ({s.id})
            </span>
          </div>
        ))}
        <hr />
        <label>Codes :</label>
        {task.reward["codes"].map((s) => (
          <div>
            <span>+{s.codes}</span>
          </div>
        ))}
        <hr />
      </TooltipWrapper>
    );
  };

  return (
    <ContainItem>
      {listTasks?.length > 0 &&
        listTasks.map((item, key) => (
          <Item key={key}>
            <Image
              onClick={() =>
                type !== "done" && history.push(`/my/task/detail/${item._id}`)
              }
            >
              <img src={item.banner} alt="" />
            </Image>
            <Content>
              {listTasks && renderCountdown(item)}
              <span className="name !flex placeholder: py-2">
                {item.name.length > 20 ? item.name.slice(0, 20) : item.name}
              </span>
              <ToolTip id={item._id} content={renderReward(item)}>
                <span id={`tooltip-` + item._id} className="gift">
                  <BsFillGiftFill size={18} />
                </span>
              </ToolTip>
              <br />
              <label className="pb-3">{item.categories}</label>
              {item.label && (
                <Campaign style={{ background: labelColors[item.label] }}>
                  <span>{item.label}</span>
                </Campaign>
              )}
              {type === "done" && <button disabled>received</button>}
            </Content>
          </Item>
        ))}
    </ContainItem>
  );
};

export default React.memo(Tasks, (prevProps, nextProps): any => {
  if (prevProps.listTasks !== nextProps.listTasks) {
    return false;
  }
  return true;
});
