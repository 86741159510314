import { ThemeContext } from "context/ThemeContext";
import { useContext } from "react";
import { Spinner } from "reactstrap";

const View = (props: any) => {
  const { type } = props;
  const { themesSetting } = useContext(ThemeContext);

  if (type === "sm") {
    return <Spinner size="sm">...</Spinner>;
  }

  return (
    <div
      className="flex justify-center items-center m-auto bg-black min-h-[100vh]"
      style={themesSetting.layout}
    >
      <img className="w-[150px]" src="/assets/images/loading.gif" alt="" />
    </div>
  );
};

export default View;
