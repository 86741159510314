/* eslint-disable indent */
"use client";
import { useEffect, useState, Fragment, useContext } from "react";
import {
  Listbox,
  Label,
  Transition,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ThemeContext } from "context/ThemeContext";

type IProps = {
  options?: any;
  label?: any;
  defaultLabel?: string;
  value?: any;
  optionLabel?: string;
  optionIcon?: string;
  customClassName?: string;
  buttonClassName?: string;
  customOptionClassName?: string;
  optionClassName?: string;
  disable?: boolean;
  onChange?: (item: any, object?: any) => void;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function VDropdown({
  options,
  label,
  value,
  defaultLabel,
  optionLabel = "label",
  optionIcon = "icon",
  customClassName,
  buttonClassName,
  optionClassName,
  customOptionClassName,
  onChange,
}: IProps) {
  const { themesSetting } = useContext(ThemeContext);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (value && options) {
      const _select = options.find((o) => o.value === value);
      if (_select) setSelected(_select);
      else setSelected(null);
    } else setSelected(null);
  }, [value]);

  const handleChange = (item) => {
    setSelected(item);
    onChange(item?.value, item);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <Label className="block text-sm font-medium mb-1">{label}</Label>
          <div className={`${customClassName || ""} relative `}>
            <ListboxButton
              className={`${buttonClassName} relative w-full border-1 px-2  cursor-default rounded-md py-1.5 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6`}
              style={themesSetting?.card}
            >
              <span className="flex items-center">
                {selected?.logo && (
                  <img
                    src={selected?.logo}
                    alt=""
                    className="h-5 w-5 flex-shrink-0 rounded-full"
                  />
                )}
                <span className={`block truncate pl-2 ${optionClassName}`}>
                  {selected
                    ? selected[optionLabel]
                    : defaultLabel
                    ? defaultLabel
                    : "Choose"}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="absolute !p-0 z-10 mt-1 max-h-56 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                style={themesSetting?.card}
              >
                <ListboxOptions>
                  {options.map((item, key) => (
                    <ListboxOption
                      key={key}
                      className={({ active }) =>
                        classNames(
                          `relative flex items-center justify-between cursor-default select-none p-2 ${
                            customOptionClassName || ""
                          }`
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <div
                            className="flex items-center gap-x-1"
                            style={
                              active
                                ? themesSetting?.dropdown?.active
                                : themesSetting?.dropdown
                            }
                          >
                            {optionIcon && item[optionIcon] && (
                              <img
                                src={item[optionIcon]}
                                alt=""
                                className="h-5 w-5 flex-shrink-0 rounded-full"
                              />
                            )}
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                ""
                              )}
                            >
                              {item[optionLabel || "label"]}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              style={
                                active
                                  ? themesSetting?.dropdown?.active
                                  : themesSetting?.dropdown
                              }
                              className={classNames("flex items-center")}
                            >
                              <CheckIcon
                                className="h-3 w-3"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default VDropdown;
