import React, { useContext } from "react";
import Draggable from "react-draggable";
import { MdOutlineSettings } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import VButton from "components/v-core/v-button";
import { v4 as uuidv4 } from "uuid";
import { convertS3Link } from "api/s3-request";
import VPopover from "components/v-popover";

type IProps = {
  data?: any;
  type?: string;
  isEdit?: boolean;
  setData?: (e: any) => void;
};

const CardItem = (props: IProps) => {
  const { data, setData, type, isEdit } = props;
  const { themesSetting } = useContext(ThemeContext);

  const handleAddInput = () => {
    setData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        inputList: prev[type]?.inputList?.concat({
          id: uuidv4(),
          width: 200,
          font_size: 12,
          height: 50,
          color: "",
          content: "",
          position: {
            x: 101,
            y: 86,
          },
        }),
      },
    }));
  };

  const handleDeleteField = (id) => {
    setData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        inputList: prev[type]?.inputList?.filter((el) => el.id !== id),
      },
    }));
  };

  const handleInputChange = (id: number, key: string, value: any) => {
    setData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        inputList: prev[type]?.inputList?.map((el) =>
          el.id === id
            ? {
                ...el,
                [key]: value,
              }
            : { ...el }
        ),
      },
    }));
  };

  const handleChangeLogoPosition = (value: any) => {
    setData((prev) => ({
      ...prev,
      logo: {
        ...prev.logo,
        position: value,
      },
    }));
  };

  const renderSettings = (input) => (
    <div
      className="flex gap-y-2 absolute p-2 rounded-md z-50 w-auto px-4"
      style={themesSetting?.card}
      contentEditable={isEdit}
    >
      <div className="flex flex-col gap-y-4 mr-2 ">
        <div className="text-xs">Width:</div>
        <div className="text-xs">Height:</div>
        <div className="text-nowrap text-xs">Font Size:</div>
        <div className="text-xs">Color:</div>
      </div>
      <div className="flex items-center flex-col gap-y-2">
        <div className="flex justify-center items-center text-xs">
          <input
            className={` bg-transparent py-0 border-none`}
            type="number"
            placeholder="....."
            min="50"
            max="300"
            value={input.width}
            onChange={(e) =>
              handleInputChange(input.id, "width", e.target.value)
            }
          />
          <p className="my-auto">px</p>
        </div>
        <div className="flex justify-center items-center mt-1 ">
          <input
            className=" w-full bg-transparent"
            type="number"
            placeholder="....."
            value={input.height}
            onChange={(e) =>
              handleInputChange(input.id, "height", e.target.value)
            }
          />
          <p className="my-auto">px</p>
        </div>
        <div className="flex justify-center items-center mt-1">
          <input
            className=" w-full bg-transparent"
            type="number"
            placeholder="....."
            value={input.font_size}
            onChange={(e) =>
              handleInputChange(input.id, "font_size", e.target.value)
            }
          />
          <p className="my-auto">px</p>
        </div>
        <div className="flex flex-col items-center gap-2 mb-2">
          <div className="flex justify-center items-center mt-1">
            <input
              className=" w-full bg-transparent"
              type="input"
              placeholder="....."
              value={input.color}
              // onChange={(e) => setInputColor(e.target.value)}
              onChange={(e) =>
                handleInputChange(input.id, "color", e.target.value)
              }
            />
          </div>
          <input
            className=" w-full"
            type="color"
            value={input.color}
            onChange={(e) =>
              handleInputChange(input.id, "color", e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full flex flex-col px-2">
      <div
        ref={data[type]?.containerRef}
        className="w-full max-w-[450px] sm:h-[250px] h-[230px] mx-auto rounded-3xl relative bg"
      >
        <img
          src={convertS3Link(data[type]?.background)}
          alt={`bank name`}
          className="w-full h-auto max-h-full object-cover absolute rounded-3xl"
        />
        {type === "front" && (
          <Draggable
            bounds="parent"
            position={data?.logo?.position}
            disabled={!isEdit}
            onDrag={(e, pos) =>
              handleChangeLogoPosition({ x: pos.x, y: pos.y })
            }
          >
            <img
              className="w-10 h-10 rounded object-cover"
              src={data?.logo?.src}
              alt="logo"
            />
          </Draggable>
        )}
        {data[type]?.inputList?.map((input) => (
          <Draggable
            key={input.id}
            bounds="parent"
            position={input.position}
            onStop={(e, data) =>
              handleInputChange(input.id, "position", { x: data.x, y: data.y })
            }
          >
            <div style={{ width: `${input.width}px` }} className="relative">
              <VInput
                disabled={!isEdit}
                inputStyle={{
                  fontSize: `${input.font_size}px`,
                  height: `${input.height}px`,
                  color: `${input.color}`,
                }}
                type="text"
                value={input.content}
                className={`ml-2 bg-transparent w-full py-0 mr-2 ${
                  !isEdit && "!border-none"
                }`}
                onChange={(e) =>
                  handleInputChange(input.id, "content", e.target.value)
                }
              />
              {isEdit && (
                <div className="absolute -right-14 top-1/2 -translate-y-1/2">
                  <div className="px-2 relative">
                    <VPopover
                      button={
                        <div className="flex items-center gap-2 cursor-pointer">
                          <MdOutlineSettings
                            style={themesSetting?.card}
                            className="p-1 w-full h-full rounded-full"
                          />
                          <div
                            className="rounded-full p-1 cursor-pointer"
                            style={themesSetting?.card}
                          >
                            <FiMinus
                              onClick={() => handleDeleteField(input.id)}
                            />
                          </div>
                        </div>
                      }
                    >
                      {renderSettings(input)}
                    </VPopover>
                  </div>
                </div>
              )}
            </div>
          </Draggable>
        ))}
        <div className="absolute top-1 right-2 flex">
          {isEdit && (
            <VButton
              className="w-6 h-6"
              radius="full"
              iconLeft={<IoMdAdd />}
              onClick={handleAddInput}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardItem;
