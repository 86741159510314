import qs from "query-string";
import { request, parseErrorResponse } from "./request";

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/ads-banner/all`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getByMenuId = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/ads-banner/getByMenuId/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AdsBannerService = {
  getAll,
  getByMenuId,
};

export default AdsBannerService;
