/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Title, Slide, Dot } from "../../../styles";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import APIs from "api";
import HistoryP2P from "../history-p2p";
import { MasterContext } from "context/MasterContext";

const titles = {
  0: "Select currency",
  1: "Select amount",
  2: "Select deposit methods",
  3: "Confirm deposit information",
  4: "Send and upload proof",
};

const View = () => {
  const { auth, stocks } = useContext(MasterContext);
  const { stock: stockParams } = useParams();
  const history = useHistory();

  const defaultData = {
    bank: null,
    amount: null,
    content: "",
    proof: "",
    currency: "",
    hash: null,
    network: "bsc",
    stock: stockParams,
  };

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [dataPayment, setDataPayment] = useState(defaultData);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [depositBanks, setDepositBanks] = useState([]);
  const [stock, setStock] = useState(null);

  useEffect(() => {
    if (stocks.length > 0) {
      setStock(stocks.find((c) => c.symbol === stockParams));
    }
  }, [stocks, stockParams]);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const backStep = () => {
    if (activeStep === 1) {
      history.push(`/my/wallets/${stockParams}/deposit/`);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const canclePayment = () => {
    setActiveStep(0);
    setDataPayment(defaultData);
  };

  const setDataByKey = (key, value) => {
    setDataPayment({
      ...dataPayment,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await APIs.PAYMENT.createPayment({
        body: {
          user_id: auth?._id,
          type: "deposit",
          bank: dataPayment.bank,
          amount: dataPayment.amount,
          content: dataPayment.content,
          proof: dataPayment.proof,
          currency: dataPayment.currency,
          hash: dataPayment.hash,
          stock: dataPayment.stock,
          network: dataPayment.network,
        },
      });
      setLoading(false);
      setActiveStep(1);
      getPayment();
      setDataPayment(defaultData);
      toast.success("Deposit successful!");
    } catch (err) {
      // handle error
      toast.error(err.message);
    }
    setLoading(false);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: auth?._id,
        type: "deposit",
        stockParams,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDepositBanks = async () => {
    const res: any = await APIs.SETTING.getSettingByName("banks");
    if (res && res?.setting) {
      setDepositBanks(res.setting?.value);
    }
  };

  useEffect(() => {
    if (auth) {
      getDepositBanks();
      getPayment();
    }
  }, [auth]);

  return (
    <>
      <Title bgColor={"grey200"} just="center" direction="column">
        <p>{titles[activeStep]}</p>
        <Slide>
          <Dot isActive={activeStep === 1 ? true : false} />
          <Dot isActive={activeStep === 2 ? true : false} />
          <Dot isActive={activeStep === 3 ? true : false} />
          <Dot isActive={activeStep === 4 ? true : false} />
        </Slide>
      </Title>

      {activeStep === 1 && (
        <Step1
          dataPayment={dataPayment}
          stock={stock}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          backStep={backStep}
        />
      )}
      {activeStep === 2 && (
        <Step2
          dataPayment={dataPayment}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          depositBanks={depositBanks}
          backStep={backStep}
        />
      )}
      {activeStep === 3 && (
        <Step3
          nextStep={nextStep}
          dataPayment={dataPayment}
          setDataByKey={setDataByKey}
          backStep={backStep}
        />
      )}
      {activeStep === 4 && (
        <Step4
          canclePayment={canclePayment}
          setDataByKey={setDataByKey}
          dataPayment={dataPayment}
          handleSubmit={handleSubmit}
          loading={loading}
          backStep={backStep}
        />
      )}

      {hisDeposit.length ? (
        <HistoryP2P lists={hisDeposit} type="deposit" stock={stockParams} />
      ) : (
        <></>
      )}
    </>
  );
};

export default View;
