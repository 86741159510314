import styled from "styled-components";

const Container = styled.div``;

export const CenterAbsolute = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    background-color: #0000003f;
`;

export const BankDepositUploadDragger = styled.label<{height, width, errUpload, padding?: any}>`
    width: ${props => props.width};
    height: ${props => props.height || '100%'};
    border: 1px dashed ${props => props.errUpload ? props.theme.colors.red100 : props.theme.colors.grey200};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    color: white;
    padding: ${props => props.padding || "10px"};
    font-size: 14px;
    position: relative;

    img {
        max-width: 100% !important;
        height: auto !important;
    }

`;

export const Ratio = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5px;
    left: 5px;
    opacity: 0;
    font-size: 10px;
    &:hover {
        opacity: 1;
    }
`;

export default Container