import { ROUTES } from "config/routes";
import { Route, Switch } from "react-router-dom";
import View from "./View";
import Profit from "./components/profit-ibo"
import Rounds from "./components/rounds";

const IBO = () => {
  return <Switch>
    <Route path={ROUTES.PROFIT} component={Profit} />
    <Route path={ROUTES.ROUND} component={Rounds} />
    <Route path={ROUTES.MY_IBO} component={View} />
  </Switch>
}

export default IBO;
