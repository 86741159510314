import Marquee from "react-fast-marquee";
import Market from "layouts/Markets/index";
import { isMobile } from "react-device-detect";
import { useContext, useMemo } from "react";
import { MasterContext } from "context/MasterContext";

export default function View() {
  const { stocks } = useContext(MasterContext);

  const data = useMemo(() => {
    return (stocks || []).filter((item) => item.allow_show?.includes("top"));
  }, [stocks]);

  return (
    <Marquee speed={isMobile ? 25 : 50} gradient={false}>
      <div className="flex justify-center items-center gap-x-5 px-2 py-2">
        {data.map((item) => (
          <Market token={item.symbol} />
        ))}
      </div>
    </Marquee>
  );
}
