import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 100px;
`;

export const Container = styled.div`
  position: relative;
  background-image: url("/assets/images/certificate_hold_stocks.png");
  background-size: cover;

  height: 630px;
  width: 900px;
  margin: auto;
  color: black;
  img {
    width: auto;
    max-width: 100%;
  }
`;

export const Data = styled.div`
  position: absolute;
  width: 500px;
  height: auto;
  color: black;
  left: 272px;
  top: 171px;
  font-size: 20px;
  font-weight: 400;
  p:nth-child(1) {
  }
  p:nth-child(2) {
    margin-left: 11px;
    margin-top: -11px;
  }
  p:nth-child(3) {
    margin-left: -20px;
    margin-top: -11px;
  }
  p:nth-child(4) {
    margin-left: 29px;
    margin-top: -9px;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const Certificate = styled.div`
  img {
    position: absolute;
    width: 100px;
    bottom: 20px;
  }
  img:nth-child(1) {
    right: 40px;
    top: 30px;
  }
  img:nth-child(2) {
    left: 25px;
  }
  img:nth-child(3) {
    left: 45%;
  }
  img:nth-child(4) {
    right: 25px;
  }
`;
