import React, { useState } from "react";
import { useParams } from "react-router-dom";
import APIs from "api";
import UploadDocs from "../upload/UploadDocs";
import FundamentalItems from "./FundamentalItems";

type IProps = {
  data?: any;
  setData?: (e: any) => void;
  isEdit?: boolean;
};

const Fundamentals = (props: IProps) => {
  const { data, setData, isEdit } = props;
  const params = useParams();
  const [image, setImage] = useState(null);

  const setFile = (f: any, id: string) => {
    setImage((pre) => ({
      ...pre,
      [id]: f,
    }));
  };

  // Modal
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmitModal = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("folderPath", `landing-page/${params.symbol}`);

    formData.append("files", image?.file);
    const listImg: any = await APIs.UPLOAD.uploadMultiFile({
      body: formData,
    });
    listImg.forEach((el) =>
      setData((prev) => ({
        ...prev,
        fundamentals: {
          ...prev.fundamentals,
          image: el.filePath,
        },
      }))
    );
    setIsLoading(false);
    setOpenModal(false);
  };

  return (
    <>
      <div className="w-full lg:h-[300px] flex gap-3 max-lg:flex-col">
        <div className="w-1/3 max-lg:hidden flex flex-col items-center gap-y-2">
          <FundamentalItems
            type="leftItems"
            isEdit={isEdit}
            data={data}
            setData={setData}
          />
        </div>
        <div className="w-1/3 max-lg:w-full lg:h-full h-[300px]">
          <UploadDocs
            isLoading={isLoading}
            data={data?.fundamentals}
            isEdit={isEdit}
            openModal={openModal}
            file={image?.file}
            setOpenModal={setOpenModal}
            setFile={setFile}
            onSubmit={onSubmitModal}
            srcImg={data?.fundamentals?.image}
          />
        </div>
        <div className="w-1/3 max-lg:hidden flex flex-col items-center gap-y-2">
          <FundamentalItems
            type="rightItems"
            isEdit={isEdit}
            data={data}
            setData={setData}
          />
        </div>
        <div className="w-full lg:hidden flex flex-col gap-y-2">
          <FundamentalItems
            type="leftItems"
            isEdit={isEdit}
            data={data}
            setData={setData}
          />
          <FundamentalItems
            type="rightItems"
            isEdit={isEdit}
            data={data}
            setData={setData}
          />
        </div>
      </div>
    </>
  );
};

export default Fundamentals;
