import styled from "styled-components";

const Container = styled.div<{isDownLoad?: boolean}>`
  width: 100%;
  max-width: 700px;
  position: ${props => props.isDownLoad ? "absolute" : "relative"};
  z-index: ${props => props.isDownLoad ? "-10" : "0"};
`;

export const Logo = styled.div<{type: string}>`
  position: absolute;
  top: ${props => props.type === 'investsSuccess' ? '3.4%' : props.type === 'buyIdo' ? '4.1%' : '4.1%'};
  right: ${props => props.type === 'investsSuccess' ? '4%' : props.type === 'buyIdo' ? '2.64%' : '2.64%'};
  height: 9.6%;
  width: 9.6%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 100%;
  }
`;

export const AvataUser = styled.div<{type: string}>`
  position: absolute;
  top: ${props => props.type === 'investsSuccess' ? '17%' : props.type === 'buyIdo' ? '17%' : '17%'};
  left: ${props => props.type === 'investsSuccess' ? '33.8%' : props.type === 'buyIdo' ? '34.5%' : '33.8%'};
  
  height: ${props => props.type === 'investsSuccess' ? '31.5%' : props.type === 'buyIdo' ? '31.5%' : '31.5%'};
  width: ${props => props.type === 'investsSuccess' ? '31.5%' : props.type === 'buyIdo' ? '31.5%' : '31.5%'};


  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70%;
    height: auto;
  }
`;

export const LogoLevel = styled.div<{type: string}>`
  position: absolute;
  z-index: 2;
  /* top: ${props => props.type === 'investsSuccess' ? '42.7%' : props.type === 'buyIdo' ? '43.5%' : '43.5%'};
  left: ${props => props.type === 'investsSuccess' ? '54.9%' : props.type === 'buyIdo' ? '44.8%' : '44.8%'};
  height: ${props => props.type === 'investsSuccess' ? '8%' : props.type === 'buyIdo' ? '9.5%' : '9.5%'};
  width: ${props => props.type === 'investsSuccess' ? '8%' : props.type === 'buyIdo' ? '9.5%' : '9.5%'}; */
  top: 35%;
  left: 54.9%;
  height: 10%;
  width: 10%;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 100%;
  }
`;

export const NameUser = styled.div<{type: string}>`
  position: absolute;
  bottom: ${props => props.type === 'investsSuccess' ? '42%' : props.type === 'buyIdo' ? '41%' : '41%'};
  width: 100%;
  height: 5%;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const AmountInvests = styled.div<{type: string}>`
  position: absolute;
  bottom: ${props => props.type === 'investsSuccess' ? '24.7%' : props.type === 'buyIdo' ? '26.7%' : '26.7%'};
  width: 100%;
  height: 5%;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  padding: 3px 0px;

  ._container_amount {
    /* background-color: #523c13db; */
    padding: 0px 6px;
    border-radius: 12px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 700px) {
    font-size: 25px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const QRInvests = styled.div<{type: string}>`
  left: ${props => props.type === 'investsSuccess' ? '4%' : props.type === 'buyIdo' ? '6.6%' : '6.6%'};
  width: 100px;
  height: 100px;

  position: absolute;
  bottom: ${props => props.type === 'investsSuccess' ? '11.2%' : props.type === 'buyIdo' ? '11.2%' : '15%'};
  overflow: hidden;
  padding: 3px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 700px) {
    width: 14%;
    height: 14%;
  }
`;

export const DateActive = styled.div`
  position: absolute;
  bottom: 7px;
  left: 15px;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
    left: 10px;
    bottom: 5px;
  }
  @media screen and (max-width: 500px) {
    font-size: 10px;
    left: 10px;
    bottom: 3px;
  }
`;

export default Container;