import APIs from "api";
import VModal from "components/v-core/v-modal";
import VBanner from "components/v-banner";
import VButton from "components/v-core/v-button";
import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";
import VUploadFile from "components/v-upload";
import Icons from "helper/icons";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

type IProps = {
  field?: string;
  isEdit?: boolean;
  data?: any;
  setData?: (e: any) => void;
};

const AdsBanner = (props: IProps) => {
  const { data, field, setData, isEdit } = props;

  const params: any = useParams();

  const [openModal, setOpenModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [adsSelected, setAdsSelected] = useState(null);
  const [link, setLink] = useState(adsSelected?.link);
  const [adsType, setAdsType] = useState(adsSelected?.type || "video");
  const [images, setImages] = useState(null);

  const handleAddAds = (type: string) => {
    setData((prev) => ({
      ...prev,
      banner: {
        ...prev.banner,
        advertisement_banner: {
          ...prev.banner.advertisement_banner,
          [type]: prev.banner.advertisement_banner[type].concat({
            id: uuidv4(),
            type: "video" || "facebook" || "image",
            link: "",
            src: "",
          }),
        },
      },
    }));
  };

  const handleUpLoad = (ads: any) => {
    setAdsSelected(ads);
    setOpenModal(field);
  };

  const handleChangeType = (type: string) => {
    setAdsType(type);
  };

  const setFile = (f: any, id: string) => {
    setImages((prev) => ({
      ...prev,
      file: f,
      isNew: true,
    }));
  };

  const onChangeLink = (value: string) => {
    setLink(value);
  };

  const handleRemove = (id: string) => {
    const _ads = data.banner.advertisement_banner[field].filter(
      (el) => el.id !== id
    );
    setData((prev) => ({
      ...prev,
      banner: {
        ...prev.banner,
        advertisement_banner: {
          ...prev.banner.advertisement_banner,
          [field]: _ads,
        },
      },
    }));
  };

  const onSubmitModal = async () => {
    setIsLoading(true);
    if (adsType === "image") {
      const formData = new FormData();
      formData.append("folderPath", `landing-page/${params.symbol}`);

      formData.append("files", images.file);
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      const img = listImg.find((el) => {
        return el;
      });
      setData((prev) => ({
        ...prev,
        banner: {
          ...prev.banner,
          advertisement_banner: {
            ...prev.banner.advertisement_banner,
            [field]: prev.banner.advertisement_banner[field].map((el) =>
              el.id === adsSelected.id
                ? {
                    ...el,
                    type: "image",
                    src: img.filePath,
                  }
                : { ...el }
            ),
          },
        },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        banner: {
          ...prev.banner,
          advertisement_banner: {
            ...prev.banner.advertisement_banner,
            [field]: prev.banner.advertisement_banner[field].map((el) =>
              el.id === adsSelected.id
                ? {
                    ...el,
                    type: adsType,
                    link: link,
                  }
                : { ...el }
            ),
          },
        },
      }));
    }
    setIsLoading(false);
    setOpenModal("");
  };

  const renderUploadImage = () => {
    return (
      <div className="relative w-full h-full rounded-md">
        <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
          <Icons iconName="add_image" />
          <span className="text-sm">Upload image</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {data?.banner?.advertisement_banner[field]?.map((el) => (
        <div
          className={`${
            isEdit && "border"
          } relative w-full max-lg:min-w-[150px] max-lg:h-[125px] h-full rounded`}
        >
          <VBanner data={el} isFull={true} classVideoName="h-full" />
          <div className="absolute-center">
            {isEdit && (
              <div className="flex items-center gap-x-2">
                <VButton radius="full" onClick={() => handleUpLoad(el)}>
                  Upload
                </VButton>
              </div>
            )}
          </div>
          {isEdit && (
            <div
              className="absolute top-0 right-0"
              onClick={() => handleRemove(el.id)}
            >
              <VButton className="w-6 h-6">
                <span className="text-red">-</span>
              </VButton>
            </div>
          )}
        </div>
      ))}
      {isEdit && data?.banner?.advertisement_banner[field]?.length < 3 && (
        <VButton
          className="w-10 h-10"
          radius="full"
          onClick={() => handleAddAds(field)}
        >
          +
        </VButton>
      )}
      <VModal
        title={`Upload`}
        isOpen={openModal !== ""}
        onCancel={() => setOpenModal("")}
        onConfirm={onSubmitModal}
        loading={isLoading}
      >
        <div className="flex flex-col items-center min-h-64 gap-y-5">
          <div className="w-full">
            <VDropdown
              options={[
                {
                  label: "Video",
                  value: "video",
                },
                {
                  label: "Facebook",
                  value: "facebook",
                },
                {
                  label: "Image",
                  value: "image",
                },
              ]}
              value={adsType}
              onChange={handleChangeType}
            />
          </div>
          <div className="w-full flex justify-center">
            {adsType === "video" || adsType === "facebook" ? (
              <div className="w-full">
                <VInput
                  placeholder={`Link ${adsType}...`}
                  value={link}
                  onChange={(e) => onChangeLink(e.target.value)}
                />
              </div>
            ) : (
              <div className="w-36 h-36 rounded">
                <VUploadFile
                  id={"file"}
                  file={images?.file}
                  src={adsSelected?.src}
                  setFile={setFile}
                  children={renderUploadImage()}
                  radius="md"
                />
              </div>
            )}
          </div>
        </div>
      </VModal>
    </>
  );
};

export default AdsBanner;
