/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { useParams } from "react-router-dom";
import { Content, DetailRightView, Title } from "../../styles";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Available,
  HistoryTitle,
  HistoryTransfer,
  SpanColor,
  Stock,
  StockWrapper,
  Submit,
  TransferWrapper,
} from "./styles";
import { formatNumber } from "utils/common";
import APIs from "api";
import { toast } from "react-toastify";
import { renderAmount, renderStatus } from "utils/render";
import { MasterContext } from "context/MasterContext";
import VInput from "components/v-core/v-input";
import VButton from "components/v-core/v-button";
import VTable from "components/v-core/v-table";

const View = () => {
  const { auth, stocks, balances } = useContext(MasterContext);

  const params = useParams();
  const { stock } = params;

  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const stockImg = useMemo(() => {
    return stocks?.find((item) => stock === item.symbol);
  }, [stock, stocks]);

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: () => <span>Balances</span>,
    },
    {
      title: "To",
      dataIndex: "To",
      key: "to",
      render: () => <span>Trade Balances</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <SpanColor color="orange100">{renderAmount(-data.amount)}</SpanColor>
      ),
    },
    {
      title: "Amount Locked",
      dataIndex: "amount_locked",
      key: "amount_locked",
      render: (data: any) => (
        <SpanColor color="orange100">
          {renderAmount(data.amountLocked)}
        </SpanColor>
      ),
    },
    {
      title: "Amount Received",
      dataIndex: "amount_received",
      key: "amount_locked",
      render: (data: any) => (
        <SpanColor color="orange100">
          {renderAmount(data.amountReceived)}
        </SpanColor>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => <span>{renderStatus(data.status)}</span>,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
  ];

  const handleTransferBalances = () => {
    setLoading(true);
    APIs.USER.swapToTradeBalances({
      user_id: auth?._id,
      amount,
      token: stock,
    })
      .then((res) => {
        if (res) {
          toast.success("Transfer success!");
          setAmount(0);
          getHistory();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const getHistory = async () => {
    try {
      const res: any = await APIs.TRANSACTION.getTransactions({
        query: {
          stock,
          type: "transfer_to_trade",
        },
      });
      setHistory(res?.transactions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setAmount(0);
  }, [stock]);

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <TransferWrapper>
      <DetailRightView>
        <Title bgColor={"grey200"}>
          Transfer {stock.toUpperCase()} to Trade Balance
        </Title>
        <Content>
          <StockWrapper>
            <Stock>
              <img
                src={stockImg?.logo || "/assets/images/coins/dollar.png"}
                alt=""
              />
              <p>{stock.toUpperCase()}</p>
            </Stock>
            <Available>
              <p>Available:</p>
              <p>
                {formatNumber(balances[stock] || 0)} {stock.toUpperCase()}
              </p>
            </Available>
          </StockWrapper>
          <VInput
            transparent={true}
            className="!bg-transparent"
            label="Amount"
            type="number"
            placeholder="0"
            value={amount || ""}
            onChange={(e) => setAmount(+e.target.value)}
            suffixIcon={stock.toUpperCase()}
          />

          <Submit>
            <VButton
              loading={loading}
              onClick={handleTransferBalances}
              disabled={!amount}
              radius="full"
            >
              Submit
            </VButton>
          </Submit>
        </Content>
      </DetailRightView>
      {history.length > 0 && (
        <DetailRightView>
          <HistoryTitle bgColor={"grey200"}>History</HistoryTitle>
          <HistoryTransfer>
            <VTable columns={columns.filter((a) => a)} data={history}></VTable>
          </HistoryTransfer>
        </DetailRightView>
      )}
    </TransferWrapper>
  );
};

export default View;
