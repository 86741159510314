import { useContext, useState } from "react";
import { MasterContext } from "context/MasterContext";
import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import VButton from "components/v-core/v-button";
import { formatNumber } from "utils/common";

const View = () => {
  const { stocks, rate } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const defaultState = {
    crypto: "",
    amount: 0,
    price: 0,
    usd: 0,
    vnex: 0,
  };
  const [state, setState] = useState(defaultState);

  const handleCheck = () => {
    if (state.amount > 0 && state.price > 0) {
      const usd = state.amount * state.price;
      const vnex = (usd * rate) / 1000;
      setState({
        ...state,
        usd: usd,
        vnex: vnex,
      });
    }
  };

  const handleChange = (value, field) => {
    let amount = state.amount;
    let price = state.price;

    if (field === "amount") amount = value;
    else price = value;

    if (price >= 0 && amount >= 0) {
      const usd = amount * price;
      const vnex = (usd * rate) / 1000;
      setState({
        ...state,
        usd: usd,
        vnex: vnex,
        [field]: value,
      });
    }
  };

  const _stocks = stocks?.map((el) => ({
    ...el,
    icon: el?.logo,
    label: `${el?.name} (${String(el?.symbol).toUpperCase()})`,
    value: el?.symbol,
  }));

  return (
    <div className="flex flex-col gap-y-3 mt-4 p-3">
      <div
        className="flex flex-col justify-center items-center gap-y-3 p-3 rounded-md"
        style={themesSetting?.container}
      >
        <div className="flex gap-x-2 w-full">
          <div className="w-1/3 p-1 px-3 items-center border rounded-lg">
            <VDropdown
              label={<span className="text-xs opacity-60">Stock</span>}
              customOptionClassName="w-full"
              customClassName="bg-transparent  w-full"
              options={_stocks}
            />
          </div>
          <div className="w-1/3 flex px-3 items-center border rounded-lg">
            <VInput
              transparent={true}
              isCurrency={true}
              type="number"
              className="!bg-transparent !border-none"
              label={<span className="text-xs opacity-60">Amount</span>}
              onValueChange={(v) => handleChange(v, "amount")}
            />
          </div>
          <div className="w-1/3 flex px-3 items-center border rounded-lg">
            <VInput
              transparent={true}
              type="number"
              isCurrency={true}
              className="!bg-transparent !border-none"
              label={<span className="text-xs opacity-60">{`Price ($)`}</span>}
              onValueChange={(v) => handleChange(v, "price")}
            />
          </div>
        </div>
        <div>
          <VButton
            radius="full"
            className="!border-none !rounded-full"
            iconLeft={<Icons iconName="down" />}
          />
        </div>
        <div
          className="w-full flex justify-around items-center h-[72px] rounded-lg"
          style={themesSetting?.card}
        >
          <div className="flex flex-col gap-y-1 items-center justify-center text-center">
            <span className="text-xs opacity-60">Total USD</span>
            <span>{formatNumber(state?.usd)}</span>
          </div>
          <hr className="h-full border-l border-dashed justify-center" />
          <div className="flex flex-col gap-y-1 items-center">
            <span className="text-xs opacity-60">Total VNEX</span>
            <span>{formatNumber(state?.vnex)}</span>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <VButton className="w-1/2" radius="full" onClick={handleCheck}>
            Count
          </VButton>
        </div>
      </div>
    </div>
  );
};

export default View;
