/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatNumber, getBase64Image } from "utils/common";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import DownloadImg from "components/download";
import APIs from "api";
import { MasterContext } from "context/MasterContext";
import VTable from "components/v-core/v-table";

const Invoice = () => {
  const { getSettingInWebConfig } = useContext(MasterContext);
  // const app_name = getSettingInWebConfig("app_name");s
  const mail_support = getSettingInWebConfig("mail_support");

  const { stocks } = useContext(MasterContext);
  const { id, type } = useParams();
  const [transaction, setTransaction] = useState(null);
  const logo_invoice = getSettingInWebConfig("logo_invoice");
  const [stock, setStock] = useState(null);
  const history = useHistory();
  const imgRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const [logoInvoice, setLogoInvoice] = useState(null);

  const types = {
    deposit: "DEPOSITE",
    withdraw: "WITHDRAWAL",
    changes: "CHANGES",
  };

  const columns = [
    {
      key: "Logo",
      title: "Logo",
      render: (item: any) => <img className="w-16 h-16" src={logo} alt="" />,
    },
    {
      key: "From",
      title: "From",
      render: (item: any) => `${item ? item["from"] : "N/A"}`,
    },
    {
      key: "To",
      title: "To",
      render: (item: any) =>
        `${
          item
            ? item["to"].length > 15
              ? item["to"].slice(0, 15) + "..."
              : item["to"]
            : "N/A"
        }`,
    },
    {
      key: "amount",
      title: "Amount",
      render: (item: any) =>
        `${
          item
            ? formatNumber(item.amount) +
              "(" +
              String(item["token"]).toUpperCase() +
              ")"
            : "N/A"
        } `,
    },
    {
      key: "status",
      title: "Status",
      render: (item: any) => `${item ? item["status"] : "N/A"}`,
    },
  ];

  useEffect(() => {
    if (id && type) {
      APIs.TRANSACTION.getTransactionById({
        params: {
          id,
        },
      })
        .then((res: any) => {
          if (res && res.transaction) {
            setTransaction(res.transaction);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [history, id, type]);

  useEffect(() => {
    const getImages = async () => {
      if (stock) {
        const res = await getBase64Image(stock.logo);
        setLogo(res);
      }
    };
    getImages();
  }, [stock]);

  useEffect(() => {
    const getLogoInvoice = async () => {
      if (logo_invoice) {
        const res = await getBase64Image(logo_invoice);
        setLogoInvoice(res);
      }
    };
    getLogoInvoice();
  }, [logo_invoice]);
  useEffect(() => {
    if (stocks && transaction) {
      setStock(stocks?.find((c) => c.symbol === transaction.token));
    }
  }, [stocks, transaction]);

  return (
    <div className="overflow-auto h-[100vh]">
      <div className="w-[600px] relative mx-auto border-1 p-3" ref={imgRef}>
        {transaction && (
          <>
            <div className="flex flex-col">
              <div className="w-full flex justify-between items-center">
                <div className="flex flex-col gap-y-2">
                  <p className="text-2xl mb-0 font-semibold">
                    Invoice #{" "}
                    <span className="text-[#007AFF]">
                      {new Date(transaction.createdAt).getTime()}
                    </span>
                  </p>
                  <div>
                    <p className="text-sm text-[#636363] mb-0">
                      Invoice to buy shares of{" "}
                      <span> {stock ? stock.name : "N/A"}</span> project
                    </p>
                    <h3 className="text-base font-semibold">
                      {types[type]} - INVOICE{" "}
                    </h3>
                  </div>
                </div>
                <div className=" w-20 h-20">
                  <img src={logo} alt=""></img>
                </div>
              </div>
              <hr className="w-full border-b border-dashed" />
              <div className="flex flex-col gap-y-2">
                <div className="w-full flex gap-x-1 mb-2">
                  <div className="w-1/2 flex flex-col gap-y-1">
                    <span className="text-sm font-normal text-[#636363]">
                      Invoice To:
                    </span>
                    <span className="text-sm font-semibold">
                      {transaction.user_id && transaction.user_id.email}
                    </span>
                  </div>
                  <div className="w-1/2 flex flex-col gap-y-1">
                    <span className="text-sm font-normal text-[#636363]">
                      Date:
                    </span>
                    <span className="text-sm font-semibold">
                      {new Date(transaction.createdAt).toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-y-1">
                  <VTable
                    color={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                    className="text-black"
                    columns={columns}
                    data={[transaction]}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex gap-x-2 justify-end items-center">
                  <div className="flex flex-col items-end">
                    <span className="text-sm py-[2px] text-[#636363]">
                      Total Balance Before :
                    </span>
                    <span className="text-sm py-[2px] text-[#636363]">
                      Total Balance After :
                    </span>
                    <span className="text-sm py-[2px] text-[#636363]">
                      Sub Total:
                    </span>
                    <span className="text-sm py-[2px] text-[#636363]">
                      Tax:
                    </span>
                  </div>
                  <div className="flex flex-col item-end">
                    <div className="flex items-center justify-end gap-x-1">
                      <span className="text-base font-semibold">
                        {(transaction.invoice &&
                          transaction.invoice.balanceBefore[
                            transaction.token
                          ]) ||
                          0}{" "}
                        ({String(transaction.token).toUpperCase()})
                      </span>
                    </div>
                    <div className="flex items-center justify-end gap-x-1">
                      <span className="text-base font-semibold">
                        {transaction.invoice &&
                          transaction.invoice.balanceAfter[
                            transaction.token
                          ]}{" "}
                        ({String(transaction.token).toUpperCase()})
                      </span>
                    </div>
                    <div className="flex items-center justify-end gap-x-1">
                      <span className="text-base font-semibold">
                        {`${formatNumber(transaction.amount)} (${
                          transaction
                            ? String(transaction.token).toUpperCase()
                            : "N/A"
                        })`}
                      </span>
                    </div>
                    <div className="flex items-center justify-end gap-x-1">
                      <span className="text-base font-semibold">
                        {transaction.fee.amount} (
                        {String(transaction.fee.symbol).toUpperCase()})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-base font-semibold">Payment Info</div>
                <div className="w-full flex gap-x-2 justify-end items-center">
                  <div className="w-1/2 flex gap-2 items-center">
                    <span className="text-sm font-normal text-[#636363]">
                      Account:
                    </span>
                    <span className="text-base font-semibold">
                      {transaction.user_id._id.slice(10)}...
                    </span>
                  </div>
                  <div className="w-1/2 flex gap-2 items-center item-end">
                    <span className="text-sm font-normal text-[#636363] ">
                      e.NFT:
                    </span>
                    <span className="text-base font-semibold mb-[0.5px]">
                      {String(transaction.token).toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
              <hr className="w-full border-b border-dashed" />
              <div className="flex flex-col items-center mb-3">
                <div className="text-base font-semibold">
                  Thank you for trusting us!
                </div>
                <p className="flex text-sm !mb-0 text-[#636363]">
                  For any queries related to this document please contact
                  us:&nbsp;
                  <p className="text-sm font-semibold text-[#007AFF] mb-0">
                    {mail_support}
                  </p>
                </p>
                {/* <p className="text-sm mb-0 text-[#636363]">
                Address :
                {estock
                  ? estock.userInfo.address
                  : " No. 6 Lot A12 Le Trong Tan KDT - Geleximco, An Khanh Ward, Hoai Duc District, Hanoi City, Vietnam."}
              </p> */}
                <div className="mt-2 w-52 h-10">
                  <img src={logoInvoice} alt="" className="w-full h-full " />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {transaction && (
        <div className="flex justify-center items-center mt-3">
          <DownloadImg
            imgRef={imgRef}
            fileName={`invoice_${type}_${transaction.token}`}
          />
        </div>
      )}
    </div>
  );
};

export default Invoice;
