/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "context/ThemeContext";
import { copyText } from "utils/common";
import { toast } from "react-toastify";
import { MasterContext } from "context/MasterContext";
import { convertDateToAgo } from "utils/times";
import { isMobile } from "react-device-detect";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
import APIs from "api";
import { ROUTES } from "config/routes";
import { useHistory } from "react-router-dom";
import VPopover from "components/v-popover";
import VTabs from "components/v-core/v-tabs";
import VModal from "components/v-core/v-modal";

export default function View() {
  const { auth } = useContext(MasterContext);
  const { handleSwitchTheme, theme } = useContext(ThemeContext);
  const { themesSetting } = useContext(ThemeContext);
  const { setAuth } = useContext(MasterContext);
  const history = useHistory();
  const [openMenu] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notifications, setNotifications] = useState({
    docs: [],
    access: false,
  });
  const [notificationsFilter, setNotificationsFilter] = useState([]);
  const [notificationSelected, setNotificationSelected] = useState(null);

  // const [showMenu, setShowMenu] = useState(false);
  const logoutUser = () => {
    setAuth(null);
    localStorage.clear();
    history.push(ROUTES.LOGIN);
  };
  const handProfile = () => {
    history.push(ROUTES.MY_PROFILE);
  };

  const handleCopy = (tx: string) => {
    copyText(tx);
    toast.success("Copied to clipboard!");
  };
  // const [show, setShow] = useState(false);

  const listNotification = [
    {
      name: "System",
      value: "system",
    },
    {
      name: "Notifications",
      value: "client",
    },
  ];
  const [activeTab, setActiveTab] = useState(listNotification[0].value);
  const [amountNot, setAmountNot] = useState(0);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (activeTab && notifications) {
      const _notifications = notifications.docs?.filter(
        (el) => el?.type === activeTab
      );
      setNotificationsFilter(_notifications);
    }
  }, [activeTab, notifications]);

  const getNotifications = async () => {
    try {
      const res: any = await APIs.NOTIFICATION.getNotificationsByUser();
      if (res && res?.notifications) {
        setNotifications((prev) => ({
          ...prev,
          docs: res.notifications,
          access: true,
        }));

        setAmountNot(res.amount_unread);
      }
    } catch (error) {}
  };

  const handleRead = async (notify) => {
    try {
      window.location.replace(notify.link);
      await APIs.NOTIFICATION.readNotification({
        params: {
          id: notify._id,
        },
      });
      getNotifications();
    } catch (error) {}
  };

  const handleChangeTabs = (tab: string) => {
    setActiveTab(tab);
  };

  const handleDetailNotification = async (notify: any) => {
    try {
      setOpenModal(true);
      setNotificationSelected(notify);
      await APIs.NOTIFICATION.readNotification({
        params: {
          id: notify._id,
        },
      });
      getNotifications();
    } catch (error) {}
  };

  const onSubmitModal = (notify: any) => {
    window.location.replace(notify.link);
  };

  // const handleShow = () => {
  //   setShow(!show);
  // };
  // const handleMenu = () => {
  //   setShowMenu(!showMenu);
  // };

  return (
    <div className={` flex items-center justify-end ${!isMobile && "pl-3"}`}>
      <VPopover
        button={
          <div
            className={`relative flex cursor-pointer items-center justify-center mr-3 ${
              isMobile ? "w-6 h-6" : "w-10 h-10"
            } rounded-full relative `}
            // onClick={handleShow}
            style={themesSetting?.card}
          >
            <VButton
              type="outline"
              radius="full"
              className="!border-none !w-full !h-full"
              iconLeft={<Icons iconName="bell" />}
            />
            {amountNot > 0 && (
              <div className="absolute -top-1 -right-1 w-5 h-5 flex justify-center items-center rounded-full bg-red-500">
                <span className="text-[10px] font-bold text-white">
                  {amountNot <= 99 ? amountNot : "99+"}
                </span>
              </div>
            )}
          </div>
        }
      >
        <div
          className="absolute flex top-0 right-2 rounded-md max-h-[500px] min-h-0 overflow-auto z-20"
          style={themesSetting?.card}
        >
          <div className="py-2 w-[300px] md:w-[400px]">
            <div className="flex items-center gap-x-3 py-3 px-2">
              <VTabs
                tabs={listNotification}
                tabActive={activeTab}
                onChangeTabs={handleChangeTabs}
                type="button"
              />
            </div>
            <div className="flex flex-col gap-y-2 py-2">
              {notificationsFilter &&
                notificationsFilter?.map((notice) => (
                  <div
                    className={`relative grid w-full px-2 z-20`}
                    style={themesSetting.card}
                  >
                    <div
                      className={`${
                        notice?.user_reads?.includes(auth._id) && "opacity-60"
                      } row-span-1 w-full flex gap-2
                            py-1`}
                      onClick={() => handleRead(notice)}
                    >
                      <div className="w-full flex gap-2 cursor-pointer">
                        <img
                          src={notice.icon}
                          alt=""
                          className="w-6 h-6 md:w-8 md:h-8"
                        />
                        <div className="w-[200px] md:w-[280px]">
                          <h6 className="truncate text-xs md:text-sm">
                            {notice.title}
                          </h6>
                          <p className="truncate md:text-sm text-xs ">
                            {notice.content}
                          </p>
                        </div>
                        <div>
                          <div className="text-[10px] whitespace-nowrap text-gray-400 ">
                            {convertDateToAgo(notice.createdAt)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute right-3 bottom-2 z-30">
                      <VButton
                        type="outline"
                        className="!border-none "
                        px="0"
                        py="0"
                        onClick={() => handleDetailNotification(notice)}
                      >
                        <div className="text-yellow-500 text-sm">View</div>
                      </VButton>
                    </div>
                  </div>
                ))}
              {/* {activeTab === "system" && (
                <div>
                  {notifications &&
                    notifications?.map((notice) => (
                      <div
                        className="grid w-full"
                        onClick={() => handleRead(notice)}
                      >
                        <div
                          className="row-span-1 w-full border-b-[1px] flex gap-2 border-gray-400
                            py-1"
                        >
                          <div className="w-full flex gap-2 cursor-pointer">
                            <img
                              src={notice.icon}
                              alt=""
                              className="w-6 h-6 md:w-8 md:h-8"
                            />
                            <div className="w-[200px] md:w-[280px]">
                              <h6
                                className="truncate text-xs md:text-sm
                              "
                              >
                                {notice.title}
                              </h6>
                              <p className="truncate md:text-sm text-xs ">
                                {notice.content}
                              </p>
                            </div>
                            <div>
                              <div className="text-[10px] whitespace-nowrap text-gray-400 ">
                                {convertDateToAgo(notice.createdAt)}
                              </div>
                              <div className="text-yellow-500 text-sm py-3">
                                View
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </VPopover>
      {/* <div className="flex border-2 cursor-pointer rounded-md  border-slate-700 items-center justify-center mr-3 w-10 h-full p-2 ">
        className={`flex cursor-pointer border-2 rounded-md border-slate-700 items-center justify-center mr-3 ${
          isMobile ? "w-7 p-1" : " w-10 p-2"
        } h-full `}
      >
        <img
          src="/assets/images/icons/notification.svg"
          className="w-full"
          alt=""
        />
      </div>
      <div
        className={`flex border-2 cursor-pointer rounded-md  border-slate-700 items-center justify-center mr-3 ${
          isMobile ? "w-7 p-1" : " w-10 p-2"
        } h-full  `}
      >
        <img src="/assets/images/icons/chat.svg" className="w-full" alt="" />
      </div> */}
      <div
        className={`flex cursor-pointer rounded-full ${
          isMobile ? "w-6 h-6" : "w-10 h-10"
        }  items-center justify-center mr-3 `}
        onClick={handleSwitchTheme}
        style={themesSetting.card}
      >
        {theme === "dark" ? (
          <VButton
            radius="full"
            className="!border-none !w-full !h-full"
            type="outline"
            iconLeft={<Icons iconName="dark" />}
          />
        ) : (
          <VButton
            radius="full"
            className="!border-none !w-full !h-full"
            type="outline"
            iconLeft={<Icons iconName="light" />}
          />
        )}
      </div>

      {!isMobile && (
        <div
          className="max-w-max flex items-center justify-between px-1 cursor-pointer rounded-md mr-4"
          style={themesSetting?.card}
        >
          <div className="flex items-center px-2">
            <div className="w-10">
              <img
                src={auth?.avatar || `/assets/images/avatar.png`}
                alt="avatar"
                className="object-fill rounded-full w-10 h-10 "
              />
            </div>
            <div className="pl-2 w-max">
              <div className="text-center font-medium whitespace-nowrap overflow-x-hidden w-full max-w-[100px] text-sm">
                {String(auth?.fullName || auth?.username)}
              </div>
              <div className="flex items-center gap-x-1">
                <div className="text-xs opacity-60">{auth?.refer_code}</div>
                <div className="w-5 h-5 flex justify-center items-center">
                  <div onClick={() => handleCopy(auth?.refer_code)}>
                    <Icons iconName="copy" />
                  </div>
                </div>
              </div>
            </div>
            <VPopover
              button={
                <VButton
                  type="outline"
                  className="!border-none"
                  iconLeft={<Icons iconName="dropdown" />}
                />
              }
              className="absolute -right-9"
            >
              <div
                className="w-full rounded shadow-lg z-10 "
                style={themesSetting.card}
              >
                <div
                  className="mt-0 flex items-center py-2 w-44 ml-2 rounded hover:opacity-80 filter-grayscale"
                  onClick={handProfile}
                >
                  <Icons iconName="profile" />
                  {openMenu && <span className="ml-3">Profile</span>}
                </div>
                <div
                  className="mt-0 flex items-center py-2 w-44 ml-2 rounded hover:opacity-80"
                  onClick={logoutUser}
                >
                  <Icons iconName="signOut" />
                  {openMenu && <span className="ml-[5px]">Sign out</span>}
                </div>
              </div>
            </VPopover>
          </div>
        </div>
      )}
      <VModal
        isOpen={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={() => onSubmitModal(notificationSelected)}
      >
        <div className="flex flex-col items-center gap-y-3">
          {notificationSelected?.banner && (
            <img
              className="w-full max-h-full object-cover"
              src={notificationSelected?.banner}
              alt=""
            />
          )}
          <div className="flex flex-col gap-y-1 items-center">
            <img
              className="w-12 max-h-full object-cover"
              src={notificationSelected?.icon}
              alt=""
            />
            <span>{notificationSelected?.title}</span>
            <span>{notificationSelected?.content}</span>
          </div>
          {(notificationSelected?.start_date ||
            notificationSelected?.end_date) && (
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center gap-x-2">
                <span className="w-[60px] text-xs">Start:</span>
                <span className="text-xs">
                  {notificationSelected?.start_date}
                </span>
              </div>
              <div className="flex items-center gap-x-2">
                <span className="w-[60px] text-xs">End:</span>
                <span className="text-xs">
                  {notificationSelected?.end_date}
                </span>
              </div>
            </div>
          )}
        </div>
      </VModal>
    </div>
  );
}
