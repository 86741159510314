/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { MasterContext } from "context/MasterContext";

interface IProps {
  [key: string]: any;
}

export default function Step1(props: IProps) {
  const { handleChangeDetail, setStep } = props;

  const { settings } = useContext(MasterContext);
  const { objects } = settings;

  const handleClick = (value) => {
    handleChangeDetail("object", value);
    setStep(2);
  };

  return (
    <>
      <p className="text-[14px] md:text-[16px] text-center">
        Blockchain technology platform building project to help students,
        individuals, organizations and businesses Create and share ideas to find
        investors using Blockchain technology
      </p>
      <div className="relative flex mt-[60px] justify-center flex-wrap gap-3">
        {objects?.map((ob: any) => (
          <div
            className="h-[150px] w-[120px] flex flex-col justify-center items-center cursor-pointer border rounded-md gap-y-2 p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 "
            onClick={() => handleClick(ob.name)}
          >
            <div className="w-full h-[70%] flex justify-center items-center">
              <img
                className="h-auto max-h-full w-full object-cover"
                src={ob.logo || "/assets/images/canhan.png"}
                alt=""
              />
            </div>
            <span className="font-semibold text-sm text-center">{ob.name}</span>
          </div>
        ))}
      </div>
    </>
  );
}
