/* eslint-disable react-hooks/exhaustive-deps */
import {
  TopMain,
  EndBorder,
  MiddleMain,
  BottomMain,
  ContainIcon,
  Facebook,
  Twitter,
  ContainButton,
  Content,
  // URLContract,
  // URL,
  Vdiarybook,
} from "./styles";
import { FormGroup } from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { MasterContext } from "context/MasterContext";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import VInput from "components/v-core/v-input";
import Icons from "helper/icons";
// import APIs from "api";

const View = () => {
  const { auth, settings } = useContext(MasterContext);

  const [state, setState] = useState({
    contact_link: "",
  });
  const { themesSetting } = useContext(ThemeContext);
  // const [commissions, setCommissions] = useState([]);
  // const [sharings, setSharings] = useState([]);

  const handleCopy = () => {
    navigator.clipboard.writeText(state.contact_link);
    toast.success("Copied to clipboard!");
  };

  // const getCommissions = async () => {
  //   try {
  //     const res: any = await APIs.USER.getCommissions({});
  //     if (res && res?.commissions) {
  //       setCommissions(res?.commissions);
  //     }
  //   } catch (error) {}
  // };

  // const getSharings = async () => {
  //   try {
  //     const res: any = await APIs.USER.getSharings({});
  //     if (res && res?.sharings) {
  //       setSharings(res?.sharings);
  //     }
  //   } catch (error) {}
  // };

  useEffect(() => {
    if (auth && auth?._id) {
      // getCommissions();
      // getSharings();
      setState({
        ...state,
        contact_link: `https://${window.location.host}/auth/register?refer_code=${auth?.refer_code}`,
      });
    }
  }, []);

  return (
    <div className="w-full mb-10">
      <Content style={themesSetting?.card} className="pb-1">
        <TopMain>
          {auth?.sponsor && (
            <div className="contain-user">
              <h4>Sponsor</h4>
              {/* <span>Username: {auth?.sponsor.username}</span> */}
              <span>Email: {auth?.sponsor.email}</span>
              <span>Refer code: {auth?.sponsor.refer_code}</span>
            </div>
          )}
          <h4>Invite Your Contact</h4>
          <div className="flex gap-2 ">
            <img alt="" src="/assets/images/icons/share.svg" />
            <span>Share this link to your contact</span>
          </div>
          <p>Clicking this button will copy the URL to the user's clipboard</p>
          <div className="flex mb-2">
            <VInput
              className="!bg-transparent"
              suffixClassName="right-3"
              transparent={true}
              value={state.contact_link}
              suffixIcon={
                <VButton className="" onClick={handleCopy}>
                  Copy URL
                </VButton>
              }
            />
          </div>
          <div className="qr-code-affilate">
            <QRCode
              value={`https://${window.location.host}/auth/register?refer_code=${auth?.refer_code}`}
              size={120}
            />
            <div className="icon_qr">
              <img
                style={{
                  width: `25px`,
                  height: `25px`,
                  margin: "auto",
                }}
                src={`${settings?.logos?.logoResponsive}`}
                alt=""
              />
            </div>
          </div>
        </TopMain>
        <EndBorder></EndBorder>
        <MiddleMain>
          <div className="flex gap-2">
            <img alt="" src="/assets/images/icons/share.svg" />
            <span className=" max-sm:hidden">Share your code on</span>
          </div>
          <ContainIcon>
            <Facebook className="flex gap-2">
              <img alt="" src="/assets/images/icons/facebook.svg" />
              <span className=" max-sm:hidden">Facebook</span>
            </Facebook>
            <Twitter className="flex gap-2">
              <img alt="" src="/assets/images/icons/twitter.svg" />
              <span className=" max-sm:hidden">Twitter</span>
            </Twitter>
            <Vdiarybook>
              <a
                className="flex gap-2"
                href="https://vdiarybook.com/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img alt="" src="/assets/images/social/vdiarybook.png" />
                <span className="max-sm:hidden">Vdiary Book</span>
              </a>
            </Vdiarybook>
          </ContainIcon>
        </MiddleMain>
        <EndBorder></EndBorder>
        <BottomMain>
          <div className="flex gap-2">
            <img alt="" src="/assets/images/icons/mail.svg" />
            <span>Invite your friends by your mail</span>
          </div>
          <p>Enter one email by line and click send.</p>
          <FormGroup>
            <VInput
              transparent={true}
              className="!bg-transparent"
              id="exampleText"
              name="text"
              type="textarea"
            />
          </FormGroup>
          <ContainButton>
            <VButton iconLeft={<Icons iconName="send" />} className="h-8">
              Send
            </VButton>
          </ContainButton>
        </BottomMain>
      </Content>

      {/* <History>
          <Title bgColor={"grey200"}>My Earning</Title>
          <Table
            columns={columns}
            dataSource={commissions}
            bgTable={"black100"}
            bgHeader={"black100"}
          ></Table>
  
          <Title bgColor={"grey200"}>My Sharing</Title>
          <Table
            columns={columns}
            dataSource={sharings}
            bgTable={"black100"}
            bgHeader={"black100"}
          ></Table>
        </History> */}
    </div>
  );
};

export default View;
