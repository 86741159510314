import VTable from "components/v-core/v-table";
import { MasterContext } from "context/MasterContext";
import { useContext } from "react";
import { renderStock } from "utils/render";

const columns = [
  {
    key: "name",
    title: "Name",
    render: (item: any) => (
      <div className="flex items-center gap-x-1">
        <img
          className="size-4 rounded-full object-cover"
          src={item.logo}
          alt="stock"
        />
        <span className="text-green-500">{renderStock(item.symbol)}</span>
      </div>
    ),
  },
  {
    key: "volume",
    title: "Volume",
    render: (item: any) => (
      <div className="flex items-center gap-x-1">
        {/* <img src="" alt="up" /> */}
        <span className="text-[12px]">Coming soon</span>
      </div>
    ),
  },
];

const Histories = () => {
  const { stocks } = useContext(MasterContext);

  return (
    <div className="h-[450px]">
      <VTable columns={columns} data={stocks} />
    </div>
  );
};

export default Histories;
