/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { Title } from "../../styles";
import { toast } from "react-toastify";

import History from "../history-vnd";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import APIs from "api";
import Step0 from "./Step0";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";

const titles = {
  0: "Select currency",
  1: "Select amount",
  2: "Select deposit methods",
  3: "Confirm deposit information",
  4: "Send and upload proof",
};

const DepositVND = () => {
  const { auth, stockDefault } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const defaultData = {
    bank: null,
    amount: null,
    content: "",
    proof: "",
    currency: "",
    hash: null,
    network: "bsc",
    stock: stockDefault,
  };
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [file, setFile] = useState();
  const [errUpload] = useState<boolean>(false);

  const [dataPayment, setDataPayment] = useState(defaultData);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [depositBanks, setDepositBanks] = useState([]);

  useEffect(() => {
    if (auth) {
      getDepositBanks();
      getPayment();
    }
  }, [auth]);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const canclePayment = () => {
    setActiveStep(0);
    setDataPayment(defaultData);
  };

  const setDataByKey = (key, value) => {
    setDataPayment({
      ...dataPayment,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let resImage;
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        resImage = await APIs.UPLOAD.uploadFile({
          body: formData,
        });
      }
      await APIs.PAYMENT.createPayment({
        body: {
          user_id: auth?._id,
          type: "deposit",
          bank: dataPayment.bank,
          amount: dataPayment.amount,
          content: dataPayment.content,
          proof: resImage.filePath,
          currency: dataPayment.currency,
          hash: dataPayment.hash,
          network: dataPayment.network,
          stock: stockDefault,
        },
      });
      setLoading(false);
      setActiveStep(0);
      getPayment();
      setDataPayment(defaultData);
      toast.success("Deposit successful!");
    } catch (err) {
      // handle error
      setLoading(false);
      return toast.error(err.errors);
    }
    setLoading(false);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: auth?._id,
        type: "deposit",
        stock: stockDefault,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDepositBanks = async () => {
    const res: any = await APIs.SETTING.getSettingByName("banks");
    if (res && res?.setting) {
      setDepositBanks(res.setting?.value);
    }
  };

  return (
    <div className="">
      <Title bgColor={"grey200"} just="center" direction="column">
        <p>{titles[activeStep]}</p>
      </Title>
      <div className="rounded-md" style={themesSetting?.container?.card}>
        {activeStep === 0 && (
          <Step0
            dataPayment={dataPayment}
            nextStep={nextStep}
            setDataByKey={setDataByKey}
            setDataPayment={setDataPayment}
          />
        )}

        {activeStep === 1 && (
          <Step1
            dataPayment={dataPayment}
            nextStep={nextStep}
            setDataByKey={setDataByKey}
            backStep={backStep}
          />
        )}
        {activeStep === 2 && (
          <Step2
            dataPayment={dataPayment}
            nextStep={nextStep}
            setDataByKey={setDataByKey}
            depositBanks={depositBanks}
            backStep={backStep}
          />
        )}
        {activeStep === 3 && (
          <Step3
            nextStep={nextStep}
            dataPayment={dataPayment}
            setDataByKey={setDataByKey}
            backStep={backStep}
          />
        )}
        {activeStep === 4 && (
          <Step4
            file={file}
            loading={loading}
            dataPayment={dataPayment}
            errUpload={errUpload}
            canclePayment={canclePayment}
            setDataByKey={setDataByKey}
            handleSubmit={handleSubmit}
            backStep={backStep}
            setFile={setFile}
          />
        )}
      </div>

      {hisDeposit.length ? (
        <History lists={hisDeposit} type="deposit" />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DepositVND;
