/* eslint-disable indent */
import { StepWrapper, TitleWrapper, VerificationWrapper } from "../styles";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import VButton from "components/v-core/v-button";
import { useContext, useEffect, useState } from "react";
import KYC from "./Kyc";
import Verify from "./Verify";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";

const Verification = () => {
  const { auth } = useContext(MasterContext);
  const defaultState = {
    status: "",
    note: "",
  };

  const [status, setStatus] = useState("init");
  const [percent, setPercent] = useState(50);
  const [stepCurrent, setStepCurrent] = useState(null);
  const [kyc, setKYC] = useState(defaultState);
  const { themesSetting } = useContext(ThemeContext);

  useEffect(() => {
    if (auth && auth.status_kyc && auth.status_kyc !== "declined") {
      setStatus(auth.status_kyc);
      if (auth.status_kyc === "verified") {
        setPercent(100);
      }
      if (
        auth.status_kyc === "kyc" ||
        auth.status_kyc === "pending_verified" ||
        auth.status_kyc === "declined_verified"
      ) {
        setPercent(75);
      }
    }
    setKYC(auth);
  }, [auth]);

  const handleClick = () => {
    setStepCurrent(status);
  };

  const getColorVerify = (type, status) => {
    if (type === "verify")
      switch (status) {
        case "pending_verified":
          return "yellow";
        case "verified":
          return "green";
        default:
          return "gray";
      }
    else
      switch (status) {
        case "pending_kyc":
          return "yellow";
        case "kyc":
          return "green";
        case "pending_verified":
          return "green";
        case "verified":
          return "green";
        case "declined_verified":
          return "green";
        default:
          return "gray";
      }
  };

  console.log({
    stepCurrent,
  });

  return (
    <VerificationWrapper
      style={themesSetting?.card}
      className="p-4 rounded-md w-full"
    >
      {stepCurrent === "init" && <KYC setStepCurrent={setStepCurrent} />}
      {(stepCurrent === "kyc" || stepCurrent === "declined_verified") && (
        <Verify setStepCurrent={setStepCurrent} />
      )}

      {!stepCurrent && (
        <>
          {" "}
          <TitleWrapper>
            <CircularProgressbar
              value={percent}
              text={`${percent}%`}
              styles={buildStyles({
                textColor: "#FFE600",
                pathColor: "#00AC11",
              })}
            />{" "}
            <h4>
              Complete the account verification steps to enhance the security of
              your wallet
            </h4>
          </TitleWrapper>
          <StepWrapper>
            <ul>
              <li className="flex gap-2">
                {" "}
                <img
                  className="w-5 h-5"
                  src={`/assets/images/kyc/verify-${getColorVerify(
                    "verify",
                    status
                  )}.png`}
                  alt=""
                />{" "}
                Account verification{" "}
                {status === "pending_verified" && <span>verifying</span>}
              </li>
              <li className="flex gap-2">
                {" "}
                <img
                  className="w-5 h-5"
                  src={`/assets/images/kyc/verify-${getColorVerify(
                    "kyc",
                    status
                  )}.png`}
                  alt=""
                />{" "}
                Account KYC {status === "pending_kyc" && <span>verifying</span>}
              </li>
              <li className="flex gap-2">
                {" "}
                <img
                  className="w-5 h-5"
                  src="/assets/images/kyc/verify-green.png"
                  alt=""
                />{" "}
                Confirm email address, phonenumber
              </li>
              <li className="flex gap-2">
                <img
                  className="w-5 h-5"
                  src="/assets/images/kyc/verify-green.png"
                  alt=""
                />{" "}
                Update personal information
              </li>
            </ul>
            {status !== "verified" && status !== "pending_verified" && (
              <VButton
                className="w-[200px]"
                radius="full"
                onClick={() => handleClick()}
                disabled={status === "pending_kyc"}
              >
                Establish
              </VButton>
            )}
            {(kyc?.status === "declined" ||
              kyc?.status === "declined_verified") &&
              kyc?.note && (
                <div className="note">
                  <BsFillInfoCircleFill color="yellow" />
                  Note: {kyc?.note}
                </div>
              )}
          </StepWrapper>
        </>
      )}
    </VerificationWrapper>
  );
};

export default Verification;
