import APIs from "api";
import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";
import { MasterContext } from "context/MasterContext";
import { useState, useContext, useRef, useEffect } from "react";

export type IAddress = {
  country?: string | null;
  province?: string | null;
  district?: string | null;
  ward?: string | null;
  countryName?: string | null;
  provinceName?: string | null;
  districtName?: string | null;
  wardName?: string | null;
  detail?: string | null;
};

type ILocation = {
  lat?: any;
  lng?: any;
};

type IProps = {
  address: IAddress;
  className?: string;
  type?: string;
  showSearch?: boolean;
  withMap?: boolean;
  defaultLocation?: any;
  showAddress?: boolean;
  setLocation?: (a: ILocation) => void;
  setAddress: (e: IAddress) => void;
};

const VLocation = (props: IProps) => {
  const {
    className,
    type,
    showAddress = true,
    address,
    setAddress,
  } = props;

  const { countries } = useContext(MasterContext);

  const [provinces, setProvinces] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);
  const firstLoad = useRef(false);

  useEffect(() => {
    if (!firstLoad.current && address) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, firstLoad.current]);

  useEffect(() => {
    setAddress({
      ...address,
      country: countries[0]?._id,
      countryName: countries[0]?.name,
    });
  }, []);

  const fetchData = async () => {
    if (address.country) {
      firstLoad.current = true;
      const _provinces = await getLocationsByParent(address.country);
      setProvinces(_provinces);
      if (address.province) {
        const _districts: any = await getLocationsByParent(address.province);
        setDistricts(_districts);
      }
      if (address.district) {
        const _wards: any = await getLocationsByParent(address.district);
        setWards(_wards);
      }
    }
  };

  const handleChange = (e: any, key: any) => {
    setAddress({
      ...address,
      [key]: e.target.value,
    });
  };

  const getLocationsByParent = async (parent: string) => {
    try {
      const locations = await APIs.SETTING.getLocations({
        query: {
          parent: parent,
        },
      });
      return locations;
    } catch (error) {
      return [];
    }
  };

  const setFilterCities = async (key: string, value: string) => {
    console.log({
      key,
      value,
    });
    const code = value;
    if (key === "country") {
      setDistricts([]);
      setWards([]);
      if (code) {
        const _provinces = await getLocationsByParent(code);
        if (_provinces) {
          setProvinces(_provinces);
          setAddress({
            country: code,
            countryName: countries?.find((e: any) => e?._id === code)?.name,
            province: null,
            district: null,
            ward: null,
          });
        } else {
          setAddress({
            country: null,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setAddress({
          country: null,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "province") {
      setWards([]);
      if (code) {
        const _districts = await getLocationsByParent(code);
        if (_districts) {
          setDistricts(_districts);
          setAddress({
            ...address,
            province: code,
            provinceName: provinces?.find((f: any) => f?._id === code)?.name,
            district: null,
            ward: null,
          });
        } else {
          setDistricts([]);
          setAddress({
            ...address,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setDistricts([]);
        setAddress({
          ...address,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "district") {
      if (code) {
        const _wards = await getLocationsByParent(code);
        setWards(_wards);
        setAddress({
          ...address,
          district: code,
          districtName: districts?.find((f: any) => f?._id === code)?.name,
          ward: null,
        });
      } else {
        setWards([]);
        setAddress({
          ...address,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "ward") {
      if (code) {
        setAddress({
          ...address,
          ward: code,
          wardName: wards?.find((f: any) => f?._id === code)?.name,
          detail: `${address.countryName}, ${address.provinceName}, ${
            address.districtName
          }, ${wards?.find((f: any) => f?._id === code)?.name} `,
        });
      } else {
        setAddress({
          ...address,
          ward: null,
        });
      }
    }
  };

  return (
    <>
      <div className={className || ""}>
        <div className={`country ${type === "event" ? "event" : ""}`}>
          <VDropdown
            label="Country"
            value={address.country}
            customClassName="bg-transparent border rounded"
            customOptionClassName="w-full"
            options={countries?.map((el: any) => {
              return {
                label: el?.name,
                value: String(el?._id),
              };
            })}
            onChange={(value) => setFilterCities("country", value)}
          />
        </div>
        <div className={`province ${type === "event" ? "event" : ""}`}>
          <VDropdown
            value={address.province}
            label="Province/City"
            customClassName="bg-transparent border rounded"
            customOptionClassName="w-full"
            options={provinces?.map((el: any) => {
              return {
                label: el?.name,
                value: String(el?._id),
              };
            })}
            onChange={(value) => setFilterCities("province", value)}
          />
        </div>
        <div className={`district ${type === "event" ? "event" : ""}`}>
          <VDropdown
            value={address.district}
            label="District"
            customClassName="bg-transparent border rounded"
            customOptionClassName="w-full"
            options={districts?.map((el: any) => {
              return {
                label: el?.name,
                value: String(el?._id),
              };
            })}
            onChange={(value) => setFilterCities("district", value)}
          />
        </div>
        <div className={`ward ${type === "event" ? "event" : ""}`}>
          <VDropdown
            value={address.ward}
            label="Ward"
            customClassName="bg-transparent border rounded"
            customOptionClassName="w-full"
            options={wards?.map((el: any) => {
              return {
                label: el?.name,
                value: String(el?._id),
              };
            })}
            onChange={(value) => setFilterCities("ward", value)}
          />
        </div>
        {showAddress && (
          <VInput
            transparent={true}
            placeholder="Enter your address line"
            className="w-full  px-2 border rounded-md bg-transparent"
            wrapperClassName="px-0"
            value={address?.detail || ""}
            label={"Detail Address"}
            onChange={(e: any) => handleChange(e, "detail")}
          />
        )}
      </div>
    </>
  );
};

export default VLocation;
