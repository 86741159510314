/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import APIs from "api";
import axios from "axios";
import ComingSoon from "components/coming-soon";
import VButton from "components/v-core/v-button";
import VTabs from "components/v-core/v-tabs";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";
import React, { useEffect, useRef, useState, useContext } from "react";
import { FaPlay } from "react-icons/fa";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { useHistory } from "react-router-dom";

let listTotal = [];
export const Social = () => {
  const tabs = [
    {
      name: "Canvanex",
      value: "canvanex",
      icon: <img src="/assets/images/social/canvanex.png" alt="" />,
    },
    {
      name: "Channel",
      value: "channel",
      icon: (
        <img
          src="/assets/images/social/youtubecircle.svg"
          alt=""
          className="object-cover"
        />
      ),
    },
    {
      name: "Facebook",
      value: "facebook",
      icon: (
        <img src="/assets/images/social/facebook.png" alt="" className="" />
      ),
    },
    {
      name: "Telegram",
      value: "telegram",
      icon: (
        <img
          src="/assets/images/social/telegramColor.png"
          alt=""
          className="w-full"
        />
      ),
    },
    {
      name: "Twiter",
      value: "twitter",
      icon: <img src="/assets/images/social/twitter.png" alt="" />,
    },
  ];

  const history = useHistory();
  const [clientHeight, setClientHeight] = useState(600);
  const [videos, setVideos] = useState([]);
  const [clientWidth, setClientWidth] = useState(500);
  const [frameWidth, setFrameWidth] = useState(500);
  const [id, setId] = useState();
  const [channels, setChannels] = useState([]);
  const { getSettingInWebConfig } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");
  const clientWidthRef = useRef(null);
  const frameWidthRef = useRef(null);
  const facebook = getSettingInWebConfig("facebook");
  const canvanex = getSettingInWebConfig("canvanex");
  const telegram = getSettingInWebConfig("telegram");
  const twitter = getSettingInWebConfig("twitter");

  useEffect(() => {
    const doc = document.getElementById("root");
    setClientHeight(doc.clientHeight);
    if (!currentTab) {
      history.push("?tab=canvanex");
    }
  }, [currentTab]);

  useEffect(() => {
    const getVideos = async () => {
      const _videos = await Promise.all(
        channels.map(async (item) => {
          const res = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${item.id}&key=AIzaSyBIcdVOywmTMvcJihZPZmaCdRsIsaKlnFo`
          );
          return res.data.items[0]
            ? {
                ...res.data.items[0]["snippet"],
                prioritized: item.prioritized,
                id: item.id,
              }
            : null;
        })
      );
      const listSort = _videos.filter((_) => _);
      listSort.sort((a, b) => (!a.prioritized && b.prioritized ? 1 : -1));
      let idStart = channels[0]?.id;
      setVideos(listSort.filter((item) => item.id !== idStart));
      listTotal = listSort;
    };
    if (channels && channels.length > 0) {
      getVideos();
      setId(channels[0].id);
    }
  }, [channels]);

  useEffect(() => {
    onGetChannels();
    if (clientWidthRef.current) {
      setClientWidth(clientWidthRef.current.clientWidth);
    }
    if (frameWidthRef.current) {
      setFrameWidth(frameWidthRef.current.clientWidth);
    }
  }, [clientWidthRef, frameWidthRef]);

  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  const YoutubeEmbed = ({ embedId }) => (
    <div className="w-full h-[300px] md:h-[600px]">
      <iframe
        className="w-full h-[300px] md:h-[600px]"
        width={clientWidth - 50}
        height={clientWidth / 2}
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );

  const onPlay = (item) => {
    setId(item.id);
    setVideos(listTotal.filter((index) => index.id !== item.id));
  };

  const onGetChannels = async () => {
    try {
      const res: any = await APIs.SETTING.getSettingByName("channel");
      if (res && res?.setting) {
        setChannels(res.setting?.value);
      }
    } catch (error) {}
  };

  const handelClick = () => {
    window.open(`${telegram}`);
  };

  return (
    <>
      <VTabs tabs={tabs} onChangeTabs={handleTabs} tabActive={currentTab} />
      <div className="h-[100vh] flex md:px-4 px-2 w-full justify-center pt-8 ">
        {currentTab === "channel" &&
          (videos && videos.length > 0 ? (
            <div className="clientWidth" ref={clientWidthRef}>
              <YoutubeEmbed embedId={id} />
              <hr />
              <div className="grid md:grid-cols-4 grid-cols-3 flex-nowrap gap-4 h-full">
                {videos.map((item) => (
                  <div className="col-span-1 " onClick={() => onPlay(item)}>
                    <div className="relative">
                      <img
                        src={item.thumbnails.high.url}
                        alt=""
                        className="w-full "
                      />
                      <div className="absolute-center hover:opacity-60 ">
                        <FaPlay size={27} />
                      </div>
                    </div>
                    <span>{item.title.substr(0, 55) + "..."}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <ComingSoon />
          ))}

        {currentTab === "canvanex" && (
          <>
            {canvanex ? (
              <div
                className="frameWidth flex justify-center w-full h-full"
                ref={frameWidthRef}
              >
                {frameWidth > 0 && (
                  <iframe
                    className="flex justify-center w-full "
                    src={`${canvanex}`}
                    width={frameWidth}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                )}
              </div>
            ) : (
              <ComingSoon />
            )}
          </>
        )}
        {currentTab === "facebook" && (
          <>
            {facebook ? (
              <div className="frameWidth w-full" ref={frameWidthRef}>
                {frameWidth > 0 && (
                  <iframe
                    className=""
                    src={`https://www.facebook.com/plugins/page.php?href=${facebook}&tabs=timeline&width=${frameWidth}&height=1000&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                    height="600"
                    width={frameWidth}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                )}
              </div>
            ) : (
              <ComingSoon />
            )}
          </>
        )}
        {currentTab === "telegram" && (
          <>
            {telegram ? (
              <div
                className="grid justify-center w-full m-auto lg:py-[300px] py-[150px] rounded-2xl "
                style={themesSetting?.card}
              >
                <div className="text-center mb-4 text-4xl font-semibold">
                  Telegram
                </div>
                <div className="flex justify-center items-center">
                  <VButton
                    className="hover:opacity-60 font-medium w-28 h-12"
                    type="secondary"
                    children={"Join Channel"}
                    onClick={handelClick}
                  />
                </div>
              </div>
            ) : (
              <ComingSoon />
            )}
          </>
        )}
        {currentTab === "twitter" && (
          <>
            {twitter ? (
              <div className="clientHight w-full ">
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName={`${twitter}`}
                  theme="dark"
                  options={{ height: clientHeight }}
                />
              </div>
            ) : (
              <ComingSoon />
            )}
          </>
        )}
      </div>
    </>
  );
};
