/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Continue } from "../../styles";
import History from "../history-vnd";
import { formatNumber, randString } from "utils/common";

import APIs from "api";
import BankAccountForm from "./components/BankAccountForm";
import Verify from "../verify";
import Network from "../networks";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
import VInput from "components/v-core/v-input";
import { renderStock } from "utils/render";

const WithdrawVND = () => {
  const { rate, balances, auth, stocks, stockDefault, stocksPrice, appName } =
    useContext(MasterContext);
  const stockVnex = stocks.find((el) => el.symbol === stockDefault);
  const { themesSetting } = useContext(ThemeContext);

  const [isOpenBankAccountModal, setOpenBankAccountModal] = useState(false);
  const [step, setStep] = useState(0); // 1 : choose bank ,2 : amount,3 : verify
  const [selectedBank, setSelectBank] = useState(null);
  const [amount, setAmount] = useState<number>(null);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [currency, setCurrency] = useState(null);
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState("bsc");
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const [myBanks, setMyBanks] = useState([]);

  useEffect(() => {
    if (auth) {
      getMyBanks();
      getPayment();
    }
  }, [auth]);

  const handleChange = (e) => {
    if (+e < 0) {
      setAmount(0);
    } else if (+e === 0) {
      setAmount(null);
    } else {
      setAmount(+e);
    }
  };

  const handleClickButtonTop = async () => {
    setErrMessage("");
    if (step !== 3) {
      if (step === 0 && currency !== "vnd") {
        setStep(step + 2);
      } else setStep(step + 1);
    } else {
      try {
        if (amount > 0) {
          setLoading(true);
          await APIs.PAYMENT.withdraw({
            body: {
              verify,
              amount: amount,
              bank: selectedBank,
              content: randString(false, 10),
              currency,
              address,
              network,
              stock: stockDefault,
            },
          });
          getPayment();
          toast.success("Withdraw successful!");
          setStep(0);
          setLoading(false);
        } else {
          setErrMessage("Please enter amount");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.errors);
      }
    }
  };

  const addBank = () => {
    setOpenBankAccountModal(true);
  };

  const backStep = () => {
    if (step === 2 && currency !== "vnd") setStep(step - 2);
    else setStep(step - 1);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: auth?._id,
        type: "withdraw",
        stock: stockDefault,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMyBanks = async () => {
    try {
      const res: any = await APIs.BANK.getBanks();
      setMyBanks(res?.banks);
    } catch (error) {}
  };

  const currencies = [
    {
      name: "vnd",
      icon: "/assets/images/coins/vnd.png",
    },
  ];

  const handleCurrency = (name) => {
    setCurrency(name);
  };

  const rateDefault = stocksPrice[stockDefault]?.vnd || 0;

  return (
    <>
      <div className="">
        <div className="flex justify-center items-center p-[15px]">
          <p>Withdraw {renderStock(stockDefault)} </p>
        </div>
        <div className="rounded-md" style={themesSetting?.container}>
          <div>
            {step === 0 && (
              <div className="flex justify-center p-[20px]">
                {currencies.map((c) => (
                  <VButton
                    onClick={() => {
                      handleCurrency(c.name);
                      setNetwork(c.name === "vnd" ? "bank" : "bsc");
                    }}
                    className={` ${
                      currency === c.name ? "!opacity-1 " : "!opacity-50"
                    }`}
                    radius="full"
                  >
                    <img src={c.icon} className="mr-[5px] h-5 rounded-full" />
                    {c.name.toUpperCase()}
                  </VButton>
                ))}
              </div>
            )}
            {step === 1 && (
              <div className="p-[20px]" style={themesSetting?.container?.card}>
                <div className="flex flex-wrap justify-center">
                  {myBanks?.map((b) => {
                    return (
                      <div
                        className={`my-2 relative w-full h-[130px] rounded-md p-3 cursor-pointer ${
                          String(b._id) === String(selectedBank?._id)
                            ? "opacity-100"
                            : "opacity-40"
                        }`}
                        style={themesSetting?.card}
                        key={b._id}
                        // selected={String(b._id) === String(selectedBank?._id)}
                        onClick={setSelectBank.bind(null, b)}
                      >
                        <div className="flex flex-col justify-center items-center w-full mt-2">
                          <h5>{b.bank_name}</h5>
                          <p>{b.account_name}</p>
                          <p>{b.account_number}</p>
                        </div>
                        <div className="absolute top-1 right-1">
                          <Icons iconName="del" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="p-4">
                <div className="w-full mb-2">
                  {currency !== "vnd" && (
                    <>
                      <Network network={network} setNetwork={setNetwork} />
                      <VInput
                        transparent={true}
                        label="Address"
                        placeholder="0x9f0F7601b9143ACc36aE838800153805698e8f08"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </>
                  )}
                  <VInput
                    isCurrency={true}
                    transparent={true}
                    className="w-full"
                    label={`Amount (${currency.toUpperCase()})*`}
                    placeholder="10,000"
                    value={amount}
                    hasError={errMessage ? true : false}
                    errMessage={errMessage}
                    iconRight={
                      <VButton
                        className={"action"}
                        onClick={() =>
                          setAmount(
                            (+balances[stockDefault] * rateDefault) /
                              (currency === "usdt" ? rate : 1)
                          )
                        }
                      >
                        MAX
                      </VButton>
                    }
                    onValueChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="w-full flex gap-x-2 overflow-x-auto">
                  <div className="flex flex-col">
                    <div className="flex gap-x-2">
                      {currency === "vnd" && (
                        <>
                          <div className="flex gap-x-1">
                            <img
                              src="/assets/images/icons/warning.svg"
                              alt="warning"
                              className="w-4 h-4"
                            />
                            <p>
                              VAT:{" "}
                              <span>
                                {formatNumber(
                                  (amount * (stockVnex?.vat || 0)) / 100
                                )}{" "}
                                {String(currency).toUpperCase()}
                              </span>
                            </p>
                          </div>
                          <div className="flex gap-x-1">
                            <img
                              src="/assets/images/icons/warning.svg"
                              alt="warning"
                              className="w-4 h-4"
                            />
                            <p>
                              Amount Received:{" "}
                              <span>
                                {formatNumber(
                                  amount -
                                    (amount * (stockVnex?.vat || 0)) / 100
                                )}{" "}
                                {String(currency).toUpperCase()}
                              </span>
                            </p>
                          </div>
                        </>
                      )}
                      <div className="flex gap-x-1">
                        <img
                          src="/assets/images/icons/warning.svg"
                          alt="warning"
                          className="w-4 h-4"
                        />
                        <p>
                          Maximum {String(currency).toUpperCase()} Withdrawal:{" "}
                          <span>
                            {formatNumber(
                              (+balances[stockDefault] * rateDefault) /
                                (currency === "usdt" ? rate : 1)
                            )}{" "}
                            {String(currency).toUpperCase()}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-x-1">
                      <img
                        src="/assets/images/icons/warning.svg"
                        alt="warning"
                        className="w-4 h-4"
                      />
                      <p>
                        {String(currency).toUpperCase()} deposit is free.{" "}
                        {appName}
                        does not collect {String(currency).toUpperCase()}{" "}
                        deposit fee, however, in some instances, you may have to
                        bear a small transaction fee charged directly by the
                        processing bank or network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && <Verify verify={verify} setVerify={setVerify} />}
          </div>
          <Continue>
            {step === 1 && (
              <VButton
                type="outline"
                radius="full"
                className="!w-[350px]"
                transparent={true}
                onClick={() => addBank()}
              >
                + Add new account
              </VButton>
            )}

            <VButton
              radius="full"
              className="!w-[350px]"
              loading={loading}
              onClick={handleClickButtonTop}
              disabled={!currency || (step === 1 && !selectedBank)}
            >
              {step === 3 ? "Confirm" : "Continue"}
            </VButton>
            {step !== 0 && (
              <VButton
                type="outline"
                radius="full"
                className="!w-[350px]"
                onClick={() => backStep()}
              >
                Back
              </VButton>
            )}
          </Continue>
        </div>
      </div>
      {hisDeposit.length ? (
        <History lists={hisDeposit} type="withdraw" stock={stockDefault} />
      ) : (
        <></>
      )}
      <BankAccountForm
        getMyBanks={getMyBanks}
        isOpen={isOpenBankAccountModal}
        setIsOpen={setOpenBankAccountModal}
      />
    </>
  );
};

export default WithdrawVND;
