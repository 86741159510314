import styled from "styled-components";

export const NotificationWrapper = styled.div``;

export const Container = styled.div`
  background: ${(props) => props.theme.colors["black100"]};
  border-radius: 8px;
  width: 100%;
  overflow: auto;
  button {
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const WrapperForm = styled.div`
  padding: 20px;
  h3 {
    text-align: center;
  }
  .select__control {
    background-color: transparent;
    min-height: 46px;
  }
  .select__menu {
    color: black;
  }
  .select__input-container {
    color: white;
  }
`;

export const SelectWrapper = styled.div`
  label {
    font-size: 14px;
    margin: 5px 0;
  }
`;

export const Fee = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
`;

export const ContainerVisit = styled.div`
  position: relative;
  height: 220px;
  max-width: 350px;
  margin: auto;
`;

export const Upload = styled.div<{ padding?; isHidden?: any }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: ${(props) => props.padding || "0"};
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
`;
