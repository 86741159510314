/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import AuthAPI from "api/auth";

export default function useCountdown() {
  let intervalRef = useRef<any>();

  const [isCountdown, setIsCountdown] = useState<boolean>(true);
  const [time, setTime] = useState<number>(59);
  const [loadingResend, setLoadingResend] = useState<boolean>(false);

  const decreaseNum = () => {
    setTime((prev) => prev - 1);
  };

  const countdownResend = () => {
    intervalRef.current = setInterval(decreaseNum, 1000);
    return () => clearInterval(intervalRef.current);
  };

  const resendCode = async (email: string) => {
    try {
      setLoadingResend(true);
      const res: any = await AuthAPI.resendCode({ body: { email: email } });
      if (res) {
        restartCountdown(true);
        setLoadingResend(false);
      } else {
        restartCountdown(false);
        setLoadingResend(false);
      }
    } catch (error) {
      restartCountdown(false);
      setLoadingResend(false);
    }
  };

  const restartCountdown = (isStart: boolean) => {
    if (isStart) {
      setTime(59);
      setIsCountdown(true);
      countdownResend();
    } else {
      setIsCountdown(false);
    }
  };

  useEffect(() => {
    if (time === 0) {
      setIsCountdown(false);
      setTime(59);
      clearInterval(intervalRef.current);
    }
  }, [time]);

  useEffect(() => {
    countdownResend();
  }, []);

  return { time, isCountdown, loadingResend, restartCountdown, resendCode };
}
