import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  height: 100%;
  margin-bottom: 40px;

  button {
    padding: 0px 10px;
  }
  p,
  .form-check,
  label {
    margin-bottom: 0px;
  }
  span {
    font-size: 16px;
    line-height: 19px;
  }
  img {
    margin-right: 8px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 24px 0 12px 0px;
  }
`;

export const Content = styled.div`
  background: ${(props) => props.theme.colors["black100"]};
  border-radius: 8px;
`;

export const History = styled.div`
  background: ${(props) => props.theme.colors["black100"]};
  height: 100px;
  margin-top: 20px;
  border-radius: 8px;
`;

export const TopMain = styled.div`
  padding: 20px;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  input {
    color: white;
    margin-left: -10px;
    padding-left: 15px;
    max-width: 100%;
  }
  .contain-user {
    margin-bottom: 10px;
    span {
      display: block;
      margin-top: 5px;
    }
    h4 {
      margin-bottom: 0px;
    }
  }

  .qr-code-affilate{
    position: relative;
    .icon_qr{
      position: absolute;
      top : 48px;
      left : 48px
    }
  }
`;

export const EndBorder = styled.div`
  border: 0.1px solid white;
  opacity: 0.3;
  margin: 15px 10px;
`;

export const MiddleMain = styled.div`
  padding: 0px 20px;
`;
export const BottomMain = styled.div`
  padding: 0 20px;
  textarea {
    background: ${(props) => props.theme.colors["black100"]};
    color: white;
    border: 1px solid white;
    max-width: 601px;
    height: 96px;
  }
`;

export const ContainIcon = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;
  margin-bottom: 30px;
`;

export const Facebook = styled.div`
  background-color: #004494;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
`;
export const Twitter = styled.div`
  background-color: #37c3ff;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  margin-left: 20px;
  cursor: pointer;
`;
export const Vdiarybook = styled.div`
  background-color: rgb(14, 88, 219);
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  margin-left: 20px;
  cursor: pointer;
  img {
    width: 20px;
  }
  a {
    color: white;
    text-decoration: none;
  }
`;
export const ContainButton = styled.div`
  button {
    padding: 0 50px !important;
    margin-bottom: 30px;
  }
`;

export const Title = styled.div<{ bgColor: any }>`
  padding: 20px;
  border-radius: 8px 8px 0 0;
  background-color: ${(props) => props.theme.colors[props.bgColor]};
  margin-top: 20px;
`;

export const URLContract = styled.div`
  display: flex;
  button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1000;
    width: 100px;
  }
`;

export const URL = styled.div`
  width: 100%;
  max-width: 506px;
`;
