/* eslint-disable max-len */
/* eslint-disable indent */
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";

export const Wrapper = styled.div<{
  bg?;
}>`
  overflow: auto;
  overflow-x: hidden;

  p {
    line-height: 100%;
  }

  #home {
    background-image: url(${(props) => props.bg});
    background-size: cover;
    .main-stock {
      min-height: ${isMobile ? "400px" : "600px"};
      padding: 130px 0;
    }
  }

  @media screen and (max-width: 1100px) {
    height: calc(var(--vh, 1vh) * 100) - 142px;
  }
`;

export const ContainerDoc = styled.div`
  ._info_doc {
    padding: 30px 20px;
    cursor: pointer;
    word-break: break-word;
  }
`;

export const Banner = styled.div<{
  bg: boolean;
  zIndex: number;
  minHeight?: string;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${(props) =>
    props.bg && "/assets/images/slide/slide-2.jpg"});
  background-color: ${(props) =>
    props.bg ? "" : props.theme.colors.bgColorLayer1};
  background-size: cover;
  min-height: ${(props) =>
    props.bg
      ? "67vh"
      : props.minHeight || "calc(calc(var(--vh, 1vh) * 100) - 108px)"};
  z-index: ${(props) => props.zIndex};
  overflow: hidden;
  cursor: pointer;
  {relative w-full h-full bg-landing-page bg-cover overflow-hidden cursor-pointer max-[1100px]:min-h-[45vh] max-[650px]:min-h-[35vh] max-[500px]:min-h-[30vh] z-${(
    props
  ) => props.zIndex} ${(props) =>
  props.bg ? "min-h-67vh" : props.minHeight || "min-h-[calc(100vh - 108px)]"}}

  .v-particles {
    height: 100% !important;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 0;
    top: 0;
    {absolute top-0 left-0 w-full h-full z-0}
  }

  @media screen and (max-width: 1100px) {
    min-height: 45vh;
  }

  @media screen and (max-width: 650px) {
    min-height: 35vh;
  }

  @media screen and (max-width: 500px) {
    min-height: 30vh;
  }
`;

export const PlayProjectIntro = styled.div`
  max-width: 900px;
  width: 100%;
  margin: auto;
`;

export const AddBanner = styled.div<{ isLeft?: boolean }>`
  position: absolute;
  top: 30px;
  z-index: 99;

  ${({ isLeft }) =>
    isLeft
      ? css`
          left: 30px;
        `
      : css`
          right: 30px;
        `}

  button {
    height: 30px;
    padding: 3px 10px;
    margin: 3px;
  }
`;

export const BackgroundBanner = styled.div<{
  isVideo?;
  isLinkPB?: boolean;
  type?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  iframe {
    width: 100%;
    height: 100%;
    pointer-events: ${(props) => (isMobile || props.isLinkPB ? "" : "none")};
  }

  img {
    width: 100%;
    height: auto;
  }

  .container_video {
    position: relative;
    width: 100%;
    height: 100%;

    .fb-video {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        height: 100% !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;

        iframe {
          width: 100% !important;
          height: auto;
        }
      }
    }
    ._speaker {
      position: absolute;
      right: 40px;
      bottom: 20px;
      z-index: 99;
      cursor: pointer;
    }
  }

  .slick-slider {
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          overflow: hidden;
          > div {
            height: 100%;
            display: flex;
            justify-content: center;

            img {
              height: 100%;
              width: auto !important;
            }
          }
        }
      }
    }
  }
`;

export const Section = styled.div<{
  isBgVideo?;
  bgColor?;
  textColor?;
  bgColorDefault?: any;
  isDisable?: boolean;
  hasWallpaper?: boolean;
}>`
  
  padding-bottom: 30px;
  color: ${(props) => props.textColor || "white"};
  position: relative;
  z-index: 1;
   {relative pb-[30px] z-10 isDisable ? "pointer-events-none opacity-50": ""}

  ${({ isDisable }) =>
    isDisable
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ""}

  .container {
    position: relative;
  }

  .row {
    margin-top: -20px;
    --bs-gutter-y: 16px !important;
  }

  table {
    max-width: 600px;
    margin: auto;
  }
`;

export const Container = styled.div<{ max?: any }>`
  max-width: ${(props) => props.max || "700px"};
  margin: auto;

  .row {
    align-items: center;
    --bs-gutter-x: 0.5rem;
  }
`;

export const ContainerFirstHome = styled.div<{ pt?: string }>`
  max-width: 900px;
  margin: auto;
  padding-top: ${(props) => props.pt || "0"};
`;

export const MainEstock = styled.div<{ isEdit?: boolean; isPadding?: boolean }>`
  margin-top: 20px;
  height: ${(props) =>
    props.isEdit ? "100%" : "calc(calc(var(--vh, 1vh) * 100) - 170px)"};
  min-height: ${(props) => (props.isEdit ? "" : "500px")};

  max-height: 900px;

  padding: ${(props) => (props.isPadding ? "100px 0" : "0")};

  .row {
    height: ${(props) => (props.isEdit ? "340px" : "50%")};
    max-height: 340px;
  }

  .col-md-7,
  .col-md-5 {
    height: ${(props) => (props.isEdit ? "" : "100%")};
  }

  @media screen and (max-width: 768px) {
    height: auto;

    .row {
      height: auto;
      max-height: 712px;
    }
    .col-md-7,
    .col-md-5 {
      min-height: 340px;
    }
  }
`;

export const MyIDO = styled.div`
  margin: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  {flex justify-center items-center m-auto h-auto p-[15px]}

  > div {
    height: 100%;
    padding-bottom: 70px;
  }
`;

export const BuySell = styled.div`
  padding-top: 25px;
  max-width: 900px;
  margin: auto;
  height: 50%;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`;

export const ContentScreen = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #00000081;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    padding: 100px 40px;
    background-color: #071120;
    text-align: center;
    box-shadow: 0 0 10px #ff9900;
    border-radius: 15px;
  }

  h1 {
    color: white;
    margin-bottom: 40px;
  }
`;

export const ItemContent = styled.div`
  height: 100%;
  position: relative;

  img {
    width: auto;
    max-width: 100%;
  }
`;

export const ItemContentDoc = styled.a`
  height: 100%;
  position: relative;
  text-decoration: unset;
  color: white;
  img {
    width: auto;
    max-width: 100%;
  }
`;

export const MainLogo = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  max-height: 340px;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: 100%;
    max-height: 340px;
  }
`;

export const VideoYT = styled.div<{ height?; isEdit?: any; type?: string }>`
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: auto;
  iframe {
    width: 100%;
    height: ${(props) => props.height || "100%"};
    min-height: 220px;
    pointer-events: none;

    ${({ type }) =>
      type === "list"
        ? css`
            @media screen and (max-width: 960px) {
              height: 130px !important;
              min-height: 130px !important;
            }

            @media screen and (max-width: 1400px) {
              min-height: 170px;
            }
          `
        : ""}
  }
  border: ${(props) => (props.isEdit ? "0.5px dashed" : "none")};
`;

export const Title = styled.div<{ titleColor?: string }>`
  text-align: center;
  padding: 40px 0;
  width: 100%;
  max-width: 800px;
  margin: auto;
  color: ${(props) => props.titleColor || "white"};
  {w-full max-w-[800px] text-center py-[40px] m-auto}

  h3 {
    margin: 0;
    display: inline;
    font-weight: bold;
  }
  {inline font-bold m-0}


  h5 {
    margin: 25px 0;
  }

  hr {
    margin: 10px auto;
    width: 64px;
    height: 2px;
    opacity: 1;
  }
  {w-[64px] h-[2px] my-[10px] mx-auto opacity-100}

  p {
    line-height: 100%;
  }
`;

export const Description = styled.p`
  margin-top: 15px;
  padding: 0 30px;
  font-size: 14px;
  {mt-[15px] px-[30px] text-[14px]}
`;

export const Fundamentals = styled.div`
  img {
    width: 22px;
  }
  h6 {
    display: inline;
  }
  p {
    font-size: 14px;
  }
`;

export const Project = styled.div<{
  isVideo?;
  isDoc?: boolean;
  pd?;
  mb?: string;
}>`
  text-align: center;
  cursor: pointer;
  position: relative;
  background-color: ${(props) =>
    props.isDoc ? "" : props.theme.colors.black100};
  padding: ${(props) => props.pd || "20px 8px"};
  margin-bottom: ${(props) => props.mb || "8px"};
  height: ${(props) => (props.isVideo ? "" : "100%")};

  h4 {
    padding: 0;
    margin: 0;
  }
  span {
    font-size: 12px;
  }
  p {
    margin: 5px 0;
    font-size: 12px;
  }

  &:hover {
    transform: scale(1.05);
    background-color: #111e32;
  }
`;

export const QrCodeWrapper = styled.div`
  padding: 10px;
  background-color: #fff;
  display: inline-block;
`;

export const TextWallet = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 4px;
`;

export const ItemDoc = styled.div`
  position: relative;
  height: 250px;

  p,
  h5 {
    position: relative;
    z-index: 99;
    overflow-wrap: anywhere;
    padding: 0 10px;
  }

  margin: auto;
  padding: 40px 0;
`;

export const IconDoc = styled.div`
  img {
    position: relative;
    z-index: 99;
    width: 30px;
    margin-bottom: 30px;
  }
`;

export const LinkDoc = styled.div`
  position: relative;
  z-index: 99;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
  color: antiquewhite;
  padding: 0 10px;

  span {
    padding: 2px;
    color: ${(props) => props.theme.colors.orange100};
    font-size: 14px;
  }
`;

export const PartnerContainer = styled.div`
  position: relative;

  .row {
    justify-content: center;
  }
`;

export const Partners = styled.div`
  height: 100%;

  input {
    margin-top: 3px;
    height: 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 30px;
  }

  @media screen and (max-width: 500px) {
    padding: 15px;
  }

  @media screen and (max-width: 430px) {
    padding: 6px;
  }
`;

export const PartnersVZX = styled.div`
  height: 100%;
`;

export const ItemPartner = styled.div`
  position: relative;
  border: 1px solid white;
  border-radius: 50%;
  padding: 10px;
  height: 160px;
  max-width: 160px;
  margin: 40px auto;

  @media screen and (max-width: 768px) {
    margin: 10px auto;
  }

  @media screen and (max-width: 400px) {
    height: 140px;
    max-width: 140px;
  }

  @media screen and (max-width: 350px) {
    height: 120px;
    max-width: 120px;
  }
`;

export const OurTeamContainer = styled.div<{ noneBorder?: boolean }>`
  position: relative;
  border-bottom: ${(props) =>
    props.noneBorder ? "none" : "0.5px dashed grey"};
`;

export const OutTeam = styled.div`
  text-align: center;
  padding: 0 20px;
  max-width: 400px;
  margin: auto;
  margin-bottom: 30px;

  h3 {
    margin: 8px 0;
  }

  @media screen and (max-width: 500px) {
    padding: 0 3px;
  }
`;

export const AddSectionTeam = styled.div`
  margin-top: 40px;
  text-align: center;

  button {
    margin: 10px;
  }
`;

export const Avta = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;

  img {
    width: auto;
    height: 100%;
  }

  @media screen and (max-width: 400px) {
    width: 140px;
    height: 140px;
  }

  @media screen and (max-width: 350px) {
    width: 120px;
    height: 120px;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const RoadmapContainer = styled.div`
  @media screen and (max-width: 770px) {
    transform: scale(0.8);
  }
`;

export const Roadmap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 180px 0;

  @media screen and (max-width: 770px) {
    flex-direction: column;
    height: 1080px;
    margin: -235px 0;
  }

  @media screen and (max-width: 400px) {
    height: 780px;
    padding: 0;
    margin: -65px 0;
  }
`;

export const Timeline = styled.div`
  @media screen and (max-width: 770px) {
    transform: rotate(-90deg);
  }
`;

export const ItemRoadmap = styled.div`
  text-align: center;
  position: relative;
  z-index: 9;
`;

export const UpRoadmap = styled.div`
  position: absolute;
  top: -150px;
  @media screen and (max-width: 770px) {
    transform: rotate(90deg);
    top: -24px;
    left: 125px;
  }
`;

export const DownRoadmap = styled.div`
  position: absolute;
  bottom: -150px;
  @media screen and (max-width: 770px) {
    transform: rotate(90deg);
    bottom: -24px;
    right: 125px;
  }
`;

export const DetailRoadmap = styled.div`
  padding: 10px;
  border: 1px solid;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  margin: auto;
  background-color: ${(props) => props.theme.colors.bgColorLayer1};
`;

export const ImageRoadmap = styled.div`
  background-color: ${(props) => props.theme.colors.orange100};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnRoad = styled.div`
  height: 50px;
  border-right: 2px solid ${(props) => props.theme.colors.orange100};
  width: 50%;
`;

export const RowRoad = styled.div`
  position: absolute;
  border-bottom: 2px solid ${(props) => props.theme.colors.orange100};
  width: 100%;
  min-width: 700px;

  @media screen and (max-width: 770px) {
    border-bottom: none;
    width: 0;
    min-height: 700px;
    border-left: 2px solid ${(props) => props.theme.colors.orange100};
    left: 50%;
  }
`;

export const Document = styled.div`
  position: relative;
  text-align: center;
  .row {
    justify-content: center;
  }
`;

export const AddOrRemoveChil = styled.div<{ mt?: string }>`
  position: absolute;
  top: ${(props) => props.mt || "16px"};
  right: 0;
  z-index: 10;
  {absolute top-20 right-0 z-10 flex gap-x-2 }

  div {
    display: inline;
    padding: 3px 15px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.orange100};
    margin-right: 10px;
    cursor: pointer;
    {inline px-[15px] py-[3px] rounded-md mr-[10px] cursor-pointer}
  }
`;

export const Certification = styled.div`
  position: relative;

  input {
    margin-top: 3px;
    height: 20px;
    color: black;
    direction: ltr;
    margin-bottom: 0;
    {h-[20px] mt-[3px] mb-0 text-black placeholder:text-[#807d7d] placeholder:hover:text-black max-[500px]:h-[14px] max-[500px]:text-[10px]}

    &:hover,
    :focus,
    ::placeholder {
      color: black;
    }
    &::placeholder {
      color: #807d7d;
    }

    @media screen and (max-width: 500px) {
      height: 14px;
      font-size: 10px;
    }
  }
`;

export const ContainerCerIcon = styled.div`
  position: absolute;
  direction: rtl;
  right: 28px;
  top: 28px;
  {absolute top-[28px] right-[28px] max-[1000px]:top-[21px] max-[1000px]:right-[24px] max-[768]:max-w-[100px] max-[500px]:right-[15px] max-[500px]:top-[21px] max-[500px]:max-w-[80px]}

  @media screen and (max-width: 1000px) {
    right: 24px;
    top: 21px;
  }
  @media screen and (max-width: 768px) {
    max-width: 100px;
  }
  @media screen and (max-width: 500px) {
    right: 15px;
    top: 10px;
    max-width: 80px;
  }
`;

export const Certificate = styled.div`
  position: relative;
  height: auto;
  max-width: 900px;
  width: 100%;
  margin: auto;
  color: black;
  {relative w-full max-w-[900px] m-auto text-black}

  p {
    font-size: 14px;
    text-align: center;
    {text-[14px] text-center}
  }
  img {
    width: auto;
    max-width: 100%;
    {max-w-full}
  }
`;

export const UploadCer = styled.div<{ isHidden?: any }>`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
   {
    absolute w-full h-full z-10 {
      ishidden?"opacity-0": "opacity-100";
    }
  }
`;

export const LogoCer = styled.div`
  position: relative;
  width: 74px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  {relative flex justify-center items-center overflow-hidden w-[74px] h-[75px] cursor-pointer max-[1000px]:w-[60px] max-[1000px]:h-[49px] max-[500px]:w-[50px] max-[500px]:h-[49px]}

  img {
    height: 100%;
    width: auto;
    {h-full w-auto}
  }

  @media screen and (max-width: 1000px) {
    width: 60px;
    height: 49px;
  }
  @media screen and (max-width: 500px) {
    width: 50px;
    height: 49px;
  }
`;

export const InfoProject = styled.div`
  position: absolute;
  width: 60%;
  height: 15%;
  top: 36.5%;
  left: 33%;
  font-size: 14px;
  color: black;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  {absolute top-[36.5%] left-[33%] flex flex-col justify-between w-[60%] h-[15%] text-[14px] text-black font-bold max-[1000px]:text-[12px] max-[774px]:text-[8px] max-[500px]:text-[6px]}

  div:nth-child(2) {
    margin-left: 2%;
  }

  div:nth-child(3) {
    margin-left: 8%;
  }

  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }

  @media screen and (max-width: 774px) {
    font-size: 8px;
  }

  @media screen and (max-width: 500px) {
    font-size: 6px;
  }
`;

export const ConfimationProject = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 20%;
  bottom: 4%;
  {absolute bottom-[4%] flex flex-row justify-around items-center w-full h-[20%]}
`;

export const ContainerImageCerBottom = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  {flex flex-col h-full w-[20%]}
`;

export const ImageCer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  {relative flex justify-center items-center w-full h-full overflow-hidden cursor-pointer}

  p {
    position: absolute;
    font-size: 12px;
    margin: 0;
    {absolute text-[12px] m-0}
  }
  img {
    height: 100%;
    width: auto;
  }
`;

export const Tokenomic = styled.div`
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  min-height: 550px;
  display: flex;
  align-items: center;
  {relative flex items-center overflow-hidden my-0 mx-auto min-h-[350px] sm:min-h-[450px] md:min-h-[550px]}

  @media screen and (max-width: 768px) {
    min-height: 450px;
  }

  @media screen and (max-width: 400px) {
    min-height: 350px;
  }

  .VictoryContainer {
    margin: -100px auto;

    svg {
      max-height: 650px;
      pointer-events: none !important;
    }

    tspan {
      fill: white !important;
      word-wrap: break-word !important;
    }
  }
`;

export const ContentPreview = styled.div<{ maxWidth?: string }>`
  position: relative;
  min-height: 600px;
  max-width: ${(props) => props.maxWidth || "100%"};
  display: flex;
  justify-content: center;
  margin: auto;
  {relative flex justify-center min-h-[600px] max-w-full m-auto}
  img {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 600px;
    {max-w-full w-auto h-full max-h-[600px]}
  }

  @media screen and (max-width: 600px) {
    min-height: 250px;
  }

  @media screen and (max-width: 430px) {
    min-height: 150px;
  }
`;

export const Upload = styled.div<{ padding?; isHidden?: any }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: ${(props) => props.padding || "0"};
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  {absolute top-0 left-0 w-full h-full ${(props) =>
    props.isHidden ? "opacity-0" : "opacity-100"}}
`;

export const UpLinkVideoYoutube = styled.div`
  position: absolute;
  top: 50%;
  width: inherit;
  text-align: center;
  cursor: pointer;
`;

export const FundamentalsImage = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  max-width: 100%;

  img {
    height: 100%;
    width: auto;
  }

  @media screen and (max-width: 768px) {
    min-height: 340px;

    img {
      max-height: 500px;
    }
  }
`;

export const ContainerPartner = styled.div`
  width: auto;
  margin: auto;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: auto;
    max-height: 500px;
  }
`;

export const ImagePartners = styled.div`
  border-radius: 50%;
  height: 100%;
  width: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
  img {
    height: 100%;
  }
`;

export const ContainerImage = styled.div`
  width: auto;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

export const UploadPdf = styled.div`
  .row {
    padding: 10px;
  }
`;

export const BgColor = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  {flex justify-center items-center mb-[20px]}

  p {
    margin: 0;
    margin-top: 5px;
  }

  input {
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 0;
  }
  {rounded-md border-none outline-none p-0}

  @media screen and (max-width: 960px) {
    justify-content: flex-start;
  }
`;

export const BoxColor = styled.div<{ color: string }>`
  position: relative;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

export const AddLink = styled.div`
  padding: 15px;
`;

export const Video = styled.div`
  .row {
    justify-content: center;
  }

  .col-6 {
    @media screen and (max-width: 768px) {
      padding: 0 5px !important;
    }
  }
`;

export const ListIconsOutTeam = styled.div`
  display: flex;
  justify-content: center;
  {flex justify-center}

  img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    {mr-[10px] w-[30px] h-[30px] cursor-pointer}
  }
`;

export const BackGroundDoc = styled.div<{
  isHidden?: boolean;
  zIndex?: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${(props) => props.zIndex || "1"};
  opacity: ${(props) => (props.isHidden ? 0 : 1)};

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }
`;

export const MovePosition = styled.div`
  position: absolute;
  z-index: 99;
  top: -13px;
  width: 100%;
  display: flex;
  justify-content: center;
  {absolute flex justify-center -top-[13px] w-full z-50}

  button {
    height: 26px;
    padding: 1px 15px;
    border-radius: 4px;
    margin: 0 4px;
  }
`;

export const OptionVideoImage = styled.div`
  position: absolute;
  z-index: 98;
  top: 0px;
  left: 0;
  {absolute top-0 left-0 z-90}

  button {
    height: 26px;
    padding: 1px 10px;
    border-radius: 4px;
    margin: 0 4px;
    display: block;
    margin-top: 4px;
  }

  ._logo_survey {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 50px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const AddSlideBanner = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  justify-content: center;
  {flex flex-wrap justify-center max-w-[1000px] w-full}

  .add_image {
    cursor: pointer;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px dashed grey;
    margin: 4px;
    {flex flex-wrap justify-center items-center w-[150px] h-[150px] cursor-pointer border-[0.5px] border-gray-500 border-dashed m-[4px]}
  }
`;

export const SlideUpload = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 4px;
  z-index: 999;
  {relative w-[150px] h-[150px] m-[4px] z-50}
`;
