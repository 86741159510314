/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import { useEffect, useState } from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { SeletedPixel, StageWrapper, UploadWrapper } from "../styles";
import { nanoid } from "nanoid";
import { AiFillDelete } from "react-icons/ai";
import UploadFile from "components/v-upload";
import APIs from "api";
import { toast } from "react-toastify";
import Select from "react-select";
import useProjects from "hooks/useProjects";

const Rounds = () => {
  const { projectSelected, getProjectById } = useProjects();

  const [rounds, setRounds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pixels, setPixels] = useState([]);

  useEffect(() => {
    getPixels();
  }, []);

  useEffect(() => {
    if (projectSelected && projectSelected?.rounds) {
      setRounds(projectSelected?.rounds);
    }
  }, [projectSelected]);

  const getPixels = async () => {
    try {
      const res: any = await APIs.PROJECT.getPixelsByProjectId({
        params: {
          projectId: projectSelected?._id,
        },
      });
      if (res) {
        setPixels(res.pixels);
      }
    } catch (error) {}
  };

  const handleAdd = () => {
    setRounds((state) => [
      ...state,
      {
        id: nanoid(10),
        name: null,
        amountSold: 0,
        price: 0,
        on_sale: false,
        logoLevel: null,
        backgroundSuccess: null,
        pixels: [],
      },
    ]);
  };

  const handleDelete = (id) => {
    setRounds((rounds) => rounds.filter((r) => r.id !== id));
  };

  const handleChangeText = (id) => (event) => {
    setRounds((state) => {
      return state.map((item) => {
        let amountToSale;
        if (event.target.name === "percentToSale") {
          amountToSale =
            (event.target.value * projectSelected?.amountToSale) / 100;
        }
        return item.id === id
          ? {
              ...item,
              [event.target.name]: event.target.value,
              amountToSale: amountToSale || item.amountToSale,
            }
          : item;
      });
    });
  };

  const handleChangeFile = (id, name) => (file) => {
    setRounds((state) => {
      return state.map((item) => {
        return item.id === id
          ? {
              ...item,
              [name]: file,
            }
          : item;
      });
    });
  };

  const handleChangeSelect = (roundId) => (value) => {
    const pixels = value.map((v) => v.value);
    setRounds((state) => {
      return state.map((item) => {
        return item.id === roundId
          ? {
              ...item,
              pixels,
            }
          : item;
      });
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let roundsUploads = rounds;
      await Promise.all(
        rounds.map(async (el: any, index: number) => {
          if (el.fileLogo) {
            const formData = new FormData();
            formData.append("files", el.fileLogo);
            const res: any = await APIs.UPLOAD.uploadFile({ body: formData });
            delete el.fileLogo;
            roundsUploads[index] = {
              ...el,
              logoLevel: res.filePath,
            };
          }
          if (el.fileBg) {
            const formData = new FormData();
            formData.append("files", el.fileBg);
            const res: any = await APIs.UPLOAD.uploadFile({ body: formData });
            delete el.fileBg;
            roundsUploads[index] = {
              ...el,
              backgroundSuccess: res.filePath,
            };
          }
        })
      );
      await APIs.PROJECT.editProject({
        params: {
          id: projectSelected?._id,
        },
        body: {
          rounds: roundsUploads,
        },
      });
      getProjectById(projectSelected?._id);
      setLoading(false);
      toast.success("Update success!");
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const handleChangeSwitch = (id) => (e) => {
    setRounds((state) => {
      return state.map((item) => {
        return item.id === id
          ? {
              ...item,
              on_sale: e.target.checked,
            }
          : item;
      });
    });
  };

  const pixelsSelectOfRound = (roundId) => {
    let pixelsSelected = [];
    rounds
      .filter((r) => r.id !== roundId)
      .map((round) => {
        pixelsSelected = pixelsSelected.concat(round.pixels);
        return round;
      });
    return _pixels.filter((p) => !pixelsSelected.includes(p.value));
  };

  const _pixels: any = pixels
    .filter((p) => p.name)
    .map((c) => ({
      label: `${c.name}`,
      value: c._id,
    }));

  return (
    <StageWrapper>
      <Container>
        <VButton onClick={handleAdd}>+ Round</VButton>
        {rounds.map((round: any) => (
          <Row key={round.id}>
            <Col xs={12} md={4}>
              <VInput
                transparent={true}
                value={round.name}
                name="name"
                label="Round Name"
                onChange={handleChangeText(round.id)}
              />
            </Col>
            <Col xs={12} md={4}>
              <VInput
                transparent={true}
                value={round.price}
                name="price"
                label={`Price (${String(
                  projectSelected?.stock_to_buy || ""
                ).toUpperCase()})`}
                onChange={handleChangeText(round.id)}
              />
            </Col>
            <Col xs={12} md={4}>
              <VInput
                type="number"
                transparent={true}
                value={round.amountSold}
                name="amountSold"
                label="Amount Sold"
                disabled={true}
                onChange={handleChangeText(round.id)}
              />
            </Col>

            <Col xs={12} md={10}>
              <SeletedPixel>
                <Select
                  options={pixelsSelectOfRound(round.id)}
                  defaultValue={[]}
                  value={_pixels.filter((p) => round.pixels.includes(p.value))}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChangeSelect(round.id)}
                />
              </SeletedPixel>
            </Col>
            <Col xs={12} md={2}>
              <FormGroup switch>
                <Input
                  type="switch"
                  checked={round.on_sale}
                  onChange={handleChangeSwitch(round.id)}
                />
                <Label check>On Sale</Label>
              </FormGroup>
            </Col>

            <Col xs={6}>
              <UploadWrapper>
                <span>Logo</span>
                <UploadFile
                  id={`${round.id}_logo`}
                  typeUpload="image"
                  file={round.fileLogo}
                  src={round.logoLevel}
                  setFile={handleChangeFile(round.id, "fileLogo")}
                />
              </UploadWrapper>
            </Col>
            <Col xs={6}>
              <UploadWrapper>
                <span>Backgound</span>
                <UploadFile
                  id={`${round.id}_bg`}
                  typeUpload="image"
                  file={round.fileBg}
                  src={round.backgroundSuccess}
                  setFile={handleChangeFile(round.id, "fileBg")}
                />
              </UploadWrapper>
            </Col>
            <Col xs={12}>
              <VButton
                className={"btn-delete"}
                onClick={() => handleDelete(round.id)}
              >
                <AiFillDelete fontSize={20} />
              </VButton>
            </Col>
            <hr />
          </Row>
        ))}
        <VButton
          disabled={rounds.length === 0 || projectSelected?.status !== "init"}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </VButton>
      </Container>
    </StageWrapper>
  );
};

export default Rounds;
