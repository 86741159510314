import Button from "components/v-core/v-button";
import Table from "components/v-core/v-table";
import { useState } from "react";
import { ActionTable } from "../../styles";
import ModalViewRequest from "components/modal-view-request";
import { BiArrowBack } from "react-icons/bi";
import { Back } from "../../styles";

const View = (props) => {
  const { data, setActive } = props;
  const column = [
    {
      title: "User name",
      dataIndex: "userName",
      key: "userName",
      render: (data: any) => <span>{data?.user?.fullName}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (data: any) => <span>{data?.user?.email}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (data: any) => <span>{data?.title}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data: any) => (
        <ActionTable>
          <Button onClick={() => handleAction(data)}>View</Button>
        </ActionTable>
      ),
    },
  ];

  const [selectData, setSelectData] = useState(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleAction = (data: any) => {
    setSelectData(data);
    setOpenModal(true);
  };

  return (
    <>
      <Back onClick={() => setActive("project")}>
        <BiArrowBack size={24} />
      </Back>
      <Table columns={column} data={data?.eStockInfo?.reviews} />
      <ModalViewRequest
        type={"landing-page"}
        openModal={openModal}
        selectData={selectData}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default View;
