import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";

const DEFAULT_DETAIL = {
  banner: "/assets/images/slide/slide-3.jpg",
  name: "Car loan",
  offer: [
    "Maximum of 90% of vehicle value",
    "Maximum of 90% of vehicle value",
    "Maximum of 90% of vehicle value",
  ],
};

const LoanDetails = () => {
  const { loanId } = useParams();

  const { themesSetting } = useContext(ThemeContext);
  console.log({ loanId });

  const renderOffer = (item) => {
    return (
      <>
        {item.map((it, index) => (
          <div key={index} className="w-1/2 flex items-center gap-x-1">
            <div
              className="w-8 h-8 flex justify-center items-center rounded"
              style={themesSetting.container}
            >
              <Icons iconName="badge-check" />
            </div>
            <span className="text-sm">{it}</span>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="w-full h-full flex flex-col gap-y-2">
      <div className="w-full h-[30%]">
        <img
          className="w-full h-full object-cover"
          src={DEFAULT_DETAIL.banner}
          alt=""
        />
      </div>
      <div className="flex flex-col items-center px-5">
        <div
          className="w-full flex flex-col gap-y-4 p-4 rounded"
          style={themesSetting.card}
        >
          <span className="text-base font-bold">Outstanding advantages</span>
          <div className="flex flex-wrap gap-y-3">
            {renderOffer(DEFAULT_DETAIL.offer)}
          </div>
        </div>
        {/* <VTabs tabs={[{
          name:"Loan calculator",
          value: "loan_calculator",
        }]} tabActive={}/> */}
      </div>
    </div>
  );
};

export default LoanDetails;
