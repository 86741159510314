import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import UserAPIs from "api/users";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import VInput from "components/v-core/v-input";
import Icons from "helper/icons";

const ChangePassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { themesSetting } = useContext(ThemeContext);
  const [errMessage, setErrMessage] = useState<any>({
    old: "",
    new: "",
    confirm: "",
  });

  const [show, setShow] = useState<any>({
    current: false,
    new: false,
    confirm: false,
  });
  const [password, setPassword] = useState<any>({
    oldPassword: "",
    newPassword: "",
    confirm: "",
  });

  const handleChangePassword = async () => {
    setLoading(true);
    if (password.newPassword !== password.confirm) {
      setErrMessage({
        confirm: `Confirm password doesn't match`,
      });
      setLoading(false);
    } else {
      try {
        await UserAPIs.changePassword({ body: password });
        setLoading(false);
        toast.success(`Change password success!`);
        localStorage.removeItem("auth");
        history.push("/auth/login");
      } catch (error) {
        setErrMessage({
          old: error.errors,
        });
        setLoading(false);
      }
    }
  };
  return (
    <div
      className="p-4 rounded-md flex flex-col gap-y-3 justify-center w-full max-w-[500px]"
      style={themesSetting?.card}
    >
      <div className="flex items-center">
        <label className="w-[170px]" htmlFor="currentPassword">
          Current Password
        </label>
        <VInput
          transparent={true}
          isBorder={false}
          className="!bg-transparent border-b"
          type={show.current ? "text" : "password"}
          placeholder="***************"
          suffixIcon={
            show.current ? (
              <img
                src="/assets/images/icons/eyes.svg"
                alt=""
                onClick={() =>
                  setShow({
                    ...show,
                    current: false,
                  })
                }
              />
            ) : (
              <img
                src="/assets/images/icons/eyes-blink.svg"
                alt=""
                onClick={() =>
                  setShow({
                    ...show,
                    current: true,
                  })
                }
              />
            )
          }
          onChange={(e) =>
            setPassword({
              ...password,
              oldPassword: e.target.value,
            })
          }
        />
      </div>
      <div className="flex items-center">
        <label className="w-[170px]" htmlFor="currentPassword">
          New Password
        </label>
        <VInput
          wrapperClassName="my-2"
          transparent={true}
          isBorder={false}
          className="!bg-transparent border-b"
          placeholder="New Password"
          type={show.new ? "text" : "password"}
          suffixIcon={
            show.new ? (
              <img
                src="/assets/images/icons/eyes.svg"
                alt=""
                onClick={() =>
                  setShow({
                    ...show,
                    new: false,
                  })
                }
              />
            ) : (
              <img
                src="/assets/images/icons/eyes-blink.svg"
                alt=""
                onClick={() =>
                  setShow({
                    ...show,
                    new: true,
                  })
                }
              />
            )
          }
          onChange={(e) =>
            setPassword({
              ...password,
              newPassword: e.target.value,
            })
          }
        />
      </div>
      <div className="flex items-center">
        <label className="w-[170px]" htmlFor="currentPassword">
          Confirm Password
        </label>
        <VInput
          transparent={true}
          isBorder={false}
          className="!bg-transparent border-b"
          type={show.confirm ? "text" : "password"}
          placeholder="Confirm Password"
          hasError={Boolean(errMessage.confirm)}
          suffixIcon={
            show.confirm ? (
              <img
                src="/assets/images/icons/eyes.svg"
                alt=""
                onClick={() =>
                  setShow({
                    ...show,
                    confirm: false,
                  })
                }
              />
            ) : (
              <img
                src="/assets/images/icons/eyes-blink.svg"
                alt=""
                onClick={() =>
                  setShow({
                    ...show,
                    confirm: true,
                  })
                }
              />
            )
          }
          onChange={(e) =>
            setPassword({
              ...password,
              confirm: e.target.value,
            })
          }
        />
      </div>
      <div className="w-full flex justify-center mt-3">
        <VButton
          radius="full"
          className="w-full h-8 px-4"
          loading={loading}
          iconLeft={<Icons iconName="save" />}
          onClick={() => handleChangePassword()}
        >
          Save
        </VButton>
      </div>
    </div>
  );
};

export default ChangePassword;
