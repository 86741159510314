/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
/* eslint-disable indent */
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CongratulationInvests from "components/congratulations";
import Modal from "components/v-core/v-modal";
import InvestService from "api/invest";
import Package from "./components/Package";
import PurchaseService from "api/purchases";
import VButton from "components/v-core/v-button";
import { renderStock } from "utils/render";
import VInput from "components/v-core/v-input";
import VTabs from "components/v-core/v-tabs";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";
import VBanner from "components/v-banner";

const defaultState = {
  profit_id: null,
  amount: 0,
  userReveiveReferCode: null,
  package_id: null,
  is_term: false,
};

const Packages = () => {
  const { themesSetting } = useContext(ThemeContext);
  const [dataInvest, setDataInvest] = useState(defaultState);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState(null);
  const [congratulationInverst, setCongratulationInverst] = useState<any>(null);
  const [openModalCongratulation, setOpenModalCongratulation] = useState(false);
  const { categories, getCategories, appName } = useContext(MasterContext);
  const [categorySelected, setCategorySelected] = useState(null);

  // Categories
  const [filter, setFilter] = useState({
    category_id: null,
    status: "opening",
  });

  const handleChangeFilter = (key: string, value: string) => {
    setFilter((p) => ({
      ...p,
      [key]: value,
    }));
  };

  useEffect(() => {
    getCategories({
      params: { group: "category_invest" },
    });
  }, []);

  useEffect(() => {
    if (categories) handleChangeFilter("category_id", categories[0]?._id);
  }, [categories]);

  useEffect(() => {
    if (filter && filter.category_id) {
      setCategorySelected(categories.find((e) => e._id === filter.category_id));
    }
  }, [filter]);

  useEffect(() => {
    if (filter.category_id) getPackages();
  }, [filter]);

  const getPackages = async () => {
    try {
      const res: any = await InvestService.getPackages({
        query: filter,
      });

      if (res && res?.invests) setPackages(res?.invests);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const purchaseInvestPackage = await PurchaseService.investPackage({
        body: {
          ...dataInvest,
          package_id: selectedPackage._id,
        },
      });
      setCongratulationInverst(purchaseInvestPackage);
      setOpenModal(false);
      setOpenModalCongratulation(true);
      toast.success("Congratulations on your successful investment!");
      setLoading(false);
      setSelectedPackage(null);
      setDataInvest(defaultState);
      return true;
    } catch (error) {
      setLoading(false);
      toast.error(error.errors);
      return false; // Return false indicating failure
    }
  };

  const handleClosed = () => {
    setOpenModal(false);
    setSelectedPackage(null);
    setCongratulationInverst(null);
    setLoading(false);
    setDataInvest(defaultState);
  };

  const handleChangeDataInvest = (name, value) => {
    setDataInvest((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderModal = () => {
    if (selectedPackage) {
      return (
        <div className="flex flex-col gap-y-1">
          {selectedPackage.is_term && (
            <div className="rounded p-2 h-[40vh] overflow-auto">
              <iframe
                src={selectedPackage.term}
                className="w-full min-h-full"
              ></iframe>
            </div>
          )}
          <hr
            className="w-full border-b border-dashed"
            style={{ borderColor: themesSetting.layout.textContent }}
          />
          <div className="packages w-full flex justify-center gap-x-2">
            {(selectedPackage.profits || []).map((p) => (
              <VButton
                className={
                  dataInvest.profit_id === p.index
                    ? "opacity-100"
                    : "opacity-40"
                }
                onClick={() => handleChangeDataInvest("profit_id", p.index)}
              >
                {p.time_lock} {selectedPackage.type_lock}
              </VButton>
            ))}
          </div>

          <VInput
            wrapperClassName="mt-2 w-full"
            className="w-full"
            label={` Amount ${renderStock(selectedPackage?.invest?.stock)}`}
            name="amountFrom"
            isCurrency={true}
            value={dataInvest.amount}
            placeholder="0.00"
            onValueChange={(e) => handleChangeDataInvest("amount", e)}
          />
          {selectedPackage?.allocation_users?.length > 0 && (
            <>
              <label>{`ID user ${appName}`}</label>
              <VInput
                name="User Id"
                value={dataInvest.userReveiveReferCode}
                placeholder="ABC....."
                onChange={(e) =>
                  handleChangeDataInvest("userReveiveReferCode", e.target.value)
                }
              />
            </>
          )}

          {selectedPackage.is_term && (
            <div className="accept_term mt-3 gap-2 items-center flex">
              <input
                type="checkbox"
                checked={dataInvest.is_term}
                onChange={(e) =>
                  handleChangeDataInvest("is_term", e.target.checked)
                }
              />
              <span> I have read and agree to the terms of the project</span>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="relative w-full block md:flex flex-wrap gap-2 py-2 mb-24 md:mb-0 z-10">
      <VTabs
        tabs={categories.map((el) => ({
          name: el.name,
          value: el._id,
          icon: <img src={el.icon} />,
        }))}
        onChangeTabs={(e) => handleChangeFilter("category_id", e)}
        tabActive={filter.category_id}
        type="button"
      />

      {categorySelected && categorySelected.is_show_banner ? (
        <div className="absolute top-14 w-full z-50">
          <VBanner
            isWidthFull={true}
            data={categorySelected?.banner}
            countdown={categorySelected?.countdown}
          />
        </div>
      ) : (
        <div className="flex flex-wrap gap-2 mt-2">
          {packages &&
            packages?.map((p, key) => (
              <Package
                key={key}
                _package={p}
                setSelectedPackage={setSelectedPackage}
                setOpenModalTrust={setOpenModal}
              />
            ))}
        </div>
      )}

      <Modal
        isOpen={openModal}
        children={renderModal()}
        title={
          <span className="text-center w-full">{selectedPackage?.name}</span>
        }
        onCancel={handleClosed}
        onConfirm={handleSubmit}
        loading={loading}
        centered={true}
      />
      <CongratulationInvests
        type="investsSuccess"
        background={congratulationInverst?.package_id?.backgroundSuccess}
        userName={congratulationInverst?.user_id?.fullName}
        avataUser={congratulationInverst?.user_id?.avatar}
        logoProject={congratulationInverst?.package_id?.icon}
        logoLevel={congratulationInverst?.package_id?.logoLevel}
        amount={congratulationInverst?.investment?.amount}
        symbol={congratulationInverst?.package_id?.invest?.stock}
        createdAt={congratulationInverst?.createdAt}
        openModalCongratulation={openModalCongratulation}
        setOpenModalCongratulation={setOpenModalCongratulation}
      />
    </div>
  );
};

export default Packages;
