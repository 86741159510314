import VTable from "components/v-core/v-table";
import React from "react";

const Profit = () => {
  return (
    <>
      <VTable
        color={{ backgroundColor: "transparent" }}
        columns={[
          {
            key: "position",
            title: "Position",
          },
          {
            key: "standard_level",
            title: "Standard level",
          },
          {
            key: "profit",
            title: "Profit",
          },
        ]}
        data={[]}
      />
    </>
  );
};

export default Profit;
