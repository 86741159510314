import styled  from "styled-components";

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #454c5354;
    z-index: 10;

`;

export const Fly = styled.div`
    img {
        height: 290px;
    }
`;

export const Transform = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 30%;
    animation-name: example;
    animation-duration: 5s;

    @keyframes example {
        0%   { top: 30%; }
        60%  { top: 30%; }
        70%  { top: 40%; }  
        100% { top: -600%; }
}
`;

export const Congratulations = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.orange100};
    h3 {
        font-size: 19px;
    }
`;