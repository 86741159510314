/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FiPackage } from "react-icons/fi";
import { AiOutlineHistory } from "react-icons/ai";
import Packages from "./packages";
import History from "./history";
import ExpectValue from "./expect-value";
import { SiFuturelearn } from "react-icons/si";
import VTabs from "components/v-core/v-tabs";
import { useHistory } from "react-router-dom";

interface IProps {
  [key: string]: any;
}

const tabs = [
  {
    name: "Packages",
    value: "packages",
    icon: <FiPackage size={17} />,
  },
  {
    name: "Histories",
    value: "histories",
    icon: <AiOutlineHistory size={17} />,
  },
  {
    name: "Expect Value",
    value: "expect_value",
    icon: <SiFuturelearn size={15} />,
  },
];

export default function Trust(props: IProps) {
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");

  useEffect(() => {
    if (!currentTab) {
      history.push("?tab=packages");
    }
  }, [currentTab]);

  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  return (
    <div className="p-2">
      <VTabs tabs={tabs} tabActive={currentTab} onChangeTabs={handleTabs} />
      <div className="mt-3">
        {currentTab === "packages" && <Packages />}
        {currentTab === "histories" && <History />}
        {currentTab === "expect_value" && <ExpectValue />}
      </div>
    </div>
  );
}
