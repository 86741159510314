import { MarginTop } from "../../../../styles";
import { Controller } from "react-hook-form";
import VInput from "components/v-core/v-input";
import VButton from "components/v-core/v-button";

const IDWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <div className="p-[15px]">
      <Controller
        name="to"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <VInput
              transparent={true}
              className="!bg-transparent"
              label="ID"
              placeholder="Input ID"
              hasError={errors.to}
              {...field}
            />
          );
        }}
      />
      {/* <MarginTop mt="20px" /> */}

      <VInput
        className="w-full !bg-transparent mb-0"
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={<VButton onClick={setMaxAmount}>MAX</VButton>}
        suffixClassName="!-right-3"
        onValueChange={(e) => handleChange(e)}
      />

      <MarginTop mt="20px" />
      <p className="mt-[5px]">
        Maximum {stockName} Available:{" "}
        <span className="text-red-400" color="red100">
          {userBalance[tokenName]}
        </span>{" "}
        {stockName}
      </p>
      <p>
        You can <span className="text-green-400">buy</span> or{" "}
        <span className="text-orange-400" color="orange100">
          deposit
        </span>{" "}
        {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <VInput
              type={"textarea"}
              height="100px"
              transparent={true}
              className="!bg-transparent"
              label="Message"
              placeholder={`Welcome to ${stockName} Stock`}
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />
    </div>
  );
};

export default IDWithdraw;
