import { ethers } from "ethers";

export const signMessage = (message) =>
  new Promise(async (rs, rj) => {
    try {
      const { ethereum }: any = window;
      if (!ethereum)
        rj(new Error("No crypto wallet found. Please install it."));
      await ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(message);
      const address = await signer.getAddress();
      return rs({
        message,
        signature,
        address,
      });
    } catch (err) {
      rj(err);
      return err;
    }
  });

export const verifyMessage = async ({ message, address, signature }) => {
  try {
    const signerAddr = await ethers.utils.verifyMessage(message, signature);
    if (signerAddr !== address) {
      return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};
