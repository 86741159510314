import styled from "styled-components";

const Wrapper = styled.div``;

export const Terms = styled.div`
  a {
    color: ${(props) => props.theme.colors.white100};
    text-decoration: unset;
    :hover {
      color: ${(props) => props.theme.colors.green100};
    }
  }
  .form-check-label {
    @media screen and (max-width: 500px) {
      font-size: 10px;
    }
  }
`;

export default Wrapper;
