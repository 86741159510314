/* eslint-disable react-hooks/exhaustive-deps */
import PurchaseService from "api/purchases";
import VModal from "components/v-core/v-modal";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { formatNumber } from "utils/common";
import { MasterContext } from "context/MasterContext";
import APIs from "api";
import VTable from "components/v-core/v-table";
import VButton from "components/v-core/v-button";

const Statis = () => {
  const { auth } = useContext(MasterContext);

  const [openModal, setOpenModal] = useState(false);
  const [branchSelected, setBranchSelected] = useState(null);
  const [statistics, setStatistics] = useState([]);

  const onGetSalesStatistics = async () => {
    try {
      const res: any = await APIs.USER.getSalesStatistics({});
      setStatistics(res);
    } catch (error) {}
  };

  useEffect(() => {
    onGetSalesStatistics();
  }, []);

  const columns = [
    {
      key: "User",
      title: "ID",
      render: (item) => {
        return `${item.refer_code}`;
      },
    },
    {
      key: "Username",
      title: "Username",
      render: (item) => {
        return `${item?.fullName || item?.username}`;
      },
    },
    {
      key: "affiliate marketing",
      title: "Affiliate marketing",
      render: (item) => {
        return `${item.level}`;
      },
    },
    {
      key: "info",
      title: "Info",
      render: (item: any) => (
        <VButton
          className="border px-3"
          radius="full"
          onClick={() => handleOpenModal(item)}
        >
          View
        </VButton>
      ),
    },
  ];

  const columnsHistoriesProfit = [
    {
      key: "name",
      title: "Package Name",
      render: (item) => {
        return item.package_id?.name;
      },
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${formatNumber(item.investment?.amount || 0)} (${String(
          item.investment?.principal?.stock
        ).toUpperCase()})`;
      },
    },
    {
      key: "date",
      title: "Date",
      render: (item) => {
        return new Date(item.createdAt).toLocaleString();
      },
    },
  ];

  const handleOpenModal = async (item) => {
    console.log({
      item,
    });
    const orders: any = await PurchaseService.getPurchase({
      params: {
        type: "package",
      },
      query: {
        limit: 100,
        page: 1,
        userId: item._id,
      },
    });
    if (orders) {
      setBranchSelected(orders.docs || []);
      setOpenModal(true);
    }
  };

  const renderModal = (investSelected: any) => {
    if (investSelected) {
      return (
        <div className="max-h-[500px] overflow-auto">
          {" "}
          <VTable
            columns={columnsHistoriesProfit}
            data={investSelected}
          ></VTable>
        </div>
      );
    }
  };

  const handleClosed = () => {
    setOpenModal(false);
    setBranchSelected(null);
  };

  const _statistics = (statistics || []).map((user) => {
    const parent = String(user.parents || "")
      .split("_")
      .reverse();
    const level = parent.findIndex((a) => a === auth?._id);
    return {
      ...user,
      level: level + 1,
    };
  });

  return (
    <div className="min-w-full">
      <div className="relative w-full">
        <div className="overflow-auto">
          <VTable columns={columns} data={_statistics}></VTable>
        </div>
      </div>
      <VModal
        isOpen={openModal}
        children={renderModal(branchSelected)}
        onCancel={handleClosed}
        centered={true}
      />
    </div>
  );
};

export default Statis;
