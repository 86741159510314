import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LoanEstimate from "./components/LoanEstimate";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

const LOAN_DEFAULT_DATA = [
  {
    id: uuidv4(),
    banner: "/assets/images/slide/slide-3.jpg",
    name: "Car loan",
    offer: [
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
    ],
  },
  {
    id: uuidv4(),
    banner: "/assets/images/slide/slide-3.jpg",
    name: "Car loan",
    offer: [
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
    ],
  },
  {
    id: uuidv4(),
    banner: "/assets/images/slide/slide-3.jpg",
    name: "Car loan",
    offer: [
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
    ],
  },
  {
    id: uuidv4(),
    banner: "/assets/images/slide/slide-3.jpg",
    name: "Car loan",
    offer: [
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
    ],
  },
  {
    id: uuidv4(),
    banner: "/assets/images/slide/slide-3.jpg",
    name: "Car loan",
    offer: [
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
    ],
  },
  {
    id: uuidv4(),
    banner: "/assets/images/slide/slide-3.jpg",
    name: "Car loan",
    offer: [
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
      "Maximum of 90% of vehicle value",
    ],
  },
];

const LoanPackage = () => {
  const { themesSetting } = useContext(ThemeContext);
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);

  const handleShowDetail = (item) => {
    return history.push(`/my/loan/detail/${item.id}`);
  };

  const renderOffer = (item) => {
    return (
      <>
        {item.map((it, index) => (
          <div key={index} className="flex items-center gap-x-1">
            <div className="w-5 h-5 rounded-full">
              <Icons iconName="badge-check" />
            </div>
            <span className="text-sm">{it}</span>
          </div>
        ))}
      </>
    );
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    nextArrow: (
      <div className="custom-slick-next ">
        <Icons
          iconName="right_arrow_alt"
          className="opacity-60 hover:opacity-100"
          style={{ color: themesSetting.layout.color }}
        />
      </div>
    ),
    prevArrow: (
      <div className="custom-slick-prev">
        <Icons
          iconName="left_arrow_alt"
          className="opacity-60 hover:opacity-100"
          style={{ color: themesSetting.layout.color }}
        />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="h-full flex flex-col gap-y-5 mt-3">
      <h3>Hello, let help you choose the right loan package</h3>
      <div className="w-full h-[30%]">
        <img
          className="w-full h-full object-cover"
          src="/assets/images/slide/slide-3.jpg"
          alt=""
        />
      </div>
      <div className="w-full px-5 gap-x-2">
        <Slider {...settings}>
          {LOAN_DEFAULT_DATA.map((loan) => (
            <div className="p-2 w-full h-full">
              <div
                key={loan.id}
                className="flex flex-col gap-y-2 rounded-lg overflow-hidden pb-2"
                style={themesSetting.card}
              >
                <div className="h-[30%] w-full">
                  <img
                    className="w-full h-full object-cover rounded-tl-lg rounded-tr-lg"
                    src={loan.banner}
                    alt=""
                  />
                </div>
                <div className="flex flex-col items-center gap-y-2 px-2">
                  <span className="text-base font-bold">{loan.name}</span>
                  <div className="max-h-[100px] flex flex-col items-start gap-y-2 mt-1 overflow-y-auto">
                    {renderOffer(loan.offer)}
                  </div>
                  <VButton
                    radius="full"
                    className="w-full mt-3"
                    onClick={() => setOpenModal(true)}
                  >
                    Loan estimate
                  </VButton>
                  <span
                    className="text-xs underline cursor-pointer"
                    onClick={() => handleShowDetail(loan)}
                  >
                    Detail
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <VModal
        isOpen={openModal}
        onCancel={() => setOpenModal(false)}
        title={"Loan estimate"}
      >
        <LoanEstimate />
      </VModal>
    </div>
  );
};

export default LoanPackage;
