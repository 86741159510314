import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type IProps = {
  data?: any[];
  className?: string;
  isWidthFull?: boolean;
  isFull?: boolean;
};

const View = ({
  data,
  className,
  isWidthFull = false,
  isFull = false,
}: IProps) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
  };

  const openWindow = (link: string) => {
    window.open(link);
  };

  return (
    <Slider className="" {...settings}>
      {data?.map((item) => (
        <img
          className={`${
            isWidthFull
              ? "w-full"
              : isFull
              ? "h-full w-full"
              : "max-h-full w-auto"
          }  object-cover ${className}`}
          src={item?.src || item?.url}
          alt="banner"
          onClick={() => item.link && openWindow(item.link)}
        />
      ))}
    </Slider>
  );
};

export default View;
