import React, { useContext, useState } from "react";
import Modal from "components/v-core/v-modal";

// import Wrapper from "./styles";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { MasterContext } from "context/MasterContext";
import APIs from "api";
import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";

interface IProps {
  [key: string]: any;
}

export default function View({ isOpen, setIsOpen, getMyBanks }: IProps) {
  const { auth } = useContext(MasterContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(null);
  console.log({ error });
  const [bankName, setBank] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAddBank = async (data) => {
    if (!bankName) {
      return setError(new Error("Please select bank name!"));
    }

    setError(null);
    setLoading(true);

    const body = {
      account_name: data.bankAccountHolderName,
      account_number: data.bankAccountNumber,
      bank_name: bankName,
      user_id: auth?._id,
    };

    try {
      await APIs.BANK.createBank({ body });
      toast.success("Added bank successful!");
      setIsOpen(false);
      getMyBanks();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const onChangeBankName = (value: any) => {
    setBank(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Add new bank account"
      centered={true}
      onConfirm={handleSubmit(handleAddBank)}
      onCancel={() => setIsOpen(false)}
      loading={loading}
    >
      <div className="mt-[15px] p-[15px]">
        <form>
          <div className="w-full">
            <VDropdown
              label="Bank name"
              options={[
                {
                  label: "Vietcombank",
                  value: "Vietcombank",
                },
                {
                  label: "Techcombank",
                  value: "Techcombank",
                },
                {
                  label: "TPBank",
                  value: "TPBank",
                },
                {
                  label: "VietinBank",
                  value: "VietinBank",
                },
                {
                  label: "MB Bank",
                  value: "MB Bank",
                },
                {
                  label: "ACB Bank",
                  value: "ACB Bank",
                },
                {
                  label: "Agribank",
                  value: "Agribank",
                },
              ]}
              onChange={(e) => onChangeBankName(e)}
            />
          </div>

          <Controller
            name="bankAccountNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <VInput
                type="number"
                className="!bg-transparent"
                transparent={true}
                placeholder="Your bank account number"
                label="Bank account number"
                hasError={errors.bankAccountNumber ? true : false}
                {...field}
              />
            )}
          />

          <Controller
            name="bankAccountHolderName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <VInput
                transparent={true}
                className="!bg-transparent"
                placeholder="Your bank account holder name"
                label="Bank account holder name"
                hasError={errors.bankAccountHolderName ? true : false}
                {...field}
              />
            )}
          />
        </form>
      </div>
    </Modal>
  );
}
