import { MasterContext } from "context/MasterContext";
import useQuery from "hooks/useQuery";
import React, { useContext } from "react";
import FileViewer from "react-file-viewer";

const PdfFiles = () => {
  const { key } = useQuery();
  const { allMenus } = useContext(MasterContext);
  const menu = allMenus?.find((m) => m._id === key);

  return (
    <div className="min-h-[80vh] flex items-center justify-center text-center">
      {/* <iframe src={menu?.pdf_file} className="w-full min-h-[80vh]"></iframe> */}
      {menu && (
        <FileViewer
          fileType="pdf"
          filePath={menu?.pdf_file}
          fullScreen={true}
        />
      )}
    </div>
  );
};

export default PdfFiles;
