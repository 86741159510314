import { request, parseErrorResponse } from "./request";

const getDocuments = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/documents`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  getDocuments,
};

export default APIs;
