import VButton from "components/v-core/v-button";

type IProps = {
  network?: string;
  stock?: any;
  setNetwork?: (e: string) => void;
};

const View = ({ network, setNetwork, stock }: IProps) => {
  return (
    <>
      <span>Network</span>
      <div className="flex gap-x-2">
        {stock?.network?.map((n) => (
          <VButton
            radius="full"
            type={network !== n ? "outline" : "primary"}
            className={`w-[100px]`}
            onClick={() => setNetwork(n)}
          >
            {n.toUpperCase()}
          </VButton>
        ))}
      </div>
    </>
  );
};

export default View;
