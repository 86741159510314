import { request, parseErrorResponse } from "./request";

const sendSupport = (body: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/supports`, body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSupportsByUser = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/supports`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SupportService = {
  sendSupport,
  getSupportsByUser,
};

export default SupportService;
