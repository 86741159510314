/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import Modal from "components/v-core/v-modal";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import UserService from "api/users";
import { formatNumber } from "utils/common";
import { MasterContext } from "context/MasterContext";
import VTable from "components/v-core/v-table";
import VButton from "components/v-core/v-button";
import { renderStock } from "utils/render";

const Revenues = () => {
  const { stockDefault } = useContext(MasterContext);
  const columns = [
    {
      key: "type",
      title: "Type",
      render: (item: any) => <span>{item.type}</span>,
    },
    {
      key: "amount",
      title: "Amount",
      render: (item: any) => (
        <span>
          {item.amount} ({renderStock(stockDefault)})
        </span>
      ),
    },
    {
      key: "claimed",
      title: "Claimed",
      render: (item: any) => (
        <span>
          {item.claimed} {renderStock(stockDefault)}
        </span>
      ),
    },
    {
      key: "Remain",
      title: "Remain",
      render: (item: any) => <span>{+item.amount - item.claimed}</span>,
    },
    {
      key: "infoRevenue",
      title: "Histories Information Revenue",
      render: (item: any) => (
        <VButton
          px="3"
          py="1"
          radius="md"
          onClick={() => handleLoadHistory(item)}
        >
          View
        </VButton>
      ),
    },
    {
      key: "Histories Claim",
      title: "Histories Claim",
      render: (item: any) => (
        <VButton
          px="3"
          py="1"
          radius="md"
          onClick={() => {
            setRevenueSelected(item);
            setOpenModalClaimed(true);
          }}
        >
          View
        </VButton>
      ),
    },
    {
      key: "claim",
      title: "Claim",
      render: (item: any) => (
        <VButton
          px="3"
          radius="md"
          py="1"
          loading={loading}
          onClick={() => handleClaim(item)}
          disabled={+item.claimed >= +item.amount || loading}
        >
          Claim
        </VButton>
      ),
    },
  ];

  const columnsHistoriesClaimed = [
    {
      key: "date",
      title: "Date",
      render: (item) => new Date(item.date).toLocaleString(),
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${item.amount} (${renderStock(stockDefault)})`;
      },
    },
  ];

  const columnsHistoriesRevenue = [
    {
      key: "date",
      title: "Date",
      render: (item) => new Date(item.createdAt || item.date).toLocaleString(),
    },
    {
      key: "id",
      title: "UserId",
      render: (item) => item?.user?.refer_code,
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${formatNumber(item.amount)} (${String(
          item.stock || "VDC"
        ).toUpperCase()})`;
      },
    },
  ];

  const defaultHistory = {
    old: [],
    new: [],
    totalPages: 1,
  };

  const defaultParams = {
    page: 1,
    limit: 20,
  };

  const { auth } = useContext(MasterContext);

  const [revenues, setRevenues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalClaimed, setOpenModalClaimed] = useState(false);
  const [openModalInfoRevenue, setOpenModalInfoRevenue] = useState(false);
  const [revenueSelected, setRevenueSelected] = useState(null);
  const [histories, setHistories] = useState(defaultHistory);
  const [activeTab, setActiveTab] = useState("new");
  const [lazyParams, setLazyParams] = useState(defaultParams);
  useEffect(() => {
    getRevenues();
  }, []);

  useEffect(() => {
    if (revenueSelected) {
      getRevenuesHistory();
    }
  }, [lazyParams, revenueSelected]);

  const handleLoadHistory = (item) => {
    setRevenueSelected(item);
    setOpenModalInfoRevenue(true);
  };

  const getRevenuesHistory = async () => {
    try {
      const res: any = await UserService.getRevenuesHistory({
        params: {
          revenueId: revenueSelected._id,
        },
        query: {
          ...lazyParams,
        },
      });
      if (res && res.docs) {
        setHistories({
          old: revenueSelected.histories_received,
          new: res.docs,
          totalPages: res?.totalPages || 1,
        });
      }
    } catch (error) {
      setHistories(defaultHistory);
    }
  };
  const getRevenues = async () => {
    try {
      const res: any = await APIs.USER.getRevenuesInSystem({});
      if (res.revenues) {
        setRevenues(res.revenues);
      }
    } catch (error) {
      // console.log();
    }
  };

  const handleClaim = async (revenue) => {
    try {
      setLoading(true);
      const { type } = revenue;
      await APIs.USER.claimRevenueSystem({
        body: {
          type,
        },
      });
      setLoading(false);
      getRevenues();
      toast.success("Claim success!");
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const renderModalClaimed = (investSelected: any) => {
    if (investSelected) {
      return (
        <div className="max-h-[500px] overflow-auto">
          {" "}
          <VTable
            columns={columnsHistoriesClaimed}
            data={investSelected.histories || []}
          ></VTable>
        </div>
      );
    }
  };

  const renderModalRevenue = (investSelected: any) => {
    if (investSelected) {
      return (
        <div className="max-h-[500px] overflow-auto">
          {" "}
          <VTable
            columns={columnsHistoriesRevenue}
            data={histories.new}
          ></VTable>
        </div>
      );
    }
  };

  const handleClosed = () => {
    setOpenModalClaimed(false);
    setOpenModalInfoRevenue(false);
    setRevenueSelected(null);
  };

  return (
    <>
      <div>
        {auth?.is_leader && <p>Percent Sale : {auth?.percent_sales || 0}%</p>}
        {auth?.id_region && (
          <p>Percent Region : {auth?.percent_region || 0}%</p>
        )}
        {auth?.id_province && (
          <p>Percent Provice : {auth?.percent_provice || 0}%</p>
        )}

        <VTable columns={columns} data={revenues} />
      </div>
      <Modal
        isOpen={openModalClaimed}
        children={renderModalClaimed(revenueSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <Modal
        isOpen={openModalInfoRevenue}
        children={renderModalRevenue(revenueSelected)}
        onCancel={handleClosed}
        centered={true}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        isPaginate={activeTab === "new" && histories?.totalPages > 1}
        lazyParams={lazyParams}
        onChangeParams={setLazyParams}
        totalPages={histories.totalPages}
      />
    </>
  );
};

export default Revenues;
