/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import Button from "components/v-core/v-button";
import { DetailRightView, Title, Warning } from "../../../styles";
import { formatNumber, randString } from "utils/common";

import APIs from "api";

import Verify from "../../verify";
import Network from "../../networks";
import HistoryP2P from "../history-p2p";
import { useParams, useHistory } from "react-router-dom";
import { MasterContext } from "context/MasterContext";
import VInput from "components/v-core/v-input";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";

const View = (props) => {
  const { balances, auth, stocks } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const { stock: stockParams } = useParams();
  const history = useHistory();

  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState<number>(null);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState("bsc");
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const [stock, setStock] = useState(null);

  useEffect(() => {
    if (stocks.length > 0) {
      setStock(stocks.find((c) => c.symbol === stockParams));
    }
  }, [stocks, stockParams]);

  const handleChange = (e) => {
    if (+e < 0) {
      setAmount(0);
    } else if (+e === 0) {
      setAmount(null);
    } else {
      setAmount(+e);
    }
  };

  const handleClickButtonTop = async () => {
    setErrMessage("");
    if (step !== 2) {
      setStep(step + 1);
    } else {
      try {
        if (amount > 0) {
          setLoading(true);
          await APIs.PAYMENT.withdraw({
            body: {
              verify,
              amount: amount,
              content: randString(false, 10),
              address,
              network,
              stock: stockParams,
            },
          });
          getPayment();
          toast.success("Withdraw successful!");
          setStep(1);
          setLoading(false);
        } else {
          setErrMessage("Please enter amount");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.errors);
      }
    }
  };

  const backStep = () => {
    if (step === 1) {
      history.push(`/my/wallets/${stockParams}/withdraw/`);
    }
    setStep(step - 1);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: auth?._id,
        type: "withdraw",
        stockParams,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPayment();
  }, []);

  return (
    <>
      <DetailRightView>
        <Title bgColor={"grey200"} just="space-between">
          <p>Withdraw {String(stockParams || "").toUpperCase()}</p>
        </Title>

        {step === 1 && (
          <div
            style={themesSetting?.card}
            className="flex flex-col gap-y-2 p-3 rounded"
          >
            <Network stock={stock} network={network} setNetwork={setNetwork} />
            <VInput
              transparent={true}
              label="Address"
              placeholder="0x9f0F7601b9143ACc36aE838800153805698e8f08"
              className="!bg-transparent"
              onChange={(e) => setAddress(e.target.value)}
            />
            <VInput
              isCurrency={true}
              transparent={true}
              label={`Amount (${stockParams?.toUpperCase()})*`}
              placeholder="10,000"
              value={amount}
              hasError={errMessage ? true : false}
              errMessage={errMessage}
              iconRight={
                <Button
                  className={"action"}
                  onClick={() => setAmount(+balances[stockParams] || 0)}
                >
                  MAX
                </Button>
              }
              onValueChange={(e) => handleChange(e)}
            />
            <Warning>
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                Maximum {String(stockParams || "").toUpperCase()} Withdrawal:{" "}
                <span>
                  {formatNumber(+balances[stockParams] || 0)}
                  {String(stockParams).toUpperCase()}
                </span>
              </p>
            </Warning>
            <Warning>
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                Please enter the correct network address for the transaction.
              </p>
            </Warning>
          </div>
        )}
        {step === 2 && <Verify verify={verify} setVerify={setVerify} />}
      </DetailRightView>
      <div className="flex flex-col items-center gap-y-2 mt-2">
        <VButton
          className="w-[350px]"
          radius="full"
          loading={loading}
          onClick={handleClickButtonTop}
        >
          {step === 2 ? "Confirm" : "Continue"}
        </VButton>
        {step !== 0 && (
          <VButton
            type="outline"
            className="w-[350px]"
            radius="full"
            onClick={() => backStep()}
          >
            Back
          </VButton>
        )}
      </div>
      {hisDeposit.length ? (
        <HistoryP2P lists={hisDeposit} type="withdraw" stock={stockParams} />
      ) : (
        <></>
      )}
    </>
  );
};

export default View;
