import React, { useState } from "react";
import UploadDocs from "../upload/UploadDocs";
import { useParams } from "react-router-dom";
import APIs from "api";

type IProps = {
  data?: any;
  setData?: (e: any) => void;
  isEdit?: boolean;
};

const Roadmaps = (props: IProps) => {
  const { data, setData, isEdit } = props;
  const params = useParams();
  const [image, setImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, SetIsLoading] = useState(false);

  const setFile = (f: any, id: string) => {
    setImage((pre) => ({
      ...pre,
      [id]: f,
    }));
  };

  const onSubmitModal = async () => {
    SetIsLoading(true);
    const formData = new FormData();
    formData.append("folderPath", `landing-page/${params.symbol}`);

    formData.append("files", image?.file);
    const listImg: any = await APIs.UPLOAD.uploadMultiFile({
      body: formData,
    });
    listImg.forEach((el) =>
      setData((prev) => ({
        ...prev,
        roadmaps: {
          ...prev.roadmaps,
          image: el.filePath,
        },
      }))
    );
    SetIsLoading(false);
    setOpenModal(false);
  };

  return (
    <>
      <div className="h-[50vh] w-full">
        <UploadDocs
          isLoading={isLoading}
          data={data?.roadmaps}
          isEdit={isEdit}
          openModal={openModal}
          setOpenModal={setOpenModal}
          file={image?.fle}
          setFile={setFile}
          onSubmit={onSubmitModal}
          srcImg={data?.roadmaps?.image}
        />
      </div>
    </>
  );
};

export default Roadmaps;
