/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "config/routes";
import DepositStock from "./components/deposit-stock";
import WithdrawStock from "./components/withdraw-stock";
import HistoryStock from "./components/history-stock";
import SellStock from "./components/sell-stock";
import DepositP2P from "./components/p2p/deposit-p2p";
import DepositSpot from "./components/spot/deposit-spot";
import WithdrawP2P from "./components/p2p/withdraw-p2p";
import WithdrawSpot from "./components/spot/withdraw-spot";
import WithdrawCV from "./components/withdraw-cv";
import WithdrawQrMart from "./components/withdraw-qrmart";
import Transfer from "./components/transfer";
import Wallet from "./components/wallets";
import VButton from "components/v-core/v-button";

const Wallets = (props) => {
  const history = useHistory();
  const location = useLocation();

  const isShowBack = location.pathname !== ROUTES.MY_WALLETS;

  const handleBack = () => {
    history.push(ROUTES.MY_WALLETS);
  };

  return (
    <div className="w-full p-3">
      {isShowBack && (
        <VButton type="outline" className="!border-none" onClick={handleBack}>
          <div className="flex items-center justify-center gap-x-1">
            <span className="text-xl font-medium">{`<`}</span>
            <span>Back</span>
          </div>
        </VButton>
      )}
      <Switch>
        {/* <Route path={ROUTES.DEPOSIT_VND} component={DepositVnd} /> */}
        {/* <Route path={ROUTES.WITHDRAW_VND} component={WithdrawVND} /> */}
        {/* <Route path={ROUTES.DEPOSIT_VNP} component={DepositVnp} /> */}

        <Route path={ROUTES.DEPOSIT_P2P} component={DepositP2P} />
        <Route path={ROUTES.WITHDRAW_P2P} component={WithdrawP2P} />
        <Route path={ROUTES.DEPOSIT_SPOT} component={DepositSpot} />
        <Route path={ROUTES.WITHDRAW_SPOT} component={WithdrawSpot} />
        <Route path={ROUTES.WITHDRAW_CV} component={WithdrawCV} />
        <Route path={ROUTES.WITHDRAW_QRMART} component={WithdrawQrMart} />
        <Route path={ROUTES.DEPOSIT_STOCK} component={DepositStock} />
        <Route path={ROUTES.WITHDRAW_STOCK} component={WithdrawStock} />
        <Route path={ROUTES.HISTORY_STOCK} component={HistoryStock} />
        <Route path={ROUTES.SELL_STOCK} component={SellStock} />
        <Route path={ROUTES.TRANSFER} component={Transfer} />
        <Route path={"/"} component={Wallet} />
      </Switch>
    </div>
  );
};

export default Wallets;
