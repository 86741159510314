import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getProducts = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/products/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInfoCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/codes/check-code/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ProductApi = {
  getProducts,
  getInfoCode,
};

export default ProductApi;
