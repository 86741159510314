import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 20px;
  border-radius: 8px;
  input {
    color: white;
    margin-top: 15px;
    height: 40px;
  }
`;

export const TopMain = styled.div`
  margin-bottom: 40px;
  h4 {
    margin-bottom: 40px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 15px 0;
  }
  .pin-secure {
    .details {
      font-size: 12px;
    }
  }
  .input-code {
    padding: 0 12px;
    margin-bottom: 15px;
    input {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }
  .reset {
    font-size: 14px;
    cursor: pointer;
    color: white;
  }
`;
export const Title = styled.h4`
  margin-bottom: 10px !important;
`;

export const ContainInput = styled.div`
  input {
    width: 100%;
  }
`;

export const Image = styled.div`
  width: 120px;
  img {
    width: 100%;
  }
`;
export const ContainButton = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const BottomMain = styled.div`
  margin: 60px 0;
`;

export const Tutorial = styled.div`
  margin-bottom: 30px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 15px 0;
  }
  img {
    margin-top: 15px;
    cursor: pointer;
  }
`;
export const Step = styled.div`
  p {
    display: inline;
    margin-left: 12px;
    font-size: 16px;
    line-height: 19px;
  }
`;
export const Item = styled.div`
  span:first-child {
    padding: 2px 7px;
    background: ${(props) => props.theme.colors["orange100"]};
    border-radius: 50%;
    color: black;
  }
  span:last-child {
    padding: none;
    background: none;
    color: ${(props) => props.theme.colors["orange100"]};
    cursor: pointer;
  }
  margin-bottom: 20px;
`;
export const Flex = styled.div`
  display: flex;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin-bottom: 10px;
    }
  }
`;
export const Description = styled.div`
  margin-left: 20px;
`;
