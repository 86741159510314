import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import configureStore from "./redux/configureStore";
// import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import ThemeProvider from "./context/ThemeContext";

import "./index.css";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-modern-drawer/dist/index.css";
import { MasterProvider } from "context/MasterContext";
import SocketProvider from "context/SocketContext";

// const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <MasterProvider>
      <ThemeProvider>
        <SocketProvider>
          <Router>
            <App />
          </Router>
        </SocketProvider>
      </ThemeProvider>
    </MasterProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
