import { request, parseErrorResponse } from "./request";

const createYourEstock = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/your-estock`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getYourEstock = (symbol: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/your-estock/${symbol}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getYourEstockByUserId = (userId: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/your-estock/listEstock/${userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateEstock = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/your-estock/${req.symbol}/${req.token}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const verifyTokenEdit = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/your-estock/verifyToken`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createTokenIntime = (id: string, req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/your-estock/create-token/${id}`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const addEStockManagers = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/your-estock/eStock-managers/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changeStatusEStock = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/your-estock/change-status/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const YourStockService = {
  createYourEstock,
  getYourEstock,
  updateEstock,
  verifyTokenEdit,
  getYourEstockByUserId,
  createTokenIntime,
  addEStockManagers,
  changeStatusEStock,
};

export default YourStockService;
