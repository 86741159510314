import { MasterContext } from "context/MasterContext";
import useQuery from "hooks/useQuery";
import { useContext } from "react";
import { convertUrl } from "utils/common";

const Webview = () => {
  const { key } = useQuery();
  const { allMenus } = useContext(MasterContext);
  const menu = allMenus?.find((m) => m._id === key);

  return (
    <div className="w-full h-full">
      <iframe className="w-full h-full" src={convertUrl(menu?.url)}></iframe>
    </div>
  );
};

export default Webview;
