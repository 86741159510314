import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.colors["black100"]};
  border-radius: 8px;
  width: 100%;
  padding: 30px 0;
  overflow: hidden;
  text-align: center;
  h4 {
    text-align: center;
    font-weight: 600;
    font-size: 25px;
  }
  img {
    max-width: 1000px;
    width: 100%;
  }
`;
export const CountdownContainer = styled.div`
  .countdown-item {
    .countdown-item-value {
      background-image: linear-gradient(90deg, #21d397 0, #7450fe) !important;
    }
  }
`;
