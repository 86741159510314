import styled from "styled-components";

export const ChannelWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .heading {
    font-size: 24px;
    font-weight: bold;
  }
`;

export const ButtonPreview = styled.div`
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  .id {
    width: 75%;
  }
  .prioritized {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  .delete {
    width: 5%;
  }
`;

export const RowItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  gap: 0px 8px;
  .input-id {
    width: 75%;
  }
  .input-prior {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
  }
  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
    button {
      background: red;
      border-radius: 50%;
      cursor: pointer;
      outline: none;
      border: none;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
    }
    button:hover {
      opacity: 85%;
    }
  }
`;
