import APIs from "api";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { MasterContext } from "context/MasterContext";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { auth } = useContext(MasterContext);
  const { verify, setVerify } = props;
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [security, setSecurity] = useState(null);

  const getCode = async () => {
    try {
      setLoading(true);
      await APIs.AUTH.resendCode({
        body: { email: auth?.email, title: "Verification Code - Set pin code" },
      });
      setIsSend(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.errors);
    }
  };

  const getSecurity = async () => {
    try {
      const res: any = await APIs.USER.getSecurity();
      setSecurity(res);
    } catch (error) {}
  };

  useEffect(() => {
    getSecurity();
  }, []);

  return (
    <div>
      <div>
        <VInput
          transparent={true}
          className="!bg-transparent"
          label="Email verification code"
          onChange={(e) =>
            setVerify({
              ...verify,
              email_code: e.target.value,
            })
          }
          value={verify.email_code}
          suffixIcon={
            <VButton
              type="outline"
              loading={loading}
              onClick={getCode}
              disabled={isSend}
            >
              {" "}
              {!isSend ? "Get code" : "Code Send"}
            </VButton>
          }
        />
        <span className="details">
          Enter the 6 digit code send to {auth?.email}
        </span>
        {security && security?.is_enable && (
          <>
            {" "}
            <VInput
              transparent={true}
              className="!bg-transparent"
              label=" Google verification code"
              onChange={(e) =>
                setVerify({
                  ...verify,
                  google_code: e.target.value,
                })
              }
              value={verify.google_code}
            />
            <span className="details">
              Enter the 6 digit code from Google Authenticator
            </span>
          </>
        )}
      </div>
    </div>
  );
}
