/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "components/v-core/v-modal";
import { toast } from "react-toastify";
import { formatCurrency, formatDate } from "utils/common";
import Notification from "./components/notifications";
import { AiFillProject } from "react-icons/ai";

import FormProjectICO from "./components/form-project";
import Advertisement from "./components/advertisement";
import RequestLandingPage from "./components/request-landing-page";

import FromAddIDManagers from "components/add-page-managers";
import Switch from "react-switch";
import Dropdown from "components/v-core/v-dropdown";
import { MasterContext } from "context/MasterContext";
import VButton from "components/v-core/v-button";
import VTable from "components/v-core/v-table";
import VTabs from "components/v-core/v-tabs";
import { ThemeContext } from "context/ThemeContext";
import { renderStatus, renderStock } from "utils/render";
import YourStockService from "api/yourEstock";
import ProjectService from "api/projects";

const defaultStateProject = {
  name: "",
  symbol: "",
  supply: 0,
  info: {},
  cal: {},
  is_holding_balance: true,
  status: "init",
  category_id: null,
};

const optionManagersSection = {
  header: {
    title: "Header",
  },
  fundamentals: {
    title: "Fundamentals",
  },
  roadmap: {
    title: "Roadmap",
  },
  doc: {
    title: "Documents",
  },
  certificate: {
    title: "Project Certificate",
  },
  partner: {
    title: "Our Partners",
  },
  dataTeam: {
    title: "Our Team",
  },
  video: {
    title: "Videos",
  },
  review: {
    title: "Review",
  },
  footer: {
    title: "Footer",
  },
  project: {
    title: "Project",
  },
  round: {
    title: "Rounds",
  },
  profit: {
    title: "Profit",
  },
};

const statusProjects = [
  {
    label: "Init",
    value: "init",
  },
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Opening",
    value: "opening",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const View = () => {
  const { auth } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const history = useHistory();

  const columnsMyProjects = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (data: any) => (
        <img className="h-[30px]" src={data?.project?.logo} alt="logoToken" />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data: any) => <span>{data?.project?.name}</span>,
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (data: any) => <span>{renderStock(data?.project?.symbol)}</span>,
    },
    {
      title: "Total supply",
      dataIndex: "supply",
      key: "supply",
      render: (data: any) => (
        <span>{formatCurrency(data?.project?.total_supply)}</span>
      ),
    },
    {
      title: "Status Project",
      dataIndex: "status_projects",
      key: "status_projects",
      render: (data) => renderStatus(data?.project?.status),
    },
    {
      key: "on_off",
      title: "On/Off Project",
      dataIndex: "on_off",
      render: (data: any) => (
        <Dropdown
          options={statusProjects}
          value={data?.projectId?.status || "init"}
          onChange={(e) => onChangeStatusProject(e, data?.projectId?._id)}
        />
      ),
    },
    {
      title: "Status Landing Page",
      dataIndex: "status_page",
      key: "status_page",
      render: (data: any) => renderStatus(data?.status),
    },
    {
      key: "on_off",
      title: "On/Off Landing Page",
      dataIndex: "on_off",
      render: (item: any) => (
        <Switch
          onChange={(e) => handleChangeStatusByTrustUser(e, item._id)}
          checked={item.status === "approved"}
        />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => formatDate(data?.project?.createdAt),
    },
    {
      title: "Action Project",
      dataIndex: "action_project",
      key: "action_project",
      render: (data) => (
        <div className=" flex flex-col gap-y-2 whitespace-nowrap">
          <VButton
            disabled
            // disabled={checkManagersSection(data, "profit")}
            onClick={() => handleAction(data?.project, "profit")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            Profit
          </VButton>
          <VButton
            onClick={() => handleAction(data?.project, "view")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            View
          </VButton>
          {/* <VButton
            disabled={checkManagersSection(data, "round")}
            onClick={() => handleAction(data?.project, "round")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            Rounds
          </VButton> */}
          <VButton
            disabled={
              // (!auth?.permissions?.["projects.trust"] &&
              //   data?.project?.status === "opening") ||
              checkManagersSection(data, "projects")
            }
            onClick={() => handleAction(data?.project, "edit")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            Edit
          </VButton>
        </div>
      ),
    },
    {
      title: "Action LandingPage",
      dataIndex: "action_landingpage",
      key: "action_landingpage",
      render: (data: any) => (
        <div className="flex flex-col gap-y-2 whitespace-nowrap">
          <VButton
            onClick={() => handleAction(data, "view-landingpage")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            View
          </VButton>
          <VButton
            // disabled={
            //   !auth?.permissions?.["projects.trust"] &&
            //   data?.status === "approved"
            // }
            onClick={() => handleAction(data, "edit-landingpage", true)}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            Edit
          </VButton>

          <VButton
            disabled
            onClick={() => handleAction(data, "view-request")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            View request
          </VButton>
        </div>
      ),
    },
    // {
    //   title: "Request Pre-mainet",
    //   dataIndex: "request_pre_mainet",
    //   key: "request_pre_mainet",
    //   render: (data: any) => (
    //     <div className="flex flex-col gap-y-2 whitespace-nowrap">
    //       {data?.projectId?.requestMainet?.status !== "pre-mainet" ? (
    //         <VButton
    //           type="outline"
    //           onClick={() => handleAction(data?.projectId, "pre-mainet")}
    //           className="h-[30px] bg-transparent border rounded-md"
    //           style={themesSetting.container}
    //         >
    //           Request
    //         </VButton>
    //       ) : (
    //         <>
    //           {data?.projectId?.requestMainet?.status} <br />
    //           {data?.projectId?.requestMainet?.enable && (
    //             <>
    //               (
    //               <span
    //                 className={`text-${
    //                   data?.projectId?.requestMainet?.enable === "enable"
    //                     ? "green-500"
    //                     : "white"
    //                 }`}
    //               >
    //                 {data?.projectId?.requestMainet?.enable}
    //               </span>
    //               )
    //             </>
    //           )}
    //         </>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "Add Managers",
      dataIndex: "ID_user",
      key: "ID_user",
      render: (data: any) => (
        <div className="flex flex-col gap-y-2 whitespace-nowrap">
          <VButton
            onClick={() => handleAction(data, "add_id_user")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            Add Managers
          </VButton>
        </div>
      ),
    },
    {
      title: "Mini Game",
      dataIndex: "mini_game",
      key: "mini_game",
      render: (data: any) => (
        <div className="flex flex-col gap-y-2 whitespace-nowrap">
          <VButton
            disabled
            onClick={() => handleAction(data?.projectId, "createMinigame")}
            className="h-[30px] bg-transparent border rounded-md"
            style={themesSetting.container}
          >
            Create
          </VButton>
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [dataTables, setDataTables] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);

  const [dataDetail, setDataDetail] = useState<any>(null);
  const [active, setActive] = useState<string>("project");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [type, setType] = useState<string>();
  const [openModalEStockManagers, setOpenModalEStockManagers] =
    useState<boolean>(false);

  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");
  const tabs = [
    {
      name: "Project",
      value: "project",
      icon: <AiFillProject size={17} />,
    },
    // {
    //   name: "Notification",
    //   value: "notification",
    //   icon: <AiFillNotification size={17} />,
    // },
    // {
    //   name: "Advertisement",
    //   value: "advertisement",
    //   icon: <RiAdvertisementLine size={17} />,
    // },
  ];

  useEffect(() => {
    getListEstock();
  }, [auth]);

  useEffect(() => {
    if (!currentTab) {
      history.push("?tab=project");
    }
  }, [currentTab]);

  const getListEstock = async () => {
    try {
      const listEstock: any = await YourStockService.getYourEstockByUserId(
        auth._id
      );
      if (listEstock) {
        setDataTables(listEstock);
      }
    } catch (error) {}
  };

  const checkManagersSection = (data: any, section: string) => {
    const managersSections = data.estock_managers_with_sections?.[auth?._id];
    if (
      !(
        managersSections?.includes("all") || managersSections?.includes(section)
      ) &&
      !(String(data.user_id) === String(auth?._id) || auth?.role === "ADMIN")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handelTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
    if (tab === "project") {
      setDataTables((prev) => prev.filter((el) => el?.projectId));
    } else {
      getListEstock();
    }
  };

  const handleAction = async (
    data: any,
    action: string,
    isEditLandigPage?: boolean
  ) => {
    switch (action) {
      case "profit":
        setType("profit");
        history.push(`/my/ibo-project/${data?._id}/edit`);
        break;
      case "round":
        setType("round");
        history.push(`/my/ibo-project/${data?._id}/round`);
        break;
      case "add_id_user":
        setType("add_id_user");
        setDataDetail(data);
        setOpenModalEStockManagers(true);
        break;
      case "pre-mainet":
        try {
          await ProjectService.requestMainet({
            params: {
              id: data._id,
            },
            body: {
              enable: "disable",
            },
          });
          getListEstock();
        } catch (error) {
          toast.error(error.errors || "Error!");
        }
        break;
      case "view-landingpage":
        window.open(`/project/${data?.project?.symbol}`);
        break;
      case "edit-landingpage":
        try {
          const token = await YourStockService.createTokenIntime(data?._id, {
            symbol: data?.project?.symbol,
          });
          if (token) {
            window.open(
              `/project/${data?.project?.symbol}?isEdit=true&token=${token}`
            );
          } else {
            toast.error("Something went error!");
          }
        } catch (error) {
          toast.error("Something went error!");
        }
        break;
      case "view-request":
        setActive("view-request");
        setDataDetail(data);
        break;
      case "createMinigame":
        setType("createMinigame");
        const token = localStorage.getItem("auth");
        window.open(
          `${process.env.REACT_APP_MINIGAME}/my/create-minigame?token=${token}&projectId=${data?._id}`
        );
        break;

      default:
        setIsModal(true);
        setDataDetail(data);
        if (action === "view") {
          setIsDisable(true);
          setIsShowDetail(true);
          setType("view");
        } else {
          setIsDisable(false);
          setIsShowDetail(false);
          setType("edit");
        }
    }
  };

  const onChangeStatusProject = async (status, id) => {
    try {
      const data = await ProjectService.changeStatusProject({
        params: {
          id: id,
        },
        body: {
          status: status.value,
        },
      });
      if (data) {
        toast.success("Successfull!");
        getListEstock();
      }
    } catch (error) {
      toast.error(error.errors || "Error!");
    }
  };

  const handleChangeStatusByTrustUser = async (
    checked: boolean,
    id: string
  ) => {
    try {
      const data = await YourStockService.changeStatusEStock({
        params: {
          id: id,
        },
        body: {
          status: checked ? "approved" : "declined",
        },
      });
      if (data) {
        toast.success("Successfull!");
        getListEstock();
      }
    } catch (error) {
      toast.error(error.errors || "Error!");
    }
  };

  const handleAddEStockManagers = async (
    value: any,
    dataManagersSection: any,
    type: string
  ) => {
    try {
      setLoading(true);
      if (!["add", "delete", "edit"].includes(type))
        return toast.error("Error!");
      const res = await YourStockService.addEStockManagers({
        params: {
          id: dataDetail._id,
        },
        body: {
          refer_code: value.refer_code,
          sectionManagers: dataManagersSection,
          type: type,
        },
      });
      if (res) {
        setLoading(false);
        toast.success("Successfull!");
        getListEstock();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.errors || "Error!");
    }
  };

  const handleChangeProject = (name, value, parent?: string) => {
    if (parent)
      setDataDetail((prev) => ({
        ...prev,
        [parent]: {
          ...(prev[parent] || {}),
          [name]: value,
        },
      }));
    else
      setDataDetail((prev) => ({
        ...prev,
        [name]: value,
      }));
  };
  console.log({
    dataDetail,
  });

  const handleEditICO = async () => {
    try {
      if (type === "view") return setIsModal(false);
      // need validate
      if (dataDetail?.cal.stock_to_buy) {
        const project: any = await ProjectService.editProject({
          params: {
            id: dataDetail._id,
          },
          body: {
            ...dataDetail,
          },
        });
        setIsModal(false);
        if (project) {
          toast.success("Update successfull!");
          getListEstock();
        } else {
          toast.error("Error!");
        }
      } else {
        toast.error("Please enter data!");
      }
    } catch (error) {
      toast.error("Error!");
      setIsModal(false);
    }
  };

  const renderEStockManagers = () => {
    return (
      <div>
        <FromAddIDManagers
          page={"landing_page"}
          data={dataDetail?.estock_managers}
          dataManagersWithSection={
            dataDetail?.estock_managers_with_sections || {}
          }
          optionSection={optionManagersSection}
          loading={loading}
          onSubmit={handleAddEStockManagers}
        />
      </div>
    );
  };

  return (
    <div className="h-full min-h-full p-[14px] w-full relative overflow-hidden">
      <VTabs tabs={tabs} onChangeTabs={handelTabs} tabActive={currentTab} />

      <br />
      {currentTab === "project" && (
        <VTable
          columns={
            auth?.permissions?.["projects.trust"]
              ? columnsMyProjects
              : columnsMyProjects.filter((el) => el.key !== "on_off")
          }
          data={dataTables}
        ></VTable>
      )}
      {active === "view-request" && (
        <RequestLandingPage data={dataDetail} setActive={setActive} />
      )}
      {currentTab === "notification" && <Notification />}
      {currentTab === "advertisement" && <Advertisement />}

      <div
        className="absolute right-0 bottom-0 md:bottom-[100px] w-[60px] h-[60px] bg-white border rounded-full p-[18px] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
        onClick={() => history.push("/create-your-estock")}
      >
        <img
          className="w-full"
          src="/assets/images/icons/createPages.svg"
          alt=""
        />
      </div>
      <Modal
        key={dataDetail?._id}
        isOpen={isModal}
        title="IBO project"
        centered={true}
        onCancel={() => setIsModal(false)}
        onConfirm={() => handleEditICO()}
      >
        <div className="max-h-[300px] md:max-h-[500px] mt-[15px] p-[15px] overflow-auto">
          <form id={dataDetail?._id}>
            <FormProjectICO
              isDisable={isDisable}
              isShowDetail={isShowDetail}
              projectInfo={dataDetail}
              handleChangeProject={handleChangeProject}
            />
          </form>
        </div>
      </Modal>

      <Modal
        centered={true}
        isOpen={openModalEStockManagers}
        title="E.Stock Managers"
        children={renderEStockManagers()}
        onCancel={() => setOpenModalEStockManagers(false)}
      />
    </div>
  );
};

export default View;
