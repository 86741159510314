import { Container, ContainerVisit, Fee, Upload, WrapperForm } from "./styles";
import Button from "components/v-core/v-button";
import Modal from "components/v-core/v-modal";
import Input from "components/v-core/v-input";
import { useContext, useEffect, useState } from "react";
import UploadFile from "components/v-upload";
import APIs from "api";
import { toast } from "react-toastify";
import NotAuth from "components/not_auth";
import { RiAdvertisementLine } from "react-icons/ri";
import { MasterContext } from "context/MasterContext";
import VTable from "components/v-core/v-table";

const columns = [
  {
    key: "Id",
    title: "id",
    dataIndex: "_id",
  },
  {
    key: "Banner",
    title: "Banner",
    render: (item) => <img src={item.banner} alt="" width={30} />,
  },
  {
    key: "Name",
    title: "Name",
    dataIndex: "name",
  },
  {
    key: "Position",
    title: "Position",
    dataIndex: "position",
  },
  {
    key: "End Date",
    title: "End Date",
    dataIndex: "end_date",
  },
  {
    key: "status",
    title: "Status",
    dataIndex: "status",
  },
];

const View = () => {
  const { advertisements, getAdvertisements } = useContext(MasterContext);
  const defaultState = {
    name: "",
    url: "",
    banner: "",
    position: "left",
    end_date: "",
    frame_time: 8,
  };

  const [openModal, setOpenModal] = useState(false);

  const [adsPosition, setAdsPosition] = useState({});
  const [form, setForm] = useState(defaultState);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const getAdvertisementPosition = async () => {
    try {
      const res: any = await APIs.SETTING.getSettingByName(
        "advertisement_position"
      );
      if (res && res?.setting) {
        setAdsPosition(res.setting?.value);
      }
      return res;
    } catch (error) {
      return error;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("files", file);
      const res: any = await APIs.UPLOAD.uploadFile({
        body: formData,
      });
      await APIs.ADVERTISEMENT.createAdvertisement({
        body: {
          ...form,
          banner: res.filePath,
        },
      });
      setLoading(false);
      setOpenModal(false);
      setForm(defaultState);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdvertisements();
    getAdvertisementPosition();
  }, []);

  const renderModal = () => {
    return (
      <Modal
        isOpen={openModal}
        centered={true}
        onCancel={() => setOpenModal(false)}
      >
        <WrapperForm>
          <h3>ADVERTISEMENT</h3>
          <Fee>
            Fee :
            <span>
              {" "}
              {adsPosition[form.position]?.amount} (
              {adsPosition[form.position]?.stock})
            </span>
          </Fee>

          <ContainerVisit>
            <Upload>
              <UploadFile
                id="banner"
                ratio="220x350"
                typeUpload="image"
                file={file}
                setFile={(f) => setFile(f)}
              />
            </Upload>
          </ContainerVisit>
          <Input
            transparent={true}
            placeholder="Name"
            label="Name"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <Input
            transparent={true}
            placeholder="Position"
            label="Position"
            name="position"
            type="select"
            value={form.position}
            onChange={handleChange}
          >
            {Object.keys(adsPosition).map((key) => (
              <option value={key}>{key.toUpperCase()}</option>
            ))}
          </Input>

          <Input
            transparent={true}
            placeholder="Link"
            label="Link"
            name="url"
            value={form.url}
            onChange={handleChange}
          />
          <Input
            transparent={true}
            placeholder="End Date"
            label="End Date"
            name="end_date"
            type="datetime-local"
            value={form.end_date}
            onChange={handleChange}
          />
          <Input
            transparent={true}
            placeholder="Frame Time"
            label="Frame Time"
            name="frame_time"
            type="select"
            value={form.frame_time}
            onChange={handleChange}
          >
            {Array(24)
              .fill(0)
              .map((_, i) => (
                <option value={i + 1}>{i + 1}</option>
              ))}
          </Input>

          <Button loading={loading} onClick={handleSubmit}>
            Submit
          </Button>
        </WrapperForm>
      </Modal>
    );
  };

  return (
    <Container>
      {!advertisements?.access ? (
        <NotAuth />
      ) : (
        <>
          {renderModal()}
          <Button onClick={() => setOpenModal(true)}>
            + <RiAdvertisementLine size={25} />
          </Button>
          <VTable
            columns={columns}
            data={advertisements?.advertisements}
          ></VTable>
        </>
      )}
    </Container>
  );
};

export default View;
