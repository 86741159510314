import Icons from "helper/icons";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import APIs from "api";
import VButton from "components/v-core/v-button";
import VModal from "components/v-core/v-modal";
import { v4 as uuidv4 } from "uuid";
import UploadItems from "../upload/UploadItems";

type IProps = {
  data?: any;
  isEdit?: boolean;
  index?: number;
  required?: any;
  style?: any;
  setData?: (e: any) => void;
  handleMoveSection?: (type: any, index: number) => void;
  checkRequiredSection?: (state: any, key: string) => any;
};

const Teams = (props: IProps) => {
  const {
    data,
    setData,
    isEdit,
    index,
    handleMoveSection,
    required,
    checkRequiredSection,
    style,
  } = props;

  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [teamsData, setTeamsData] = useState(null);
  const [teamSelected, setTeamSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getDataDefault = () => {
    return {
      id: uuidv4(),
      title: "Title",
      desc: "Description",
      items: [
        {
          id: uuidv4(),
          src: "",
          isNew: true,
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          link: {
            vdiarybook: "",
            facebook: "",
            youtube: "",
            twitter: "",
            telegram: "",
          },
        },
        {
          id: uuidv4(),
          src: "",
          isNew: true,
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          link: {
            vdiarybook: "",
            facebook: "",
            youtube: "",
            twitter: "",
            telegram: "",
          },
        },
        {
          id: uuidv4(),
          src: "",
          isNew: true,
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          link: {
            vdiarybook: "",
            facebook: "",
            youtube: "",
            twitter: "",
            telegram: "",
          },
        },
      ],
    };
  };

  const onOpenModal = (data: any) => {
    setTeamSelected(data);
    setTeamsData(data?.items);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setData((prev) => ({
      ...prev,
      teams: prev.teams?.concat(getDataDefault()),
    }));
  };

  const handleSub = (item: any) => {
    const _data = data.teams?.filter((el) => el.id !== item.id);
    setData((prev) => ({
      ...prev,
      teams: [..._data],
    }));
  };

  const onBlurDocument = (e: any, name: string, uuid?: string) => {
    setData((prev) => ({
      ...prev,
      teams: prev.teams.map((el) =>
        el.id === uuid
          ? {
              ...el,
              [name]: e.target.innerText,
            }
          : { ...el }
      ),
    }));
  };

  const onSubmitModal = async () => {
    if (checkRequiredSection(teamsData, "teams")) {
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("folderPath", `landing-page/${params.symbol}`);

    const statesNew = teamsData
      .filter((st) => st.file)
      .map((stNew) => {
        formData.append("files", stNew.file);
        return stNew;
      });
    if (!statesNew) {
      setIsLoading(false);
    }

    const listImg: any = await APIs.UPLOAD.uploadMultiFile({
      body: formData,
    });

    listImg.forEach((el) => {
      statesNew[el.id] = {
        ...statesNew[el.id],
        src: el?.filePath,
        isNew: false,
      };
    });

    setData((prev) => ({
      ...prev,
      teams: prev?.teams?.map((team) =>
        team.id === teamSelected.id
          ? {
              ...teamSelected,

              items: teamsData?.map((it) => {
                const _stateFound = statesNew.find((c) => c.id === it.id);
                if (_stateFound) {
                  return {
                    ...it,
                    ..._stateFound,
                  };
                }
                return it;
              }),
            }
          : { ...team }
      ),
    }));

    setIsLoading(false);
    setOpenModal(false);
  };

  // Links navigate
  const openTagWindow = (url: string) => {
    window.open(url);
  };

  return (
    <>
      <div
        className="relative w-full flex flex-col items-center gap-y-5"
        style={style}
      >
        {isEdit && (
          <div className="absolute top-2 right-3 flex gap-x-3">
            <VButton
              className="w-8 h-8"
              radius="full"
              iconLeft={<Icons iconName="arrow-up" />}
              onClick={() => handleMoveSection("up", index)}
            />
            <VButton
              className="w-8 h-8"
              radius="full"
              iconLeft={<Icons iconName="arrow-down" />}
              onClick={() => handleMoveSection("down", index)}
            />
          </div>
        )}
        {data?.teams?.map((team) => (
          <>
            <div className="flex flex-col items-center justify-center max-w-[50vh]">
              <span
                className="text-xl font-bold text-center"
                contentEditable={isEdit}
                onBlur={(e) => onBlurDocument(e, "title", team.id)}
              >
                {team.title}
              </span>
              <div className="w-10 border-b-2 m-0" />
              <span
                className="text-sm opacity-70 text-center mt-2"
                contentEditable={isEdit}
                onBlur={(e) => onBlurDocument(e, "desc", team.id)}
              >
                {team.desc}
              </span>
            </div>
            <div
              className="relative w-full flex flex-wrap justify-center gap-5"
              key={team.id}
            >
              {team &&
                team.items?.map((it) => (
                  <div
                    key={it.id}
                    className=" flex flex-col items-center gap-y-1"
                  >
                    {it.src ? (
                      <img
                        className={`${
                          isEdit ? "w-48 h-36" : "w-36 h-36 rounded-full"
                        }  object-cover`}
                        src={it.src}
                        alt=""
                      />
                    ) : (
                      <div
                        className={`${
                          isEdit
                            ? "w-48 h-36 border rounded"
                            : "w-36 h-36 rounded-full"
                        } flex justify-center items-center`}
                      >
                        {isEdit && <Icons iconName="add_image" />}
                      </div>
                    )}
                    <span className="text-base font-semibold">{it.name}</span>
                    <span className="">{it.role}</span>
                    <span className="opacity-70">{it.desc}</span>
                    <div className="w-full flex gap-x-2 items-center justify-center">
                      {it?.links?.map((e) => (
                        <>
                          {e.link && (
                            <div
                              className="w-4 h-4 cursor-pointer hover:opacity-80"
                              onClick={() => !isEdit && openTagWindow(e.link)}
                            >
                              <img
                                className="w-full h-full object-cover rounded-full"
                                src={e.logo}
                                alt=""
                              />
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              {isEdit && (
                <div className="absolute right-0 -top-10 flex gap-x-2">
                  <VButton
                    className="w-8 h-8"
                    radius="full"
                    onClick={() => handleSub(team)}
                  >
                    <span className="text-red-500 text-xl font-bold">-</span>
                  </VButton>
                  <VButton
                    className="w-8 h-8"
                    radius="full"
                    iconLeft={<Icons iconName="edit" />}
                    onClick={() => onOpenModal(team)}
                  />
                  <VButton
                    className="w-8 h-8"
                    radius="full"
                    onClick={handleAdd}
                  >
                    +
                  </VButton>
                </div>
              )}
            </div>
          </>
        ))}
        <VModal
          title={`Upload`}
          isOpen={openModal}
          onCancel={() => setOpenModal(false)}
          onConfirm={onSubmitModal}
          loading={isLoading}
        >
          <UploadItems
            type="teams"
            data={teamsData}
            setData={setTeamsData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            required={required}
          />
        </VModal>
      </div>
    </>
  );
};

export default Teams;
