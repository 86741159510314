import React from "react";

const IconProfile = ({ iconName, iconClassName }) => {
  return (
    <>
      {iconName === "name" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9805 13.918H8.76172C5.57813 13.918 3 16.4961 3 19.6797C3 20.9609 4.03906 22 5.32031 22H18.4219C19.7031 22 20.7422 20.9609 20.7422 19.6797C20.7422 16.4961 18.1641 13.918 14.9805 13.918Z"
            fill="currentColor"
          />
          <path
            d="M11.8711 12.2734C14.708 12.2734 17.0078 9.97365 17.0078 7.13672C17.0078 4.29979 14.708 2 11.8711 2C9.03418 2 6.73439 4.29979 6.73439 7.13672C6.73439 9.97365 9.03418 12.2734 11.8711 12.2734Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "object" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2308 6.14353C12.2156 6.49727 13.5095 6.69207 14.874 6.69207C16.2385 6.69207 17.5324 6.49727 18.5172 6.14353C20.025 5.60196 20.3414 4.86713 20.3414 4.34603C20.3414 3.82494 20.025 3.09011 18.5172 2.54853C17.5324 2.1948 16.2385 2 14.874 2C13.5094 2 12.2156 2.1948 11.2308 2.54853C9.72302 3.09011 9.40654 3.82494 9.40654 4.34603C9.40654 4.86713 9.72302 5.60196 11.2308 6.14353Z"
            fill="currentColor"
          />
          <path
            d="M14.874 7.88509C13.6609 7.88509 12.488 7.74095 11.4958 7.47433L16.0777 9.8754C16.3524 10.0194 16.5862 10.2342 16.7538 10.4966C16.9364 10.7826 17.0364 11.1209 17.0364 11.4603V18.0449C17.5789 17.9537 18.0807 17.8279 18.5199 17.6701C20.0254 17.1293 20.3415 16.3936 20.3415 15.8716V6.53027C19.982 6.79919 19.5182 7.05165 18.9204 7.26633C17.8096 7.66536 16.3725 7.88509 14.874 7.88509Z"
            fill="currentColor"
          />
          <path
            d="M9.69864 7.87918C9.52523 7.78832 9.31834 7.78832 9.1449 7.87918C9.1449 7.87918 4.71053 10.2029 3.57986 10.7955L9.42205 13.8404L15.27 10.7988L9.69864 7.87918Z"
            fill="currentColor"
          />
          <path
            d="M10.0182 22.1228L15.5246 19.2285C15.7208 19.1255 15.8435 18.9222 15.8435 18.7006C15.8435 18.7006 15.8435 13.343 15.8435 11.8452L10.0182 14.8751V22.1228Z"
            fill="currentColor"
          />
          <path
            d="M3 18.7004C3 18.922 3.12283 19.1253 3.31894 19.2284L8.82532 22.1226V14.8745L3 11.8384V18.7004Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "group" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.33333 10C6.71404 10 7.83333 8.88071 7.83333 7.5C7.83333 6.11929 6.71404 5 5.33333 5C3.95262 5 2.83333 6.11929 2.83333 7.5C2.83333 8.88071 3.95262 10 5.33333 10Z"
            fill="currentColor"
          />
          <path
            d="M8.075 11.7249C7.23625 12.2483 6.54449 12.9765 6.06485 13.8411C5.58521 14.7056 5.33347 15.6779 5.33333 16.6666H3.66667C3.22504 16.6653 2.80189 16.4892 2.48961 16.177C2.17734 15.8647 2.00132 15.4415 2 14.9999V13.3333C2.00198 12.6708 2.26601 12.0361 2.73442 11.5677C3.20283 11.0993 3.83757 10.8352 4.5 10.8333H6.16667C6.53048 10.8341 6.88972 10.9143 7.21933 11.0683C7.54893 11.2223 7.84095 11.4464 8.075 11.7249Z"
            fill="currentColor"
          />
          <path
            d="M18.6667 10C20.0474 10 21.1667 8.88071 21.1667 7.5C21.1667 6.11929 20.0474 5 18.6667 5C17.286 5 16.1667 6.11929 16.1667 7.5C16.1667 8.88071 17.286 10 18.6667 10Z"
            fill="currentColor"
          />
          <path
            d="M22 13.3333V14.9999C21.9987 15.4415 21.8227 15.8647 21.5104 16.177C21.1981 16.4892 20.7749 16.6653 20.3333 16.6666H18.6667C18.6665 15.6779 18.4148 14.7056 17.9351 13.8411C17.4555 12.9765 16.7637 12.2483 15.925 11.7249C16.159 11.4464 16.4511 11.2223 16.7807 11.0683C17.1103 10.9143 17.4695 10.8341 17.8333 10.8333H19.5C20.1624 10.8352 20.7972 11.0993 21.2656 11.5677C21.734 12.0361 21.998 12.6708 22 13.3333Z"
            fill="currentColor"
          />
          <path
            d="M12 11.6667C13.841 11.6667 15.3333 10.1743 15.3333 8.33333C15.3333 6.49238 13.841 5 12 5C10.1591 5 8.66667 6.49238 8.66667 8.33333C8.66667 10.1743 10.1591 11.6667 12 11.6667Z"
            fill="currentColor"
          />
          <path
            d="M17 16.6667V17.5C16.998 18.1624 16.734 18.7972 16.2656 19.2656C15.7972 19.734 15.1624 19.998 14.5 20H9.5C8.83757 19.998 8.20283 19.734 7.73442 19.2656C7.26601 18.7972 7.00198 18.1624 7 17.5V16.6667C7 15.5616 7.43899 14.5018 8.22039 13.7204C9.00179 12.939 10.0616 12.5 11.1667 12.5H12.8333C13.9384 12.5 14.9982 12.939 15.7796 13.7204C16.561 14.5018 17 15.5616 17 16.6667Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "role" && (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99195 16.6878L10.9105 16.1577C10.8849 15.9612 10.8719 15.7677 10.8719 15.5803C10.8719 15.3927 10.8849 15.1989 10.9105 15.0027L9.99231 14.4729C9.71415 14.3118 9.61855 13.9546 9.77837 13.6766L10.9559 11.6374C11.0335 11.5026 11.159 11.4059 11.3098 11.3654C11.4592 11.3253 11.6169 11.3455 11.7518 11.4236L12.6718 11.9545C12.9777 11.7216 13.3129 11.5282 13.6707 11.3783V10.3164C13.6707 10.1582 13.7345 10.0149 13.8371 9.90978C12.5762 8.69551 10.8926 8.00513 9.12501 8.00513H6.80489C3.05249 8.00513 0 11.0578 0 14.81V16.7816H9.87353C9.90866 16.7465 9.94694 16.7139 9.99195 16.6878Z"
            fill="currentColor"
          />
          <path
            d="M19.4871 14.4177L20.3652 13.9106L19.3439 12.1414L18.464 12.6496C18.2587 12.7684 18.0036 12.7493 17.8149 12.5989C17.5151 12.3589 17.1811 12.1663 16.8214 12.0258C16.597 11.9377 16.4523 11.7274 16.4523 11.4897V10.4719H14.5132C14.5127 10.4719 14.5122 10.472 14.5118 10.4719H14.4096V11.4889C14.4096 11.7276 14.2646 11.9383 14.0405 12.0256C13.6805 12.1665 13.3464 12.3591 13.0477 12.5983C12.8594 12.7484 12.6043 12.7684 12.3987 12.6499L11.518 12.1416L10.4967 13.9106L11.3748 14.4173C11.583 14.5386 11.6926 14.7704 11.6558 15.0088C11.6259 15.2036 11.6107 15.3961 11.6107 15.5801C11.6107 15.7641 11.6259 15.9564 11.6558 16.1519C11.693 16.39 11.5826 16.6222 11.3752 16.7423L10.4967 17.2494L11.518 19.0186L12.3979 18.5105C12.6043 18.3916 12.8594 18.4114 13.0473 18.5617C13.3468 18.8013 13.6812 18.9939 14.0409 19.1344C14.2646 19.2217 14.4096 19.4321 14.4096 19.6704V20.6881H16.4523V19.6711C16.4523 19.4324 16.5973 19.2219 16.8217 19.1344C17.1811 18.9939 17.5151 18.8011 17.8142 18.5619C18.0025 18.4113 18.2572 18.3909 18.4636 18.5101L19.3439 19.0184L20.3652 17.2494L19.4875 16.7427C19.2797 16.6227 19.1689 16.3906 19.2061 16.1512C19.236 15.9584 19.2512 15.7663 19.2512 15.5801C19.2512 15.3938 19.236 15.2017 19.2061 15.0088C19.1689 14.77 19.2793 14.5378 19.4871 14.4177ZM15.431 18.0864C14.0488 18.0864 12.9247 16.962 12.9247 15.5801C12.9247 14.198 14.0488 13.0736 15.431 13.0736C16.8131 13.0736 17.9372 14.198 17.9372 15.5801C17.9372 16.962 16.8131 18.0864 15.431 18.0864Z"
            fill="currentColor"
          />
          <path
            d="M7.96517 7.26596C9.97161 7.26596 11.5982 5.63942 11.5982 3.63298C11.5982 1.62654 9.97161 0 7.96517 0C5.95873 0 4.33218 1.62654 4.33218 3.63298C4.33218 5.63942 5.95873 7.26596 7.96517 7.26596Z"
            fill="currentColor"
          />
          <path
            d="M15.431 13.8125C14.4565 13.8125 13.6635 14.6055 13.6635 15.5801C13.6635 16.5547 14.4565 17.3475 15.431 17.3475C16.4054 17.3475 17.1984 16.5547 17.1984 15.5801C17.1984 14.6055 16.4054 13.8125 15.431 13.8125Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "phone" && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3549 16.8053L17.425 18.728C16.8485 19.3051 16.1192 19.7058 15.3229 19.8831C14.5266 20.0603 13.6962 20.0068 12.9293 19.7287C10.0188 18.6717 7.37557 16.9891 5.18602 14.7995C2.99646 12.6099 1.31387 9.96673 0.256866 7.05622C-0.0212324 6.28929 -0.0748051 5.45894 0.102435 4.66262C0.279676 3.8663 0.680375 3.13706 1.25751 2.56048L3.18017 0.630666C3.37938 0.430767 3.61609 0.272157 3.87672 0.163933C4.13736 0.0557092 4.4168 0 4.69901 0C4.98122 0 5.26065 0.0557092 5.52129 0.163933C5.78192 0.272157 6.01863 0.430767 6.21784 0.630666L8.74089 3.15372C8.94079 3.35292 9.0994 3.58963 9.20762 3.85027C9.31585 4.1109 9.37156 4.39034 9.37156 4.67255C9.37156 4.95476 9.31585 5.2342 9.20762 5.49483C9.0994 5.75547 8.94079 5.99218 8.74089 6.19138L7.95467 6.9776C7.85359 7.08648 7.78895 7.22414 7.76974 7.37145C7.75053 7.51876 7.77769 7.6684 7.84746 7.79956C8.7948 9.67078 10.3147 11.1907 12.186 12.1381C12.32 12.2042 12.4713 12.2267 12.6188 12.2023C12.7662 12.1779 12.9023 12.108 13.0079 12.0023L13.7941 11.216C13.9933 11.0161 14.2301 10.8575 14.4907 10.7493C14.7513 10.6411 15.0308 10.5854 15.313 10.5854C15.5952 10.5854 15.8746 10.6411 16.1353 10.7493C16.3959 10.8575 16.6326 11.0161 16.8318 11.216L19.3549 13.7391C19.5591 13.9388 19.7214 14.1773 19.8321 14.4405C19.9429 14.7038 20 14.9866 20 15.2722C20 15.5579 19.9429 15.8406 19.8321 16.1039C19.7214 16.3672 19.5591 16.6057 19.3549 16.8053Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "email" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.59266 4.88267L9.5524 10.845C10.8522 12.1423 12.977 12.1435 14.2779 10.845L20.2377 4.88267C20.2977 4.82265 20.289 4.72381 20.2193 4.67525C19.6115 4.25132 18.8719 4 18.0752 4H5.75517C4.95846 4 4.21888 4.25137 3.61101 4.67525C3.54138 4.72381 3.53264 4.82265 3.59266 4.88267ZM2 7.75513C2 7.12865 2.15533 6.53673 2.42893 6.01646C2.47171 5.93507 2.58016 5.91861 2.64518 5.98362L8.52948 11.8679C10.3928 13.734 13.4364 13.7351 15.3009 11.8679L21.1852 5.98362C21.2502 5.91861 21.3587 5.93507 21.4014 6.01646C21.675 6.53673 21.8304 7.1287 21.8304 7.75513V15.5942C21.8304 17.6664 20.1448 19.3494 18.0752 19.3494H5.75517C3.68564 19.3494 2 17.6664 2 15.5942V7.75513Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "address" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.789 2C8.27477 2 5.34373 4.83084 5.34373 8.44528C5.34373 9.82035 5.75694 11.0465 6.54994 12.1955L11.2958 19.6009C11.526 19.9609 12.0525 19.9602 12.2823 19.6009L17.0487 12.1703C17.8246 11.0734 18.2343 9.78539 18.2343 8.44528C18.2343 4.89135 15.3429 2 11.789 2ZM11.789 11.3749C10.1737 11.3749 8.85934 10.0606 8.85934 8.44528C8.85934 6.82993 10.1737 5.5156 11.789 5.5156C13.4044 5.5156 14.7187 6.82993 14.7187 8.44528C14.7187 10.0606 13.4044 11.3749 11.789 11.3749Z"
            fill="currentColor"
          />
          <path
            d="M16.3696 15.4648L13.4192 20.0776C12.6555 21.2683 10.9183 21.2644 10.1582 20.0787L7.20302 15.4661C4.60288 16.0672 3 17.1685 3 18.4846C3 20.7682 7.52841 22.0002 11.789 22.0002C16.0496 22.0002 20.578 20.7682 20.578 18.4846C20.578 17.1676 18.9729 16.0657 16.3696 15.4648Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "verification" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.71196 15.82C4.37597 15.82 4.10395 15.548 4.10395 15.204V7.60015L9.56479 12.4951C9.99575 12.8861 10.5463 13.0856 11.1049 13.0856C11.6555 13.0856 12.206 12.8861 12.629 12.4951L18.096 7.60073V10.076C18.696 10.092 19.272 10.22 19.8 10.428V7.22803C19.8 6.604 19.544 6.01201 19.08 5.55601C18.648 5.148 18.08 4.91602 17.496 4.91602H4.71196C4.11996 4.91602 3.55195 5.148 3.11196 5.56401C2.65595 6.01201 2.39999 6.604 2.39999 7.22803V15.204C2.39999 16.484 3.43998 17.516 4.71196 17.516H13.048C12.808 16.988 12.664 16.42 12.608 15.82H4.71196Z"
            fill="currentColor"
          />
          <path
            d="M17.896 11.668C15.848 11.668 14.192 13.34 14.192 15.372C14.192 17.428 15.848 19.084 17.896 19.084C19.944 19.084 21.6 17.428 21.6 15.372C21.6 13.34 19.944 11.668 17.896 11.668ZM19.544 15.236L17.68 16.484C17.576 16.5479 17.464 16.58 17.344 16.58C17.16 16.58 16.976 16.492 16.856 16.324L16.216 15.412C16.032 15.14 16.096 14.772 16.368 14.58C16.64 14.388 17.016 14.452 17.2 14.724L17.5039 15.156L18.88 14.236C19.1519 14.052 19.528 14.132 19.712 14.404C19.896 14.684 19.824 15.052 19.544 15.236Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "status" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.0454 3.47959L12.6818 2.06143C12.2922 1.97952 11.8897 1.97952 11.5 2.06143L5.13646 3.47959C4.53037 3.61411 3.98832 3.95139 3.59988 4.4357C3.21144 4.92001 2.99986 5.52236 3.00013 6.1432V9.84315C2.98868 12.4234 3.75788 14.9468 5.20672 17.0819C6.65555 19.217 8.71626 20.8641 11.1182 21.8066C11.4268 21.9334 11.7573 21.9983 12.0909 21.9976C12.4235 21.9968 12.7532 21.9352 13.0636 21.8157C15.4649 20.8694 17.5245 19.2202 18.9729 17.084C20.4214 14.9478 21.1912 12.4241 21.1817 9.84315V6.1432C21.182 5.52236 20.9704 4.92001 20.582 4.4357C20.1935 3.95139 19.6515 3.61411 19.0454 3.47959ZM16.4091 9.96134L12.0364 14.2613C11.8661 14.4306 11.6356 14.5256 11.3955 14.5256C11.1553 14.5256 10.9249 14.4306 10.7546 14.2613L8.68188 12.2249C8.59667 12.1404 8.52904 12.0399 8.48289 11.9291C8.43673 11.8183 8.41297 11.6995 8.41297 11.5795C8.41297 11.4595 8.43673 11.3407 8.48289 11.2299C8.52904 11.1191 8.59667 11.0186 8.68188 10.9341C8.8522 10.7647 9.08261 10.6697 9.32278 10.6697C9.56294 10.6697 9.79335 10.7647 9.96368 10.9341L11.4 12.3431L15.1363 8.7068C15.2217 8.62264 15.3228 8.55611 15.4339 8.51102C15.5449 8.46593 15.6638 8.44315 15.7837 8.444C15.9035 8.44484 16.0221 8.46929 16.1325 8.51594C16.2429 8.56259 16.3431 8.63054 16.4272 8.7159C16.5114 8.80125 16.5779 8.90235 16.623 9.01342C16.6681 9.12449 16.6909 9.24335 16.69 9.36322C16.6892 9.4831 16.6648 9.60163 16.6181 9.71205C16.5715 9.82247 16.5035 9.92263 16.4181 10.0068L16.4091 9.96134Z"
            fill="currentColor"
          />
        </svg>
      )}
    </>
  );
};

export default IconProfile;
