/* eslint-disable max-len */
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import { useContext, useMemo } from "react";
import VButton from "../v-button";

interface Props {
  page: number;
  limit: number;
  totalDocs: number;
  totalPages: number;
  onFilter: (type: string) => void;
}

const VPagination = ({
  page,
  limit,
  totalDocs,
  totalPages,
  onFilter,
}: Props) => {
  const { themesSetting } = useContext(ThemeContext);

  const startCounter = useMemo(() => {
    if (page <= 1) return page;
    return page * limit - limit + 1;
  }, [page, limit]);

  const endCounter = useMemo(() => {
    const counter = page * limit;
    return counter > totalDocs ? totalDocs : counter;
  }, [page, limit, totalDocs]);

  return (
    <div
      className="flex items-center justify-between text-white px-4 py-3 sm:px-6"
      style={themesSetting?.pagination}
    >
      <div className="flex flex-1 justify-between sm:hidden">
        <VButton
          disabled={page <= 1}
          className={`${page <= 1 && "cursor-not-allowed"} w-24`}
          onClick={() => onFilter("previous")}
          iconLeft={<Icons iconName="chevronleft" />}
        >
          Previous
        </VButton>
        <VButton
          disabled={page >= totalPages}
          className={`${page >= totalPages && "cursor-not-allowed"} w-24`}
          onClick={() => onFilter("next")}
          iconRight={<Icons iconName="chevronright" />}
        >
          Next
        </VButton>
      </div>
      <div
        className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between"
        style={themesSetting?.pagination?.text}
      >
        <div>
          <p className="m-0">
            Showing <span className="font-medium">{startCounter}</span> to{" "}
            <span className="font-medium">{endCounter}</span> of{" "}
            <span className="font-medium">{totalDocs}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm gap-x-2"
            aria-label="Pagination"
          >
            <VButton
              disabled={page <= 1}
              className={`${page <= 1 && "cursor-not-allowed"} w-24`}
              onClick={() => onFilter("previous")}
              iconLeft={<Icons iconName="chevronleft" />}
            >
              Previous
            </VButton>

            <VButton
              disabled={page >= totalPages}
              className={` ${page >= totalPages && "cursor-not-allowed"} w-24`}
              onClick={() => onFilter("next")}
              iconRight={<Icons iconName="chevronright" />}
            >
              Next
            </VButton>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default VPagination;
