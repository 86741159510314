import { useContext, useState } from "react";
import UploadFile from "components/v-upload";
import { ChangeAvt } from "../styles";
import { Upload } from "pages/home/landing-page/styles";
import APIs from "api";
import { toast } from "react-toastify";
import { MasterContext } from "context/MasterContext";
import { renderKycStatus } from "utils/render";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";

const AvatarProfile = ({ profile }: any) => {
  const { getUser } = useContext(MasterContext);
  const [fileAvt, setFileAvt] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onEditUser = async (req: any) => {
    const res = await APIs.USER.editUser(req);
    return res;
  };

  const handleEditAvt = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("files", fileAvt);
    const res: any = await APIs.UPLOAD.uploadFile({
      body: formData,
    });
    if (res) {
      const user: any = await onEditUser({
        params: {
          id: profile._id,
        },
        body: {
          avatar: res.filePath,
        },
      });

      if (user) {
        await getUser();
        setLoading(false);
        toast.success("Update success!");
      } else {
        toast.error("Error");
        setLoading(false);
      }
      setFileAvt(null);
    }
  };

  return (
    <div className="flex flex-col justify-center gap-y-3">
      <div className="flex gap-x-3">
        <div className="relative w-[100px] h-[115px] flex justify-center items-center rounded-full">
          <Upload isHidden={Boolean(!fileAvt) && profile?.avatar}>
            <UploadFile
              id="avatar"
              ratio="340x340"
              file={fileAvt}
              setFile={setFileAvt}
            />
          </Upload>
          <img
            className="w-full size-24 rounded-[50%] object-cover"
            hidden={fileAvt}
            src={profile?.avatar}
            alt=""
          />
        </div>
        <div className="flex flex-col gap-y-2 h-[100px]">
          <span className="text-[24px] font-bold">{profile?.username}</span>
          <span className="text-[14px] font-medium opacity-70">
            {profile?.email}
          </span>
          <span>{renderKycStatus(profile?.status_kyc)}</span>
        </div>
      </div>
      {fileAvt && (
        <ChangeAvt>
          <VButton
            loading={loading}
            onClick={handleEditAvt}
            radius="full"
            className="!w-[120px]"
            iconLeft={<Icons iconName="save" />}
          >
            Save
          </VButton>
        </ChangeAvt>
      )}
    </div>
  );
};

export default AvatarProfile;
