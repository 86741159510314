import Icons from "helper/icons";
// import { useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import VButton from "components/v-core/v-button";
import VModal from "components/v-core/v-modal";
import { v4 as uuidv4 } from "uuid";
import VInput from "components/v-core/v-input";
import { Disclosure } from "@headlessui/react";
import { ThemeContext } from "context/ThemeContext";
import APIs from "api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

type IProps = {
  data?: any;
  setData?: (e: any) => void;
  isEdit?: boolean;
};

const Review = (props: IProps) => {
  const { data, setData, isEdit } = props;
  const { themesSetting, theme } = useContext(ThemeContext);

  //   const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [commentSelected, setCommentSelected] = useState(null);

  const getDataDefault = () => {
    return {
      id: uuidv4(),
      title: "Title",
      fields: [
        {
          id: uuidv4(),
          label: "",
          type: "",
        },
      ],
    };
  };

  const onOpenModal = (data: any) => {
    setCommentSelected(data);
    setOpenModal(true);
  };

  const handleAdd = (type?: string) => {
    if (type === "field") {
      setCommentSelected((prev) => ({
        ...prev,
        items: prev.items.concat({
          id: uuidv4(),
          label: "",
          type: "",
        }),
      }));
    } else {
      setData((prev) => ({
        ...prev,
        commentsField: {
          ...prev?.commentsField,
          fields: prev?.commentsField?.fields?.concat(getDataDefault()),
        },
      }));
    }
  };

  const handleSub = (item: any, type?: string) => {
    if (type === "field") {
      const _items = commentSelected?.items?.filter((el) => el.id !== item.id);
      setCommentSelected((prev) => ({
        ...prev,
        items: _items,
      }));
    } else {
      const _data = data.commentsField?.fields?.filter(
        (el) => el.id !== item.id
      );
      setData((prev) => ({
        ...prev,
        commentsField: {
          ...prev?.commentsField,
          fields: _data,
        },
      }));
    }
  };

  const handleChange = (value: string, name: string, id?: string) => {
    if (name === "field") {
      setCommentSelected((prev) => ({
        ...prev,
        title: value,
      }));
    } else {
      setCommentSelected((prev) => ({
        ...prev,
        items: prev?.items.map((it) =>
          it.id === id
            ? {
                ...it,
                [name]: value,
              }
            : { ...it }
        ),
      }));
    }
  };

  const onSubmitModal = async () => {
    setData((prev) => ({
      ...prev,
      commentsField: {
        ...prev.commentsField,
        fields: prev.commentsField?.fields?.map((el) =>
          el.id === commentSelected.id
            ? {
                ...commentSelected,
              }
            : { ...el }
        ),
      },
    }));
    setOpenModal(false);
    setCommentSelected(null);
  };

  // Send messages
  const { symbol } = useParams();

  const [commentData, setCommentData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChangeMessage = (name: string, value: string) => {
    setCommentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSend = async (title: string) => {
    try {
      setLoading(true);
      await APIs.REVIEW.sendReviewPageByType({
        params: {
          type: "estock",
        },
        body: {
          symbol: symbol,
          title: title,
          value: commentData,
        },
      });
      toast.success(`Send review success!`);
      setLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <div className="w-full flex flex-col items-center gap-y-10">
        {data?.commentsField?.fields?.map((field) => (
          <>
            <div
              className="relative w-full flex flex-wrap justify-center gap-5 px-3 rounded"
              key={field.id}
              style={
                data?.settingMenu?.colors[theme]?.content?.via ||
                data?.settingMenu?.colors[theme]?.content?.to
                  ? {
                      backgroundImage: `linear-gradient(to right, ${data?.settingMenu?.colors[theme]?.content?.from},${data?.settingMenu?.colors[theme]?.content?.via}, ${data?.settingMenu?.colors[theme]?.content?.to})`,
                      color: data?.settingMenu?.colors[theme]?.content?.text,
                    }
                  : {
                      backgroundColor:
                        data?.settingMenu?.colors[theme]?.content?.from,
                      color: data?.settingMenu?.colors[theme]?.content?.text,
                    }
              }
            >
              <Disclosure
                key={field.id}
                defaultOpen={false}
                as="div"
                className="flex flex-col w-full"
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={
                        "relative flex items-center justify-between w-full text-left rounded-md py-2 gap-x-3 text-sm leading-6 font-semibold transition-colors duration-300 ease-linear"
                      }
                    >
                      {field.title}
                      <Icons iconName="dropdown" />
                    </Disclosure.Button>
                    <div
                      className={`transition-all duration-500 ease-linear overflow-hidden`}
                      style={
                        open
                          ? {
                              height: "300px",
                            }
                          : { height: 0 }
                      }
                    >
                      {field &&
                        field.items?.map((it) => (
                          <Disclosure.Panel as="ul" className={`mt-1 px-2`}>
                            {it.type === "textarea" ? (
                              <div className="flex flex-col gap-y-1">
                                <label htmlFor="label">{it.label}</label>
                                <textarea
                                  name="message"
                                  id="message"
                                  placeholder="Send message..."
                                  rows={5}
                                  disabled={isEdit}
                                  className="w-full bg-transparent border rounded p-2"
                                  value={commentData[it.label]}
                                  onChange={(e) =>
                                    handleChangeMessage(
                                      it.label,
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                              </div>
                            ) : (
                              <VInput
                                type={it.type}
                                label={it.label}
                                disabled={isEdit}
                                value={commentData[it.label]}
                                onChange={(e) =>
                                  handleChangeMessage(it.label, e.target.value)
                                }
                              />
                            )}
                            {!isEdit && (
                              <VButton
                                className="w-24 mt-3"
                                loading={loading}
                                onClick={() => handleSend(it.title)}
                              >
                                Send
                              </VButton>
                            )}
                          </Disclosure.Panel>
                        ))}
                    </div>
                  </>
                )}
              </Disclosure>
              {isEdit && (
                <div className="absolute right-0 -top-10 flex gap-x-2">
                  <VButton
                    className="w-8 h-8"
                    radius="full"
                    onClick={() => handleSub(field)}
                  >
                    <span className="text-red-500 text-xl font-bold">-</span>
                  </VButton>
                  <VButton
                    className="w-8 h-8"
                    radius="full"
                    iconLeft={<Icons iconName="edit" />}
                    onClick={() => onOpenModal(field)}
                  />
                  <VButton
                    className="w-8 h-8"
                    radius="full"
                    onClick={handleAdd}
                  >
                    +
                  </VButton>
                </div>
              )}
            </div>
          </>
        ))}
        <VModal
          title={"Comment field"}
          isOpen={openModal}
          onCancel={() => setOpenModal(false)}
          onConfirm={onSubmitModal}
        >
          <div className="w-full min-h-[50vh] flex flex-col gap-y-3">
            <div className="w-full">
              <VInput
                value={commentSelected?.title}
                label="Title"
                onChange={(e) => handleChange(e.target.value, "field")}
              />
            </div>
            <div
              className="flex flex-col gap-y-5 p-2 rounded"
              style={themesSetting.container}
            >
              <span className="text-center text-base font-semibold">Field</span>
              {commentSelected?.items?.map((it) => (
                <div className="relative flex items-center gap-x-2">
                  <div className="absolute top-0 right-0">
                    <VButton
                      className="w-6 h-6"
                      onClick={() => handleSub(it, "field")}
                    >
                      -
                    </VButton>
                  </div>
                  <div className="w-1/2 flex flex-col items-start">
                    <label htmlFor="type">Type</label>
                    <div className="w-full">
                      <VInput
                        value={it.type}
                        onChange={(e) =>
                          handleChange(e.target.value, "type", it.id)
                        }
                      />
                      {/* <VDropdown
                        options={[
                          {
                            label: "Text",
                            value: "text",
                          },
                          {
                            label: "Number",
                            value: "number",
                          },
                          {
                            label: "Textarea",
                            value: "textarea",
                          },
                        ]}
                        value={commentType || it.type}
                        onChange={() =>
                          commentSelected?.items?.map((e) =>
                            e.id === it.id ? true : false
                          )
                            ? handleChangeType
                            : {}
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="w-1/2">
                    <VInput
                      label="Label"
                      value={it.label}
                      onChange={(e) =>
                        handleChange(e.target.value, "label", it.id)
                      }
                    />
                  </div>
                </div>
              ))}
              <div className="w-full flex justify-center">
                <VButton
                  className="w-8 h-8"
                  radius="full"
                  onClick={() => handleAdd("field")}
                >
                  +
                </VButton>
              </div>
            </div>
          </div>
        </VModal>
      </div>
    </>
  );
};

export default Review;
