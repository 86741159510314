import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import VPagination from "components/v-core/v-pagination";
import VTable from "components/v-core/v-table";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext, useState } from "react";
import Details from "./components/Details";

const DEFAULT_DATA = [
  {
    name: "Robert Fox",
    avatar: "/assets/images/avatar.png",
    total_sales: 150992175,
    affiliate: 92770128,
    group_sales: 375996084,
    customers: 914,
    ranking: 1,
  },
];

const columns = [
  {
    key: "position",
    title: "Position",
    render: () => (
      <div className="flex items-center gap-x-1">
        <span>1</span>
        <Icons iconName="arrow_drop_up" />
      </div>
    ),
  },
  {
    key: "User",
    title: "User",
    render: (item: any) => (
      <div className="flex items-center gap-x-1">
        <img
          className="w-4 h-4 rounded-full object-cover"
          src="/assets/images/avatar.png"
          alt=""
        />
        <span>Robert Fox</span>
      </div>
    ),
  },
  {
    key: "total_sales",
    title: "Total sales",
    render: (item) => <span>$150.992.175</span>,
  },
  {
    key: "affiliates",
    title: "Affiliates",
    render: (item) => <span>$92.770.128</span>,
  },
  {
    key: "group_sales",
    title: "Group sales",
    render: (item) => <span>$375.996.084</span>,
  },
  {
    key: "custom",
    title: "Custom",
    render: (item) => <span>914</span>,
  },
];

const Rankings = () => {
  const { themesSetting } = useContext(ThemeContext);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const onFilter = (type: string) => {
    if (type === "next") {
      setLazyParams((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    } else {
      setLazyParams((prev) => ({
        ...prev,
        page: prev.page - 1,
      }));
    }
  };

  console.log({ lazyParams });

  return (
    <>
      <div className="flex flex-col gap-y-3">
        <div className="w-full grid lg:grid-cols-4 grid-cols-2 gap-2">
          <div
            className="col-span-1 flex flex-col gap-y-1 rounded p-2"
            style={themesSetting.card}
          >
            <div className="w-8 h-8 rounded-full">
              <VButton radius="full" iconLeft={<Icons iconName="profit" />} />
            </div>
            <div className="flex flex-col">
              <span className="text-xs opacity-70">Total sales</span>
              <div className="flex gap-x-1">
                <span>$150.992</span>
                <div className="flex items-center">
                  <Icons iconName="arrow-up" iconClassName="!w-4 !h-4" />
                  <span className="text-xs text-green-500">+12.5%</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-span-1 flex flex-col gap-y-1 rounded p-2"
            style={themesSetting.card}
          >
            <div className="w-8 h-8 rounded-full">
              <VButton
                radius="full"
                iconLeft={<Icons iconName="affiliate" />}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-xs opacity-70">Affiliate</span>
              <div className="flex gap-x-1">
                <span>$150.992</span>
                <div className="flex items-center">
                  <Icons iconName="arrow-up" iconClassName="!w-4 !h-4" />
                  <span className="text-xs text-green-500">+12.5%</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-span-1 flex flex-col gap-y-1 rounded p-2"
            style={themesSetting.card}
          >
            <div className="w-8 h-8 rounded-full">
              <VButton
                radius="full"
                iconLeft={<Icons iconName="group_sales" />}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-xs opacity-70">Group sales</span>
              <div className="flex gap-x-1">
                <span>$150.992</span>
                <div className="flex items-center">
                  <Icons iconName="arrow-up" iconClassName="!w-4 !h-4" />
                  <span className="text-xs text-green-500">+12.5%</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-span-1 flex flex-col gap-y-1 rounded p-2"
            style={themesSetting.card}
          >
            <div className="w-8 h-8 rounded-full">
              <VButton radius="full" iconLeft={<Icons iconName="customer" />} />
            </div>
            <div className="flex flex-col">
              <span className="text-xs opacity-70">Customers</span>
              <div className="flex gap-x-1">
                <span>914</span>
                <div className="flex items-center">
                  <Icons iconName="arrow-down" iconClassName="!w-4 !h-4" />
                  <span className="text-xs text-red-500">-12.5%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full rounded flex justify-center gap-x-6 py-3"
          style={themesSetting.card}
        >
          <div
            className="flex flex-col justify-end items-center gap-y-1 cursor-pointer hover:opacity-80"
            onClick={() => setOpenModalDetail(true)}
          >
            <div className="w-6 h-6 flex justify-center items-center rounded bg-orange-500 text-white text-xs">
              2
            </div>
            <div className="w-14 h-14 rounded-full">
              <img
                className="w-full h-full rounded-full object-cover"
                src="/assets/images/avatar.png"
                alt=""
              />
            </div>
            <span className="text-base font-bold">Robert Fox</span>
            <span className="text-xs opacity-70">$150.992.175</span>
          </div>
          <div
            className="flex flex-col justify-center items-center gap-y-1 cursor-pointer hover:opacity-80"
            onClick={() => setOpenModalDetail(true)}
          >
            <div className="w-6 h-6">
              <Icons iconName="top-1" />
            </div>
            <div className="w-20 h-20 rounded-full">
              <img
                className="w-full h-full rounded-full object-cover"
                src="/assets/images/avatar.png"
                alt=""
              />
            </div>
            <span className="text-base font-bold">Robert Fox</span>
            <span className="text-xs opacity-70">$150.992.175</span>
          </div>

          <div
            className="flex flex-col justify-end items-center gap-y-1 cursor-pointer hover:opacity-80"
            onClick={() => setOpenModalDetail(true)}
          >
            <div className="w-6 h-6 flex justify-center items-center rounded bg-orange-500 text-white text-xs">
              3
            </div>
            <div className="w-14 h-14 rounded-full">
              <img
                className="w-full h-full rounded-full object-cover"
                src="/assets/images/avatar.png"
                alt=""
              />
            </div>
            <span className="text-base font-bold">Robert Fox</span>
            <span className="text-xs opacity-70">$150.992.175</span>
          </div>
        </div>
        <div className="w-full">
          <VTable
            columns={columns}
            data={DEFAULT_DATA}
            isActionTable={true}
            onClick={() => setOpenModalDetail(true)}
          />
          <VPagination
            page={1}
            limit={10}
            totalDocs={0}
            totalPages={1}
            onFilter={onFilter}
          />
        </div>
        <VModal
          title="Robert Fox"
          isOpen={openModalDetail}
          onCancel={() => setOpenModalDetail(false)}
        >
          <Details />
        </VModal>
      </div>
    </>
  );
};

export default Rankings;
