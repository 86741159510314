/* eslint-disable react-hooks/exhaustive-deps */
import Switch from "react-switch";

import { useContext, useEffect, useState } from "react";

import { MasterContext } from "context/MasterContext";
import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";

const FormProjectICO = (props: any) => {
  const { stocks } = useContext(MasterContext);
  const { isDisable, isShowDetail, projectInfo, handleChangeProject } = props;
  const [fieldTokenomics, setFieldTokenomics] = useState(
    projectInfo?.info.tokenomics || []
  );

  const addFieldTokenomics = () => {
    setFieldTokenomics(
      fieldTokenomics.concat({
        name: "",
        percent: "",
      })
    );
  };

  const removeField = () => {
    const newArr = fieldTokenomics.slice(0, fieldTokenomics.length - 1);
    setFieldTokenomics(newArr);
  };

  const onChangeTokenomics = (key, type, e) => {
    const newArr: any = fieldTokenomics;
    newArr[key] = {
      ...fieldTokenomics[key],
      [type]: e.target.value,
    };
    setFieldTokenomics(newArr);
  };

  useEffect(() => {
    if (fieldTokenomics) {
      handleChangeProject("tokenomics", fieldTokenomics, "info");
    }
  }, [fieldTokenomics]);

  return (
    <>
      <VInput
        className="w-full py-2 px-2 border rounded-md bg-transparent"
        placeholder="your contract address"
        label="Your contract address"
        onChange={(e) => handleChangeProject("contract", e.target.value)}
        value={projectInfo?.contract}
        disabled={isDisable}
      />
      <div className="flex items-center my-[10px]">
        <label className="mr-[20px] text-[14px]">Holding balance:</label>
        <Switch
          onChange={(check) => handleChangeProject("is_holding_balance", check)}
          checked={projectInfo?.is_holding_balance}
        />
      </div>
      <VInput
        className="w-full py-2 px-2 border rounded-md bg-transparent"
        transparent={true}
        disabled={isDisable}
        placeholder="url"
        label="Url"
        value={projectInfo?.info.url}
        onChange={(e) => handleChangeProject("url", e.target.value, "info")}
      />

      <div className="flex justify-between items-center w-full">
        <div className="w-[48%]">
          <VInput
            className="w-full py-2 px-2 border rounded-md bg-transparent"
            transparent={true}
            placeholder="tabs"
            label="Tabs"
            value={projectInfo?.info.tabs}
            disabled={isDisable}
            onChange={(e) =>
              handleChangeProject("tabs", e.target.value, "info")
            }
          />
        </div>
        <div className="w-[48%]">
          <VInput
            className="w-full py-2 px-2 border rounded-md bg-transparent"
            type="text"
            transparent={true}
            placeholder="0"
            label="Amount To Sale"
            isCurrency={true}
            allowNegativeValue={false}
            value={projectInfo?.cal.amount_to_sale}
            disabled={isDisable}
            onValueChange={(e) =>
              handleChangeProject("amount_to_sale", e, "cal")
            }
          />
        </div>
      </div>
      <div className="flex justify-between items-center w-full">
        <div className="w-[48%]">
          <VInput
            className="w-full py-2 px-2 border rounded-md bg-transparent"
            type="text"
            transparent={true}
            placeholder="0"
            label="Stock price"
            isCurrency={true}
            allowNegativeValue={false}
            value={projectInfo?.cal.stock_price}
            disabled={isDisable}
            onValueChange={(e) => handleChangeProject("stock_price", e, "cal")}
          />
        </div>
        <div className="w-[48%]">
          <VDropdown
            value={projectInfo?.cal.stock_to_buy}
            customClassName="bg-transparent rounded-md"
            customOptionClassName="w-full"
            label="Stock to buy"
            options={stocks?.map((el: any) => {
              return {
                label: el?.name,
                value: el?.symbol,
              };
            })}
            onChange={(value) =>
              handleChangeProject("stock_to_buy", value, "cal")
            }
          />
        </div>
      </div>
      <div className="flex justify-between items-center w-full">
        <div className="w-[48%]">
          <VInput
            className="w-full py-2 px-2 border rounded-md bg-transparent"
            type="text"
            transparent={true}
            placeholder="0"
            label="Max per user"
            isCurrency={true}
            allowNegativeValue={false}
            value={projectInfo?.cal.max_per_user}
            disabled={isDisable}
            onValueChange={(e) => handleChangeProject("max_per_user", e, "cal")}
          />
        </div>
        <div className="w-[48%]">
          <VInput
            className="w-full py-2 px-2 border rounded-md bg-transparent"
            type="text"
            transparent={true}
            placeholder="0"
            label="Max per product"
            isCurrency={true}
            allowNegativeValue={false}
            value={projectInfo?.cal.max_per_product}
            disabled={isDisable}
            onValueChange={(e) =>
              handleChangeProject("max_per_product", e, "cal")
            }
          />
        </div>
      </div>
      <div className="flex justify-between items-center w-full">
        <div className="w-[48%]">
          <VDropdown
            value={projectInfo.type_sell}
            customClassName="bg-transparent rounded-md"
            customOptionClassName="w-full"
            label="Type Sell"
            options={[
              { label: "round", value: "round" },
              { label: "pixel", value: "pixel" },
            ]}
            onChange={(value) => handleChangeProject("type_sell", value)}
          />
        </div>
        <div className="w-[48%]">
          <VDropdown
            value={projectInfo.type_product}
            customClassName="bg-transparent rounded-md"
            customOptionClassName="w-full"
            label="Type Product"
            options={[
              { label: "tree", value: "tree" },
              { label: "apartment", value: "apartment" },
              { label: "land", value: "land" },
              { label: "image", value: "image" },
              { label: "product", value: "product" },
            ]}
            onChange={(value) => handleChangeProject("type_product", value)}
          />
        </div>
      </div>
      <VInput
        className="w-full py-2 px-2 border rounded-md bg-transparent"
        placeholder="logo_pixel"
        label="Logo pixel"
        value={projectInfo?.info?.logo_pixel}
        disabled={isDisable}
        onChange={(e) =>
          handleChangeProject("logo_pixel", e.target.value, "info")
        }
      />

      <div className="flex justify-between items-center w-full">
        <div className="w-[48%]">
          <VInput
            className="w-full py-2 px-2 border rounded-md bg-transparent"
            type="text"
            transparent={true}
            placeholder="0"
            label="Min Sale"
            isCurrency={true}
            allowNegativeValue={false}
            value={projectInfo?.cal.min}
            disabled={isDisable}
            onValueChange={(e) => handleChangeProject("min", e, "cal")}
          />
        </div>
        <div className="w-[48%]">
          <VInput
            className="w-full py-2 px-2 border rounded-md bg-transparent"
            type="text"
            transparent={true}
            placeholder="0"
            label="Max Sale"
            isCurrency={true}
            allowNegativeValue={false}
            value={projectInfo?.cal.max}
            disabled={isDisable}
            onValueChange={(e) => handleChangeProject("max", e, "cal")}
          />
        </div>
      </div>

      {isShowDetail ? (
        <div className="relative flex items-center text-[14px]">Tokenomics</div>
      ) : (
        <div className={`relative flex items-center text-[14px] gap-x-3`}>
          Tokenomics:&ensp;
          <div
            className="w-[30px] border-[0.1px] cursor-pointer rounded-md text-center hover:bg-red-500"
            onClick={removeField}
          >
            -
          </div>
          <div
            className="w-[30px] mr-[20px] border-[0.1px] cursor-pointer rounded-md text-center hover:bg-blue-500"
            onClick={addFieldTokenomics}
          >
            +
          </div>
        </div>
      )}
      {fieldTokenomics.length > 0 &&
        fieldTokenomics.map((el, index) => (
          <div className="flex justify-between">
            <div className="w-[48%]">
              <VInput
                className="w-full py-2 px-2 border rounded-md bg-transparent"
                transparent={true}
                defaultValue={el?.name}
                disabled={isDisable}
                placeholder="A"
                onChange={(e) => onChangeTokenomics(index, "name", e)}
              />
            </div>
            <div className="w-[48%]">
              <VInput
                className="w-full py-2 px-2 border rounded-md bg-transparent"
                transparent={true}
                defaultValue={Number(el?.percent)}
                disabled={isDisable}
                type="number"
                min="0"
                placeholder="%"
                onChange={(e) => onChangeTokenomics(index, "percent", e)}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default FormProjectICO;
