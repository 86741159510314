import { Disclosure } from "@headlessui/react";
import Icons from "helper/icons";
import React from "react";

type IProps = {
  title?: string;
  height?: any;
  children?: React.ReactNode;
};

const VDisclosure = (props: IProps) => {
  const { title, height, children } = props;
  return (
    <Disclosure defaultOpen={false} as="div" className="flex flex-col w-full">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={
              "relative flex items-center justify-between w-full text-left rounded-md py-2 gap-x-3 text-sm leading-6 font-semibold transition-colors duration-300 ease-linear"
            }
          >
            {title}
            <Icons iconName="dropdown" />
          </Disclosure.Button>
          <div
            className={`transition-all duration-500 ease-linear overflow-hidden`}
            style={
              open
                ? {
                    height: height ? height : "300px",
                  }
                : { height: 0 }
            }
          >
            <Disclosure.Panel as="ul" className={`mt-1 px-2`}>
              {children && children}
            </Disclosure.Panel>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default VDisclosure;
