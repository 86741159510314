import Button from "components/v-core/v-button";
import { formatNumber } from "utils/common";
import { HistoriesWrapper } from "./styles";
import VTable from "components/v-core/v-table";

const Histories = (props) => {
  const { setCongratulationInverst, setOpenModalCongratulation } = props;

  const columns = [
    {
      key: "Period",
      title: "Period",
      dataIndex: "_id",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },
    {
      key: "invoice",
      title: "Invoice",
      render: (item: any) => (
        <a
          href={`/invoice/ico/${item.project_id["symbol"]}/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <Button>View</Button>
        </a>
      ),
    },
    {
      key: "congratulation",
      title: "Congratulation",
      render: (item: any) => (
        <Button onClick={() => handleViewCongratulation(item)}>View</Button>
      ),
    },
    {
      key: "Project",
      title: "Project",
      render: (item: any) =>
        `${item.project_id ? item.project_id["name"] : "N/A"}`,
    },

    {
      key: "Amount Sent",
      title: "Amount Sent",
      render: (item: any) =>
        `${formatNumber(item.project.amount)} (${
          item.project_id ? item.project_id["stock_to_buy"] : "N/A"
        })`,
    },
    {
      key: "amountReceived",
      title: "AmountReceived",
      render: (item: any) =>
        `${formatNumber(item.project.amountReceived)} (${
          item.project_id ? item.project_id["symbol"] : "N/A"
        })`,
    },
    {
      key: "Hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.project.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.project.hash?.slice(0, 20)}...
        </a>
      ),
    },
  ];

  const handleViewCongratulation = (data: any) => {
    setCongratulationInverst(data);
    setOpenModalCongratulation(true);
  };

  // useEffect(() => {
  //   getPurchases({
  //     params: {
  //       type: "project",
  //     },
  //   });
  // }, []);

  return (
    <HistoriesWrapper>
      <VTable columns={columns} data={[]}></VTable>
    </HistoriesWrapper>
  );
};

export default Histories;
