import styled from "styled-components";
import { Link } from "react-router-dom";

export const Content = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 100%;
  .education-experience-particles {
    z-index: 0;
  }
  .content {
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 410px) {
    /* padding-bottom: 0px; */
    padding: 0px;
  }
`;

export const Form = styled.form`
  width: 100%;

  @media screen and (max-width: 456px) {
    padding: 0 20px;
  }
`;

export const FormTitle = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
  @media screen and (max-width: 420px) {
    text-align: center;
  }
`;

export const FormDescription = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
`;

export const FormItem = styled.div`
  margin: 12px 0;

  > button {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
  }
`;

export const HelperLink = styled(Link)`
  color: white;
  display: block;
  text-decoration: none;
  margin-top: 8px;

  span {
    color: ${(props) => props.theme.colors.orange100};

    :hover {
      color: ${(props) => props.theme.hoverColors.orange100};
    }
  }

  :hover {
    color: #ffffffbb;
  }
`;

export const Countdown = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-align: end;
  width: fit-content;
  color: ${(props) => props.theme.colors.orange100};

  .spinner-border {
    width: 17px;
    height: 17px;
  }
`;

export const SocialIcons = styled.div`
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    width: 50px;
    margin: 0 10px;
  }
  .metamask {
    width: 55px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 20px;
  }
`;

export const QrCodeLogin = styled.div`
  margin-top: 100px;
  margin-right: 100px;
  text-align: center;
  width: 200px;

  svg {
    margin-bottom: 30px;
  }

  p {
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    margin-right: 0px;
    margin-top: 50px;
    width: auto;
  }
`;
