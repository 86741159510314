/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import History from "./History";
import Progress from "components/progress";
import VButton from "components/v-core/v-button";
import { formatNumber } from "utils/common";
import VInput from "components/v-core/v-input";
import VDropdown from "components/v-core/v-dropdown";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";
import StockService from "api/cryptos";
import VTabs from "components/v-core/v-tabs";
import Icons from "helper/icons";
import { renderStock } from "utils/render";

const View = () => {
  const tabs = [
    {
      name: "Market place",
      value: "market",
    },
    {
      name: "IBO Stock Projects",
      value: "projects",
      disable: true,
    },
  ];

  const { stocksPrice, balances, getBalances, stockDefault } =
    useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);

  const [stocksSwap, setStocksSwap] = useState([]);
  const [stocksProjectSwap, setStocksProjectSwap] = useState([]);
  const [active, setActive] = useState<string>(tabs[0]?.value);
  const [state, setState] = useState({
    from: null,
    to: null,
    listFrom: [],
    listTo: [],
    amountFrom: null,
    amountTo: null,
    fee: 0,
    oneWay: true,
  });
  const [loading, setLoading] = useState(false);
  const [valueProgress, setValueProgress] = useState(0);
  const [history, setHistory] = useState({
    docs: [],
  });
  const [filter, setFilter] = useState({ page: 1, limit: 10 });

  useEffect(() => {
    getStocksSwap();
    getStocksProjectSwap();
  }, []);

  useEffect(() => {
    if (balances && state.from)
      setState({
        ...state,
        amountFrom: ((balances[state.from.symbol] || 0) * valueProgress) / 100,
      });
  }, [valueProgress]);

  useEffect(() => {
    init();
  }, [stocksSwap]);

  useEffect(() => {
    if (state.from && state.to) {
      const amountFrom = state.amountFrom || 0;
      let amountTo;
      if (active === "market") {
        amountTo =
          amountFrom *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          amountFrom *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }
      const fee_swap = state.from.fee_swap || {
        stock: stockDefault,
        percent: 1,
      };
      const fee = (amountFrom * +fee_swap.percent) / 100;
      setState({
        ...state,
        amountFrom,
        amountTo,
        fee,
      });
    }
  }, [state.amountFrom, state.amountTo]);

  useEffect(() => {
    if (state.from && state.to) {
      setValueProgress(0);
      setState({
        ...state,
        amountFrom: null,
        amountTo: null,
      });
    }
  }, [state.from, state.to]);

  useEffect(() => {
    getHistory();
  }, [filter]);

  const init = () => {
    if (stocksSwap) {
      const fromSelected = stocksSwap.find(
        (s) => s.symbol === stocksSwap[0]?.symbol
      );
      const listTo = stocksSwap.filter(
        (s) => (fromSelected.pairs || {})[s.symbol]
      );
      setState({
        ...state,
        from: fromSelected,
        to: listTo[0],
        listFrom: stocksSwap,
        listTo,
      });
    }
  };

  const getStocksSwap = async () => {
    try {
      await StockService.getStocksSwap().then((res: any) => {
        if (res && res.stocks)
          setStocksSwap(
            res.stocks.map((i) => ({
              ...i,
              value: i.symbol,
              label: String(i.symbol).toUpperCase(),
              icon: i.logo,
            }))
          );
      });
    } catch (error) {}
  };

  const getStocksProjectSwap = async () => {
    try {
      await StockService.getStockProjetsSwap().then((res: any) => {
        if (res && res.stocks) setStocksProjectSwap(res);
      });
    } catch (error) {}
  };

  const getHistory = () => {
    StockService.getHistorySwap({
      query: filter,
    }).then((res: any) => setHistory(res));
  };

  const onFilter = (type: string) => {
    if (type === "next") {
      setFilter((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        page: prev.page - 1,
      }));
    }
  };

  const handleTabs = (tab: string) => {
    if (tab === "projects") {
      setState({
        ...state,
        from: stocksProjectSwap[0],
        to: stocksProjectSwap[1],
      });
    } else {
      init();
    }
    setActive(tab);
  };

  const handleChoose = (type, stock) => {
    if (active === "projects") {
      if (type === "from") {
        setState({
          ...state,
          from: stock,
        });
      } else
        setState({
          ...state,
          to: stock,
        });
    } else {
      let from;
      let to;
      let oneWay = true;
      let listFrom = [];
      let listTo = [];
      if (type === "from") {
        listFrom = stocksSwap;
        listTo = stocksSwap.filter((s) => (stock.pairs || {})[s.symbol]);
        from = stock;
        to = listTo[0];
      } else {
        listFrom = stocksSwap.filter((s) => (s.pairs || {})[stock.symbol]);
        listTo = state.listTo;
        from = state.from;
        to = stock;
      }
      if (
        from &&
        to &&
        (from.pairs || {})[to.symbol] &&
        (to.pairs || {})[from.symbol]
      ) {
        oneWay = false;
      }
      setState({
        ...state,
        from,
        to,
        listFrom,
        listTo,
        oneWay,
      });
    }
  };

  const handleChange = (type, value) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      if (active === "market") {
        amountTo =
          +value *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          +value *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }
      setValueProgress((amountFrom / balances[state.from.symbol]) * 100);
    } else {
      amountTo = +value;
      if (active === "market") {
        amountFrom =
          +value *
          (+stocksPrice[state.to.symbol]["vnd"] /
            +stocksPrice[state.from.symbol]["vnd"]);
      } else {
        amountFrom =
          +value *
          ((+stocksPrice[state.to.stock_to_buy]["vnd"] * state.to.stock_price) /
            (+stocksPrice[state.from.stock_to_buy]["vnd"] *
              state.from.stock_price));
      }
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleSwap = async () => {
    try {
      setLoading(true);
      let res;
      if (active === "market") {
        res = await StockService.swap({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      } else {
        res = await StockService.swapStocksProjectAsync({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      }

      if (res) {
        setState({
          ...state,
          amountFrom: null,
          amountTo: null,
        });
        getBalances();
        getHistory();
        toast.success("Swap success!");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  // const handleSwapStocks = () => {
  //   setState((state) => {
  //     return {
  //       ...state,
  //       from: state.to,
  //       to: state.from,
  //     };
  //   });
  // };

  const handleChangeProgress = (value) => {
    setValueProgress(value);
  };

  const listFrom = useMemo(() => {
    if (state?.listFrom) return state?.listFrom;
    return [];
  }, [state]);

  const listTo = useMemo(() => {
    if (state?.listTo) return state?.listTo;
    return [];
  }, [state]);

  return (
    <div className="px-3 py-4 w-full">
      <div className="lg:flex lg:justify-center">
        <div className="p-3 lg:w-[700px] md:w-full" style={themesSetting?.card}>
          <div className="gap-2 mb-4 w-full">
            <VTabs
              tabActive={active}
              tabs={tabs}
              type="button"
              onChangeTabs={handleTabs}
            />
          </div>

          <div className="">
            <div className="rounded-lg">
              <div className="w-full">
                <VDropdown
                  options={listFrom}
                  value={state.from?.value}
                  optionLabel="label"
                  optionIcon="logo"
                  onChange={(_, object) => handleChoose("from", object)}
                />
              </div>

              <div className="w-full cursor-pointer my-1 flex justify-center items-center">
                <Icons
                  isActive={!state.oneWay}
                  iconClassName="w-8 h-8"
                  iconName="stock_swap"
                  // onClick={handleSwapStocks}
                />
              </div>
              <div className="w-full">
                <VDropdown
                  options={listTo}
                  value={state.to?.value}
                  optionLabel="label"
                  optionIcon="logo"
                  onChange={(_, object) => handleChoose("to", object)}
                />
              </div>
            </div>

            <div className="flex flex-col w-full gap-y-5 my-4 ">
              <div className="w-full relative">
                <VInput
                  inputStyle={themesSetting?.card}
                  className="w-full  h-[90px] rounded-lg justify-center items-center flex px-3"
                  name="amountTo"
                  isCurrency={true}
                  value={state.amountFrom}
                  placeholder="0.00"
                  onValueChange={(value) => handleChange("from", value)}
                  disabled={state.from && state.to ? false : true}
                />
                <div className="w-full mt-2">
                  <Progress
                    value={valueProgress}
                    handleChange={handleChangeProgress}
                  />
                </div>
                <div
                  style={themesSetting?.card}
                  className="flex !m-0 absolute justify-center items-center top-0 left-[10px] p-1 rounded-md -translate-y-1/2"
                >
                  <Icons iconName="wallets" />
                  <div className="pl-1 text-sm">
                    {formatNumber(balances[state?.from?.symbol] || 0)}
                    <span className="ml-1 font-bold">
                      {renderStock(state?.from?.symbol)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-full relative mt-2">
                <VInput
                  inputStyle={themesSetting?.card}
                  className="w-full  h-[90px] rounded-lg justify-center items-center flex px-3"
                  name="amountTo"
                  isCurrency={true}
                  value={state.amountTo}
                  placeholder="0.00"
                  onValueChange={(value) => handleChange("to", value)}
                  disabled={state.from && state.to ? false : true}
                />
                <div
                  style={themesSetting?.card}
                  className="flex !m-0 absolute justify-center items-center top-0 left-[10px] p-1 rounded-md -translate-y-1/2"
                >
                  <Icons iconName="wallets" />
                  <div className="pl-1 text-sm">
                    {formatNumber(balances[state?.to?.symbol] || 0)}
                    <span className="ml-1 font-bold">
                      {renderStock(state?.to?.symbol)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full justify-center items-center m-auto">
              <VButton
                className="w-full py-3 text-base rounded-lg mb-4 "
                loading={loading}
                onClick={handleSwap}
                disabled={!state.from || !state.to}
              >
                Claim
              </VButton>
            </div>
            <div
              style={themesSetting?.input}
              className="grid grid-cols-9 items-center justify-center h-[90px] rounded-lg"
            >
              <div className="col-span-4 flex justify-center items-center gap-2">
                <img className="w-6 h-6" src={state?.from?.logo} alt="" />
                {state.from && state.to && (
                  <p className="m-0">
                    <span>1</span>
                  </p>
                )}
              </div>
              <div className="col-span-1 text-center">=</div>
              <div className="col-span-4 flex justify-center items-center gap-2">
                <img className="w-6 h-6" src={state?.to?.logo} alt="" />
                <p className="m-0">
                  {active === "market" &&
                    formatNumber(
                      +stocksPrice[state.from?.symbol]?.["vnd"] /
                        stocksPrice[state.to?.symbol]?.["vnd"]
                    )}
                </p>
              </div>
            </div>
            <div className="">
              <span>Fee : {formatNumber(state.fee)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 w-full shadow-md shadow-gray-500">
        <History history={history} filter={filter} onFilter={onFilter} />
      </div>
    </div>
  );
};

export default View;
