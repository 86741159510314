// import VButton from "components/v-core/v-button";
import React, { useState } from "react";
import FiltersArea from "./components/filters";
import ListNfts from "./components/list";
// import { useHistory } from "react-router-dom";

const Nfts = () => {
  // const history = useHistory();
  const [itemSelected, setItemSelected] = useState(null);
  // const handleCreate = () => {
  //   history.push("/my/shop/create");
  // };
  return (
    <div className="flex flex-col gap-y-4 px-3 py-6">
      {/* <VButton className="w-[120px]" onClick={handleCreate}>
        Create NFT
      </VButton> */}
      {/* <hr className="w-full border-b border-dashed" /> */}
      <FiltersArea />
      <hr className="w-full border-b border-dashed" />
      <ListNfts itemSelected={itemSelected} setItemSelected={setItemSelected} />
    </div>
  );
};

export default Nfts;
