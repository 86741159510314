const IconLandingPage = ({ iconName, iconClassName }) => {
  return (
    <>
      {iconName === "play_button" && (
        <svg
          width="70"
          height="52"
          viewBox="0 0 110 92"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M87 0.285645H23C16.9379 0.285645 11.1241 2.6938 6.83756 6.98035C2.55101 11.2669 0.142853 17.0807 0.142853 23.1428V68.8571C0.142853 71.8587 0.73407 74.831 1.88275 77.6041C3.03143 80.3773 4.71507 82.897 6.83756 85.0195C11.1241 89.3061 16.9379 91.7142 23 91.7142H87C90.0016 91.7142 92.9739 91.123 95.7471 89.9743C98.5202 88.8256 101.04 87.142 103.162 85.0195C105.285 82.897 106.969 80.3773 108.117 77.6041C109.266 74.831 109.857 71.8587 109.857 68.8571V23.1428C109.857 17.0807 107.449 11.2669 103.162 6.98035C98.8759 2.6938 93.0621 0.285645 87 0.285645ZM70.915 50.0045L48.0578 62.5759C47.3619 62.9582 46.5784 63.1525 45.7845 63.1399C44.9906 63.1273 44.2137 62.9081 43.5303 62.504C42.8468 62.0999 42.2805 61.5247 41.8869 60.8351C41.4933 60.1455 41.2861 59.3653 41.2857 58.5714V33.4285C41.2859 32.6342 41.493 31.8536 41.8867 31.1637C42.2804 30.4739 42.8471 29.8985 43.5309 29.4943C44.2146 29.0901 44.9919 28.871 45.7862 28.8587C46.5804 28.8463 47.3641 29.0411 48.0601 29.4239L70.9173 41.9954C71.635 42.3892 72.2337 42.9686 72.6507 43.6731C73.0677 44.3776 73.2878 45.1813 73.2878 45.9999C73.2878 46.8186 73.0677 47.6222 72.6507 48.3267C72.2337 49.0312 71.6327 49.6107 70.915 50.0045Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "upload" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2C9.55912 2 7.4608 3.45744 6.52373 5.54534C6.44011 5.73166 6.38425 5.85598 6.34206 5.94471C6.33167 5.96657 6.32326 5.98381 6.31654 5.99729L6.29677 5.99799C6.23318 5.99989 6.14598 6 6 6C3.79086 6 2 7.79086 2 10C2 12.2091 3.79086 14 6 14H6.17157L8.17157 12H6C4.89543 12 4 11.1046 4 10C4 8.89543 4.89543 8 6 8L6.06419 8.0001H6.0642C6.27177 8.00057 6.51342 8.00111 6.71431 7.96068C6.92683 7.91791 7.17254 7.84692 7.414 7.69062C7.65546 7.53433 7.82083 7.33923 7.94687 7.16285C8.03371 7.04134 8.10219 6.90047 8.14829 6.8035C8.20111 6.69243 8.26605 6.54772 8.34352 6.37511L8.34839 6.36427C8.97472 4.96871 10.3753 4 12 4C13.6247 4 15.0253 4.96871 15.6516 6.36427L15.6565 6.37511C15.7339 6.54772 15.7989 6.69243 15.8517 6.8035C15.8978 6.90047 15.9663 7.04134 16.0531 7.16285C16.1792 7.33923 16.3445 7.53433 16.586 7.69062C16.8275 7.84692 17.0732 7.91791 17.2857 7.96068C17.4866 8.00111 17.7282 8.00057 17.9358 8.0001H17.9358L18 8C19.1046 8 20 8.89543 20 10C20 11.1046 19.1046 12 18 12H15.8284L17.8284 14H18C20.2091 14 22 12.2091 22 10C22 7.79086 20.2091 6 18 6C17.854 6 17.7668 5.99989 17.7032 5.99799L17.6835 5.99729C17.6767 5.98381 17.6683 5.96657 17.6579 5.94471C17.6158 5.85598 17.5599 5.73166 17.4763 5.54534C16.5392 3.45744 14.4409 2 12 2ZM6.29756 6.03369C6.29755 6.03369 6.29761 6.03357 6.29774 6.03336L6.29777 6.03333L6.29756 6.03369ZM17.7024 6.03369C17.7024 6.03369 17.7024 6.03358 17.7022 6.03333L17.7023 6.03336L17.7024 6.03369Z"
            fill="currentColor"
          />
          <path
            d="M12 12L11.2929 11.2929L12 10.5858L12.7071 11.2929L12 12ZM13 21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21L13 21ZM7.29289 15.2929L11.2929 11.2929L12.7071 12.7071L8.70711 16.7071L7.29289 15.2929ZM12.7071 11.2929L16.7071 15.2929L15.2929 16.7071L11.2929 12.7071L12.7071 11.2929ZM13 12L13 21L11 21L11 12L13 12Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "check" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM11.7682 15.6402L16.7682 9.64018L15.2318 8.35982L10.9328 13.5186L8.70711 11.2929L7.29289 12.7071L10.2929 15.7071L11.0672 16.4814L11.7682 15.6402Z"
            fill="#00DE38"
          />
        </svg>
      )}
      {iconName === "add_image" && (
        <svg
          width="91"
          height="91"
          viewBox="0 0 91 91"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.34881 20.1047C6.34881 12.5075 12.5075 6.34884 20.1046 6.34884H53.9651C55.7182 6.34884 57.1395 4.92759 57.1395 3.17442C57.1395 1.42125 55.7182 0 53.9651 0H20.1046C9.00114 0 -3.05176e-05 9.00117 -3.05176e-05 20.1047V70.8953C-3.05176e-05 81.999 9.00114 91 20.1046 91H70.8953C81.999 91 91 81.999 91 70.8953V37.0349C91 35.2817 89.5787 33.8605 87.8256 33.8605C86.0724 33.8605 84.6511 35.2817 84.6511 37.0349V51.8874C84.4602 51.7346 84.2617 51.5894 84.056 51.4522L73.9787 44.7339C67.2638 40.2571 58.3225 41.1426 52.6157 46.8493L38.3842 61.0809C32.6774 66.7877 23.7362 67.6731 17.0213 63.1963L6.34881 56.0814V20.1047ZM78.3023 3.17442C78.3023 1.42125 76.881 0 75.1279 0C73.3748 0 71.9535 1.42125 71.9535 3.17442V12.6977H62.4302C60.6771 12.6977 59.2558 14.1189 59.2558 15.8721C59.2558 17.6253 60.6771 19.0465 62.4302 19.0465H71.9535V28.5698C71.9535 30.3229 73.3748 31.7442 75.1279 31.7442C76.881 31.7442 78.3023 30.3229 78.3023 28.5698V19.0465H87.8256C89.5787 19.0465 91 17.6253 91 15.8721C91 14.1189 89.5787 12.6977 87.8256 12.6977H78.3023V3.17442ZM30.686 41.2674C36.53 41.2674 41.2674 36.53 41.2674 30.686C41.2674 24.8421 36.53 20.1047 30.686 20.1047C24.8421 20.1047 20.1046 24.8421 20.1046 30.686C20.1046 36.53 24.8421 41.2674 30.686 41.2674Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "add_video" && (
        <svg
          width="112"
          height="88"
          viewBox="0 0 112 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86.5454 0.98584H25.4546C11.4048 0.98584 0 12.3907 0 26.4404V62.0768C0 76.1265 11.4048 87.5313 25.4546 87.5313H50.9091V64.1648L44.3416 70.782C42.3555 72.7681 39.0991 72.7681 37.1129 70.782C35.1268 68.7959 35.1268 65.5395 37.1129 63.5533L52.4354 48.2806C53.3526 47.3633 54.6775 46.804 56 46.804C57.3747 46.804 58.6474 47.3633 59.6143 48.2806L74.8871 63.5533C76.8732 65.5394 76.8732 68.7959 74.8871 70.782C73.8679 71.749 72.5952 72.2586 71.2727 72.2586C69.9503 72.2586 68.6776 71.749 67.6584 70.782L61.0909 64.1648V87.5313H86.5455C100.595 87.5313 112 76.1264 112 62.0767V26.4404C112 12.3907 100.595 0.98584 86.5454 0.98584ZM86.5454 11.1677C94.945 11.1677 101.818 18.0409 101.818 26.4404V31.5313H88.6832L68.3196 11.1677H86.5454ZM53.8622 11.1677L74.2259 31.5313H53.0469L32.6832 11.1677H53.8622ZM10.1818 26.4404C10.1818 20.1787 14 14.7323 19.4986 12.4404L38.5895 31.5313H10.1818V26.4404Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "copy_right" && (
        <svg
          width="17"
          height="20"
          viewBox="0 0 20 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0.258789C15.523 0.258789 20 4.73579 20 10.2588C20 15.7818 15.523 20.2588 10 20.2588C4.477 20.2588 0 15.7818 0 10.2588C0 4.73579 4.477 0.258789 10 0.258789ZM10 2.25879C7.87827 2.25879 5.84344 3.10164 4.34315 4.60193C2.84285 6.10223 2 8.13706 2 10.2588C2 12.3805 2.84285 14.4154 4.34315 15.9156C5.84344 17.4159 7.87827 18.2588 10 18.2588C12.1217 18.2588 14.1566 17.4159 15.6569 15.9156C17.1571 14.4154 18 12.3805 18 10.2588C18 8.13706 17.1571 6.10223 15.6569 4.60193C14.1566 3.10164 12.1217 2.25879 10 2.25879ZM10 5.25879C10.7094 5.25805 11.4108 5.40858 12.0575 5.70034C12.7041 5.99211 13.2811 6.4184 13.75 6.95079C13.9256 7.1497 14.0149 7.41021 13.9984 7.67501C13.9819 7.93982 13.8609 8.18722 13.662 8.36279C13.4631 8.53836 13.2026 8.62773 12.9378 8.61122C12.673 8.59472 12.4256 8.4737 12.25 8.27479C11.847 7.81768 11.3143 7.49415 10.7229 7.34725C10.1315 7.20036 9.50931 7.23706 8.93926 7.45247C8.3692 7.66789 7.87827 8.0518 7.53179 8.55312C7.18531 9.05444 6.99972 9.64939 6.99972 10.2588C6.99972 10.8682 7.18531 11.4631 7.53179 11.9645C7.87827 12.4658 8.3692 12.8497 8.93926 13.0651C9.50931 13.2805 10.1315 13.3172 10.7229 13.1703C11.3143 13.0234 11.847 12.6999 12.25 12.2428C12.4256 12.0439 12.673 11.9229 12.9378 11.9064C13.2026 11.8899 13.4631 11.9792 13.662 12.1548C13.8609 12.3304 13.9819 12.5778 13.9984 12.8426C14.0149 13.1074 13.9256 13.3679 13.75 13.5668C13.1973 14.1932 12.4964 14.6712 11.7114 14.9571C10.9264 15.2429 10.0822 15.3274 9.25611 15.203C8.42999 15.0786 7.64821 14.7492 6.98219 14.2448C6.31618 13.7405 5.78715 13.0773 5.44345 12.3158C5.09975 11.5544 4.95232 10.7189 5.01465 9.8858C5.07697 9.05269 5.34705 8.24848 5.80022 7.54663C6.25338 6.84478 6.87519 6.26767 7.60883 5.86801C8.34247 5.46836 9.16456 5.25891 10 5.25879Z"
            fill="currentColor"
          />
        </svg>
      )}
    </>
  );
};

export default IconLandingPage;
