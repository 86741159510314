/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getLinkYoutubeIframe } from "utils/common";

type IProps = {
  link?: string;
};

const linkVideo =
  "https://www.youtube.com/watch?v=Aq6W-nmRO94&ab_channel=Vzonex";

const BackgroundVideoComponent = (props: IProps) => {
  const { link } = props;
  const [idBackground, setIdBackground] = useState<string>();

  const convertIdVideo = (data: any) => {
    let idVideo;
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

    if (data) {
      const match = data.match(regExp);
      if (match && match[2].length === 11) {
        idVideo = match[2];
      } else {
        idVideo = "";
      }
    }
    setIdBackground(idVideo);
  };

  useEffect(() => {
    convertIdVideo(link ? link : linkVideo);
  }, [link]);

  return idBackground ? (
    <div className="fixed top-0 left-0 w-screen h-screen z-0">
      <iframe
        className=" pointer-events-none scale-125"
        style={{ width: "auto", height: "100%", minWidth: "100%" }}
        // eslint-disable-next-line max-len
        src={getLinkYoutubeIframe(idBackground)}
        frameBorder="0"
        allow="accelerometer"
        allowTransparency
        picture-in-picture
        allowFullScreen
      ></iframe>
    </div>
  ) : (
    <div className="fixed top-0 left-0 w-screen h-screen z-0">
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        playing={true}
        muted={true}
        loop={true}
        url={link}
      />
    </div>
  );
};

export default BackgroundVideoComponent;
