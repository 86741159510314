/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { getBase64Image } from "utils/common";
import UploadFile from "components/v-upload";
import APIs from "api";
import { exportComponentAsPNG } from "react-component-export-image";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";
import UserService from "api/users";

const CardVisit = () => {
  const visitcardFrontRef = useRef();
  const visitcardBackRef = useRef();
  const { auth, getUser } = useContext(MasterContext);
  const profile: any = auth;
  const { wallet } = profile?.vzxWallet || {};
  const vzxAddress = wallet?.address;

  const [loading, setLoading] = useState(false);
  const [showMessDownload, setShowMessDownload] = useState<boolean>(false);
  const { themesSetting } = useContext(ThemeContext);
  const { getSettingInWebConfig } = useContext(MasterContext);

  const app_name = getSettingInWebConfig("app_name");
  const url_web = getSettingInWebConfig("url_web");

  const [listFiles, setListFiles] = useState<any>({});

  const [infoUser, setInfoUser] = useState<any>({
    fullName: profile.fullName,
    phone: profile.phone,
    email: profile.email,
    visit_card: profile.visit_card,
    object: profile.object_type,
    group: profile.group,
  });

  const [isEditVisit, setIsEditVisit] = useState<boolean>(false);

  const [imageBase64, setImageBase64] = useState<any>([]);

  const [address, setAddress] = useState<any>({
    country: null,
    province: null,
    district: null,
    ward: null,
  });

  // const findLocationName = (locationId, data) => {
  //   return data?.find((item) => item._id === locationId)?.name;
  // };

  useEffect(() => {
    if (profile && profile.address) {
      setAddress(profile.address);
    }
  }, [profile]);

  const renderAddressDetail = () => {
    // const countryName = findLocationName(
    //   address?.country,
    //   location?.countries
    // );
    // const provinceName = findLocationName(
    //   address?.province,
    //   locations?.provinces
    // );
    // const districtName = findLocationName(
    //   address?.district,
    //   locations?.districts
    // );
    // const wardName = findLocationName(address?.ward, locations?.wards);
    // return `${!!wardName ? `${wardName}, ` : ""}${
    //   !!districtName ? `${districtName}, ` : ""
    // }${!!provinceName ? `${provinceName}, ` : ""}${
    //   !!countryName ? `${countryName}` : ""
    // }`;
    return ``;
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const convertBase64 = async () => {
    if (infoUser && infoUser.visit_card) {
      const { logoFrontVisit, backVisitcard } = infoUser.visit_card;
      const res = await Promise.all(
        [logoFrontVisit, backVisitcard].map(
          async (i, index) => await getBase64Image(i)
        )
      );
      setImageBase64(res);
    }
  };

  const handleDownloadVisit = async () => {
    setLoading(true);
    // if (KYC?.status === "approved") {
    await convertBase64();

    const visitCardRefs = [visitcardFrontRef, visitcardBackRef];

    const fileNames = [
      `${infoUser?.fullName}_visitFront_${new Date().getTime()}` || "vzonex",
      `${infoUser?.fullName}_visitBack_${new Date().getTime()}` || "vzonex",
    ];

    for (let i = 0; i < visitCardRefs.length; i++) {
      exportComponentAsPNG(visitCardRefs[i], { fileName: fileNames[i] });
    }
    // }
    // else {
    //   setShowMessDownload(true)
    // }
    setLoading(false);
  };

  const handleEdit = async () => {
    setLoading(true);
    let data = {
      ...infoUser,
      address,
    };
    if (Object.keys(listFiles).length > 0) {
      const formData = new FormData();
      Object.keys(listFiles).map((el: any) => {
        formData.append("files", listFiles[el]);
      });
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      if (listImg?.length > 0) {
        await Promise.all(
          listImg?.map((el: any) => {
            Object.keys(listFiles).map((item: any, indexKey: number) => {
              if (el.id === indexKey) {
                data = {
                  ...data,
                  visit_card: {
                    ...data.visit_card,
                    [item]: el?.filePath,
                  },
                };
              }
            });
          })
        );
        setListFiles({});
      }
    }
    const user = await UserService.editUser({
      params: {
        id: profile._id,
      },
      body: data,
    });

    if (user) {
      getUser();
      setInfoUser({
        fullName: data?.payload?.user?.fullName,
        phone: data?.payload?.user?.phone,
        email: data?.payload?.user?.email,
        visit_card: data?.payload?.user?.visit_card,
        group: data?.payload?.user?.group,
        object: data?.payload?.user?.object_type,
        address: data?.payload?.user?.address,
      });
      // setTab("profile");
      setLoading(false);
      toast.success("Update success!");
    } else {
      toast.error("Error");
      setLoading(false);
    }
  };

  const handleClickCard = async () => {
    if (isEditVisit) {
      await handleEdit();
    }
    setIsEditVisit(!isEditVisit);
    setShowMessDownload(false);
  };

  const onBlurText = (e: any, type?: string) => {
    if (type === "profile") {
      setInfoUser((prev) => ({
        ...prev,
        [e.target.getAttribute("data-key")]: e.target.innerText,
      }));
    } else {
      setInfoUser((prev) => ({
        ...prev,
        visit_card: {
          ...prev.visit_card,
          [e.target.getAttribute("data-key")]: e.target.innerText,
        },
      }));
    }
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="p-[10px] mt-10 flex flex-col 2xl:flex-row w-full gap-2 justify-center">
          <div
            className="mb-[30px] md:p-4 p-2 rounded-md shadow-md shadow-gray-500 max-w-[500px] max-h-[360px]"
            style={themesSetting?.card}
          >
            <div className="text-center mb-[15px] text-base font-bold">
              Front Visit Card
            </div>
            <div
              className=" w-full rounded-md overflow-hidden relative"
              ref={visitcardFrontRef}
            >
              <img
                className="w-full"
                src="/assets/images/frontvisitcard.png"
                alt="frontvisit"
              />
              <div
                className="absolute px-[10px] w-full h-[44%] top-[29%] left-0
                 flex flex-col items-center text-black"
              >
                <div className="relative w-auto min-w-[40px] max-w-full h-[30px] md:h-10 ">
                  {isEditVisit && (
                    <div
                    // isHidden={
                    //   Boolean(!listFiles["logoFrontVisit"]) &&
                    //   infoUser?.visit_card?.logoFrontVisit
                    // }
                    >
                      <UploadFile
                        id="logoFrontVisit"
                        ratio="50x50"
                        typeUpload="image"
                        file={listFiles["logoFrontVisit"]}
                        padding="0px"
                        setFile={setFile}
                      />
                    </div>
                  )}
                  <img
                    className="h-full w-auto"
                    hidden={listFiles["logoFrontVisit"]}
                    src={
                      imageBase64[0] ||
                      infoUser?.visit_card?.logoFrontVisit ||
                      ""
                    }
                    alt=""
                  />
                </div>
                <div className="text-center xl:h-[40%] h-[30%] w-full ">
                  <div
                    className="lg:text-base mb-0 font-bold whitespace-nowrap overflow-hidden"
                    data-key={"nameFrontVisit"}
                    contentEditable={isEditVisit}
                    onBlur={onBlurText}
                  >
                    {infoUser?.visit_card?.nameFrontVisit?.toUpperCase() ||
                      app_name}
                  </div>
                  <div
                    className="text-[9px] xl:text-xs text-[#8c8b8b] xl:leading-[120%] leading-[135%] whitespace-nowrap over-hidden"
                    data-key={"descFrontVisit"}
                    contentEditable={isEditVisit}
                    onBlur={onBlurText}
                  >
                    {infoUser?.visit_card?.descFrontVisit?.toUpperCase() ||
                      "DESCRIPTION"}
                  </div>
                </div>
                <div
                  className="whitespace-nowrap overflow-hidden xl:max-w-[228px] text-[16px] mt-[6px] max-w-[150px] absolute left-1/2 
                    -translate-x-1/2 bottom-0 "
                  data-key={"linkFrontVisit"}
                  contentEditable={isEditVisit}
                  onBlur={onBlurText}
                >
                  {infoUser?.visit_card?.linkFrontVisit || url_web}
                </div>
              </div>
            </div>
          </div>
          <div
            className="mb-[30px] md:p-4 p-2 rounded-md shadow-md shadow-gray-500 max-w-[500px] max-h-[360px]"
            style={themesSetting?.card}
          >
            <div className="text-center mb-[15px] text-base font-bold">
              Back Visit Card
            </div>
            <div
              className="w-full rounded-md overflow-hidden relative"
              ref={visitcardBackRef}
            >
              <img
                className="w-full"
                src="/assets/images/backvisitcard.png"
                alt="backvisit"
              />
              <div className="py-[7.5%] px-[10%] flex justify-between text-black absolute w-full h-full left-0 top-0">
                <div className="w-[35%] flex flex-col items-center overflow-hidden">
                  <div className="relative w-auto min-w-[40px] max-w-full h-10">
                    {isEditVisit && (
                      <div
                      // isHidden={
                      //   Boolean(!listFiles["backVisitcard"]) &&
                      //   infoUser?.visit_card?.backVisitcard
                      // }
                      >
                        <UploadFile
                          id="backVisitcard"
                          ratio="50x50"
                          typeUpload="image"
                          file={listFiles["backVisitcard"]}
                          padding="0px"
                          setFile={setFile}
                        />
                      </div>
                    )}
                    <img
                      className="h-full w-auto"
                      hidden={listFiles["backVisitcard"]}
                      src={
                        imageBase64[1] ||
                        infoUser?.visit_card?.backVisitcard ||
                        ""
                      }
                      alt=""
                    />
                  </div>
                  <div className="tetx-center xl:h-[40%] mt-[10px] w-full h-[30%]">
                    <div
                      className="xl:text-2xl text-base overflow-hidden font-bold whitespace-nowrap mb-0 text-center"
                      data-key={"nameBackVisit"}
                      contentEditable={isEditVisit}
                      onBlur={onBlurText}
                    >
                      {infoUser?.visit_card?.nameBackVisit?.toUpperCase() ||
                        app_name}
                    </div>
                    <div
                      className="text-[9px] xl:text-[13px] text-center text-[#8c8b8b] xl:leading-[120%] leading-[135%] whitespace-nowrap over-hidden"
                      data-key={"descBackVisit"}
                      contentEditable={isEditVisit}
                      onBlur={onBlurText}
                    >
                      {infoUser?.visit_card?.descFrontVisit?.toUpperCase() ||
                        "DESCRIPTION"}
                    </div>
                  </div>
                  <div className="p-1 bg-[#fff] inline-block mt-[15px]">
                    {vzxAddress && <QRCode value={vzxAddress} size={60} />}
                  </div>
                </div>
                <div className="w-[58%] text-right relative">
                  <div className="absolute right-0 max-w-full bottom-3/4">
                    <h5
                      className="mb-0 md:text-lg text-sm  max-h-[40px] overflow-hidden text-ellipsis break-words "
                      data-key={"fullName"}
                      contentEditable={isEditVisit}
                      onBlur={(e) => onBlurText(e, "profile")}
                    >
                      {profile?.fullName?.toUpperCase() || "FULLNAME"}
                    </h5>
                    <p
                      className="md:text-xs  text-[9px] !mb-0 text-gray-500"
                      data-key={"positionVisit"}
                      contentEditable={isEditVisit}
                      onBlur={onBlurText}
                    >
                      {infoUser?.visit_card?.positionVisit?.toUpperCase() ||
                        "POSITION"}
                    </p>
                  </div>
                  <div className="absolute w-full right-[8%] bottom-[26%] h-[34%] flex flex-col justify-between">
                    <p
                      className="mb-0 text-xs w-full overflow-hidden whitespace-nowrap "
                      data-key={"phone"}
                      contentEditable={isEditVisit}
                      onBlur={(e) => onBlurText(e, "profile")}
                    >
                      {profile?.phone || "+84..."}
                    </p>
                    <p
                      className="mb-0 text-xs w-full overflow-hidden whitespace-nowrap "
                      data-key={"phone2"}
                      contentEditable={isEditVisit}
                      onBlur={onBlurText}
                    >
                      {infoUser?.visit_card?.phone2 || "+84"}
                    </p>
                    <p className="mb-0 text-xs w-full overflow-hidden whitespace-nowrap ">
                      {profile?.email}
                    </p>
                    <p
                      className="mb-0 text-xs w-full overflow-hidden whitespace-nowrap "
                      data-key={"linkBackVisit"}
                      contentEditable={isEditVisit}
                      onBlur={onBlurText}
                    >
                      {infoUser?.visit_card?.linkBackVisit || url_web}
                    </p>
                  </div>
                  <div
                    className="absolute w-[93%] right-[10%] bottom-0 flex items-center justify-end h-[22px] md:h-[30px]"
                    data-key={"address"}
                    contentEditable={isEditVisit}
                    onBlur={(e) => onBlurText(e, "profile")}
                  >
                    <p
                      className="break-work overflow-hidden mb-0 text-right leading-[100%] md:text-sm text-[10px]  text-white max-h-full
                       truncate "
                    >
                      {renderAddressDetail() ||
                        "119 My Dinh, Nam Tu Liem, Ha Noi"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-[20px] flex justify-center gap-4">
          <VButton
            type="secondary"
            className={"px-4 h-8"}
            onClick={handleClickCard}
            loading={loading}
          >
            {isEditVisit ? "Save" : "Edit"}
          </VButton>
          <VButton
            className="mx-[10px] h-8"
            onClick={handleDownloadVisit}
            loading={loading}
          >
            Download
          </VButton>
        </div>
        {showMessDownload && (
          <div className="text-center mt-[20px]">
            You have not KYC or are waiting for approval.{" "}
            <p
              className="inline cursor-pointer"
              // onClick={() => setTab("verification")}
            >
              Click here !
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default CardVisit;
