import { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { copyText, formatCurrency, randString } from "utils/common";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import VInput from "components/v-core/v-input";
import Icons from "helper/icons";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";

const Step3 = ({ nextStep, dataPayment, setDataByKey, backStep }) => {
  const [confirmAmount] = useState(formatCurrency(dataPayment.amount));
  const [confirmString] = useState(randString(false, 10));
  // const [globalError, setGlobalError] = useState<string | null>(null);
  const { themesSetting } = useContext(ThemeContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const confirmSubmit = (data) => {
    if (data.cfAmount !== confirmAmount) {
      return setError("cfAmount", new Error("Invalid amount!"));
    }

    if (dataPayment.currency === "vnd" && data.cfString !== confirmString) {
      return setError("cfString", new Error("Invalid amount!"));
    }

    setDataByKey("amount", dataPayment.amount);
    setDataByKey("content", confirmString);

    nextStep();
  };

  const handleCopy = (tx: string) => () => {
    copyText(tx);
    toast.success("Copied to clipboard!");
  };

  return (
    <div className="p-[15px]" style={themesSetting?.card}>
      {/* <div className=""> */}
      <div>
        {" "}
        <VInput
          className="!bg-transparent"
          transparent={true}
          label={`${dataPayment.bank && dataPayment.bank.bank_name} -  ${
            dataPayment.bank && dataPayment.bank.account_name
          }`}
          value={dataPayment.bank && dataPayment.bank.account_number}
          iconRight={
            <div className="text-goldenrod flex cursor-pointer text-[10px] align-middle ml-1">
              <Icons
                iconName="copy"
                onClick={handleCopy(
                  dataPayment.bank && dataPayment.bank.account_number
                )}
              />
            </div>
          }
          disabled={true}
        />
        <VInput
          transparent={true}
          className="!bg-transparent"
          label="Amount to be paid"
          value={
            confirmAmount + " " + String(dataPayment.currency).toUpperCase()
          }
          iconRight={
            <div className="text-goldenrod flex cursor-pointer text-[10px] align-middle ml-1">
              <Icons iconName="copy" onClick={handleCopy(confirmAmount)} />
            </div>
          }
          disabled={true}
        />
        {dataPayment.bank.memo && (
          <VInput
            className="!bg-transparent"
            transparent={true}
            label="MEMO"
            value={dataPayment.bank.memo.toUpperCase()}
            suffixIcon={
              <div className="text-goldenrod flex cursor-pointer text-[10px] align-middle ml-1">
                <Icons
                  iconName="copy"
                  onClick={handleCopy(dataPayment.bank.memo)}
                />
              </div>
            }
            disabled={true}
          />
        )}
        {dataPayment.currency === "vnd" && (
          <VInput
            className="!bg-transparent"
            transparent={true}
            disabled={true}
            label="Bank transfer content"
            placeholder="LDC9DI038K"
            value={confirmString}
            iconRight={
              <div className="text-goldenrod flex cursor-pointer text-[10px] align-middle ml-1">
                <Icons iconName="copy" onClick={handleCopy(confirmString)} />
              </div>
            }
          />
        )}
      </div>
      {dataPayment.currency !== "vnd" && (
        <div>
          <div className="p-[10px] bg-white inline-block">
            <QRCode value={dataPayment.bank.account_number} size={150} />
          </div>
          <br />
          <small>
            {" "}
            <b>Network:</b> {String(dataPayment.network || "").toUpperCase()}{" "}
          </small>
          <br />
          <small>
            <b>Note:</b> Please direct to the selected network. We are not
            responsible for this issue.
          </small>
        </div>
      )}
      {/* </div> */}
      <div className="pt-[10px]">
        <p className=" text-orange-400 mb-4">Please confirm again.</p>
        <Controller
          name="cfAmount"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <VInput
              className="!bg-transparent"
              transparent={true}
              label={`The ${String(
                dataPayment.currency
              ).toUpperCase()} amount you will transfer`}
              hasError={Boolean(errors.cfAmount)}
              placeholder={`10,000 ${String(
                dataPayment.currency
              ).toUpperCase()}`}
              {...field}
            />
          )}
        />

        {dataPayment.currency === "vnd" && (
          <Controller
            name="cfString"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <VInput
                className="!bg-transparent"
                transparent={true}
                hasError={Boolean(errors.cfString)}
                label="The bank transfer content you will use"
                placeholder="LDC9DI038K"
                {...field}
              />
            )}
          />
        )}
        <div className="text-center flex flex-col items-center justify-center w-full m-auto pt-[25px] gap-y-4">
          <VButton
            onClick={handleSubmit(confirmSubmit)}
            className="min-w-[300px]"
            radius="full"
          >
            I’m ready to transfer 
          </VButton>
          <VButton
            transparent={true}
            radius="full"
            type="outline"
            onClick={() => backStep()}
            className="min-w-[300px]"
          >
            Back
          </VButton>
        </div>
      </div>
    </div>
  );
};

export default Step3;
