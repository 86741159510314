import { UserIcon } from "@heroicons/react/20/solid";
import VButton from "components/v-core/v-button";
import SocialsLogin from "pages/auth/socials-login";

interface Props {
  setStep: (step: number) => void;
}

const Step0 = ({ setStep }: Props) => {
  return (
    <div className="flex flex-col w-full gap-y-4">
      <VButton
        iconLeft={<UserIcon className="w-5 h-5" />}
        iconLeftClassName={"absolute left-3 top-1/2 -translate-y-1/2"}
        className="py-3 font-bold"
        onClick={() => setStep(1)}
      >
        Sign Up With Email
      </VButton>
      <SocialsLogin />
    </div>
  );
};

export default Step0;
