import VButton from "components/v-core/v-button";
import React, { useMemo } from "react";
import { formatNumber } from "utils/common";
import { renderStatus, renderStock } from "utils/render";
import { compareDateWithCur } from "utils/times";
import Countdown from "components/v-countdown";

type IProps = {
  item?: any;
  allowTrust: any;
  _package?: any;
};

const Header = ({ item, allowTrust, _package }: IProps) => {
  const renderCountDown = (p) => {
    let time = 0;
    if (p.time.start_date && compareDateWithCur(p.time.start_date) > 0) {
      time = new Date().getTime() + compareDateWithCur(p.time.start_date);
    } else {
      time = new Date().getTime() + compareDateWithCur(p.time.end_date);
    }
    return (
      <div className="mt-2">
        <Countdown date={time} />
      </div>
    );
  };
  const status = useMemo(() => {
    if (
      _package.time.start_date &&
      compareDateWithCur(_package.time.start_date) > 0
    ) {
      return "upcoming";
    } else {
      return "happening";
    }
  }, [_package]);

  return (
    <div className="flex flex-col justify-center items-center gap-y-2 min-h-[125px]">
      <div className="flex items-center gap-x-2 flex-col">
        <img
          className="w-14 h-14  object-cover"
          src={item?.icon || "/assets/images/icons/package.png"}
          alt=""
        />
      </div>
      <div className="flex justify-between items-center">
        <VButton
          className="border-[#F6D558] text-[14px] !font-bold text-[#F6D558] min-w-[250px]"
          type="outline"
          radius="full"
        >
          {`${formatNumber(item.invest.from, 0)} - ${formatNumber(
            item.invest.to,
            0
          )} ${renderStock(item.invest.stock)}`}
        </VButton>
      </div>
      {allowTrust > 1 && (
        <div className="flex gap-x-1 text-[12px]">
          <span className="opacity-70">Amount remain:</span>
          <span>{formatNumber(allowTrust)}</span>
          <span>{renderStock(item.invest.stock)}</span>
        </div>
      )}
      <div className="flex flex-col items-center gap-y-2">
        <span>{renderStatus(status)}</span>
        <div className="">{renderCountDown(_package)}</div>
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        <span className="max-w-full whitespace-nowrap text-ellipsis overflow-hidden text-base font-semibold">
          {item?.name}
        </span>
        <span className="max-w-full opacity-70 text-xs font-normal whitespace-nowrap text-ellipsis overflow-hidden">
          {item?.description}
        </span>
      </div>
    </div>
  );
};

export default Header;
