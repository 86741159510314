/* eslint-disable max-len */
import { ThemeContext } from "context/ThemeContext";
import { ReactNode, useContext } from "react";
import Countdown from "react-countdown";

type IProps = {
  className?: ReactNode;
  date?: any;
};

const VCountdown = ({ date, className }: IProps) => {
  const { themesSetting } = useContext(ThemeContext);

  const CountDownElement = (props) => {
    return (
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center justify-center mr-[10px] gap-y-1">
          <span
            className={`flex justify-center items-center text-[22px] leading-8 rounded-lg w-[40px] h-[40px] font-bold  bg-purple-500 ${className}`}
            style={themesSetting.countdown}
          >
            {props.days}
          </span>
          <span className="text-[12px] lowercase leading-5 tracking-[0.01px] text-center">
            Days
          </span>
        </div>
        <div className="flex flex-col items-center justify-center mr-[10px] gap-y-1">
          <span
            className={`flex justify-center items-center text-[22px] leading-8 rounded-lg w-[40px] h-[40px] font-bold  bg-purple-500 ${className}`}
            style={themesSetting.countdown}
          >
            {props.hours}
          </span>
          <span className="text-[12px] lowercase leading-5 tracking-[0.01px] text-center">
            Hours
          </span>
        </div>
        <div className="flex flex-col items-center justify-center mr-[10px] gap-y-1">
          <span
            className={`flex justify-center items-center text-[22px] leading-8 rounded-lg w-[40px] h-[40px] font-bold  bg-purple-500 ${className}`}
            style={themesSetting.countdown}
          >
            {props.minutes}
          </span>
          <span className="text-[12px] lowercase leading-5 tracking-[0.01px] text-center">
            Minutes
          </span>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-1">
          <span
            className={`flex justify-center items-center text-[22px] leading-8 rounded-lg w-[40px] h-[40px] font-bold  bg-purple-500 ${className}`}
            style={themesSetting.countdown}
          >
            {props.seconds}
          </span>
          <span className="text-[12px] lowercase leading-5 tracking-[0.01px] text-center">
            Seconds
          </span>
        </div>
      </div>
    );
  };

  const countdow_render = ({ days, hours, minutes, seconds }) => {
    return (
      <CountDownElement
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  };

  return (
    <div>
      <Countdown date={date} renderer={countdow_render} />
    </div>
  );
};

export default VCountdown;
