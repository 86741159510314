/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { compareDateWithCur } from "utils/times";
import ToolTip from "components/v-tooltip";
import Modal from "components/v-core/v-modal";
import Countdown from "components/v-countdown";
import { BsCameraVideoFill, BsFillImageFill } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import VTabs from "components/v-core/v-tabs";
import { AiOutlineHistory } from "react-icons/ai";
import { MdOutlineCampaign } from "react-icons/md";
import CampaignHistories from "./components/CampaignHistories";
import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";
import { renderStatus, renderStock } from "utils/render";
import VBanner from "components/v-banner";
import TaskService from "api/tasks";
interface IProps {
  [key: string]: any;
}

const tabs = [
  {
    name: "Campaign",
    value: "campaign",
    icon: <MdOutlineCampaign size={17} />,
  },
  {
    name: "History",
    value: "history",
    icon: <AiOutlineHistory size={17} />,
  },
];

export default function View(props: IProps) {
  const history = useHistory();
  const { themesSetting } = useContext(ThemeContext);

  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [videoSelected, setVideoSelected] = useState(null);
  const [campaigns, setCampaigns] = useState([]);

  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");

  const { categories, getCategories } = useContext(MasterContext);
  const [filter, setFilter] = useState({
    category_id: null,
    // status: "opening",
  });
  const [isShowModalDetails, setIsShowModalDetails] = useState(false);

  useEffect(() => {
    getCategories({
      params: { group: "category_campaign" },
    });
  }, []);

  useEffect(() => {
    if (!currentTab) {
      history.push("?tab=campaign");
    }
  }, [currentTab]);

  useEffect(() => {
    if (categories) handleChangeFilter("category_id", categories[0]?._id);
  }, [categories]);

  useEffect(() => {
    if (filter.category_id) onGetCampaigns();
  }, [filter]);

  const handleChangeFilter = (key: string, value: string) => {
    setFilter((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  const onGetCampaigns = async () => {
    try {
      const res: any = await TaskService.getCampaigns({
        query: filter,
      });
      if (res && res?.campaigns) {
        setCampaigns(res.campaigns);
      }
    } catch (error) {}
  };

  const renderCountDown = (p) => {
    let title = "";
    let time = 0;
    if (p.start_date && compareDateWithCur(p.start_date) > 0) {
      title = "upcoming";
      time = new Date().getTime() + compareDateWithCur(p.start_date);
    } else {
      title = "happening";
      time = new Date().getTime() + compareDateWithCur(p.end_date);
    }

    return (
      <div className="flex flex-col gap-y-2">
        <span className="font-medium">{renderStatus(title)}</span>
        <Countdown
          date={new Date(time).toLocaleDateString()}
          className="!text-base !w-[40px] !h-[45px]"
        />
      </div>
    );
  };

  const renderImage = (image) => {
    return (
      <div className="w-full h-[350px] overflow-auto">
        <img src={image} alt="" />
      </div>
    );
  };

  const renderVideo = (video) => {
    return (
      <div className="video-responsive overflow-hidden">
        <iframe
          width="100%"
          height="315px"
          src={`https://www.youtube.com/embed/${video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        ></iframe>
      </div>
    );
  };

  const handleShowDetail = (item) => {
    return history.push(`/my/campaign/${item._id}`);
  };

  const handleClosed = () => {
    setShowImage(false);
    setShowVideo(false);
  };

  return (
    <div className="w-full">
      <VTabs tabActive={currentTab} tabs={tabs} onChangeTabs={handleTabs} />
      <div className="pt-4">
        {currentTab === "campaign" && (
          <div className="flex flex-wrap gap-3">
            <VTabs
              tabs={categories.map((el) => ({
                name: el.name,
                value: el._id,
                icon: <img src={el.icon} />,
              }))}
              onChangeTabs={(e) => handleChangeFilter("category_id", e)}
              tabActive={filter.category_id}
              type="button"
            />
            {campaigns &&
              campaigns.map((p: any) => (
                <div
                  className={`relative bg-no-repeat bg-cover w-[360px] h-[500px] rounded-md p-[5px] text-center`}
                  style={themesSetting.card}
                >
                  <div className="flex w-full h-[40%] overflow-hidden">
                    <VBanner isWidthFull={true} data={p.banner} />
                  </div>
                  <div className={`px-[5px] py-[5px] mt-2 rounded-lg`}>
                    {renderCountDown(p)}
                    <div className="mt-2">
                      <p className="text-xl font-bold w-full mb-0">
                        {p.info.name}
                      </p>
                      <p className="text-md font-bold w-full mb-0">
                        e.NFT : {renderStock(p.stock)}
                      </p>
                      <div className="flex gap-x-2 justify-center items-center">
                        <div className="flex items-center gap-x-1">
                          <span className="text-[12px] opacity-60">
                            Start Date:
                          </span>
                          <span className="text-[12px]">
                            {new Date(p.start_date).toLocaleDateString()}
                          </span>
                        </div>
                        <hr
                          className="h-full border-l border-solid"
                          style={{
                            borderColor: themesSetting.layout.textContent,
                          }}
                        />
                        <div className="flex items-center gap-x-1">
                          <span className="text-[12px] opacity-60">
                            End Date:
                          </span>
                          <span className="text-[12px]">
                            {new Date(p.end_date).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <hr
                        className="border-dashed my-1"
                        style={{
                          borderColor: themesSetting.layout.textContent,
                        }}
                      />
                    </div>
                    <div
                      className="text-[13px] flex gap-x-5 items-center justify-center rounded-full px-2 py-1"
                      style={themesSetting.card}
                    >
                      <ToolTip
                        id={`${p._id}_image`}
                        content={renderImage(p.info.guide)}
                      >
                        <span
                          id={`tooltip-` + `${p._id}_image`}
                          onClick={() => {
                            setImageSelected(p.info.guide);
                            setShowImage(true);
                          }}
                        >
                          <BsFillImageFill size={18} />
                        </span>
                      </ToolTip>
                      <ToolTip
                        id={`${p._id}_video`}
                        content={"Click view video"}
                      >
                        <span
                          id={`tooltip-` + `${p._id}_video`}
                          onClick={() => {
                            setVideoSelected(p.info.video);
                            setShowVideo(true);
                          }}
                        >
                          {" "}
                          <BsCameraVideoFill size={22} />
                        </span>
                      </ToolTip>
                      <ToolTip id={`${p._id}_website`} content={p.info.website}>
                        <span
                          id={`tooltip-` + `${p._id}_website`}
                          onClick={() => {
                            window.open(p.website);
                          }}
                        >
                          {" "}
                          <TbWorld size={22} />
                        </span>
                      </ToolTip>
                    </div>
                    <hr
                      className="border-dashed my-1"
                      style={{
                        borderColor: themesSetting.layout.textContent,
                      }}
                    />
                    {p.info.attachments.length > 0 && (
                      <>
                        <div className="flex gap-x-3">
                          {(p.info.attachments || []).map((attach, index) => (
                            <ToolTip
                              id={`${index}_attachments`}
                              content={() => attach.name}
                            >
                              <a
                                id={`tooltip-` + `${index}_attachments`}
                                href={attach.link}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                <img
                                  src={attach.icon}
                                  alt=""
                                  width={25}
                                  height={25}
                                />
                              </a>
                            </ToolTip>
                          ))}
                        </div>
                        <hr />
                      </>
                    )}
                  </div>
                  <div className="absolute bottom-2 left-0 w-full flex justify-center px-2">
                    <VButton
                      className="w-full"
                      radius="full"
                      onClick={() => handleShowDetail(p)}
                    >
                      DETAIL - CAMPAIGN {"=>"}
                    </VButton>
                  </div>
                </div>
              ))}

            {/* intro  */}
            <VModal
              title="Campaign details"
              isOpen={isShowModalDetails}
              onCancel={() => setIsShowModalDetails(false)}
              centered={true}
            >
              {/* <div className="flex flex-col items-center w-full h-full overflow-y-auto gap-y-4">
                <span className="text-xl font-bold">{p?.info?.name}</span>
                <img
                  className="w-full object-cover"
                  src={p.info.intro}
                  alt="campaign-banner"
                />
              </div> */}
            </VModal>
            <Modal
              centered={true}
              isOpen={showImage}
              children={renderImage(imageSelected)}
              onCancel={handleClosed}
            />
            <Modal
              centered={true}
              isOpen={showVideo}
              children={renderVideo(videoSelected)}
              onCancel={handleClosed}
            />
          </div>
        )}
        {currentTab === "history" && <CampaignHistories />}
      </div>
    </div>
  );
}
