/* eslint-disable indent */
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import { useEffect, useState } from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { StageWrapper, UpdateWrapper } from "../styles";
import { nanoid } from "nanoid";
import { AiFillDelete } from "react-icons/ai";
import UploadFile from "components/v-upload";
import APIs from "api";
import { toast } from "react-toastify";
import useProjects from "hooks/useProjects";

const Stages = () => {
  const { projectSelected, getProjectById } = useProjects();

  const [rounds, setRounds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (projectSelected && projectSelected?.stages) {
      setRounds(projectSelected?.stages);
    }
  }, [projectSelected]);

  const handleAdd = () => {
    setRounds((state) => [
      ...state,
      {
        id: nanoid(10),
        name: null,
        percentToSale: 0,
        amountToSale: 0,
        amountSold: 0,
        price: 0,
        on_sale: false,
        logoLevel: null,
        backgroundSuccess: null,
      },
    ]);
  };

  const handleDelete = (id) => {
    setRounds((rounds) => rounds.filter((r) => r.id !== id));
  };

  const handleChangeText = (id) => (event) => {
    setRounds((state) => {
      return state.map((item) => {
        let amountToSale;
        if (event.target.name === "percentToSale") {
          amountToSale =
            (event.target.value * projectSelected?.amountToSale) / 100;
        }
        return item.id === id
          ? {
              ...item,
              [event.target.name]: event.target.value,
              amountToSale: amountToSale || item.amountToSale,
            }
          : item;
      });
    });
  };

  const handleChangeFile = (id, name) => (file) => {
    setRounds((state) => {
      return state.map((item) => {
        return item.id === id
          ? {
              ...item,
              [name]: file,
            }
          : item;
      });
    });
  };

  const handleChangeSwitch = (id) => (e) => {
    setRounds((state) => {
      return state.map((item) => {
        return item.id === id
          ? {
              ...item,
              on_sale: e.target.checked,
            }
          : item;
      });
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let roundsUploads = rounds;
      await Promise.all(
        rounds.map(async (el: any, index: number) => {
          if (el.fileLogo) {
            const formData = new FormData();
            formData.append("files", el.fileLogo);
            const res: any = await APIs.UPLOAD.uploadFile({ body: formData });
            delete el.fileLogo;
            roundsUploads[index] = {
              ...el,
              logoLevel: res.filePath,
            };
          }
          if (el.fileBg) {
            const formData = new FormData();
            formData.append("files", el.fileBg);
            const res: any = await APIs.UPLOAD.uploadFile({ body: formData });
            delete el.fileBg;
            roundsUploads[index] = {
              ...el,
              backgroundSuccess: res.filePath,
            };
          }
        })
      );
      await APIs.PROJECT.editProject({
        params: {
          id: projectSelected?._id,
        },
        body: {
          stages: roundsUploads,
        },
      });
      getProjectById(projectSelected?._id);
      setLoading(false);
      toast.success("Update success!");
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  return (
    <StageWrapper>
      <Container>
        <VButton onClick={handleAdd}>+ Round</VButton>
        {rounds.map((round: any) => (
          <Row key={round.id}>
            <Col xs={12} md={4}>
              <VInput
                transparent={true}
                value={round.name}
                name="name"
                label="Round Name"
                onChange={handleChangeText(round.id)}
              />
            </Col>
            <Col xs={12} md={4}>
              <VInput
                transparent={true}
                value={round.price}
                name="price"
                label={`Price (${String(
                  projectSelected?.stock_to_buy || ""
                ).toUpperCase()})`}
                onChange={handleChangeText(round.id)}
              />
            </Col>
            <Col xs={12} md={4}>
              <VInput
                type="number"
                transparent={true}
                value={round.percentToSale}
                name="percentToSale"
                label="Percent Sale (%)"
                onChange={handleChangeText(round.id)}
              />
            </Col>
            <Col xs={12} md={4}>
              <VInput
                type="number"
                transparent={true}
                value={round.amountToSale}
                name="amountToSale"
                label="Amount Sale"
                disabled={true}
                onChange={handleChangeText(round.id)}
              />
            </Col>
            <Col xs={12} md={4}>
              <VInput
                type="number"
                transparent={true}
                value={round.amountSold}
                name="amountSold"
                label="Amount Sold"
                disabled={true}
                onChange={handleChangeText(round.id)}
              />
            </Col>

            <Col xs={12} md={4}>
              <FormGroup switch>
                <br />
                <Input
                  type="switch"
                  checked={round.on_sale}
                  onChange={handleChangeSwitch(round.id)}
                />
                <Label check>On Sale</Label>
              </FormGroup>
            </Col>

            <Col xs={6}>
              <UpdateWrapper>
                <span>Logo</span>
                <UploadFile
                  id={`${round.id}_logo`}
                  typeUpload="image"
                  file={round.fileLogo}
                  src={round.logoLevel}
                  setFile={handleChangeFile(round.id, "fileLogo")}
                />
              </UpdateWrapper>
            </Col>
            <Col xs={6}>
              <UpdateWrapper>
                <span>Backgound</span>
                <UploadFile
                  id={`${round.id}_bg`}
                  typeUpload="image"
                  file={round.fileBg}
                  src={round.backgroundSuccess}
                  setFile={handleChangeFile(round.id, "fileBg")}
                />
              </UpdateWrapper>
            </Col>
            <Col xs={12}>
              <VButton
                className={"btn-delete"}
                onClick={() => handleDelete(round.id)}
              >
                <AiFillDelete fontSize={20} />
              </VButton>
            </Col>
            <hr />
          </Row>
        ))}
        <VButton
          disabled={rounds.length === 0 || projectSelected?.status !== "init"}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </VButton>
      </Container>
    </StageWrapper>
  );
};

export default Stages;
