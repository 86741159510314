/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormGroup, Label, Input as CheckBox, Container } from "reactstrap";
import { isMobile } from "react-device-detect";

import { Content, Form, FormItem, FormTitle } from "../styles";
import AuthAPI from "api/auth";
import { ROUTES } from "config/routes";
import useQuery from "hooks/useQuery";
import { Terms } from "./styles";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Modal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import Step0 from "./steps/step0";
import Icons from "helper/icons";
import { MasterContext } from "context/MasterContext";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { appName } = useContext(MasterContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMesage] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [openModal, setModal] = useState(false);

  const query = useQuery();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    resetField,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setErrMesage("");
      setLoading(true);
      if (step === 1) {
        await AuthAPI.checkUserExists({ body: { email: data.email } });
        setStep(2);
      } else {
        await AuthAPI.register(data);
        history.push(`${ROUTES.VERIFY_EMAIL}?email=${data.email}`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrMesage("Username or email already exist!");
      setError("email", new Error("Username or email already exist!"), {
        shouldFocus: true,
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      history.push(ROUTES.DASHBOARD);
    }
  }, []);

  useEffect(() => {
    if (step === 0) {
      resetField("email");
      resetField("password");
      resetField("fullName");
      resetField("refer_code");
    }
  }, [step]);

  const renderModalKyc = () => {
    return (
      <Modal
        centered={true}
        isOpen={openModal}
        onCancel={() => setModal(false)}
      >
        <Container>
          <h3>Complete KYC here!</h3>
          <a>Click Here</a>
        </Container>
      </Modal>
    );
  };

  return (
    <Content>
      {renderModalKyc()}
      <div className="flex md:flex-row flex-col-reverse items-center justify-center mx-auto gap-x-[100px] h-full overflow-auto md:mt-[-60px]">
        <div className="flex flex-col w-fit items-center overflow-auto">
          <Form onSubmit={handleSubmit(onSubmit)}>
            {step !== 0 && (
              <div
                className="flex items-center gap-x-2 hover:opacity-80 cursor-pointer pb-4"
                onClick={() => setStep(step - 1)}
              >
                <Icons iconName="chevronleft" />
                <p className="hidden md:flex text-base font-bold m-0">Back</p>
              </div>
            )}
            <FormTitle className={`hidden md:flex pb-4`}>
              {step === 0
                ? !isMobile && `Welcome to ${appName}`
                : !isMobile
                ? `${appName} Account Sign up`
                : "Sign up"}
            </FormTitle>
            {step === 0 && <Step0 setStep={setStep} />}
            {step === 1 && (
              <Step1
                control={control}
                errors={errors}
                errMessage={errMessage}
                register={register}
              />
            )}
            {step === 2 && (
              <Step2 control={control} errors={errors} query={query} />
            )}
            {step !== 0 && (
              <FormItem>
                <FormGroup check>
                  <Terms>
                    <Label check>
                      <CheckBox type="checkbox" /> I have read and agree to the{" "}
                      <a href="/terms" target="_blank">
                        User Agreement and Privacy Policy.
                      </a>
                    </Label>
                  </Terms>
                </FormGroup>
                <VButton
                  className="hover:!opacity-80 cursor-pointer !rounded-lg !border-none py-2"
                  loading={loading}
                >
                  {step === 1 ? "Next" : "Sign up"}
                </VButton>
              </FormItem>
            )}

            <div className="flex items-center pt-3 gap-x-1">
              <span className="font-medium">You already have account?</span>
              <a href={ROUTES.LOGIN} className="no-underline">
                <span className="font-medium">Sign in</span>
              </a>
            </div>
          </Form>
        </div>
        <div
          className={`flex flex-col items-center max-w-[400px] ${
            step === 0 && "mb-4 md:mb-0"
          }`}
        >
          {!isMobile && (
            <div className="flex flex-col items-center gap-y-6 w-full">
              <img
                className="w-auto h-auto object-contain max-h-[200px] md:max-h-[250px]"
                src="/assets/images/fly.png"
                alt="vdiarybook-logo"
              />
              <p className="text-center font-medium hidden md:flex">
                Follow the registration steps to redeem your rewards and start
                your journey with us!
              </p>
            </div>
          )}
        </div>
      </div>
    </Content>
  );
}
