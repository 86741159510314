/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import { MasterContext } from "./MasterContext";
import UserService from "api/users";
import SettingService from "api/settings";
import { defaultTheme } from "helper/context";
import get from "lodash.get";

type ITheme = {
  themesSetting?: any;
  defaultTheme: any;
  userTheme?: any;

  theme?: string;
  themeColors?: object;
  logos?: any;
  handleSwitchTheme?: () => void;
  getThemesSetting: (type: any) => void;
  setThemesSetting: (item: any) => void;
  getThemeByKey: (key: string) => any;
};

export const ThemeContext = createContext<ITheme>({} as ITheme);

const ThemesColors = {
  // white100: themesSetting.dark.text.color,
  // orange100: "#FF9900",
  // green100: "#00AC11",
  // bgColorLayer1: themesSetting.dark.background.background_color,
  // bgColorLayer2: themesSetting.dark.background.background_color,
  // black100: themesSetting.dark.background.background_color,
  // blue200: "#0d203e",
  // blue300: "#172D4D",
  // grey100: "#a0a0a0",
  // grey200: "#3A3F51",
  // red100: "#dc3545",
  // yellow100: "#FFE600",
};

const ThemesColorsHover = {
  white100: "#cecbcb",
  orange100: "#ff8800d8",
  red100: "#b81727",
  green100: "#00ac11a9",
  blue100: "#2465e8a9",
};

const ThemeProvider = (props: any) => {
  const [theme, setTheme] = useState("dark");
  const [themesSetting, setThemesSetting] = useState<any>(defaultTheme[theme]);
  const [logos, setLogos] = useState();
  const { auth } = useContext(MasterContext);

  useEffect(() => {
    if (auth) {
      updateUserTheme({
        params: {
          id: auth?._id,
        },
        body: {
          theme: theme,
        },
      });
    }
    getThemesSetting(theme);
  }, [theme]);

  const updateUserTheme = async (req: any) => {
    try {
      await UserService.editUser(req);
    } catch (error) {}
  };

  const getThemesSetting = async (type: string) => {
    try {
      await SettingService.getSettingByName("themes").then((res: any) => {
        if (res.setting && res.setting.value) {
          setThemesSetting({
            ...defaultTheme[type],
            ...res.setting.value[type],
          });
        } else {
          setThemesSetting(defaultTheme[type]);
        }
      });
    } catch (error) {}
  };

  const handleSwitchTheme = async () => {
    setTheme(theme === "dark" ? "light" : "dark");
    const data = defaultTheme[theme === "dark" ? "light" : "dark"];
    setThemesSetting({
      ...defaultTheme[theme],
      ...data,
    });
  };

  const getLogos = () => {
    SettingService.getSettingByName("logos").then((res: any) => {
      setLogos(res?.setting?.value);
    });
  };

  const getThemeByKey = (key: string) => {
    return get(themesSetting, key);
  };

  useEffect(() => {
    getLogos();
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themesSetting,
        defaultTheme,
        logos,
        handleSwitchTheme,
        setThemesSetting,
        getThemesSetting,
        getThemeByKey,
      }}
    >
      <ThemeProviderStyled
        theme={{
          colors: ThemesColors,
          hoverColors: ThemesColorsHover,
        }}
      >
        {props.children}
      </ThemeProviderStyled>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
