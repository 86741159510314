/* eslint-disable jsx-a11y/alt-text */
import VButton from "components/v-core/v-button";
// import { Continue } from "../../styles";
// import { Step0Wrapper } from "./styles";

const Step0 = ({ dataTrading, nextStep, setDataByKey, setDataTrading }) => {
  const currencies = Object.keys(dataTrading.sell_pairs);

  return (
    <div className="p-4">
      <div className="flex flex-wrap gap-2 ml-4 w-full">
        {currencies.map((c) => (
          <VButton
            // className={`btn-currency ${
            //   dataTrading.currency === c ? "active" : ""
            // }`}
            type="outline"
            radius="full"
            className="!w-[80px]"
            onClick={() => {
              setDataTrading((dataTrading) => {
                return {
                  ...dataTrading,
                  currency: c,
                };
              });
            }}
          >
            {c.toUpperCase()}
          </VButton>
        ))}
      </div>
      <div className="text-center flex flex-col items-center justify-center w-full gap-y-3 mt-4">
        <VButton
          className="!w-[350px]"
          radius="full"
          onClick={() => nextStep()}
          disabled={!dataTrading.currency}
        >
          Continue
        </VButton>
      </div>
    </div>
  );
};

export default Step0;
