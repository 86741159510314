import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import VUploadFile from "components/v-upload";
import Icons from "helper/icons";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  type?: string;
  data: any;
  openModal: boolean;
  required?: any;
  setOpenModal: (e: any) => void;
  setData?: (e: any) => void;
};

const UploadItems = (props: IProps) => {
  const { data, setData, type, required } = props;

  const [itemSelected, setItemSelected] = useState(null);

  const handleAdd = () => {
    setData((prev) =>
      prev.concat({
        id: uuidv4(),
        title: "",
        decs: "",
        src: "",
        link: "",
        isNew: true,
      })
    );
  };

  const handleSub = (doc: any) => {
    const _data = data.filter((el) => el.id !== doc.id);
    setData(_data);
  };

  const setFile = (f: any, id: string) => {
    setData((prev) =>
      prev.map((el) =>
        el.id === id ? { ...el, file: f, isNew: true } : { ...el }
      )
    );
  };

  const handleChangeInput = (
    name: string,
    value: string,
    id: string,
    parent?: any
  ) => {
    if (type === "teams" && parent) {
      setData((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                links: el.links?.map((e) =>
                  e.id === parent.id
                    ? {
                        ...e,
                        link: value,
                      }
                    : { ...e }
                ),
              }
            : { ...el }
        )
      );
    } else {
      setData((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                [name]: value,
              }
            : { ...el }
        )
      );
    }
  };

  const renderUploadImage = () => {
    return (
      <div className="relative w-full h-full rounded-md">
        <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
          <Icons iconName="add_image" />
          <span className="text-sm">Upload image</span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-wrap items-center gap-4 min-h-48">
      {data.map((item) => (
        <div className="relative flex flex-col gap-y-1" key={item.id}>
          <div className="absolute top-0 right-0 z-50">
            <VButton
              className="w-6 h-6"
              radius="full"
              onClick={() => handleSub(item)}
            >
              -
            </VButton>
          </div>
          <div className="w-48 h-48">
            <VUploadFile
              required={required[type] && !item.file}
              id={item.id}
              file={item.file}
              src={item.src}
              setFile={setFile}
              children={renderUploadImage()}
              radius="md"
            />
          </div>
          <div className="w-full">
            {type === "teams" ? (
              <VInput
                value={item.name}
                placeholder="Name"
                onChange={(e) =>
                  handleChangeInput("name", e.target.value, item.id)
                }
              />
            ) : (
              <VInput
                value={item.title}
                placeholder="Title"
                onChange={(e) =>
                  handleChangeInput("title", e.target.value, item.id)
                }
              />
            )}
          </div>
          {type === "teams" && (
            <div className="w-full">
              <VInput
                value={item.role}
                placeholder="Responsibility"
                onChange={(e) =>
                  handleChangeInput("role", e.target.value, item.id)
                }
              />
            </div>
          )}
          <div className="w-full">
            <VInput
              value={item.desc}
              placeholder="Description"
              onChange={(e) =>
                handleChangeInput("desc", e.target.value, item.id)
              }
            />
          </div>
          {type === "teams" ? (
            <>
              <VButton className="w-full" onClick={() => setItemSelected(item)}>
                Add link
              </VButton>
              <VModal
                title={`Add link`}
                isOpen={item.id === itemSelected?.id ? true : false}
                onCancel={() => setItemSelected(null)}
                //   onConfirm={onSubmitModal}
              >
                <div className="flex flex-col gap-y-2">
                  {item?.links?.map((it) => (
                    <div className="w-full" key={it.id}>
                      <VInput
                        value={it.link}
                        label={it.name}
                        placeholder="Link..."
                        onChange={(e) =>
                          handleChangeInput(
                            "vdiarybook",
                            e.target.value,
                            item.id,
                            it
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </VModal>
            </>
          ) : (
            <div className="w-full">
              <VInput
                value={item.link}
                placeholder="Link"
                onChange={(e) =>
                  handleChangeInput("link", e.target.value, item.id)
                }
              />
            </div>
          )}
        </div>
      ))}
      <VButton className="w-10 h-10" radius="full" onClick={handleAdd}>
        +
      </VButton>
    </div>
  );
};

export default UploadItems;
