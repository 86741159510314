import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import VUploadFile from "components/v-upload";
import Icons from "helper/icons";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import APIs from "api";
import VBanner from "components/v-banner";
import VInput from "components/v-core/v-input";

type IProps = {
  data?: any;
  setData?: (e: any) => void;
  isEdit?: boolean;
};

const Video = (props: IProps) => {
  const { data, setData, isEdit } = props;
  const params: any = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [videos, setVideos] = useState(null);
  const [link, setLink] = useState({
    video: "",
  });

  const onSubmitModal = async () => {
    if (link?.video) {
      setData((prev) => ({
        ...prev,
        video: {
          ...prev.video,
          type: "video",
          link: link?.video,
        },
      }));
    } else {
      const formData = new FormData();
      formData.append("folderPath", `landing-page/${params.symbol}`);
      formData.append("files", videos?.video);

      const listFile: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      listFile.forEach((el) =>
        setData((prev) => ({
          ...prev,
          video: {
            ...prev.video,
            type: "video",
            link: el.filePath,
          },
        }))
      );
    }
    setOpenModal(false);
  };

  const setFile = (f: any, id: string, key: string) => {
    setVideos((pre) => ({
      ...pre,
      [id]: f,
    }));
  };

  // Video
  const onChangeLink = (value: string, name: string) => {
    setLink((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderUploadVideo = () => {
    return (
      <div className="relative w-full h-full rounded border-1 border-dashed">
        <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
          <Icons iconName="add_video" />
          <span className="text-sm">Upload video</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full h-[50vh] flex gap-x-5 items-center">
        <div
          className={`${
            isEdit && "border-1 border-dashed"
          } relative w-full h-full `}
        >
          <VBanner data={data.video} isFull={true} />
          {isEdit && (
            <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
              <div className="flex items-center gap-x-2">
                <VButton
                  radius="full"
                  iconLeft={<Icons iconName="upload" />}
                  onClick={() => setOpenModal(true)}
                >
                  Upload
                </VButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <VModal
        title={`Upload`}
        isOpen={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={onSubmitModal}
      >
        <div className="flex flex-col items-center min-h-64 gap-y-5">
          <div className="w-48 flex flex-col gap-y-3">
            <div className="h-48 w-full">
              <VUploadFile
                id={"video"}
                sectionName={"video"}
                typeUpload="video"
                file={videos?.video?.file}
                src={data?.video?.link}
                setFile={setFile}
                children={renderUploadVideo()}
                radius="md"
              />
            </div>
            <div className="w-full">
              <VInput
                value={link.video}
                onChange={(e) => onChangeLink(e.target.value, "video")}
              />
            </div>
          </div>
        </div>
      </VModal>
    </>
  );
};

export default Video;
