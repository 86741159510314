/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Title, Slide, Dot } from "../../styles";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Step0 from "./Step0";
import Step1 from "./Step1";
import APIs from "api";

const titles = {
  0: "Select currency",
  1: "Select amount"
};

const View = () => {
  const { stock } = useParams();

  const defaultData = {
    stock,
    amount: null,
    currency: "",
    sell_pairs: {}
  };

  const [activeStep, setActiveStep] = useState(0);

  const [dataTrading, setDataTrading] = useState(defaultData);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setDataByKey = (key, value) => {
    setDataTrading({
      ...dataTrading,
      [key]: value,
    });
  };

  const getStock = async () => {
    try {
      const res: any = await APIs.CRYPTO.getCryptoDetailBySymbol(stock);
      if (res.crypto.sell_pairs) {
        setDataTrading({
          ...dataTrading,
          stock,
          sell_pairs: res.crypto.sell_pairs,
        });
      } else {
        setDataTrading({
          ...dataTrading,
          stock,
          sell_pairs: {},
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = async () => {
    console.log(dataTrading);
    if (+dataTrading.amount < 10)
      return toast.error("Minimum sell is 10 stocks!");
    if (dataTrading.currency === "usdt" && +dataTrading.amount <= 0)
      return toast.error("Amount must be greater 0!");

    const body = {
      stock: dataTrading.stock,
      amount: dataTrading.amount,
      currency: dataTrading.currency,
      type: "sell"
    }
    const trade: any = await APIs.TRADING.createTrade({ body });
    console.log(trade);
    if (trade) {
      toast.success("Sell successfully!");
      backStep();
    } else {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getStock();
  }, [stock]);

  return (
    <>
      <Title bgColor={"grey200"} just="center" direction="column">
        <p>{titles[activeStep]}</p>
        <Slide>
          <Dot isActive={activeStep === 0 ? true : false} />
          <Dot isActive={activeStep === 1 ? true : false} />
        </Slide>
      </Title>

      {activeStep === 0 && (
        <Step0
          dataTrading={dataTrading}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          setDataTrading={setDataTrading}
        />
      )}

      {activeStep === 1 && (
        <Step1
          dataTrading={dataTrading}
          handleClick={handleClick}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          backStep={backStep}
        />
      )}
    </>
  );
};

export default View;

