import APIs from "api";
import UploadFile from "components/v-upload";
import { Upload } from "pages/home/landing-page/styles";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import VButton from "components/v-core/v-button";

type IProps = {
  campaignId?: string;
  setOpenModalUpload?: any;
};

const UploadFiles = ({ campaignId, setOpenModalUpload }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [filesPreview, setFilesPreview] = useState([]);
  const [videosPreview, setVideosPreview] = useState([]);
  const [attachmentsPreview, setAttachmentsPreview] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState(null);
  const [levelNext, setLevelNext] = useState(null);
  const [level, setLevel] = useState({
    data: {
      total_f1: 0,
      total_user: 0,
      total_my_invest: 0,
      total_user_invest: 0,
      amount_user_invest: 0,
    },
    level_id: "",
    images: [],
    videos: [],
    attachments: [],
    status_upload: "pending",
    images_uploaded: [],
    videos_uploaded: [],
    attachments_uploaded: [],
    enable_amount_image: false,
    enable_amount_video: false,
    enable_amount_attachment: false,
    createdAt: "",
  });

  const setFiles = (level) => {
    setFilesPreview(level.images);
    setVideosPreview(level.videos);
    setAttachmentsPreview(level.attachments);
  };

  const setFileUpLoad = (f: any, id: string, key?: string) => {
    const updateUpload = (setStateUpload) => {
      setStateUpload((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
                ...el,
                file: f,
                isNew: true,
              }
            : el
        )
      );
    };
    if (key === "image") {
      updateUpload(setFilesPreview);
    } else if (key === "video") {
      updateUpload(setVideosPreview);
    } else if (key === "attachments") {
      updateUpload(setAttachmentsPreview);
    } else {
      // console.log();
    }
  };

  useEffect(() => {
    getLevel();
  }, []);

  const getLevel = async () => {
    const res: any = await APIs.TASK.getLevelUserCampaign({
      params: {
        id: campaignId,
      },
    });
    if (res.campaign) {
      setCampaignSelected(res.campaign);
    }
    if (res.level) {
      setFiles(res.level);
      setLevel(res.level);
    }
  };

  useEffect(() => {
    if (campaignSelected?.levels && level) {
      const index = campaignSelected?.levels.findIndex(
        (l) => l.id === level.level_id
      );
      setLevelNext(campaignSelected?.levels[index + 1]);
    }
  }, [campaignSelected, level]);

  const onSubmit = async () => {
    try {
      setLoading(true);

      let imageUploaded = filesPreview;
      let videoUploaded = videosPreview;
      let attachmentUploaded = attachmentsPreview;

      const uploadSectionImg = async (state: any, uploadedState: any) => {
        if (state?.length > 0 && state?.filter((it) => it.file)?.length > 0) {
          const formData = new FormData();
          formData.append("folderPath", `campaigns`);

          const statesNew = state
            .filter((st) => st.isNew)
            .map((stNew) => {
              formData.append("files", stNew.file);
              return stNew;
            });

          const listImg: any = await APIs.UPLOAD.uploadMultiFile({
            body: formData,
          });

          listImg.forEach((el) => {
            statesNew[el.id] = {
              ...statesNew[el.id],
              src: el?.filePath,
            };
          });
          if (uploadedState === "imageUploaded") {
            imageUploaded = state.map((st) => {
              const newStFound = statesNew.find((c) => c.id === st.id);
              if (newStFound) {
                return {
                  ...st,
                  ...newStFound,
                };
              }
              return st;
            });
          } else if (uploadedState === "videoUploaded") {
            videoUploaded = state.map((st) => {
              const newStFound = statesNew.find((c) => c.id === st.id);
              if (newStFound) {
                return {
                  ...st,
                  ...newStFound,
                };
              }
              return st;
            });
          } else if (uploadedState === "attachmentUploaded") {
            attachmentUploaded = state.map((st) => {
              const newStFound = statesNew.find((c) => c.id === st.id);
              if (newStFound) {
                return {
                  ...st,
                  ...newStFound,
                };
              }
              return st;
            });
          } else {
            // console.log();
          }
        }
      };

      await uploadSectionImg(filesPreview, "imageUploaded");
      await uploadSectionImg(videosPreview, "videoUploaded");
      await uploadSectionImg(attachmentsPreview, "attachmentUploaded");

      await APIs.TASK.updateLevel({
        body: {
          ...level,
          campaignId: campaignId,
          images: imageUploaded.length > 0 ? imageUploaded : level.images,
          videos: videoUploaded.length > 0 ? videoUploaded : level.videos,
          attachments:
            attachmentUploaded.length > 0
              ? attachmentUploaded
              : level.attachments,
        },
      });

      toast.success("Upload success!");
      getLevel();
      setLoading(false);
      setOpenModalUpload(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.errors || "Image upload error!");
    }
  };

  const removeChil = (uuid: string, key: string) => {
    const updateState = (state, setState) => {
      const newArr = state.filter((el: any) => el.id !== uuid);
      setState(newArr);
    };
    if (key === "image") {
      updateState(filesPreview, setFilesPreview);
    } else if (key === "video") {
      updateState(videosPreview, setVideosPreview);
    } else if (key === "attachments") {
      updateState(attachmentsPreview, setAttachmentsPreview);
    } else {
    }
  };

  const {
    // amount_image,
    // amount_video,
    // amount_attachment,
    enable_amount_image,
    enable_amount_video,
    enable_amount_attachment,
  } = levelNext || {};

  return (
    <div>
      <h3>Upload</h3>
      {enable_amount_image && (
        <div className="flex items-center">
          <h6 className="w-[50%]">Upload images:</h6>
          <div className="detail">
            {filesPreview.map((sl: any, index: number) => (
              <div>
                <div>
                  <div onClick={() => removeChil(sl.id, "image")}>-</div>
                </div>
                <Upload>
                  <UploadFile
                    id={sl.id}
                    typeUpload="image"
                    sectionName="image"
                    file={sl.file}
                    src={sl.src}
                    setFile={setFileUpLoad}
                  />
                </Upload>
              </div>
            ))}
            {filesPreview.length < levelNext?.amount_image && (
              <div
                className="add_image"
                onClick={() =>
                  setFilesPreview(
                    filesPreview.concat({
                      id: uuidv4(),
                      src: "",
                    })
                  )
                }
              >
                +
              </div>
            )}

            <br />
            <hr />
          </div>
        </div>
      )}
      {enable_amount_video && (
        <div className="flex items-center">
          <h6 className="w-[50%]">Upload videos:</h6>
          <div className="detail">
            {videosPreview.map((sl: any, index: number) => (
              <div>
                <div>
                  <div onClick={() => removeChil(sl.id, "video")}>-</div>
                </div>
                <Upload>
                  <UploadFile
                    id={sl.id}
                    typeUpload="video"
                    sectionName="video"
                    file={sl.file}
                    src={sl.src}
                    setFile={setFileUpLoad}
                  />
                </Upload>
              </div>
            ))}
            <div
              className="add_image"
              onClick={() =>
                setVideosPreview(
                  videosPreview.concat({
                    id: uuidv4(),
                    src: "",
                  })
                )
              }
            >
              +
            </div>
            <br />
            <hr />
          </div>
        </div>
      )}
      {enable_amount_attachment && (
        <div className="flex items-center">
          <h6 className="w-[50%]">Upload attachments:</h6>

          <div className="detail">
            {attachmentsPreview.map((sl: any, index: number) => (
              <div>
                <div>
                  <div onClick={() => removeChil(sl.id, "attachments")}>-</div>
                </div>
                <Upload>
                  <UploadFile
                    id={sl.id}
                    typeUpload="application/pdf"
                    sectionName="attachments"
                    file={sl.file}
                    src={sl.src}
                    setFile={setFileUpLoad}
                  />
                </Upload>
              </div>
            ))}
            <div
              className="add_image"
              onClick={() =>
                setAttachmentsPreview(
                  attachmentsPreview.concat({
                    id: uuidv4(),
                    src: "",
                  })
                )
              }
            >
              +
            </div>
            <br />
            <hr />
          </div>
        </div>
      )}
      <div className="flex justify-center w-full mt-3">
        <VButton
          radius="full"
          className="border min-w-[200px] bg-transparent hover:!bg-green-500 hover:!text-white"
          onClick={() => onSubmit()}
          loading={loading}
        >
          Submit
        </VButton>
      </div>
    </div>
  );
};

export default UploadFiles;
