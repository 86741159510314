import {
  ItemProfit,
  ContainerInput,
  Action,
  ItemShare,
  Title,
  Detail,
  ContainerReferCode,
} from "../styles";
import { AddField } from "pages/my/my-project/styles";

import Input from "components/v-core/v-input";
import Dropdown from "components/v-core/v-dropdown";
import ReferCodeSection from "../refer_code_section";
import { useContext } from "react";
import { MasterContext } from "context/MasterContext";

const levelShare = [
  {
    name: "1",
    value: "f1",
  },
  {
    name: "2",
    value: "f2",
  },
  {
    name: "3",
    value: "f3",
  },
  {
    name: "4",
    value: "f4",
  },
  {
    name: "5",
    value: "f5",
  },
];

const View = (props: any) => {
  const { stocks } = useContext(MasterContext);

  const {
    isWebview,
    dataProfit,
    dataListFriends,
    onChangePercent,
    onChangeStockName,
    handleAddField,
    handleRemoveField,
    onChangeAffilate,
  } = props;

  return (
    <>
      <ItemProfit isWebview={isWebview}>
        <Title>Admin commission fee</Title>
        <Detail>
          <ContainerInput>
            <Input
              transparent={!isWebview}
              color={isWebview ? "black" : "white"}
              type="number"
              placeholder="0"
              suffixIcon="%"
              value={dataProfit?.affilateProfitSetting?.admin?.percent}
              // hasError={errors[`tokenomics${index}${index}${active}`]}
              onChange={(e) =>
                onChangePercent(e, "admin", "affilateProfitSetting")
              }
            />
          </ContainerInput>
        </Detail>
      </ItemProfit>
      <ItemProfit isWebview={isWebview}>
        <Title>Afiliate marketing commission fee</Title>
        <Detail>
          <ItemShare>
            <ContainerInput>
              <Dropdown
                key={
                  dataProfit?.affilateProfitSetting?.affiliate_marketing?.estock
                }
                // hasError={errorField['stock_to_buy']}
                label="e.NFT stock received"
                options={stocks?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.symbol,
                  };
                })}
                value={
                  dataProfit?.affilateProfitSetting?.affiliate_marketing
                    ?.estock || "Choose the stock"
                }
                onChange={(stock) =>
                  onChangeStockName(
                    stock,
                    "affiliate_marketing",
                    "affilateProfitSetting"
                  )
                }
              />
            </ContainerInput>
            <ContainerInput>
              <Input
                transparent={!isWebview}
                color={isWebview ? "black" : "white"}
                type="number"
                label="% profit received"
                placeholder="0"
                suffixIcon="%"
                value={
                  dataProfit?.affilateProfitSetting?.affiliate_marketing
                    ?.percent
                }
                // hasError={errors[`tokenomics${index}${index}${active}`]}
                onChange={(e) =>
                  onChangePercent(
                    e,
                    "affiliate_marketing",
                    "affilateProfitSetting"
                  )
                }
              />
            </ContainerInput>
          </ItemShare>
        </Detail>
      </ItemProfit>
      <ItemProfit isWebview={isWebview}>
        <Title>{`Marketing specialist`}</Title>
        <Detail>
          <ContainerInput>
            <Dropdown
              key={dataProfit?.affilateProfitSetting?.connector?.estock}
              label="e.NFT stock received"
              options={stocks?.map((el: any) => {
                return {
                  label: el?.name,
                  value: el?.symbol,
                };
              })}
              value={
                dataProfit?.affilateProfitSetting?.connector?.estock ||
                "Choose the stock"
              }
              onChange={(stock) =>
                onChangeStockName(stock, "connector", "affilateProfitSetting")
              }
            />
          </ContainerInput>
          <Action>
            <AddField
              bgColor="green100"
              onClick={() =>
                handleAddField("connector", "affilateProfitSetting")
              }
            >
              +
            </AddField>
            <AddField
              bgColor="red100"
              onClick={() =>
                handleRemoveField("connector", "affilateProfitSetting")
              }
            >
              -
            </AddField>
          </Action>
          <ContainerReferCode
            minHeight={
              dataProfit.affilateProfitSetting?.connector?.option?.length > 0
                ? "200px"
                : ""
            }
          >
            {dataProfit.affilateProfitSetting?.connector?.option?.length >
              0 && (
              <ReferCodeSection
                dataProfit={dataProfit.affilateProfitSetting?.connector?.option}
                dataListFriends={dataListFriends}
                field={"connector"}
                type={"affilateProfitSetting"}
                isWebview={isWebview}
                onChangeAffilate={onChangeAffilate}
              />
            )}
          </ContainerReferCode>
        </Detail>
      </ItemProfit>
      <ItemProfit isWebview={isWebview} noBottom={true}>
        <Title>{`Share commision (for buyer)`}</Title>
        <Detail>
          <Action>
            <AddField
              bgColor="green100"
              onClick={() =>
                handleAddField("commission_for_buyer", "affilateProfitSetting")
              }
            >
              +
            </AddField>
            <AddField
              bgColor="red100"
              onClick={() =>
                handleRemoveField(
                  "commission_for_buyer",
                  "affilateProfitSetting"
                )
              }
            >
              -
            </AddField>
          </Action>
          {dataProfit.affilateProfitSetting?.commission_for_buyer?.option
            ?.length > 0 &&
            dataProfit.affilateProfitSetting?.commission_for_buyer?.option?.map(
              (el: any, index: number) => (
                <ItemShare isWrap={true}>
                  <ContainerInput>
                    <Dropdown
                      key={el?.level}
                      // hasError={errorField['stock_to_buy']}
                      label="Level"
                      options={levelShare?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?.value,
                        };
                      })}
                      value={el?.level || "Choose the level"}
                      onChange={(stock) => {
                        onChangeStockName(
                          stock,
                          "commission_for_buyer",
                          "affilateProfitSetting",
                          index,
                          "level"
                        );
                      }}
                    />
                  </ContainerInput>
                  <ContainerInput>
                    <Dropdown
                      key={el?.estock}
                      label="e.NFT stock received"
                      options={stocks?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?.symbol,
                        };
                      })}
                      value={el?.estock || "Choose the stock"}
                      onChange={(stock) =>
                        onChangeStockName(
                          stock,
                          "commission_for_buyer",
                          "affilateProfitSetting",
                          index,
                          "estock"
                        )
                      }
                    />
                  </ContainerInput>
                  <ContainerInput>
                    <Input
                      transparent={!isWebview}
                      color={isWebview ? "black" : "white"}
                      type="number"
                      label="% profit received"
                      placeholder="0"
                      suffixIcon="%"
                      value={el?.percent}
                      // hasError={errors[`tokenomics${index}${index}${active}`]}
                      onChange={(e) =>
                        onChangeAffilate(
                          index,
                          "percent",
                          e,
                          "commission_for_buyer",
                          "affilateProfitSetting"
                        )
                      }
                    />
                  </ContainerInput>
                </ItemShare>
              )
            )}
        </Detail>
      </ItemProfit>
    </>
  );
};

export default View;
