import { Warning, ContentRightView } from "../../../styles";
import { toast } from "react-toastify";
import Network from "../../networks";
import VInput from "components/v-core/v-input";
import VButton from "components/v-core/v-button";

const Step1 = ({ dataPayment, nextStep, setDataByKey, backStep, stock }) => {
  const handleChange = (e) => {
    setDataByKey("amount", e);
  };

  const handleClick = () => {
    if (+dataPayment.amount <= 0)
      return toast.error("Amount must be greater 0!");
    nextStep();
  };

  return (
    <ContentRightView>
      <Network
        stock={stock}
        network={dataPayment.network}
        setNetwork={(data) => {
          setDataByKey("network", data);
        }}
      />
      <VInput
        className="w-full !bg-transparent"
        isCurrency={true}
        transparent={true}
        label={`Amount (${String(dataPayment.stock || "").toUpperCase()}) *`}
        value={dataPayment.amount}
        onValueChange={(e) => handleChange(e)}
        placeholder="10,000"
      />
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          {dataPayment?.stock?.toUpperCase()} deposit is free. Vzonex does not
          collect {dataPayment?.stock?.toUpperCase()} deposit fee, however, in
          some instances, you may have to bear a small transaction fee charged
          directly by the processing bank or network.
        </p>
      </Warning>
      <div className="text-center flex flex-col items-center justify-center w-full gap-y-4 mt-4">
        <VButton radius="full" onClick={handleClick} className="!w-[350px]">
          Continue
        </VButton>
        <VButton
          radius="full"
          type="outline"
          transparent={true}
          onClick={() => backStep()}
          className="!w-[350px]"
        >
          Back
        </VButton>
      </div>
    </ContentRightView>
  );
};

export default Step1;
