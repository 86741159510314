/* eslint-disable indent */
import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const Container = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors[props.color]};
  height: auto;
  padding-bottom: 50px;

  button {
    padding: 0px 10px;
  }
  p,
  .form-check,
  label {
    margin-bottom: 0px;
  }
`;

export const SpanColor = styled.span<{ color: any }>`
  color: ${(props) => props.theme.colors[props.color]};
`;

export const MarginTop = styled.div<{ mt: any }>`
  margin-top: ${(props) => props.mt};
`;

export const TopMain = styled.div`
  padding: 20px;
  /* display: flex; */
`;

export const ItemBalance = styled.div<{ isLocked?: boolean }>`
  h2 {
    font-size: ${(props) =>
      props.isLocked
        ? isMobile
          ? "18px"
          : "24px"
        : isMobile
        ? "22px"
        : "30px"};
  }
  span {
    color: ${(props) =>
      props.isLocked
        ? props.theme.colors["yellow100"]
        : props.theme.colors["green100"]};
  }
`;

export const LeftView = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled.div<{ bgColor?; just?; direction?: any }>`
  display: flex;
  padding: 15px;
  justify-content: ${(props) => props.just};
  align-items: center;
  flex-direction: ${(props) => (props.direction ? props.direction : "")};
`;

export const Content = styled.div`
  background-color: ${(props) =>
    props.color ? props.theme.colors[props.color] : "#071120"};
  padding: 20px 15px;
  margin-top: 2px;
  align-items: center;
  color: white;
  input {
    width: 100%;
    border-color: white;
    margin-bottom: 0;
  }
  .action {
    right: 10px;
    color: ${(props) => props.theme.colors["yellow100"]};
    background-color: transparent;
    border: unset;
    font-weight: 700;
  }
  .details {
    font-size: 12px;
  }
  button {
    top: 25px;
  }
  select {
    width: 100%;
    height: 45px;
    background: transparent;
    color: white;
    border-radius: 5px;
    border-color: white;
  }
`;

export const Detail = styled.div`
  span {
    color: ${(props) => props.theme.colors["green100"]};
  }
  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  @media screen and (max-width: 457px) {
    h6 {
      font-size: 14px;
    }
  }
`;

export const TextHold = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  span {
    font-size: 12px;
    color: ${(props) => props.theme.colors["yellow100"]};
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin-right: 14px;
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
    justify-content: start;

    button {
      margin-bottom: 14px;
      margin-right: 0px;
    }
  }
`;

export const His = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    color: unset;
    text-decoration: unset;
  }
`;

export const PColor = styled.div<{ color?: any }>`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : "white"};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  img,
  svg {
    margin-right: 2px;
  }
`;

export const RightView = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey200};
`;

export const Tabs = styled.div`
  display: flex;
  margin: 10px 0;
  p {
    margin: 15px;
  }
`;

export const Tab = styled.p<{ isActive?: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#fff" : "grey")};
  border-bottom: ${(props) => (props.isActive ? "1px solid #fff" : "")};
`;

export const Slide = styled.div`
  display: flex;
  margin-top: 10px;
  color: goldenrod;
  cursor: pointer;
  font-size: 10px;
  vertical-align: middle;
  margin-left: 4px;
`;
export const Dot = styled.div<{ isActive?: boolean }>`
  width: 5px;
  height: 5px;
  margin: 2px 7px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive
      ? props.theme.colors.orange100
      : props.theme.colors.bgColorLayer1};
`;

export const ContentRightView = styled.div`
  padding: 15px 15px;
  // input {
  //   width: 100%;
  //   border-color: white;
  // }
  // .qr-code-wrapper {
  //   justify-content: center;
  //   display: flex;
  //   align-items: center;
  // }
`;

export const Continue = styled.div`
  text-align: center;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;

  button {
    width: 350px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 470px) {
    button {
      max-width: 100%;
    }
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Amount = styled.div`
  text-align: end;
  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
  }
  @media screen and (max-width: 490px) {
    h6 {
      font-size: 14px;
    }
  }
`;

export const Status = styled.p`
  color: ${(props) => props.theme.colors[props.color]};
  font-size: 12px;
  span {
    /* display: flex; */
    margin-left: 3px;
  }
`;

export const Warning = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 6px;

  img {
    margin-right: 5px;
  }
  span {
    color: ${(props) => props.theme.colors.green100};
  }
  p {
    font-size: 10px;
  }
`;

export const DetailRightView = styled.div<{ paddingTop?: any }>`
  padding-top: ${(props) => props.paddingTop};

  .btn-max-withdraw {
    color: ${(props) => props.theme.colors["yellow100"]};
    background-color: transparent;
    border: unset;
    font-weight: 700;
    top: 25px;
  }
`;

export const Confirm = styled.form`
  padding-top: 10px;
  p {
    color: ${(props) => props.theme.colors.orange100};
    margin-bottom: 15px;
  }
`;

export const NetWork = styled.div`
  padding-top: 10px;
  display: flex;
  button {
    margin-right: 10px;
    padding: 0 20px;
    background: transparent;
  }
  .active {
    background: red;
  }

  @media screen and (max-width: 490px) {
    width: 100%;
    overflow: hidden;
    button {
      width: 25%;
      margin-right: 2px;
      padding: 0;
    }
  }
`;

export const StockAddress = styled.div`
  img {
    width: 100%;
  }

  p {
    overflow-wrap: break-word;
  }
`;

export const WalletInfo = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  padding: 15px;

  input {
    width: 100%;
  }
`;

export const SubmitWithdraw = styled.div`
  text-align: center;
  padding: 15px;
  margin-bottom: 15px;
  button {
    width: 300px;
  }

  @media screen and (max-width: 435px) {
    button {
      max-width: 100%;
    }
  }
`;

export const WithdrawPhone = styled.div`
  p {
    width: fit-content;
    padding: 5px 10px;
    font-size: 14px;
    background-color: ${(props) => props.theme.colors.blue300};
  }
`;

export const PhoneNumber = styled.div`
  display: flex;
`;

export const InputPhone = styled.div`
  padding-left: 15px;
  width: 100%;
`;

export const QrCodeWrapper = styled.div`
  padding: 10px;
  background-color: #fff;
  display: inline-block;
  span {
    font-size: 12px;
  }
`;

export const RightViewContent = styled.div`
  padding: 15px;
  .currency {
    width: 100%;
    border-color: white;
  }
  .btn-max {
    margin-top: -30px !important;
  }

  input {
    width: 100%;
    margin-bottom: 0px;
  }
`;

export const HistoryTransfer = styled.div`
  overflow: auto;
  max-height: calc(var(--vh, 1vh) * 100);
  div {
    min-width: 700px;
  }
`;

export const DepositStockWrapper = styled.div`
  padding: 20px;
  justify-content: center;
  align-items: center;
  h5 {
    text-align: center;
  }
  .btn-currency {
    margin: 4px;
    font-weight: 600;
    opacity: 0.6;
    img {
      margin-right: 5px;
      height: 20px;
    }
  }
  .active {
    opacity: 1 !important;
  }
`;

export const CopyAndViewQR = styled.div`
  display: flex;

  button {
    height: 36px;
    margin: 5px;
  }
`;

export const TitleModal = styled.div`
  background-color: ${(props) => props.theme.colors.bgColorLayer2};
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  img {
    width: auto;
    height: 100%;
    max-height: 40px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bgColorLayer1};
  border: 1px solid ${(props) => props.theme.colors.grey200};
  border-radius: 4px;
  overflow: hidden;
`;

export const ContentModal = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    color: ${(props) => props.theme.colors.orange100};
  }
`;

export const ActionModal = styled.div`
  display: flex;
  justify-content: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;

export const Description = styled.div`
  width: 100%;
  margin: auto;
  max-width: 400px;
  padding: 10px 25px;
  padding-bottom: 20px;
  text-align: center;

  p {
    font-size: 14px;
    overflow-wrap: break-word;
  }

  span {
    color: ${(props) => props.theme.colors.orange100};
  }
`;

export const ShowLeftView = styled.div<{ isShow: boolean }>`
  text-align: right;
  margin-bottom: 10px;
  color: ${(props) => (props.isShow ? "red" : "green")};
`;
