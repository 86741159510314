/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatNumber, getBase64Image } from "utils/common";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import DownloadImg from "components/download";
import APIs from "api";
import { MasterContext } from "context/MasterContext";
import VTable from "components/v-core/v-table";

const InvoiceInvest = () => {
  const { getSettingInWebConfig } = useContext(MasterContext);
  // const app_name = getSettingInWebConfig("app_name");
  const mail_support = getSettingInWebConfig("mail_support");
  const logo_invoice = getSettingInWebConfig("logo_invoice");
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [owner, setOwner] = useState(null);
  // const [estock, setEstock] = useState(null);
  const history = useHistory();
  const imgRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const [logoInvoice, setLogoInvoice] = useState(null);

  const columns = [
    {
      key: "Logo",
      title: "Logo",
      render: (item: any) => (
        <img
          className="w-8 h-8 rounded-full object-cover"
          src={logo || invoice.package_id.logo}
          alt=""
        />
      ),
    },
    {
      key: "Project",
      title: "Package",
      render: (item: any) =>
        `${item.package_id ? item.package_id["name"] : "N/A"}`,
    },
    {
      key: "Amount",
      title: "Amount",
      render: (item: any) =>
        `${formatNumber(item.investment.principal?.total)} (${String(
          item.investment.principal?.stock
        ).toUpperCase()})`,
    },
    {
      key: "Profit",
      title: "Profit",
      render: (item: any) =>
        `${formatNumber(item.investment.profit?.total)} (${String(
          item.investment.profit?.stock
        ).toUpperCase()})`,
    },
    {
      key: "time",
      title: "Trust mining time",
      render: (item: any) =>
        `${item.investment.time_lock} ${item.investment.type_lock}s`,
    },
  ];

  useEffect(() => {
    if (id) {
      APIs.PURCHASE.getInvoice({
        params: {
          id,
          type: "package",
        },
      })
        .then((res: any) => {
          if (res && res.invoice) {
            setInvoice(res.invoice);
            setOwner(res.owner);
            // setEstock(res.setEstock);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [history, id]);

  useEffect(() => {
    const getImages = async () => {
      if (invoice && invoice.package_id) {
        const res = await getBase64Image(invoice.package_id.icon);
        setLogo(res);
      }
    };
    getImages();
  }, [invoice]);

  useEffect(() => {
    const getLogoInvoice = async () => {
      if (logo_invoice) {
        const res = await getBase64Image(logo_invoice);
        setLogoInvoice(res);
      }
    };
    getLogoInvoice();
  }, [logo_invoice]);

  return (
    <div className="overflow-auto h-[100vh]">
      <div className="w-[600px] relative mx-auto border-1 p-3" ref={imgRef}>
        {invoice && (
          <div className="flex flex-col">
            <div className="w-full flex justify-between items-center">
              <div className="flex flex-col gap-y-2">
                <div className="flex items-center gap-x-1 text-md font-semibold">
                  <span>Invoice</span>
                  <span className="text-blue-500">{`#${new Date(
                    invoice.createdAt
                  ).getTime()}`}</span>
                </div>
                <div className="flex flex-col gap-y-1">
                  <span className=" text-sm font-normal text-[#636363]">
                    Invoice to buy shares of project
                  </span>
                  <span className=" text-base font-semibold">
                    {invoice.package_id.name}
                  </span>
                </div>
              </div>
              <div className="w-20 h-20">
                <img
                  className="w-full h-full object-cover"
                  src={logo || invoice.package_id.icon}
                  alt=""
                />
              </div>
            </div>
            <hr className="w-full border-b border-dashed" />
            <div className="flex flex-col gap-y-2">
              <div className="w-full flex gap-x-1 mb-2">
                <div className="w-1/2 flex flex-col gap-y-1">
                  <span className="text-sm font-normal text-[#636363]">
                    Invoice To:
                  </span>
                  <span className="text-sm font-semibold">
                    {invoice.user_id.email}
                  </span>
                </div>
                <div className="w-1/2 flex flex-col gap-y-1">
                  <span className="text-sm font-normal text-[#636363]">
                    Date:
                  </span>
                  <span className="text-sm font-semibold">
                    {new Date(invoice.createdAt).toLocaleDateString()}
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col gap-y-1">
                <span className="text-sm font-semibold">Invoice Detail</span>
                <VTable
                  columns={columns}
                  data={[invoice]}
                  color={{ color: "#000", backgroundColor: "#FFF" }}
                />
              </div>
            </div>
            <hr className="w-full border-b border-dashed" />
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <div className="text-base font-semibold">Payment Info</div>
                <div className="w-full flex flex-col gap-x-2 justify-end">
                  <div className="w-1/2 flex items-center gap-2">
                    <span className="text-sm font-normal text-[#636363]">
                      Account:
                    </span>
                    <span className="text-base font-semibold">
                      {invoice.user_id._id.slice(10)}...
                    </span>
                  </div>
                  <div className="w-1/2 flex items-center gap-2 ">
                    <span className="text-sm font-normal text-[#636363]">
                      e.NFT:
                    </span>
                    <span className="text-base font-semibold mb-[0.5px]">
                      {String(
                        invoice.investment?.principal.stock
                      ).toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
              <hr className="h-full border-l border-dashed" />
              <div className="flex gap-x-2 justify-end">
                <div className="flex flex-col items-end">
                  <span className="text-sm py-[2px] text-[#636363]">
                    Sub Total:
                  </span>
                  <span className="text-sm py-[2px] text-[#636363]">Tax:</span>
                  <span className="text-sm py-[2px] text-[#636363]">
                    Total:
                  </span>
                </div>
                <div className="flex flex-col item-end">
                  <div className="flex items-center justify-end gap-x-1">
                    <span className="text-base font-semibold">
                      {formatNumber(invoice.investment.amount)}
                    </span>
                    <span className="text-base">
                      {String(
                        invoice.investment?.principal.stock
                      ).toUpperCase()}
                    </span>
                  </div>
                  <div className="flex items-center justify-end gap-x-1">
                    <span className="text-base font-semibold">0.00</span>
                    <span className="text-base">
                      {String(
                        invoice.investment?.principal.stock
                      ).toUpperCase()}
                    </span>
                  </div>
                  <div className="flex items-center justify-center gap-x-1">
                    <span className="text-base font-semibold text-[#007AFF]">
                      {formatNumber(invoice.investment.amount)}
                    </span>
                    <span className="text-base">
                      {String(
                        invoice.investment?.principal.stock
                      ).toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <hr className="w-full border-b border-dashed" />
            <div className="flex flex-col items-center justify-center mb-3">
              <div className="text-base font-semibold">
                Thank you for trusting us!
              </div>
              <p className="flex text-sm !mb-0 text-[#636363]">
                For any queries related to this document please contact
                us:&nbsp;
                <p className="text-sm font-semibold text-[#007AFF] mb-0">
                  {owner ? owner.email : mail_support}
                </p>
              </p>
              {/* <p className="text-sm mb-0 text-[#636363]">
                Address :
                {estock
                  ? estock.userInfo.address
                  : " No. 6 Lot A12 Le Trong Tan KDT - Geleximco, An Khanh Ward, Hoai Duc District, Hanoi City, Vietnam."}
              </p> */}
              <div className="flex mt-2 w-52 h-10 items-center">
                <img src={logoInvoice} alt="" className="w-full h-full " />
              </div>
            </div>
          </div>
        )}
      </div>
      {invoice && (
        <div className="flex justify-center items-center mt-3">
          <DownloadImg
            imgRef={imgRef}
            fileName={`invoice_${invoice.package_id.name}`}
          />
        </div>
      )}
    </div>
  );
};

export default InvoiceInvest;
