/* eslint-disable max-len */
/* eslint-disable indent */

import { Label, FormGroup, Input as InputB } from "reactstrap";

// import { AiOutlineSetting } from "react-icons/ai";
import VButton from "components/v-core/v-button";
import { useContext, useState } from "react";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import VInput from "components/v-core/v-input";
import VDisclosure from "components/v-disclosure";

type IProps = {
  data?: any;
  isEdit?: boolean;
  setData?: (e: any) => void;
};

const SETTING_LABEL_DATA = {
  color: {
    name: "Color",
    isShow: false,
  },

  option: {
    name: "Option",
    isShow: false,
  },
  other: {
    name: "Other",
    isShow: false,
  },
};

const SettingMenu = (props: IProps) => {
  const { data, setData } = props;
  const { themesSetting, handleSwitchTheme } = useContext(ThemeContext);

  const [subMenuData, setSubMenuData] = useState(SETTING_LABEL_DATA);
  const [openSettingColor, setOpenSettingColor] = useState<boolean>(false);

  //   const wallpaperVideo = dataEvent?.wallpaperVideo;

  const [checkboxValue, setCheckboxValue] = useState("dark");
  const handleShowMenu = (type: string) => {
    setSubMenuData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        isShow: !prev[type].isShow,
      },
    }));
  };

  const handleShowSection = (checked, section) => {
    setData((prev) => ({
      ...prev,
      settingMenu: {
        ...prev.settingMenu,
        sections: prev.settingMenu?.sections?.map((el) =>
          el.key === section.key
            ? {
                ...el,
                isShow: checked,
              }
            : { ...el }
        ),
      },
    }));
  };

  const onChangeColor = (
    e: any,
    name: string,
    type: string,
    parent?: string
  ) => {
    if (parent) {
      setData((prev) => ({
        ...prev,
        settingMenu: {
          ...prev.settingMenu,
          colors: {
            ...prev.settingMenu?.colors,

            [type]: {
              ...prev.settingMenu?.colors[type],
              [parent]: {
                ...prev.settingMenu?.colors[type][parent],
                [name]: e.target.value,
              },
            },
          },
        },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        settingMenu: {
          ...prev.settingMenu,
          colors: {
            ...prev.settingMenu?.colors,

            [type]: {
              ...prev.settingMenu?.colors[type],
              [name]: e.target.value,
            },
          },
        },
      }));
    }
  };

  const handleChangeInput = (value: string, name: string) => {
    setData((prev) => ({
      ...prev,
      settingMenu: {
        ...prev.settingMenu,
        [name]: value,
      },
    }));
  };

  const renderSectionColor = (title: string, sectionName: string) => {
    return (
      <div className="rounded px-3" style={themesSetting.container}>
        <VDisclosure title={title} height={"260px"}>
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col items-center gap-y-5">
              <div className="flex flex-col items-center gap-y-1">
                <span className="font-semibold text-center">
                  Background color
                </span>
                <div className="flex flex-col gap-y-2">
                  <div className="w-full flex items-center gap-x-2">
                    <span className="min-w-[35px]">From:</span>
                    <input
                      type="color"
                      value={
                        data?.settingMenu?.colors[checkboxValue][sectionName]
                          ?.from
                      }
                      onChange={(e) => {
                        onChangeColor(e, "from", checkboxValue, sectionName);
                      }}
                    />
                    <VInput
                      className="w-full"
                      type="text"
                      value={
                        data?.settingMenu?.colors[checkboxValue][sectionName]
                          ?.from
                      }
                      onChange={(e) => {
                        onChangeColor(e, "from", checkboxValue, sectionName);
                      }}
                    />
                  </div>
                  <div className="w-full flex items-center gap-x-2">
                    <span className="min-w-[35px]">Via:</span>
                    <input
                      type="color"
                      value={
                        data?.settingMenu?.colors[checkboxValue][sectionName]
                          ?.via
                      }
                      onChange={(e) => {
                        onChangeColor(e, "via", checkboxValue, sectionName);
                      }}
                    />
                    <VInput
                      className="w-full"
                      type="text"
                      value={
                        data?.settingMenu?.colors[checkboxValue][sectionName]
                          ?.via
                      }
                      onChange={(e) => {
                        onChangeColor(e, "via", checkboxValue, sectionName);
                      }}
                    />
                  </div>
                  <div className="w-full flex items-center gap-x-2">
                    <span className="min-w-[35px]">To:</span>
                    <input
                      type="color"
                      value={
                        data?.settingMenu?.colors[checkboxValue][sectionName]
                          ?.to
                      }
                      onChange={(e) => {
                        onChangeColor(e, "to", checkboxValue, sectionName);
                      }}
                    />
                    <VInput
                      className="w-full"
                      type="text"
                      value={
                        data?.settingMenu?.colors[checkboxValue][sectionName]
                          ?.to
                      }
                      onChange={(e) => {
                        onChangeColor(e, "to", checkboxValue, sectionName);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-1">
                <span className="font-semibold text-center">Text</span>
                <div className="w-full flex items-center gap-x-2">
                  <span className="min-w-[35px]">Color:</span>
                  <input
                    type="color"
                    value={
                      data?.settingMenu?.colors[checkboxValue][sectionName]
                        ?.text
                    }
                    onChange={(e) => {
                      onChangeColor(e, "text", checkboxValue, sectionName);
                    }}
                  />
                  <VInput
                    className="w-full"
                    type="text"
                    value={
                      data?.settingMenu?.colors[checkboxValue][sectionName]
                        ?.text
                    }
                    onChange={(e) => {
                      onChangeColor(e, "text", checkboxValue, sectionName);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </VDisclosure>
      </div>
    );
  };

  return (
    <>
      <div
        className={`fixed top-[25%] right-0 flex ${
          openSettingColor ? "w-[300px]" : "w-0"
        } max-h-[70vh] opacity-100 shadow-lg rounded-l-lg z-50`}
        style={themesSetting.card}
      >
        <div
          className={`absolute ${
            openSettingColor
              ? "-left-[35px] top-5 py-2"
              : "right-0 top-5 py-3 opacity-70"
          } flex flex-col justify-center items-center  z-0 gap-2 pr-1 pl-2 rounded-l-lg `}
          //   style={{ backgroundColor: color[checkboxValue].bgSection1 }}
        >
          <VButton
            iconLeft={
              <img src="/assets/images/icons/setting-ld.svg" alt=""></img>
            }
            className="!bg-slate-400 w-8 h-8 p-1 rounded-md"
            onClick={() => setOpenSettingColor(!openSettingColor)}
          />
          <VButton
            iconLeft={<img src="/assets/images/icons/sun.svg" alt=""></img>}
            className={`!bg-slate-400 w-8 h-8 p-1 rounded-md ${
              openSettingColor ? "hidden" : ""
            }`}
            onClick={handleSwitchTheme}
          />
          <VButton
            iconLeft={
              <img src="/assets/images/icons/stream-line.svg" alt=""></img>
            }
            className={`!bg-slate-400 w-8 h-8 p-1 rounded-md ${
              openSettingColor ? "hidden" : ""
            }`}
          />
        </div>
        <div className="flex flex-col overflow-y-auto  p-[20px] w-full max-h-[70vh] rounded-md z-10">
          {Object.keys(subMenuData).map((el: any, index) => (
            <>
              <div
                key={index}
                className="flex justify-between w-full py-2 cursor-pointer focus:bg-opacity-50"
                onClick={() => handleShowMenu(el)}
              >
                <div className="text-lg font-bold ">{subMenuData[el].name}</div>
                <Icons iconName="dropdown" />
              </div>
              {el === "color" && subMenuData[el].isShow && (
                <>
                  <div className="flex justify-between gap-x-4 mb-3 font-semibold">
                    <FormGroup check>
                      <Label check>
                        <InputB
                          type="checkbox"
                          checked={checkboxValue === "dark"}
                          onChange={(e) => setCheckboxValue("dark")}
                        />
                        {`Dark`}
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <InputB
                          type="checkbox"
                          checked={checkboxValue === "light"}
                          onChange={(e) => setCheckboxValue("light")}
                        />
                        {`Light`}
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="flex flex-col gap-y-3">
                    <div className="flex flex-col gap-y-2">
                      {renderSectionColor("Header", "header")}
                      {renderSectionColor("Banner", "banner")}
                      {renderSectionColor("Footer", "footer")}
                      {renderSectionColor("Section 1", "section1")}
                      {renderSectionColor("Section 2", "section2")}
                      {renderSectionColor("Content", "content")}
                    </div>
                  </div>
                </>
              )}
              {el === "option" &&
                subMenuData[el].isShow &&
                data?.settingMenu?.sections?.map((item, idx) => (
                  <div
                    key={idx}
                    // hidden={
                    //   !managersSections?.includes(item) &&
                    //   !managersSections.includes("all")
                    // }
                  >
                    <FormGroup check>
                      <Label check>
                        <InputB
                          type="checkbox"
                          checked={item.isShow}
                          onChange={(e) =>
                            handleShowSection(e.target.checked, item)
                          }
                        />
                        {item.name}
                      </Label>
                    </FormGroup>
                  </div>
                ))}
              {el === "other" && subMenuData[el].isShow && (
                <div className="flex flex-col gap-y-2 ">
                  <div className="flex items-center gap-x-2 w-full">
                    <div>Chat:</div>
                    <div className="flex-1">
                      <VInput
                        transparent={true}
                        className="w-full border !bg-transparent rounded-sm px-1"
                        placeholder="Link..."
                        value={data?.settingMenu?.chat}
                        onChange={(e) =>
                          handleChangeInput(e.target.value, "chat")
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default SettingMenu;
