/* eslint-disable react-hooks/exhaustive-deps */
import QRCode from "react-qr-code";

import { copyText } from "utils/common";
import { toast } from "react-toastify";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "components/v-core/v-modal";
import Network from "../../networks";
import { useParams } from "react-router-dom";
import { exportComponentAsJPEG } from "react-component-export-image";
import { MasterContext } from "context/MasterContext";
import APIs from "api";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import { renderStock } from "utils/render";

const View = () => {
  const { auth, settings, stocks } = useContext(MasterContext);
  const { logos } = settings;
  const { themesSetting } = useContext(ThemeContext);
  const imgQrRef = useRef();
  // const params = useParams();
  // const stockName = params.stock;
  const { stock: stockName } = useParams();
  const stockRender = renderStock(stockName);
  const [walletSelected, setWalletSelected] = useState(null);
  const [amount, setAmount] = useState<number>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [stock, setStock] = useState(null);
  const [network, setNetwork] = useState("bsc");

  useEffect(() => {
    if (stocks.length > 0) {
      setStock(stocks.find((c) => c.symbol === stockName));
    }
  }, [stocks, stockName]);

  const walletAddress = useMemo(
    () => walletSelected?.address || "N/A",
    [walletSelected]
  );

  const handleChange = (e) => {
    setAmount(e);
  };
  const handleCopy = () => {
    copyText(walletAddress);
    toast.success("Copied to clipboard!");
  };

  const handlePreview = () => {
    setOpenModal(true);
  };

  const handleDownload = () => {
    exportComponentAsJPEG(imgQrRef, {
      fileName: `${"Qr-code"}_${new Date().getTime()}`,
    });
    setOpenModal(false);
  };

  const getWalletSelected = async (req: any) => {
    try {
      const res: any = await APIs.WALLET.getWalletsByUserAndOptions(req);
      if (res && res?.wallet) {
        setWalletSelected(res.wallet);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.getElementById("right")?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }, []);

  const renderQr = () => {
    return (
      <div ref={imgQrRef}>
        <div className="flex justify-center m-4">
          <img src={logos.logo} className="h-[50px]" alt="" />
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="p-2 bg-white">
            <QRCode
              value={`vzxwallet-${stockRender}-${walletAddress}${
                amount >= 0 && `-${amount}`
              }`}
              size={280}
            />
          </div>
          <p className="mt-2">{`${walletAddress.slice(
            0,
            12
          )}...${walletAddress.slice(-12)}`}</p>
          <VInput
            className="w-full !bg-transparent"
            isCurrency={true}
            label={`Amount (${stockRender})`}
            value={amount}
            onValueChange={(e) => handleChange(e)}
            placeholder="10,000"
          />
          {/* {amount > 0 && <h3>{`${formatCurrency(amount)} ${stock}`}</h3>} */}
        </div>
        <div className="mt-4">
          <p>
            Send only <span>{stockRender}</span> to this address.
            <br /> Sending and other coins may result in permanent loss.{" "}
          </p>
        </div>
      </div>
    );
  };

  const handleClosed = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (auth) {
      getWalletSelected({
        params: {
          userId: auth?._id,
        },
        query: {
          network: "bsc",
          token: stockName,
        },
      });
    }
  }, [stockName, auth]);

  return (
    <>
      <div>
        <div>
          <p>Deposit {stockRender}</p>
        </div>
        <div style={themesSetting.card} className="p-[15px] ">
          <Network stock={stock} network={network} setNetwork={setNetwork} />

          <div className="mt-4">{stockRender} Address</div>
          <div className="mb-1">
            <div className="p-[10px] bg-white inline-block">
              <QRCode value={walletAddress} size={150} />
            </div>
            <p className="break-words mt-2">{walletAddress}</p>
            <div className="flex gap-x-2 mt-2">
              <VButton onClick={handleCopy} className="!h-9 m-[5px]">
                Copy to Clipboard
              </VButton>
              <VButton onClick={handlePreview} className="!h-9 m-[5px]">
                Transfer internal
              </VButton>
            </div>
          </div>
        </div>
        <div className="p-[15px]">
          <div className="flex items-start pt-[6px]">
            <img
              src="/assets/images/icons/warning.svg"
              alt="warning"
              className="mr-[5px]"
            />
            <p className="text-[10px]">
              You have to deposit at least 0.5 {stockRender} to be credited. Any
              deposit that is less than 0.5 {stockRender} will not be refunded
            </p>
          </div>
          <div className="flex items-start pt-[6px]">
            <img
              src="/assets/images/icons/warning.svg"
              alt="warning"
              className="mr-[5px]"
            />
            <p className="text-[10px]">
              This deposit address only accepts {stockRender}. Do not send other
              coins to it.
            </p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        children={renderQr()}
        onCancel={handleClosed}
        onConfirm={handleDownload}
        confirmLabel={
          <div className="flex justify-center">
            <img
              className="cursor-pointer w-5 h-5"
              src="/assets/images/icons/download.svg"
              alt=""
            />
          </div>
        }
        centered={true}
      />
    </>
  );
};

export default View;
