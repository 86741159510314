/* eslint-disable indent */

import { Col, Row, Progress } from "reactstrap";
import Modal from "components/v-core/v-modal";
import { useContext, useState } from "react";
import useProjects from "hooks/useProjects";
import { SOCIAL_IMG } from "utils/constants";
import { toast } from "react-toastify";
import APIs from "api";
import Histories from "./Histories";
import { formatNumber } from "utils/common";
import Stage from "./Stage";
import ToolTip from "components/v-tooltip";
import { BsFillGiftFill } from "react-icons/bs";
import Reward from "components/v-reward";
import CongratulationInvests from "components/congratulations";
import { useHistory } from "react-router-dom";
import VDropdown from "components/v-core/v-dropdown";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import VButton from "components/v-core/v-button";
import Countdown from "components/v-countdown";
import { compareDateWithCur } from "utils/times";
import { renderProjectStatus } from "utils/render";
import { MasterContext } from "context/MasterContext";

const filters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Live",
    value: "opening",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const filterProject = (projects, filter) => {
  if (filter.value === "all") return projects;
  return projects.filter((p) => p.status === filter.value);
};

const View = () => {
  const { stocks } = useContext(MasterContext);
  const { projects, projectsByType } = useProjects();
  const [state, setState] = useState({
    isOpen: false,
    amountFrom: null,
    amountTo: null,
  });
  const [projectSeleted, setProjectSeleted] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    label: "Live",
    value: "opening",
  });
  const [openModalCongratulation, setOpenModalCongratulation] = useState(false);
  const [congratulationInverst, setCongratulationInverst] = useState<any>(null);
  const history = useHistory();
  const { themesSetting } = useContext(ThemeContext);

  const handleOpen = (project) => {
    if (project.status !== "opening")
      return toast.warning("Now is not the time to sell!");
    setProjectSeleted(project);
    setState({
      ...state,
      isOpen: true,
    });
  };

  const handleClosed = () => {
    setProjectSeleted(null);
    setState({
      ...state,
      isOpen: false,
      amountFrom: 0,
      amountTo: 0,
    });
  };

  const handleChange = (type, value, round) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      amountTo = +value / +round.price;
    } else {
      amountTo = +value;
      amountFrom = +value * +round.price;
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res: any = await APIs.PURCHASE.buyIBO({
        body: {
          project_id: projectSeleted._id,
          amount: +state.amountFrom,
        },
      });
      if (res) {
        setCongratulationInverst(res.purchase);
        setOpenModalCongratulation(true);
        toast.success("Congratulations on your successful purchase!");
        setProjectSeleted(null);
        setState({
          ...state,
          isOpen: false,
          amountFrom: 0,
          amountTo: 0,
        });
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const renderModalCongratulation = (investSelected: any) => {
    if (investSelected) {
      const stageBuyIdo = investSelected?.project_id?.stages?.filter(
        (el: any) => el.name === investSelected?.project?.round
      );
      return (
        <CongratulationInvests
          type="buyIdo"
          background={stageBuyIdo[0]?.backgroundSuccess}
          userName={investSelected?.user_id?.fullName}
          avataUser={investSelected?.user_id?.avatar}
          logoProject={investSelected?.project_id?.logo}
          logoLevel={stageBuyIdo[0]?.logoLevel}
          amount={investSelected?.project?.amountReceived}
          symbol={investSelected?.project_id?.symbol}
          createdAt={investSelected?.createdAt}
          openModalCongratulation={openModalCongratulation}
          setOpenModalCongratulation={setOpenModalCongratulation}
        />
      );
    }
  };

  const renderModal = (projectSeleted) => {
    if (projectSeleted) {
      const round = projectSeleted.stages.find((s) => s.on_sale);

      return (
        <div className="py-[10px] px-[25px]">
          <div className="flex items-center mb-[20px]">
            <img
              className="w-[40px] mr-[10px]"
              src={projectSeleted.logo}
              alt=""
            />
            {/* <span>VZONEX</span> */}
            <span className="text-[24px] font-bold uppercase mr-[7px]">
              {projectSeleted.name}
            </span>
          </div>
          <div>
            <div>
              <img
                className="mb-[5px] mr-[5px]"
                src={stocks[projectSeleted.stock_to_buy]["logo"]}
                alt=""
              />
              <span>{projectSeleted.stock_to_buy.toUpperCase()}</span>
              <VInput
                className="w-full"
                name="amountFrom"
                // type="number"
                isCurrency={true}
                value={state.amountFrom}
                placeholder="0.00"
                onValueChange={(e) => handleChange("from", e, round)}
              />
            </div>
            <div className="text-center">
              <img src="/assets/images/icons/transfer.svg" alt="" />
            </div>
            <div>
              <img
                className="mb-[5px] mr-[5px]"
                src={projectSeleted.logo}
                alt=""
              />
              <span>{projectSeleted.symbol.toUpperCase()}</span>

              <VInput
                className="w-full"
                isCurrency={true}
                name="amountTo"
                value={state.amountTo}
                placeholder="0.00"
                onValueChange={(e) => handleChange("to", e, round)}
              />
            </div>
            <div className="flex justify-between mt-[10px]">
              <span className="text-[15px]">Rate</span>
              <span className="text-[16px] font-semibold">
                1 {String(projectSeleted.symbol || "").toUpperCase()} ={" "}
                {round.price}{" "}
                {String(projectSeleted.stock_to_buy || "").toUpperCase()}
              </span>
            </div>
            {/* <Description>
                <span>Fee</span>
                <span>0.5%</span>
              </Description> */}
            <VButton onClick={handleSubmit} loading={loading}>
              Buy
            </VButton>
          </div>
        </div>
      );
    }
  };

  const projectByFilter = filterProject(projects, filter);

  const renderProgress = (project: any) => {
    if (project.stages) {
      const stage = project.stages.find((s) => s.on_sale);
      if (project.status === "upcoming") {
        return (
          <>
            <div className="min-h-[31px] flex items-center justify-between my-[10px]">
              <label>Start</label>
            </div>
            <Progress value={0} color="warning" />
          </>
        );
      }
      if (stage) {
        return (
          <>
            <div className="min-h-[31px] flex items-center justify-between my-[10px]">
              <label>
                {formatNumber(+stage.amountSold || 0)} /{" "}
                {formatNumber(stage.amountToSale)}{" "}
                {project.symbol.toUpperCase()}
              </label>
            </div>
            <Progress
              value={(+stage.amountSold / +stage.amountToSale) * 100}
              color="warning"
            >
              <span className="text-[10px] font-semibold text-v-black">
                {((+stage.amountSold / +stage.amountToSale) * 100).toFixed(1)}
                {"%"}
              </span>
            </Progress>
          </>
        );
      } else {
        return (
          <>
            <div className="min-h-[31px] flex items-center justify-between my-[10px]">
              <label className="w-full text-center text-[20px] font-semibold text-red-500">
                END
              </label>
            </div>
            <Progress value={100} color="warning" />
          </>
        );
      }
    }
  };

  const renderProgressPixel = (project: any) => {
    if (project.rounds) {
      const stage = project.rounds.find((s) => s.on_sale);
      if (project.status === "upcoming") {
        return (
          <>
            <div className="min-h-[31px] flex items-center justify-between my-[10px]">
              <label>Start</label>
              <div className="flex">
                {/* <span>516</span> */}
                {project.status === "opening" && (
                  <VButton type="outline" disabled>
                    View
                  </VButton>
                )}
              </div>
            </div>
            <Progress value={0} color="warning" />
          </>
        );
      }
      if (stage) {
        return (
          <>
            <div className="min-h-[31px] flex items-center justify-between my-[10px]">
              <label>
                {formatNumber(+stage.amountSold || 0)} /{" "}
                {formatNumber(stage.pixels.length)}{" "}
                {project.symbol.toUpperCase()}
              </label>
              <div className="flex">
                {/* <span>516</span> */}
                {project.status === "opening" && (
                  <VButton
                    type="outline"
                    onClick={() => history.push(`/my/ico/${project._id}`)}
                    disabled={!project.rounds.some((s) => s.on_sale)}
                  >
                    View
                  </VButton>
                )}
              </div>
            </div>
            <Progress
              value={(+stage.amountSold / +stage.pixels.length) * 100}
              color="warning"
            >
              <span className="text-[10px] font-semibold text-black">
                {((+stage.amountSold / +stage.pixels.length) * 100).toFixed(1)}
                {"%"}
              </span>
            </Progress>
          </>
        );
      } else {
        return (
          <>
            <div className="min-h-[31px] flex items-center justify-between my-[10px]">
              <label className="w-full text-center text-red-500 text-[20px] font-bold">
                END
              </label>
              <div className="flex">
                {/* <span>516</span> */}
                {project.status === "opening" && (
                  <VButton type="outline" disabled>
                    View
                  </VButton>
                )}
              </div>
            </div>
            <Progress value={100} color="warning" />
          </>
        );
      }
    }
  };

  return (
    <div className="flex flex-col gap-y-5 overflow-x-hidden">
      <div>
        <Row className="flex items-center">
          <Col xs={12} sm={4} md={12} lg={4}>
            <span>{projectsByType["opening"].length} opening</span>
          </Col>
          <Col xs={12} sm={4} md={12} lg={4}>
            <VDropdown
              options={filters}
              // defaultValue={filter}
              // isMulti
              onChange={(e) => {
                setFilter(e);
              }}
            />
          </Col>
          <Col xs={12} sm={4} md={12} lg={4}>
            {" "}
            <VInput
              transparent={true}
              className="!bg-transparent"
              placeholder={"Search..."}
            />
          </Col>
        </Row>
      </div>
      <div className="flex flex-wrap gap-3">
        {projectByFilter.map((project, key) => (
          <div
            className="relative w-[380px] h-[700px] flex flex-col gap-y-5 rounded"
            key={key}
            style={themesSetting.container}
          >
            <div className="relative w-full h-1/3">
              <img
                className="w-full object-cover rounded-t"
                src={project.banner || "/assets/images/slide/slide-1.jpg"}
              />
              <div className="absolute top-1 left-1">
                <img
                  src={project.logo || "/logo32.png"}
                  className="w-8 h-8 object-cover rounded"
                  alt="logo"
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-2 px-2">
              <div>
                <Row>
                  <Col xs={12} className="flex justify-center mb-3">
                    <a
                      className="text-xl font-bold text-center no-underline"
                      href={`/project/${project.symbol}`}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {project.name.length > 31
                        ? `${project.name.slice(0, 28)}...`
                        : project.name}
                    </a>
                  </Col>
                  {project.status !== "closed" && (
                    <Col xs={12}>
                      <div className="flex justify-center items-center h-full">
                        <Countdown
                          date={compareDateWithCur(
                            project.status === "opening"
                              ? project.keep_time
                                ? project.keep_time.ido_close
                                : 0
                              : project.keep_time
                              ? project.keep_time.ido_open
                              : 0
                          )}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              {project.social.length > 0 && (
                <div className="min-h-[24px]">
                  {Object.keys(project.social || []).map((key) => {
                    return (
                      <a
                        href={project.social[key]}
                        target="_blank"
                        rel="noreferrer"
                        key={key}
                      >
                        <img src={SOCIAL_IMG[key]} alt="" />
                      </a>
                    );
                  })}
                </div>
              )}
              <label>
                Starts{" "}
                {project.keep_time
                  ? new Date(project.keep_time.ido_open).toLocaleString()
                  : "N/A"}{" "}
                in your time
              </label>
              <div className="min-h-[16px] my-[10px]">
                <Stage
                  stages={
                    project.type_sell === "pixel"
                      ? project.rounds
                      : project.stages
                  }
                  name={project.symbol}
                  stock={project.stock_to_buy}
                  type={project.type_sell}
                />
              </div>
              {/* <Cost>
                  <Element>
                    <img src="/assets/images/icons/lock.svg" alt="" />
                    <span>100%</span>
                  </Element>
                  <Percent>
                    <img src="/assets/images/icons/lock.svg" alt="" />
                    <span>0.2%</span>
                  </Percent>
                </Cost> */}
              <Row>
                <Col xs={10} className="flex flex-col gap-y-1">
                  <label className="font-bold">
                    {renderProjectStatus(project.status)}
                  </label>
                  <label className="opacity-50">Markets Initialized</label>
                </Col>
                <Col xs={2}>
                  {" "}
                  <ToolTip
                    id={project._id}
                    content={<Reward reward={project.reward} />}
                  >
                    <span id={`tooltip-` + project._id} className="gift">
                      <BsFillGiftFill size={18} />
                    </span>
                  </ToolTip>
                </Col>
              </Row>
              <div>
                {project.type_sell === "pixel"
                  ? renderProgressPixel(project)
                  : renderProgress(project)}
              </div>
            </div>

            {project.status === "opening" && (
              <div className="absolute bottom-2 left-0 w-full px-2">
                <VButton
                  onClick={() => handleOpen(project)}
                  disabled={!project.stages.some((s) => s.on_sale)}
                  radius="full"
                  className="w-full h-[40px]"
                >
                  BUY
                </VButton>
              </div>
            )}
          </div>
        ))}
      </div>
      <br />
      {projectSeleted && (
        <Modal
          isOpen={state.isOpen}
          children={renderModal(projectSeleted)}
          onCancel={handleClosed}
          centered={true}
        />
      )}
      {renderModalCongratulation(congratulationInverst)}
      <Histories
        setCongratulationInverst={setCongratulationInverst}
        setOpenModalCongratulation={setOpenModalCongratulation}
      />
    </div>
  );
};

export default View;
