import React from "react";

import { AiOutlineDownload, AiOutlineCloseCircle } from "react-icons/ai";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import VButton from "components/v-core/v-button";

const View = (props) => {
  const { srcImg, download, closeModal, loading } = props;

  return (
    <>
      <div className="w-full p-[15px] h-full">
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut, ...rest }) => (
            <React.Fragment>
              <div className="flex flex-col justify-between ">
                <div className=" flex justify-between px-[20px]">
                  <p>{srcImg?.split("/")?.[srcImg?.split("/").length - 1]}</p>
                </div>
                <div className="flex justify-center w-full overflow-hidden">
                  <TransformComponent>{props.children}</TransformComponent>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <VButton
                    className="h-[36px] w-16 mx-[2px] text-[19px]"
                    onClick={download}
                    loading={loading}
                  >
                    <AiOutlineDownload />
                  </VButton>
                  <VButton
                    className="h-[36px] w-16 mx-[2px] text-[19px]"
                    onClick={() => zoomIn()}
                  >
                    <BsZoomIn />
                  </VButton>
                  <VButton
                    className="h-[36px] w-16 mx-[2px] text-[19px]"
                    onClick={() => zoomOut()}
                  >
                    <BsZoomOut />
                  </VButton>
                  <VButton
                    className="h-[36px] w-16 mx-[2px] text-[19px]"
                    onClick={() => closeModal(false)}
                  >
                    <AiOutlineCloseCircle />
                  </VButton>
                </div>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
    </>
  );
};

export default View;
