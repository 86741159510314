/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
/* eslint-disable indent */

import Input from "components/v-core/v-input";
import Modal from "components/v-core/v-modal";
import ToolTip from "components/v-tooltip";
import { TooltipWrapper } from "pages/my/task/styles";
import { useContext, useMemo, useState } from "react";
// import {
//   BsCameraVideoFill,
//   BsFillGiftFill,
//   BsFillImageFill,
// } from "react-icons/bs";
// import { FcStatistics } from "react-icons/fc";
// import { TbAffiliate, TbWorld } from "react-icons/tb";
// import { RiDivideFill } from "react-icons/ri";
import { Button, Row } from "reactstrap";
import { formatNumber } from "utils/common";
import { MasterContext } from "context/MasterContext";
import Header from "./Header";
import Profits from "./Profits";
import VButton from "components/v-core/v-button";
import { renderStock } from "utils/render";
import Icons from "helper/icons";
import VReward from "components/v-reward";
import { compareDateWithCur } from "utils/times";
import { ThemeContext } from "context/ThemeContext";

const calDefault = {
  input: {
    profit_id: null,
    amount: 0,
  },
  output: {
    stock: 0,
    vnex: 0,
    name_stock: "",
  },
};

const Package = ({ _package, setSelectedPackage, setOpenModalTrust }) => {
  const { auth, stocksPrice, settings, stockDefault } =
    useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const { objects } = settings;

  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showCal, setShowCal] = useState(false);

  const [dataCal, setDataCal] = useState(calDefault);

  const allowTrust = useMemo(() => {
    if (_package.allocation_users?.length > 0) {
      const allocationUser = _package.allocation_users.find(
        (a) => a.id === auth.refer_code
      );
      if (!allocationUser) return -1;
      else
        return (
          (allocationUser.amount || 0) - (allocationUser.amount_trusted || 0)
        );
    }

    if (
      _package?.allocation_location &&
      _package?.allocation_location.length > 0
    ) {
      const tokenForLocation = _package?.allocation_location?.find(
        (item) => item?.address?.province === auth?.address?.province
      );
      if (tokenForLocation) {
        return (
          (tokenForLocation.amount || 0) -
          (tokenForLocation.amount_trusted || 0)
        );
      }
      return 0;
    }
    return 1;
  }, [_package]);

  const handleChange = (amount) => {
    let amountTemp = +amount;
    let profitTam = null;
    const profits = _package.profits || [];
    if (amountTemp > 0 && profits.length > 0) {
      if (dataCal.input.profit_id) {
        profitTam = profits.find(
          (item) => item.index === dataCal.input.profit_id
        );
      } else {
        profitTam = profits[0];
      }
      const stockTemp = (amountTemp * profitTam.percent) / 100;
      const vnexTemp =
        (stockTemp * stocksPrice[profitTam.stock].vnd) /
        stocksPrice[stockDefault]?.vnd;
      setDataCal({
        ...dataCal,
        output: {
          stock: stockTemp,
          vnex: vnexTemp,
          name_stock: profitTam.stock,
        },
      });
    } else {
      setDataCal(calDefault);
    }
  };

  const handleChangeCal = (name, value, parent) => {
    setDataCal({
      ...dataCal,
      [parent]: {
        ...dataCal[parent],
        [name]: value,
      },
    });
  };

  const renderReward = (p: any) => {
    return (
      <TooltipWrapper>
        <VReward reward={p.reward} />
      </TooltipWrapper>
    );
  };

  const renderAffilate = (p: any) => {
    const _affiliates = Object.keys(p.affiliates || {})?.splice(0, 3);
    return (
      <TooltipWrapper>
        {_affiliates.map((key) => {
          const reward = p.affiliates[key];
          return <VReward key={key} reward={reward} />;
        })}
      </TooltipWrapper>
    );
  };

  const renderMachingBonusAffilate = (p: any) => {
    return (
      <TooltipWrapper>
        {Object.keys(p.affiliates_profit || {}).map((key) => {
          const reward = p.affiliates_profit[key];
          return <VReward key={key} reward={reward} />;
        })}
      </TooltipWrapper>
    );
  };

  const renderStatis = (p: any) => {
    return (
      <TooltipWrapper>
        <div>
          <strong>You join project: </strong> {formatNumber(0, 0)}/
          {formatNumber(p.require.max_per_user, 0) || 0}
        </div>
        <hr />
        <div>
          <strong>Total package:</strong>{" "}
          {formatNumber(p.count.amount_user_joined, 0) || 0}/
          {formatNumber(p.require.total_package, 0)}
        </div>
        <hr />
      </TooltipWrapper>
    );
  };

  const renderImage = () => {
    return (
      <div>
        <div className="image">
          <img className="img-info" src={_package.image} alt="" />
        </div>
      </div>
    );
  };

  const renderVideo = () => {
    return (
      <div className="video-responsive">
        <iframe
          className="w-full"
          height="315"
          src={`https://www.youtube.com/embed/${_package.video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        ></iframe>
      </div>
    );
  };

  const renderCal = () => {
    const selectedPackage = _package;
    return (
      <div>
        <Row>
          <div className="packages">
            {(selectedPackage.profits || []).map((p, key) => (
              <Button
                key={key}
                className={
                  dataCal.input.profit_id === p.index
                    ? "opacity-100"
                    : "opacity-40"
                }
                outline
                onClick={() => handleChangeCal("profit_id", p.index, "input")}
              >
                {p.time_lock} {selectedPackage.type_lock}s
              </Button>
            ))}
          </div>
          <Input
            label="Amount"
            name="amount"
            isCurrency={true}
            value={dataCal.input.amount}
            onValueChange={(e) => handleChange(e)}
          />
          <div>
            <Button children="=>" />
          </div>

          <div>
            <div className="output">
              <span>Total profits: </span>
              <div>
                ~ {formatNumber(dataCal.output?.stock)}{" "}
                {renderStock(dataCal.output?.name_stock)}
              </div>
              <div>
                ~ {formatNumber(dataCal.output?.stock)}{" "}
                {renderStock(stockDefault)}
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  };

  const handleTrust = () => {
    setOpenModalTrust(true);
    setSelectedPackage(_package);
  };

  const status = useMemo(() => {
    if (
      _package.time.start_date &&
      compareDateWithCur(_package.time.start_date) > 0
    ) {
      return "upcoming";
    } else {
      return "happening";
    }
  }, [_package]);

  return (
    <>
      <div
        className="relative max-sm:w-full w-[380px] h-[670px] flex flex-col bg-no-repeat bg-cover border-2 rounded-md p-2 md:p-3"
        style={{
          background: `url(${_package?.background})`,
          color: "#FFF",
          backgroundSize: "100%",
          borderColor: themesSetting.layout.textContent,
        }}
      >
        <Header item={_package} allowTrust={allowTrust} _package={_package} />
        <hr
          className="my-2 border-b border-dashed"
          style={{ borderColor: themesSetting.layout.textContent }}
        />
        <div className="flex">
          <Profits item={_package} />
        </div>
        <hr
          className="w-full border-b border-dashed  my-2"
          style={{ borderColor: themesSetting.layout.textContent }}
        />
        <div className="h-6 flex !bg-transparent justify-center items-center rounded-full overflow-hidden">
          <div className="absolute">
            {formatNumber(_package?.count?.amount_invested || 0, 0)} /
            {formatNumber(_package.require.total_amount, 0)}
          </div>
          {/* <progress
            className="w-full h-6"
            value={
              (_package?.count?.amount_invested || 0) /
              _package.require.total_amount
            }
            style={{ backgroundColor: "transparent" }}
          /> */}
        </div>
        <hr
          className="w-full border-b border-dashed my-2"
          style={{ borderColor: themesSetting.layout.textContent }}
        />
        <div className="flex flex-col gap-y-2 mb-6">
          <div className="w-full flex items-center p-2 border-0.5 rounded-2xl justify-center gap-x-6">
            <ToolTip
              id={`${_package._id}_gift`}
              content={renderReward(_package)}
            >
              <span id={`tooltip-` + `${_package._id}_gift`} className="gift">
                <Icons iconName="gift" />
              </span>
            </ToolTip>

            <ToolTip
              id={`${_package._id}_aff`}
              content={renderAffilate(_package)}
            >
              <span id={`tooltip-` + `${_package._id}_aff`} className="gift">
                <Icons iconName="connect" />
              </span>
            </ToolTip>

            {_package.is_affiliate_profit && (
              <ToolTip
                id={`${_package._id}_aff_maching`}
                content={renderMachingBonusAffilate(_package)}
              >
                <span
                  id={`tooltip-` + `${_package._id}_aff_maching`}
                  className="gift"
                >
                  <img
                    className="w-10 h-10"
                    src="/assets/images/machingbonus.png"
                    alt=""
                  />
                  {/* <Icons iconName="connect" /> */}
                </span>
              </ToolTip>
            )}

            <ToolTip
              id={`${_package._id}_statis`}
              content={renderStatis(_package)}
            >
              <span id={`tooltip-` + `${_package._id}_statis`} className="gift">
                <Icons iconName="trendup" />
              </span>
            </ToolTip>
            <ToolTip id={`${_package._id}_image`}>
              <span
                id={`tooltip-` + `${_package._id}_image`}
                className="gift"
                onClick={() => setShowImage(true)}
              >
                <Icons iconName="image" />
              </span>
            </ToolTip>
            <ToolTip id={`${_package._id}_video`} content={"Click view video"}>
              <span
                id={`tooltip-` + `${_package._id}_video`}
                className="gift"
                onClick={() => setShowVideo(true)}
              >
                <Icons iconName="video" />
              </span>
            </ToolTip>
            {/* <ToolTip
              id={`${_package._id}_cal`}
              content={"Click view investment returns"}
            >
              <span
                id={`tooltip-` + `${_package._id}_cal`}
                className="gift"
                onClick={() => setShowCal(true)}
              >
                <Icons iconName="devide" />
              </span>
            </ToolTip> */}
            <ToolTip id={`${_package._id}_website`} content={_package.website}>
              <span
                id={`tooltip-` + `${_package._id}_website`}
                className="gift"
                onClick={() => {
                  window.open(_package.website);
                }}
              >
                <Icons iconName="web" />
              </span>
            </ToolTip>
          </div>
          <div className="intended-for -skew-x-12 min-h-[40px] text-xs opacity-60 text-center">
            * Priority for{" "}
            {objects?.map((o) =>
              (_package?.objects || []).includes(o.id) ? `${o.name}, ` : ""
            )}
          </div>
        </div>
        <div className="absolute bottom-2 left-0 w-full px-3">
          <VButton
            onClick={() => handleTrust()}
            radius="full"
            iconLeft={
              <img src="/assets/images/trust/mine.png" className="w-5 h-5" />
            }
            className="w-full"
            disabled={status !== "happening" || allowTrust < 1}
          >
            {allowTrust === -1
              ? "CONTACT FOR MINING"
              : `START MINING ${renderStock(_package.invest.stock)}`}
          </VButton>
        </div>
      </div>

      <Modal
        isOpen={showImage}
        children={renderImage()}
        onCancel={(_) => setShowImage(false)}
        centered={true}
      />
      <Modal
        isOpen={showVideo}
        children={renderVideo()}
        onCancel={(_) => setShowVideo(false)}
        centered={true}
      />
      <Modal
        isOpen={showCal}
        children={renderCal()}
        onCancel={(_) => setShowCal(false)}
        centered={true}
      />
    </>
  );
};

export default Package;
