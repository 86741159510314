/* eslint-disable indent */
import styled from "styled-components";

export const BankDepositWrapper = styled.div`
  padding: 15px;
  margin: 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};

  p {
    font-size: 14px;
    margin: 10px;
  }

  span {
    font-weight: bold;
  }
`;

export const CopyBtn = styled.span`
  color: goldenrod;
  cursor: pointer;
  font-size: 10px;
  vertical-align: middle;
  margin-left: 4px;
`;

export const BankDepositUploadConfirm = styled.div`
  padding: 15px;

  button {
    height: 40px;
    width: 200px;
  }
`;

export const BankDepositUploadDragger = styled.label<{ errUpload: boolean }>`
  width: 200px;
  height: 200px;
  margin: auto;
  border: 1px dashed
    ${(props) =>
      props.errUpload ? props.theme.colors.red100 : props.theme.colors.grey200};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

export const SubmitButton = styled.div`
  text-align: center;
  margin-top: 40px;
  button {
    margin: 4px;
  }
`;

export const Step0Wrapper = styled.div`
  padding: 20px;
  .btn-currency {
    margin: 4px;
    font-weight: 600;
    opacity: 0.6;
    img {
      margin-right: 5px;
      height: 20px;
    }
  }
  .active {
    opacity: 1 !important;
  }
`;
