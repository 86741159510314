import { Controller } from "react-hook-form";

import { FormItem } from "../../styles";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import { useContext, useState } from "react";

const Step1 = (props) => {
  const { theme, getThemeByKey } = useContext(ThemeContext);
  const { control, errors, errMessage, register } = props;
  const [hoverField, setHoverField] = useState(null);

  const inputTheme = getThemeByKey("auth.input");

  return (
    <>
      <FormItem>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <VInput
              required
              label="Email"
              {...register("email")}
              placeholder="Email"
              hasError={Boolean(errors.email)}
              errMessage={errMessage}
              wrapperClassName={"bg-transparent gap-y-1 px-0"}
              labelClassName={`text-xs font-bold ${
                theme === "dark" ? "text-white" : "text-gray-6"
              }`}
              inputWrapperClassName={`w-full border-[1px] rounded`}
              className={`w-full bg-transparent p-2`}
              onMouseEnter={() => setHoverField("email")}
              onMouseLeave={() => setHoverField(null)}
              inputStyle={{
                ...inputTheme,
                borderColor:
                  hoverField === "email"
                    ? inputTheme?.hover?.borderColor
                    : inputTheme?.borderColor,
              }}
              {...field}
            />
          )}
        />
      </FormItem>
      <FormItem>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <VInput
              required
              type="password"
              label="Password"
              {...register("password")}
              placeholder="Password"
              hasError={Boolean(errors.password)}
              errMessage={errMessage}
              wrapperClassName={"bg-transparent gap-y-1 px-0"}
              labelClassName={`text-xs font-bold ${
                theme === "dark" ? "text-white" : "text-gray-6"
              }`}
              inputWrapperClassName={`w-full border-[1px] rounded`}
              className={`w-full bg-transparent p-2`}
              onMouseEnter={() => setHoverField("password")}
              onMouseLeave={() => setHoverField(null)}
              inputStyle={{
                ...inputTheme,
                borderColor:
                  hoverField === "password"
                    ? inputTheme?.hover?.borderColor
                    : inputTheme?.borderColor,
              }}
              {...field}
            />
          )}
        />
      </FormItem>
    </>
  );
};

export default Step1;
