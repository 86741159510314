/*eslint max-len: ["error", { "code": 500 }]*/

import { v4 as uuidv4 } from "uuid";

export const listProjectCategory = [
  "Sản xuất con dấu",
  "Kinh doanh công cụ hỗ trợ (bao gồm cả sửa chữa)",
  "Kinh doanh các loại pháo, trừ pháo nổ",
  "Kinh doanh thiết bị, phần mềm ngụy trang dùng để ghi âm, ghi hình, định vị",
  "Kinh doanh súng bắn sơn",
  "Kinh doanh quân trang, quân dụng cho lực lượng vũ trang, vũ khí quân dụng, trang thiết bị, kỹ thuật, khí tài, phương tiện chuyên dùng quân sự, công an; linh kiện, bộ phận, phụ tùng, vật tư và trang thiết bị đặc chủng, công nghệ chuyên dùng chế tạo chúng",
  "Kinh doanh dịch vụ cầm đồ",
  "Kinh doanh dịch vụ xoa bóp",
  "Kinh doanh thiết bị phát tín hiệu của xe được quyền ưu tiên",
  "Kinh doanh dịch vụ bảo vệ",
  "Kinh doanh dịch vụ phòng cháy, chữa cháy",
  "Hành nghề luật sư",
  "Hành nghề công chứng",
  "Hành nghề giám định tư pháp trong các lĩnh vực tài chính, ngân hàng, xây dựng, cổ vật, di vật, bản quyền tác giả",
  "Hành nghề đấu giá tài sản",
  "Hành nghề thừa phát lại",
  "Hành nghề quản lý, thanh lý tài sản của doanh nghiệp, hợp tác xã trong quá trình giải quyết phá sản",
  "Kinh doanh dịch vụ kế toán",
  "Kinh doanh dịch vụ kiểm toán",
  "Kinh doanh dịch vụ làm thủ tục về thuế",
  "Kinh doanh dịch vụ làm thủ tục hải quan",
  "Kinh doanh hàng miễn thuế",
  "Kinh doanh kho ngoại quan, địa điểm thu gom hàng lẻ",
  "Kinh doanh địa điểm làm thủ tục hải quan, tập kết, kiểm tra, giám sát hải quan",
  "Kinh doanh chứng khoán",
  "Kinh doanh dịch vụ đăng ký, lưu ký, bù trừ và thanh toán chứng khoán của Tổng công ty lưu ký và bù trừ chứng khoán Việt Nam, tổ chức thị trường giao dịch chứng khoán niêm yết và các loại chứng khoán khác",
  "Kinh doanh bảo hiểm",
  "Kinh doanh tái bảo hiểm",
  "Môi giới bảo hiểm, hoạt động phụ trợ bảo hiểm",
  "Đại lý bảo hiểm",
  "Kinh doanh dịch vụ thẩm định giá",
  "Kinh doanh xổ số",
  "Kinh doanh trò chơi điện tử có thưởng dành cho người nước ngoài",
  "Kinh doanh dịch vụ xếp hạng tín nhiệm",
  "Kinh doanh ca-si-nô (casino)",
  "Kinh doanh đặt cược",
  "Kinh doanh dịch vụ quản lý quỹ hưu trí tự nguyện",
  "Kinh doanh xăng dầu",
  "Kinh doanh khí",
  "Kinh doanh dịch vụ giám định thương mại",
  "Kinh doanh vật liệu nổ công nghiệp (bao gồm cả hoạt động tiêu hủy)",
  "Kinh doanh tiền chất thuốc nổ",
  "Kinh doanh ngành, nghề có sử dụng vật liệu nổ công nghiệp và tiền chất thuốc nổ",
  "Kinh doanh dịch vụ nổ mìn",
  "Kinh doanh hóa chất, trừ hóa chất bị cấm theo Công ước quốc tế về cấm phát triển, sản xuất, tàng trữ, sử dụng và phá hủy vũ khí hóa học",
  "Kinh doanh rượu",
  "Kinh doanh sản phẩm thuốc lá, nguyên liệu thuốc lá, máy móc, thiết bị thuộc chuyên ngành thuốc lá",
  "Kinh doanh thực phẩm thuộc lĩnh vực quản lý chuyên ngành của Bộ Công Thương",
  "Hoạt động Sở Giao dịch hàng hóa",
  "Hoạt động phát điện, truyền tải, phân phối, bán buôn, bán lẻ, tư vấn chuyên ngành điện lực",
  "Xuất khẩu gạo",
  "Kinh doanh tạm nhập, tái xuất hàng hóa có thuế tiêu thụ đặc biệt",
  "Kinh doanh tạm nhập, tái xuất hàng thực phẩm đông lạnh",
  "Kinh doanh tạm nhập, tái xuất hàng hóa thuộc Danh mục hàng hóa đã qua sử dụng",
  "Kinh doanh khoáng sản",
  "Kinh doanh tiền chất công nghiệp",
  "Hoạt động mua bán hàng hóa và các hoạt động liên quan trực tiếp đến hoạt động mua bán hàng hóa của nhà cung cấp dịch vụ nước ngoài tại Việt Nam",
  "Kinh doanh theo phương thức bán hàng đa cấp",
  "Hoạt động thương mại điện tử",
  "Hoạt động dầu khí",
  "Kiểm toán năng lượng",
  "Hoạt động giáo dục nghề nghiệp",
  "Kiểm định chất lượng giáo dục nghề nghiệp",
  "Kinh doanh dịch vụ đánh giá kỹ năng nghề",
  "Kinh doanh dịch vụ kiểm định kỹ thuật an toàn lao động",
  "Kinh doanh dịch vụ huấn luyện an toàn lao động, vệ sinh lao động",
  "Kinh doanh dịch vụ việc làm",
  "Kinh doanh dịch vụ đưa người lao động đi làm việc ở nước ngoài",
  "Kinh doanh dịch vụ cai nghiện ma túy tự nguyện, cai nghiện thuốc lá, điều trị HIV/AIDS, chăm sóc người cao tuổi, người khuyết tật, trẻ em",
  "Kinh doanh dịch vụ cho thuê lại lao động",
  "Kinh doanh vận tải đường bộ",
  "Kinh doanh dịch vụ bảo hành, bảo dưỡng xe ô tô",
  "Sản xuất, lắp ráp, nhập khẩu xe ô tô",
  "Kinh doanh dịch vụ kiểm định xe cơ giới",
  "Kinh doanh dịch vụ đào tạo lái xe ô tô",
  "Kinh doanh dịch vụ đào tạo thẩm tra viên an toàn giao thông",
  "Kinh doanh dịch vụ sát hạch lái xe",
  "Kinh doanh dịch vụ thẩm tra an toàn giao thông",
  "Kinh doanh vận tải đường thủy",
  "Kinh doanh dịch vụ đóng mới, hoán cải, sửa chữa, phục hồi phương tiện thủy nội địa",
  "Kinh doanh dịch vụ đào tạo thuyền viên và người lái phương tiện thủy nội địa",
  "Đào tạo, huấn luyện thuyền viên hàng hải và tổ chức tuyển dụng, cung ứng thuyền viên hàng hải",
  "Kinh doanh dịch vụ bảo đảm an toàn hàng hải",
  "Kinh doanh vận tải biển",
  "Kinh doanh dịch vụ lai dắt tàu biển",
  "Nhập khẩu, phá dỡ tàu biển đã qua sử dụng",
  "Kinh doanh dịch vụ đóng mới, hoán cải, sửa chữa tàu biển",
  "Kinh doanh khai thác cảng biển",
  "Kinh doanh vận tải hàng không",
  "Kinh doanh dịch vụ thiết kế, sản xuất, bảo dưỡng, thử nghiệm tàu bay, động cơ tàu bay, cánh quạt tàu bay và trang bị, thiết bị tàu bay tại Việt Nam",
  "Kinh doanh cảng hàng không, sân bay",
  "Kinh doanh dịch vụ hàng không tại cảng hàng không, sân bay",
  "Kinh doanh dịch vụ bảo đảm hoạt động bay",
  "Kinh doanh dịch vụ đào tạo, huấn luyện nghiệp vụ nhân viên hàng không",
  "Kinh doanh vận tải đường sắt",
  "Kinh doanh kết cấu hạ tầng đường sắt",
  "Kinh doanh đường sắt đô thị",
  "Kinh doanh dịch vụ vận tải đa phương thức",
  "Kinh doanh dịch vụ vận chuyển hàng nguy hiểm",
  "Kinh doanh vận tải đường ống",
  "Kinh doanh bất động sản",
  "Kinh doanh nước sạch (nước sinh hoạt)",
  "Kinh doanh dịch vụ kiến trúc",
  "Kinh doanh dịch vụ tư vấn quản lý dự án đầu tư xây dựng",
  "Kinh doanh dịch vụ khảo sát xây dựng",
  "Kinh doanh dịch vụ thiết kế, thẩm tra thiết kế xây dựng",
  "Kinh doanh dịch vụ tư vấn giám sát thi công xây dựng công trình",
  "Kinh doanh dịch vụ thi công xây dựng công trình",
  "Hoạt động xây dựng của nhà thầu nước ngoài",
  "Kinh doanh dịch vụ quản lý chi phí đầu tư xây dựng",
  "Kinh doanh dịch vụ kiểm định xây dựng",
  "Kinh doanh dịch vụ thí nghiệm chuyên ngành xây dựng",
  "Kinh doanh dịch vụ quản lý vận hành nhà chung cư",
  "Kinh doanh dịch vụ quản lý, vận hành cơ sở hỏa táng",
  "Kinh doanh dịch vụ lập thiết kế quy hoạch xây dựng",
  "Kinh doanh sản phẩm amiang trắng thuộc nhóm Serpentine",
  "Kinh doanh dịch vụ bưu chính",
  "Kinh doanh dịch vụ viễn thông",
  "Kinh doanh dịch vụ chứng thực chữ ký số",
  "Hoạt động của nhà xuất bản",
  "Kinh doanh dịch vụ in, trừ in bao bì",
  "Kinh doanh dịch vụ phát hành xuất bản phẩm",
  "Kinh doanh dịch vụ mạng xã hội",
  "Kinh doanh trò chơi trên mạng viễn thông, mạng Internet",
  "Kinh doanh dịch vụ phát thanh, truyền hình trả tiền",
  "Kinh doanh dịch vụ thiết lập trang thông tin điện tử tổng hợp",
  "Dịch vụ gia công, tái chế, sửa chữa, làm mới sản phẩm công nghệ thông tin đã qua sử dụng thuộc Danh mục sản phẩm công nghệ thông tin đã qua sử dụng cấm nhập khẩu cho đối tác nước ngoài",
  "Kinh doanh dịch vụ nội dung thông tin trên mạng viễn thông di động, mạng Internet",
  "Kinh doanh dịch vụ đăng ký, duy trì tên miền",
  "Kinh doanh dịch vụ trung tâm dữ liệu",
  "Kinh doanh dịch vụ định danh và xác thực điện tử",
  "Kinh doanh sản phẩm, dịch vụ an toàn thông tin mạng",
  "Kinh doanh dịch vụ phát hành báo chí nhập khẩu",
  "Kinh doanh sản phẩm, dịch vụ mật mã dân sự",
  "Kinh doanh các thiết bị gây nhiễu, phá sóng thông tin di động",
  "Hoạt động của cơ sở giáo dục mầm non",
  "Hoạt động của cơ sở giáo dục phổ thông",
  "Hoạt động của cơ sở giáo dục đại học",
  "Hoạt động của cơ sở giáo dục có vốn đầu tư nước ngoài, văn phòng đại diện giáo dục nước ngoài tại Việt Nam, phân hiệu cơ sở giáo dục có vốn đầu tư nước ngoài",
  "Hoạt động của cơ sở giáo dục thường xuyên",
  "Hoạt động của trường chuyên biệt",
  "Hoạt động liên kết đào tạo với nước ngoài",
  "Kiểm định chất lượng giáo dục",
  "Kinh doanh dịch vụ tư vấn du học",
  "Khai thác thủy sản",
  "Kinh doanh thủy sản",
  "Kinh doanh thức ăn thủy sản, thức ăn chăn nuôi",
  "Kinh doanh dịch vụ khảo nghiệm thức ăn thủy sản, thức ăn chăn nuôi",
  "Kinh doanh chế phẩm sinh học, vi sinh vật, hóa chất, chất xử lý môi trường trong nuôi trồng thủy sản, chăn nuôi",
  "Kinh doanh đóng mới, cải hoán tàu cá",
  "Đăng kiểm tàu cá",
  "Đào tạo, bồi dưỡng thuyền viên tàu cá",
  "Nuôi, trồng các loài thực vật, động vật hoang dã thuộc các Phụ lục của Công ước CITES và danh mục thực vật rừng, động vật rừng, thủy sản nguy cấp, quý, hiếm",
  "Nuôi động vật rừng thông thường",
  "Xuất khẩu, nhập khẩu, tái xuất khẩu, quá cảnh và nhập nội từ biển mẫu vật từ tự nhiên của các loài thuộc các Phụ lục của Công ước CITES và danh mục thực vật rừng, động vật rừng, thủy sản nguy cấp, quý, hiếm",
  "Xuất khẩu, nhập khẩu, tái xuất khẩu mẫu vật nuôi sinh sản, nuôi sinh trưởng, trồng cấy nhân tạo của các loài thuộc các Phụ lục của Công ước CITES và danh mục thực vật rừng, động vật rừng, thủy sản nguy cấp, quý, hiếm",
  "Chế biến, kinh doanh, vận chuyển, quảng cáo, trưng bày, cất giữ mẫu vật của các loài thực vật, động vật thuộc các Phụ lục của Công ước CITES và danh mục thực vật rừng, động vật rừng, thủy sản nguy cấp, quý, hiếm",
  "Kinh doanh thuốc bảo vệ thực vật",
  "Kinh doanh dịch vụ xử lý vật thể thuộc diện kiểm dịch thực vật",
  "Kinh doanh dịch vụ khảo nghiệm thuốc bảo vệ thực vật",
  "Kinh doanh dịch vụ bảo vệ thực vật",
  "Kinh doanh thuốc thú y, vắc xin, chế phẩm sinh học, vi sinh vật, hóa chất dùng trong thú y",
  "Kinh doanh dịch vụ kỹ thuật về thú y",
  "Kinh doanh dịch vụ xét nghiệm, phẫu thuật động vật",
  "Kinh doanh dịch vụ tiêm phòng, chẩn đoán bệnh, kê đơn, chữa bệnh, chăm sóc sức khỏe động vật",
  "Kinh doanh dịch vụ kiểm nghiệm, khảo nghiệm thuốc thú y (bao gồm thuốc thú y, thuốc thú y thủy sản, vắc xin, chế phẩm sinh học, vi sinh vật, hóa chất dùng trong thú y, thú y thủy sản)",
  "Kinh doanh chăn nuôi trang trại",
  "Kinh doanh giết mổ gia súc, gia cầm",
  "Kinh doanh thực phẩm thuộc lĩnh vực quản lý chuyên ngành của Bộ Nông nghiệp và Phát triển nông thôn",
  "Kinh doanh dịch vụ cách ly kiểm dịch động vật, sản phẩm động vật",
  "Kinh doanh phân bón",
  "Kinh doanh dịch vụ khảo nghiệm phân bón",
  "Kinh doanh giống cây trồng, giống vật nuôi",
  "Kinh doanh giống thủy sản",
  "Kinh doanh dịch vụ khảo nghiệm giống cây trồng, giống vật nuôi",
  "Kinh doanh dịch vụ khảo nghiệm giống thủy sản",
  "Kinh doanh dịch vụ thử nghiệm, khảo nghiệm chế phẩm sinh học, vi sinh vật, hóa chất, chất xử lý môi trường trong nuôi trồng thủy sản, chăn nuôi",
  "Kinh doanh sản phẩm biến đổi gen",
  "Kinh doanh dịch vụ khám bệnh, chữa bệnh",
  "Kinh doanh dịch vụ phẫu thuật thẩm mỹ",
  "Kinh doanh dược",
  "Sản xuất mỹ phẩm",
  "Kinh doanh hóa chất, chế phẩm diệt côn trùng, diệt khuẩn dùng trong lĩnh vực gia dụng y tế",
  "Kinh doanh trang thiết bị y tế",
  "Kinh doanh dịch vụ giám định về sở hữu trí tuệ (bao gồm giám định về quyền tác giả và quyền liên quan, giám định sở hữu công nghiệp và giám định về quyền đối với giống cây trồng)",
  "Kinh doanh dịch vụ tiến hành công việc bức xạ",
  "Kinh doanh dịch vụ hỗ trợ ứng dụng năng lượng nguyên tử",
  "Kinh doanh dịch vụ đánh giá sự phù hợp",
  "Kinh doanh dịch vụ kiểm định, hiệu chuẩn, thử nghiệm phương tiện đo, chuẩn đo lường",
  "Kinh doanh dịch vụ đánh giá, thẩm định giá và giám định công nghệ",
  "Kinh doanh dịch vụ đại diện quyền sở hữu trí tuệ (bao gồm dịch vụ đại diện sở hữu công nghiệp và dịch vụ đại diện quyền đối với giống cây trồng)",
  "Kinh doanh dịch vụ phát hành và phổ biến phim",
  "Kinh doanh dịch vụ giám định cổ vật",
  "Kinh doanh dịch vụ lập quy hoạch dự án, tổ chức thi công, giám sát thi công dự án bảo quản, tu bổ và phục hồi di tích",
  "Kinh doanh dịch vụ ka-ra-ô-kê (karaoke), vũ trường",
  "Kinh doanh dịch vụ lữ hành",
  "Kinh doanh hoạt động thể thao của doanh nghiệp thể thao, câu lạc bộ thể thao chuyên nghiệp",
  "Kinh doanh dịch vụ biểu diễn nghệ thuật, trình diễn thời trang, tổ chức thi người đẹp, người mẫu",
  "Kinh doanh bản ghi âm, ghi hình ca múa nhạc, sân khấu",
  "Kinh doanh dịch vụ lưu trú",
  "Mua bán di vật, cổ vật, bảo vật quốc gia",
  "Xuất khẩu di vật, cổ vật không thuộc sở hữu nhà nước, sở hữu của tổ chức chính trị, tổ chức chính trị – xã hội; nhập khẩu hàng hóa văn hóa thuộc diện quản lý chuyên ngành của Bộ Văn hóa, Thể thao và Du lịch",
  "Kinh doanh dịch vụ bảo tàng",
  "Kinh doanh trò chơi điện tử (trừ kinh doanh trò chơi điện tử có thưởng dành cho người nước ngoài và kinh doanh trò chơi điện tử có thưởng trên mạng)",
  "Kinh doanh dịch vụ tư vấn điều tra, đánh giá đất đai",
  "Kinh doanh dịch vụ về lập quy hoạch, kế hoạch sử dụng đất",
  "Kinh doanh dịch vụ xây dựng hạ tầng kỹ thuật công nghệ thông tin, xây dựng phần mềm của hệ thống thông tin đất đai",
  "Kinh doanh dịch vụ xây dựng cơ sở dữ liệu đất đai",
  "Kinh doanh dịch vụ xác định giá đất",
  "Kinh doanh dịch vụ đo đạc và bản đồ",
  "Kinh doanh dịch vụ dự báo, cảnh báo khí tượng thủy văn",
  "Kinh doanh dịch vụ khoan nước dưới đất, thăm dò nước dưới đất",
  "Kinh doanh dịch vụ khai thác, sử dụng tài nguyên nước, xả nước thải vào nguồn nước",
  "Kinh doanh dịch vụ điều tra cơ bản, tư vấn lập quy hoạch, đề án, báo cáo tài nguyên nước",
  "Kinh doanh dịch vụ thăm dò khoáng sản",
  "Khai thác khoáng sản",
  "Kinh doanh dịch vụ vận chuyển, xử lý chất thải nguy hại",
  "Nhập khẩu phế liệu",
  "Kinh doanh dịch vụ quan trắc môi trường",
  "Hoạt động kinh doanh của ngân hàng thương mại",
  "Hoạt động kinh doanh của tổ chức tín dụng phi ngân hàng",
  "Hoạt động kinh doanh của ngân hàng hợp tác xã, quỹ tín dụng nhân dân, tổ chức tài chính vi mô",
  "Cung ứng dịch vụ trung gian thanh toán, cung ứng dịch vụ thanh toán không qua tài khoản thanh toán của khách hàng",
  "Cung ứng dịch vụ thông tin tín dụng",
  "Hoạt động kinh doanh, cung ứng dịch vụ ngoại hối của tổ chức không phải là tổ chức tín dụng",
  "Kinh doanh vàng",
  "Hoạt động in, đúc tiền",
].map((el: string) => ({
  label: el,
  value: el,
}));

export const DATA_ESTOCKS_DEFAULT = {
  name: "",
  symbol: "",
  logo: "",
  menus: [],
  linkLogin: "https://id.canvanex.com/auth/login",
  linkRegister: "https://id.canvanex.com/auth/register",
  linkIntro: "Aq6W-nmRO94",
  logoToken: "",
  settingMenu: {
    colors: {
      dark: {
        header: {
          from: "#0D1321",
          via: "",
          to: "",
          text: "#FFFFFF",
        },
        banner: {
          from: "#0D1321",
          via: "",
          to: "",
          text: "#FFFFFF",
        },
        footer: {
          from: "#0D1321",
          via: "",
          to: "",
          text: "#FFFFFF",
        },
        section1: {
          from: "#171E2E",
          via: "",
          to: "",
          text: "#FFFFFF",
        },
        section2: {
          from: "#0D1321",
          via: "",
          to: "",
          text: "#FFFFFF",
        },
        content: {
          from: "#1F2937",
          via: "",
          to: "",
          text: "#FFFFFF",
        },
        textColor: "#FFFFFF",
      },
      light: {
        header: {
          from: "#FCFCFC",
          via: "",
          to: "",
          text: "#000000",
        },
        banner: {
          from: "#FCFCFC",
          via: "",
          to: "",
          text: "#000000",
        },
        footer: {
          from: "#FCFCFC",
          via: "",
          to: "",
          text: "#000000",
        },
        section1: {
          from: "#E7E8E8",
          via: "",
          to: "",
          text: "#000000",
        },
        section2: {
          from: "#FCFCFC",
          via: "",
          to: "",
          text: "#000000",
        },
        content: {
          from: "#E1E1E2",
          via: "",
          to: "",
          text: "#000000",
        },
        textColor: "#000000",
      },
    },
    sections: [
      {
        name: "Profit",
        key: "profit",
        isShow: true,
      },
      {
        name: "Fundamentals",
        key: "fundamentals",
        isShow: true,
      },
      {
        name: "Roadmaps",
        key: "roadmaps",
        isShow: true,
      },
      {
        name: "Tokenomics",
        key: "tokenomics",
        isShow: true,
      },
      {
        name: "Documents",
        key: "docs",
        isShow: true,
      },
      {
        name: "Certificates",
        key: "certificates",
        isShow: true,
      },
      {
        name: "Partners",
        key: "partners",
        isShow: true,
      },
      {
        name: "Teams",
        key: "teams",
        isShow: true,
      },
      {
        name: "Video",
        key: "video",
        isShow: true,
      },
      {
        name: "Review",
        key: "commentsField",
        isShow: true,
      },
    ],
    chat: "",
  },
  banner: {
    type: "slide" || "video" || "facebook",
    link: "",
    slide: [],
    imageBottom: {
      src: "",
    },
    advertisement_banner: {
      left: [],
      right: [],
    },
  },
  profit: {
    title: "Profit",
    desc: "Description",
  },
  fundamentals: {
    image: "",
    title: "Fundamentals",
    desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    leftItems: [
      {
        id: uuidv4(),
        title: "Title",
        desc: "Desc",
      },
    ],
    rightItems: [
      {
        id: uuidv4(),
        title: "Title",
        desc: "Desc",
      },
    ],
  },
  roadmaps: {
    title: "Roadmaps",
    desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
    image: "",
  },
  tokenomics: {
    title: "Tokenomics",
    desc: "Description",
    image: "",
  },
  docs: [
    {
      id: uuidv4(),
      title: "Documents",
      desc: "Desc",
      items: [
        {
          id: uuidv4(),
          title: "Doc 1",
          desc: "Doc 1",
          src: "",
          link: "",
          isNew: true,
        },
        {
          id: uuidv4(),
          title: "Doc 2",
          desc: "Doc 2",
          src: "",
          link: "",
          isNew: true,
        },
        {
          id: uuidv4(),
          title: "Doc 3",
          desc: "Doc 3",
          src: "",
          link: "",
          isNew: true,
        },
      ],
    },
  ],
  certificates: {
    title: "Certificates",
    desc: "Description",
    type: "slide" || "video",
    link: "",
    slide: [],
  },
  partners: [
    {
      id: uuidv4(),
      title: "Our partners",
      desc: "In the crypto industry, partnerships play a vital role in fostering collaboration, expanding networks, and driving adoption",
      items: [
        {
          id: uuidv4(),
          src: "",
          isNew: true,
        },
        {
          id: uuidv4(),
          src: "",
          isNew: true,
        },
        {
          id: uuidv4(),
          src: "",
          isNew: true,
        },
        {
          id: uuidv4(),
          src: "",
          isNew: true,
        },
      ],
    },
  ],
  teams: [
    {
      id: uuidv4(),
      title: "Project teams",
      desc: "The team composition may vary depending on the specific project, its goals, and the stage of development",
      items: [
        {
          id: uuidv4(),
          src: "",
          isNew: true,
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          links: [
            {
              id: uuidv4(),
              name: "vdiarybook",
              link: "",
              logo: "/assets/images/social/vdiarybook.png",
            },
            {
              id: uuidv4(),
              name: "facebook",
              link: "",
              logo: "/assets/images/social/facebook.png",
            },
            {
              id: uuidv4(),
              name: "youtube",
              link: "",
              logo: "/assets/images/social/youtube.png",
            },
            {
              id: uuidv4(),
              name: "telegram",
              link: "",
              logo: "/assets/images/social/telegram.png",
            },
            {
              id: uuidv4(),
              name: "twitter",
              link: "",
              logo: "/assets/images/social/twitter.png",
            },
          ],
        },
        {
          id: uuidv4(),
          src: "",
          isNew: true,
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          links: [
            {
              id: uuidv4(),
              name: "vdiarybook",
              link: "",
              logo: "/assets/images/social/vdiarybook.png",
            },
            {
              id: uuidv4(),
              name: "facebook",
              link: "",
              logo: "/assets/images/social/facebook.png",
            },
            {
              id: uuidv4(),
              name: "youtube",
              link: "",
              logo: "/assets/images/social/youtube.png",
            },
            {
              id: uuidv4(),
              name: "telegram",
              link: "",
              logo: "/assets/images/social/telegramColor.png",
            },
            {
              id: uuidv4(),
              name: "twitter",
              link: "",
              logo: "/assets/images/social/twitter.png",
            },
          ],
        },
        {
          id: uuidv4(),
          src: "",
          isNew: true,
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          links: [
            {
              id: uuidv4(),
              name: "vdiarybook",
              link: "",
              logo: "/assets/images/social/vdiarybook.png",
            },
            {
              id: uuidv4(),
              name: "facebook",
              link: "",
              logo: "/assets/images/social/facebook.png",
            },
            {
              id: uuidv4(),
              name: "youtube",
              link: "",
              logo: "/assets/images/social/youtube.png",
            },
            {
              id: uuidv4(),
              name: "telegram",
              link: "",
              logo: "/assets/images/social/telegramColor.png",
            },
            {
              id: uuidv4(),
              name: "twitter",
              link: "",
              logo: "/assets/images/social/twitter.png",
            },
          ],
        },
      ],
    },
  ],
  video: {
    title: "Video",
    desc: "Description",
    type: "video",
    link: "",
  },
  commentsField: {
    title: "Review",
    desc: "Description",
    fields: [
      {
        id: uuidv4(),
        title: "Title",
        items: [
          {
            id: uuidv4(),
            label: "Message",
            type: "textarea",
          },
        ],
      },
    ],
  },
  footer: {
    logo: "",
    desc: " Website is in the process of Experimental Research, Copyright belongs to Blockchain Technology and Education Joint Stock Company.",
    importantLinks: [
      {
        id: uuidv4(),
        label: "Home",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Feature",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Integrations",
        value: "",
      },
      {
        id: uuidv4(),
        label: "About",
        value: "",
      },
      {
        id: uuidv4(),
        label: "User Agreement & Privacy Policy",
        value: "",
      },
    ],
    information: [
      {
        id: uuidv4(),
        label: "Name",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Address",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Phone",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Email",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Business Name",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Business Code",
        value: "",
      },
      {
        id: uuidv4(),
        label: "Agent Name",
        value: "",
      },
    ],
    social: [
      {
        id: uuidv4(),
        name: "vdiarybook",
        link: "",
        logo: "/assets/images/social/vdiarybook.png",
      },
      {
        id: uuidv4(),
        name: "facebook",
        link: "",
        logo: "/assets/images/social/facebook.png",
      },
      {
        id: uuidv4(),
        name: "youtube",
        link: "",
        logo: "/assets/images/social/youtube.png",
      },
      {
        id: uuidv4(),
        name: "telegram",
        link: "",
        logo: "/assets/images/social/telegramColor.png",
      },
      {
        id: uuidv4(),
        name: "twitter",
        link: "",
        logo: "/assets/images/social/twitter.png",
      },
    ],
    aboutUs: {
      title:
        "Start-up project Blockchain platform application in operation management of small and medium enterprises, Crowdfunding, Internal stock management, Digital transformation...",
      copyright: "2024 | All Right Reserved by VZONE TECH",
      items: [
        {
          id: uuidv4(),
          label: "Founder & CEO",
          value: "",
        },
        {
          id: uuidv4(),
          label: "Project Owner",
          value: "",
        },
        {
          id: uuidv4(),
          label: "Project Director",
          value: "",
        },
        {
          id: uuidv4(),
          label: "Chief Technology Officer",
          value: "",
        },
      ],
    },
  },
  reviews: [],
};
