import { ThemeContext } from "context/ThemeContext";
import { useContext, useMemo } from "react";
import CurrencyInput from "react-currency-input-field";
import { twMerge } from "tailwind-merge";

type IProps = {
  transparent?: boolean;
  label?: any;
  className?: string;
  labelClassName?: string;
  inputWrapperClassName?: string;
  wrapperClassName?: string;
  hasError?: boolean;
  errMessage?: string;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  isCurrency?: boolean;
  [key: string]: any;
  suffixIcon?: any;
  suffixClassName?: string;
  labelStyle?: any;
  isBorder?: boolean;
  onValueChange?: (i) => void;
  onChange?: (i) => void;
};

const VInput = ({
  type = "text",
  placeholder = "",
  label,
  name,
  value,
  className,
  disabled = false,
  direction = "vertical",
  iconLeft,
  iconRight,
  transparent = false,
  labelClassName = "",
  inputWrapperClassName = "",
  wrapperClassName = "",
  hasError = false,
  errMessage = "",
  inputStyle,
  isCurrency,
  suffixIcon,
  suffixClassName,
  labelStyle,
  isBorder = true,
  onChange,
  onValueChange,
  ...props
}: IProps) => {
  const { themesSetting } = useContext(ThemeContext);

  const classNameCustom = useMemo(() => {
    if (isBorder) return "border-[0.5px] rounded p-2";
    return "";
  }, [isBorder]);

  return (
    <div
      className={twMerge(`w-full bg-transparent`, wrapperClassName)}
      style={inputStyle ? inputStyle : themesSetting?.input}
    >
      {label && (
        <div
          className={twMerge(
            "text-md mb-1 text-left font-medium",
            labelClassName
          )}
          style={labelStyle || {}}
        >
          {label}
        </div>
      )}
      {isCurrency && (
        <div className="relative w-full h-full">
          <CurrencyInput
            className={`bg-transparent flex items-center rounded p-2 border-[0.5px] focus-visible:!border focus-visible:!outline-none  ${className}`}
            name={name}
            placeholder={placeholder}
            decimalsLimit={2}
            value={value}
            onValueChange={(value, name, values) =>
              onValueChange && onValueChange(values.float)
            }
          />
          {suffixIcon && (
            <div
              className={`absolute inset-y-0 right-0 flex items-center pr-3 ${suffixClassName}`}
            >
              {suffixIcon}
            </div>
          )}
        </div>
      )}
      {type === "textarea" ? (
        <textarea
          name={name}
          id={name}
          {...props}
          style={{ ...themesSetting?.input, ...inputStyle }}
          onChange={onChange}
          value={value}
          className={`flex bg-transparent w-full items-center focus-visible:!border focus-visible:!outline-none ${classNameCustom} ${className}`}
          placeholder={placeholder}
          disabled={disabled}
          cols={30}
          rows={5}
        ></textarea>
      ) : (
        <>
          {!isCurrency && (
            <div
              className={twMerge(
                "relative flex items-center gap-x-2 w-full h-full",
                inputWrapperClassName
              )}
              style={inputStyle}
            >
              {iconLeft && (
                <div className="flex items-center justify-center">
                  {iconLeft}
                </div>
              )}
              <input
                {...props}
                style={{ ...themesSetting?.input, ...inputStyle }}
                type={type}
                onChange={onChange}
                value={value}
                className={`flex bg-transparent w-full items-center focus-visible:!border focus-visible:!outline-none ${classNameCustom} ${className}`}
                placeholder={placeholder}
                disabled={disabled}
              />
              {iconRight && (
                <div className="flex items-center justify-center">
                  {iconRight}
                </div>
              )}
              {suffixIcon && (
                <div
                  className={`absolute top-0 inset-y-0 right-3 flex items-center ${suffixClassName}`}
                >
                  {suffixIcon}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {(hasError || !!errMessage) && (
        <div className="flex items-center w-full text-xs text-red-500">
          {errMessage}
        </div>
      )}
    </div>
  );
};

export default VInput;
