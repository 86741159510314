import React from "react";
import Nfts from "./nfts";
import { Switch, Route } from "react-router-dom";
import { ROUTES } from "config/routes";
import NftDetails from "./nfts/components/details";

const Shop = () => {
  return (
    <div className="w-full">
      <Switch>
        <Route path={ROUTES.SHOP_DETAIL} component={NftDetails} />
        <Route path={"/"} component={Nfts} />
      </Switch>
    </div>
  );
};

export default Shop;
