/* eslint-disable jsx-a11y/alt-text */
import VButton from "components/v-core/v-button";
import { useContext } from "react";
import { ThemeContext } from "context/ThemeContext";

const Step0 = ({ dataPayment, nextStep, setDataByKey, setDataPayment }) => {
  const currencies = [
    {
      name: "vnd",
      icon: "/assets/images/coins/vnd.png",
    },
  ];

  const { themesSetting } = useContext(ThemeContext);

  return (
    <div style={themesSetting?.card} className="flex flex-col p-5  shadow-lg ">
      <div className="flex justify-center">
        {currencies.map((c, key) => (
          <VButton
            key={key}
            className={`p-2 opacity-60 !font-semibold ${
              dataPayment.currency === c.name ? "opacity-100" : "opacity-40"
            }`}
            onClick={() => {
              setDataPayment((dataPayment) => {
                return {
                  ...dataPayment,
                  network: c.name === "vnd" ? "bank" : "stock",
                  currency: c.name,
                };
              });
            }}
          >
            <img src={c.icon} className="mr-[3px] h-5" />
            {c.name.toUpperCase()}
          </VButton>
        ))}
      </div>
      <div className="pt-[25px] flex flex-col w-full  items-center justify-center">
        <VButton
          radius="full"
          py="2"
          className="!w-[300px]"
          onClick={() => nextStep()}
          disabled={!dataPayment.currency}
        >
          Continue
        </VButton>
      </div>
    </div>
  );
};

export default Step0;
