/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import qs from "query-string";

export default function useQuery() {
  const [query, setQuery] = useState<any>({});

  useEffect(() => {
    try {
      setQuery(qs.parse(window.location.search));
    } catch {
      setQuery({});
    }
  }, [window.location.search]);

  return query;
}
