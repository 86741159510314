import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Container = styled.div`
  margin-top: 70px;
  background-color: ${(props) => props.theme.colors.black100};
  padding: 14px;
  overflow: auto;
  height: 100%;
  min-height: 500px;
  {h-full min-h-full mt-[70px] p-[14px] overflow-auto}
  th {
    white-space: nowrap;
  }

  th,
  td,
  tr {
    text-align: center;
    width: fit-content;
    text-overflow: ellipsis;
  }

  button {
    margin: 1px 2px;
    padding: 0px 15px;
    white-space: nowrap;
  }
`;

export const TextColor = styled.span<{ color: string }>`
  color: ${(props) => props.theme.colors[props.color]};
`;

export const Wrapper = styled.div`
  margin-top: 15px;
  padding: 15px;
  max-height: ${isMobile ? "300px" : "500px"};
  overflow: auto;
  {max-h-[300px] md:max-h-[500px] mt-[15px] p-[15px] overflow-auto}
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  {flex justify-center w-full}

  > div {
    width: 48%;
  }

  input {
    display: block;
    width: 100%;
  }
`;

export const FieldTokenomics = styled.div<{ err?: boolean }>`
  position: relative;
  font-size: 14px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.err ? props.theme.colors.red100 : "white")};
  {relative flex items-center text-[14px] pb-[10px]}
`;

export const Tokenomics = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  padding: 0 20px;
  {flex justify-between mt-[16px] px-[20px]}

  > div {
    width: 48%;
  }
`;

export const AddField = styled.div<{ bgColor?: string }>`
  width: 30px;
  margin-right: 20px;
  border: 0.1px solid;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  background-color: ${(props) =>
    props.theme.colors[props.bgColor] || "transparent"};
    {w-[30px] mr-[20px] border-[0.1px] cursor-pointer rounded-md text-center bg-transparent hover:bg-blue-500}

  &:hover {
    background-color: ${(props) => props.theme.colors.blue300};
  }
`;

export const ErrorTk = styled.p`
  position: absolute;
  bottom: -20px;
  font-size: 10px;
  color: ${(props) => props.theme.colors.red100};
  {absolute -bottom-[20px] text-[10px] text-red-500}
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled.p<{ active: boolean; color?: string }>`
  font-size: 17px;
  margin-right: 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props.color || "white"}` : ""};
  font-weight: ${(props) => (props.active ? "bold" : "500")};
`;

export const ActionTable = styled.div`
  white-space: nowrap;
`;

export const CreateProject = styled.div`
  position: fixed;
  cursor: pointer;
  bottom: ${isMobile ? "130px" : "100px"};
  right: 24px;
  width: 60px;
  height: 60px;
  background-color: white;
  padding: 18px;
  border-radius: 50%;
  {fixed right-[24px] bottom-[130px] md:bottom-[100px] w-[60px] h-[60px] bg-white border rounded-full p-[18px] cursor-pointer}

  img {
    width: 100%;
    border-radius: 4px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const HoldingSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  {flex items-center mb-[10px]}

  label {
    margin-right: 20px;
    font-size: 14px;
    {mr-[20px] text-[14px]}
  }
`;

export const Back = styled.div`
  padding-bottom: 20px;
  cursor: pointer;
`;

export default Container;
