/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { formatCurrencyByType } from "utils/common";
import { MasterContext } from "context/MasterContext";

type IMarket = {
  token: string;
};

const Market = ({ token }: IMarket) => {
  const { stocks, stocksPrice, rate } = useContext(MasterContext);
  const [market, setMarket] = React.useState({
    logo: null,
    isStock: false,
    price: null,
  });

  // useEffect(() => {
  //   const wss = new WebSocket(
  //     `wss://stream.binance.com:9443/ws/${token}usdt@trade`
  //   );
  //   wss.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     setMarket(data);
  //   };
  //   return () => {
  //     wss.close();
  //   };
  // }, []);

  useEffect(() => {
    if (stocks) {
      const stock = stocks.find((a) => a.symbol === token);
      if (stock)
        setMarket({
          ...stock,
          price: stocksPrice[token] ? stocksPrice[token]["vnd"] : `N/A`,
          isStock: true,
        });
    }
  }, [stocks, token, stocksPrice]);

  return (
    <div className="flex justify-center items-center scroll-mr-[40px] gap-x-1">
      <img
        src={market.logo || `/logo32.png`}
        className="scroll-mr-[6px] w-[20px] h-[20px] rounded-full object-contain"
        alt={token}
      />
      <span className="align-middle">
        {token.toUpperCase()}:{" "}
        {!!+market?.price ? (
          !market.isStock ? (
            formatCurrencyByType(+market?.price)
          ) : (
            <span>
              {formatCurrencyByType(+market?.price, "vi-VN", "VND")}{" "}
              <span>({formatCurrencyByType(+market?.price / +rate)})</span>
            </span>
          )
        ) : (
          "N/A"
        )}
      </span>
    </div>
  );
};

export default Market;
