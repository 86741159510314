import { DATA_ESTOCKS_DEFAULT } from "config/constant";
import { ThemeContext } from "context/ThemeContext";
import React, { useContext, useEffect, useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Banner from "./components/sections/Banner";
import Cerificate from "./components/sections/Cerificate";
import Documents from "./components/sections/Documents";
import Fundamentals from "./components/sections/Fundamentals";
import Partners from "./components/sections/Partners";
import Profit from "./components/sections/Profit";
import Roadmaps from "./components/sections/Roadmaps";
import Teams from "./components/sections/Teams";
import Tokenomics from "./components/sections/Tokenommics";
import Video from "./components/sections/Video";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
import { toast } from "react-toastify";
import Review from "./components/sections/Review";
import SettingMenu from "./components/setting-menu";
import YourStockService from "api/yourEstock";

const ProjectLandingPage = (props: any) => {
  const { themesSetting, theme } = useContext(ThemeContext);
  const params: any = useParams();
  const location: any = useLocation();

  const [token, setToken] = useState<any>(null);
  const [dataEStocks, setDataEStock] = useState(DATA_ESTOCKS_DEFAULT);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [required, setRequired] = useState({
    docs: false,
    partners: false,
    teams: false,
  });

  useEffect(() => {
    const { token, isEdit } = queryString.parse(location.search);
    if (isEdit === "true") {
      setIsEdit(true);
      setToken(token);
    } else {
      setIsEdit(false);
    }
    getYourEStock();
  }, [location]);

  const getYourEStock = async () => {
    try {
      const res: any = await YourStockService.getYourEstock(params.symbol);
      if (res.estock_info) {
        setDataEStock(res.estock_info);
      }
    } catch (error) {}
  };

  const handleMoveSection = (type: string, index: number) => {
    const newArr = [...dataEStocks?.settingMenu?.sections];
    const el = newArr[index];
    if (type === "up") {
      newArr[index] = newArr[index - 1];
      newArr[index - 1] = el;
    } else {
      newArr[index] = newArr[index + 1];
      newArr[index + 1] = el;
    }
    setDataEStock((prev) => ({
      ...prev,
      settingMenu: {
        ...prev.settingMenu,
        sections: newArr,
      },
    }));
  };

  const checkRequiredSection = (state: any, key: string, type?: string) => {
    if (type === "create") {
      const _required = (state || []).some((st) => {
        const found = (st.items || []).find((e) => !e.file && e.isNew);
        if (found) {
          return true;
        } else {
          return false;
        }
      });

      if (state.length === 0 || _required) {
        //   setIsLoading(false);
        //   toast.error("Please enter all required fields");
        //   setRequired((prev) => ({
        //     ...prev,
        //     [key]: true,
        //   }));
        //   return true;
        // } else {
        return false;
      }
    } else {
      const found = (state || []).some((e) => !e.file && e.isNew);
      if (state?.length === 0 || found) {
        setIsLoading(false);
        toast.error("Please enter all required fields");
        setRequired((prev) => ({
          ...prev,
          [key]: true,
        }));
        return true;
      } else {
        return false;
      }
    }
  };

  const onBlurDocument = (
    e: any,
    type: string,
    name: string,
    index?: number,
    uuid?: string
  ) => {
    setDataEStock((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: e.target.innerText,
      },
    }));
  };

  const renderSection = (
    sectionName: string,
    title: string,
    desc: string,
    children?: React.ReactNode,
    index?: number
  ) => {
    return (
      <div
        className="relative w-full flex flex-col items-center gap-y-5"
        style={renderBgColor(index)}
      >
        <div className="flex flex-col items-center justify-center max-w-[50vh]">
          <span
            className="text-xl font-bold text-center"
            contentEditable={isEdit}
            onBlur={(e) => onBlurDocument(e, sectionName, "title")}
          >
            {title}
          </span>
          <div className="w-10 border-b-2 m-0" />
          <span
            className="text-sm opacity-70 text-center mt-2"
            contentEditable={isEdit}
            onBlur={(e) => onBlurDocument(e, sectionName, "desc")}
          >
            {desc}
          </span>
        </div>
        {isEdit && (
          <div className="absolute top-2 right-3 flex gap-x-3">
            {index > 0 && (
              <VButton
                className="w-8 h-8"
                radius="full"
                iconLeft={<Icons iconName="arrow-up" />}
                onClick={() => handleMoveSection("up", index)}
              />
            )}
            {index < dataEStocks?.settingMenu?.sections?.length - 1 && (
              <VButton
                className="w-8 h-8"
                radius="full"
                iconLeft={<Icons iconName="arrow-down" />}
                onClick={() => handleMoveSection("down", index)}
              />
            )}
          </div>
        )}
        {children}
      </div>
    );
  };

  const renderBgColor = (index: number) => {
    if (index % 2 === 0) {
      if (
        dataEStocks?.settingMenu?.colors[theme]?.section2?.via ||
        dataEStocks?.settingMenu?.colors[theme]?.section2?.to
      )
        return {
          backgroundImage: `linear-gradient(to right, ${dataEStocks?.settingMenu?.colors[theme]?.section2?.from},${dataEStocks?.settingMenu?.colors[theme]?.section2?.via}, ${dataEStocks?.settingMenu?.colors[theme]?.section2?.to})`,
          color: dataEStocks?.settingMenu?.colors[theme]?.section2?.text,
        };
      else
        return {
          backgroundColor:
            dataEStocks?.settingMenu?.colors[theme]?.section2?.from,
          color: dataEStocks?.settingMenu?.colors[theme]?.section2?.text,
        };
    } else {
      if (
        dataEStocks?.settingMenu?.colors[theme]?.section1?.via ||
        dataEStocks?.settingMenu?.colors[theme]?.section1?.to
      )
        return {
          backgroundImage: `linear-gradient(to right, ${dataEStocks?.settingMenu?.colors[theme]?.section1?.from},${dataEStocks?.settingMenu?.colors[theme]?.section1?.via}, ${dataEStocks?.settingMenu?.colors[theme]?.section1?.to})`,
          color: dataEStocks?.settingMenu?.colors[theme]?.section1?.text,
        };
      else
        return {
          backgroundColor:
            dataEStocks?.settingMenu?.colors[theme]?.section1?.from,
          color: dataEStocks?.settingMenu?.colors[theme]?.section1?.text,
        };
    }
  };

  const handleSubmit = async () => {
    try {
      if (checkRequiredSection(dataEStocks?.docs, "docs", "create")) {
        return;
      }
      if (checkRequiredSection(dataEStocks?.partners, "partners", "create")) {
        return;
      }
      if (checkRequiredSection(dataEStocks?.teams, "teams", "create")) {
        return;
      }
      setIsLoading(true);
      const res: any = await YourStockService.updateEstock({
        symbol: params.symbol,
        token: token,
        body: { ...dataEStocks },
      });
      if (res) {
        setIsLoading(false);
        // setIsEdit(false);
        toast.success("Update successfull!");
        getYourEStock();
      }
    } catch (error) {}
  };

  return (
    <div
      className="relative min-w-screen min-h-screen"
      style={{
        ...themesSetting.layout,
      }}
    >
      {isEdit && (
        <div className="absolute bottom-16 left-2 z-50">
          <VButton
            className="w-28"
            radius="full"
            loading={isLoading}
            iconLeft={<Icons iconName="save" />}
            onClick={handleSubmit}
          >
            Save
          </VButton>
        </div>
      )}
      <div
        className="w-full flex max-lg:flex-col-reverse justify-between items-center px-28 max-lg:px-2 py-2 gap-y-3"
        style={
          dataEStocks?.settingMenu?.colors[theme]?.header?.via ||
          dataEStocks?.settingMenu?.colors[theme]?.header?.to
            ? {
                backgroundImage: `linear-gradient(to right, ${dataEStocks?.settingMenu?.colors[theme]?.header?.from},${dataEStocks?.settingMenu?.colors[theme]?.header?.via}, ${dataEStocks?.settingMenu?.colors[theme]?.header?.to})`,
                color: dataEStocks?.settingMenu?.colors[theme]?.header?.text,
              }
            : {
                backgroundColor:
                  dataEStocks?.settingMenu?.colors[theme]?.header?.from,
                color: dataEStocks?.settingMenu?.colors[theme]?.header?.text,
              }
        }
      >
        <Header data={dataEStocks} setData={setDataEStock} isEdit={isEdit} />
      </div>
      <div className="w-full flex flex-col items-center *:px-28 *:py-16 max-lg:*:px-2 max-lg:*:py-2 odd:*:bg-[#1F2937] first:pt-0 last:pb-[55px] h-[calc(100vh-88px)] overflow-y-auto pb-[55px]">
        <Banner
          data={dataEStocks}
          setData={setDataEStock}
          isEdit={isEdit}
          style={
            dataEStocks?.settingMenu?.colors[theme]?.banner?.via ||
            dataEStocks?.settingMenu?.colors[theme]?.banner?.to
              ? {
                  backgroundImage: `linear-gradient(to right, ${dataEStocks?.settingMenu?.colors[theme]?.banner?.from},${dataEStocks?.settingMenu?.colors[theme]?.banner?.via}, ${dataEStocks?.settingMenu?.colors[theme]?.banner?.to})`,
                  color: dataEStocks?.settingMenu?.colors[theme]?.banner?.text,
                }
              : {
                  backgroundColor:
                    dataEStocks?.settingMenu?.colors[theme]?.banner?.from,
                  color: dataEStocks?.settingMenu?.colors[theme]?.banner?.text,
                }
          }
        />
        {dataEStocks?.settingMenu?.sections?.map((el, index) => (
          <>
            {el?.key === "profit" && el?.isShow && (
              <>
                {renderSection(
                  "profit",
                  dataEStocks.profit?.title,
                  dataEStocks.profit?.desc,
                  <Profit />,
                  index
                )}
              </>
            )}
            {el?.key === "fundamentals" && el?.isShow && (
              <>
                {renderSection(
                  "fundamentals",
                  dataEStocks.fundamentals?.title,
                  dataEStocks.fundamentals?.desc,
                  <Fundamentals
                    data={dataEStocks}
                    setData={setDataEStock}
                    isEdit={isEdit}
                  />,
                  index
                )}
              </>
            )}
            {el?.key === "roadmaps" && el?.isShow && (
              <>
                {renderSection(
                  "roadmaps",
                  dataEStocks.roadmaps?.title,
                  dataEStocks.roadmaps?.desc,
                  <Roadmaps
                    data={dataEStocks}
                    setData={setDataEStock}
                    isEdit={isEdit}
                  />,
                  index
                )}
              </>
            )}
            {el?.key === "tokenomics" && el?.isShow && (
              <>
                {renderSection(
                  "tokenomics",
                  dataEStocks.tokenomics?.title,
                  dataEStocks.tokenomics?.desc,
                  <Tokenomics
                    data={dataEStocks}
                    setData={setDataEStock}
                    isEdit={isEdit}
                  />,
                  index
                )}
              </>
            )}
            {el?.key === "docs" && el?.isShow && (
              <>
                <Documents
                  data={dataEStocks}
                  setData={setDataEStock}
                  isEdit={isEdit}
                  handleMoveSection={handleMoveSection}
                  index={index}
                  required={required}
                  checkRequiredSection={checkRequiredSection}
                  style={renderBgColor(index)}
                />
              </>
            )}
            {el?.key === "certificates" && el?.isShow && (
              <>
                {renderSection(
                  "certificates",
                  dataEStocks.certificates?.title,
                  dataEStocks.certificates?.desc,
                  <Cerificate
                    data={dataEStocks}
                    setData={setDataEStock}
                    isEdit={isEdit}
                  />,
                  index
                )}
              </>
            )}
            {el?.key === "partners" && el?.isShow && (
              <>
                <Partners
                  data={dataEStocks}
                  setData={setDataEStock}
                  isEdit={isEdit}
                  handleMoveSection={handleMoveSection}
                  index={index}
                  required={required}
                  checkRequiredSection={checkRequiredSection}
                  style={renderBgColor(index)}
                />
              </>
            )}
            {el?.key === "teams" && el?.isShow && (
              <>
                <Teams
                  data={dataEStocks}
                  setData={setDataEStock}
                  isEdit={isEdit}
                  handleMoveSection={handleMoveSection}
                  index={index}
                  required={required}
                  checkRequiredSection={checkRequiredSection}
                  style={renderBgColor(index)}
                />
              </>
            )}
            {el?.key === "video" && el?.isShow && (
              <>
                {renderSection(
                  "video",
                  dataEStocks.video?.title,
                  dataEStocks.video?.desc,
                  <Video
                    data={dataEStocks}
                    setData={setDataEStock}
                    isEdit={isEdit}
                  />,
                  index
                )}
              </>
            )}
            {el?.key === "commentsField" && el?.isShow && (
              <>
                {renderSection(
                  "commentsField",
                  dataEStocks.commentsField?.title,
                  dataEStocks.commentsField?.desc,
                  <Review
                    data={dataEStocks}
                    setData={setDataEStock}
                    isEdit={isEdit}
                  />,
                  index
                )}
              </>
            )}
          </>
        ))}
        <div
          className="flex flex-col gap-y-4 w-full"
          style={
            dataEStocks?.settingMenu?.colors[theme]?.footer?.via ||
            dataEStocks?.settingMenu?.colors[theme]?.footer?.to
              ? {
                  backgroundImage: `linear-gradient(to right, ${dataEStocks?.settingMenu?.colors[theme]?.footer?.from},${dataEStocks?.settingMenu?.colors[theme]?.footer?.via}, ${dataEStocks?.settingMenu?.colors[theme]?.footer?.to})`,
                  color: dataEStocks?.settingMenu?.colors[theme]?.footer?.text,
                }
              : {
                  backgroundColor:
                    dataEStocks?.settingMenu?.colors[theme]?.footer?.from,
                  color: dataEStocks?.settingMenu?.colors[theme]?.footer?.text,
                }
          }
        >
          <Footer data={dataEStocks} setData={setDataEStock} isEdit={isEdit} />
        </div>
      </div>
      {isEdit && (
        <SettingMenu
          data={dataEStocks}
          setData={setDataEStock}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};

export default ProjectLandingPage;
