/* eslint-disable max-len */
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import TopHeader from "layouts/MainLayout/top-header";

import UploadFile from "components/v-upload";
import {
  AiOutlineHome,
  AiOutlineTeam,
  AiOutlineFundProjectionScreen,
  AiOutlineCloseSquare,
} from "react-icons/ai";
import { RiRoadMapLine } from "react-icons/ri";
import { FaInfo, FaPeopleArrows, FaRegNewspaper } from "react-icons/fa";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import { isMobile } from "react-device-detect";
import Icons from "helper/icons";

interface IProps {
  [key: string]: any;
}

const View = (props: IProps) => {
  const { role, isEdit, urlLogo, listFiles, setFile, main, color } = props;
  const prams: any = useParams();
  const { themesSetting, logos } = useContext(ThemeContext);

  const listMenuDefault = [
    {
      icon: <AiOutlineHome />,
      title: "Home",
      path: "home",
    },
    {
      icon: <FaInfo size={13} />,
      title: "About",
      path: "about",
    },
    {
      icon: <AiOutlineFundProjectionScreen />,
      title: "Projects",
      path: "projects",
    },
    {
      icon: <RiRoadMapLine />,
      title: "Roadmap",
      path: "roadmap",
    },
    {
      icon: <MdOutlineGeneratingTokens />,
      title: main ? "NFTs" : "Tokenomic",
      path: main ? "nfts" : "tokenomic",
    },
    {
      icon: <FaPeopleArrows />,
      title: "Partner",
      path: "partner",
    },
    {
      icon: <AiOutlineTeam />,
      title: "Team",
      path: "team",
    },
    {
      icon: <FaRegNewspaper />,
      title: "Whitepaper",
      path: `whitepaper`,
    },
  ];

  const [listMenu, setListMenu] = useState<any>(listMenuDefault);

  const history = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { handleSwitchTheme, theme } = useContext(ThemeContext);

  const goToApp = (path: string) => {
    try {
      if (main) {
        history.push(path);
      } else {
        if (path === "/auth/login" || path === "/auth/register") {
          history.push(path);
        } else if (path === "whitepaper") {
          history.push(`/${role}/${prams.symbol}/whitepaper`);
        } else {
          !isEdit ? history.push(`/${role}/${prams.symbol}`) : console.log("");
        }
      }
      setIsOpen(false);
      document.getElementById(path).scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    } catch (error) {
      setIsOpen(false);
    }
  };

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!main) {
      setListMenu((prev) => prev.filter((el: any) => el.title !== "Projects"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="fixed top-0 w-full h-[30px] flex items-center z-50"
        style={
          color
            ? {
                backgroundColor: color?.bgColorHeader,
                color: color?.textColor,
              }
            : themesSetting.layout
        }
      >
        <TopHeader />
      </div>
      <div
        style={
          color
            ? {
                backgroundColor: color?.bgColorHeader,
                color: color?.textColor,
              }
            : themesSetting.layout
        }
        className="fixed top-[30px] w-full lg:h-[78px] px-[30px] py-[20px] z-50 h-[56px]"
      >
        <div className="flex justify-between items-center h-full max-[1100px]:justify-center">
          <div
            className="relative flex items-center w-[227.75px] h-[62px] cursor-pointer max-[1100px]:justify-center max-[1100px]:w-[150px] max-[1100px]:h-[40px]"
            onClick={() => goToApp("/")}
          >
            {isEdit && (
              <div
                className={`absolute max-w-full h-full w-auto ${
                  Boolean(!listFiles && urlLogo) ? "opacity-0" : "opacity-100"
                }`}
              >
                <UploadFile
                  id="logo"
                  ratio="227x62"
                  file={listFiles}
                  setFile={setFile}
                  radius="md"
                />
              </div>
            )}
            {main ? (
              <img
                className="max-w-full max-h-[62px] max-[1100px]:max-h-[40px]"
                src={
                  isMobile
                    ? String(
                        logos?.logoResponsive ||
                          "https://vzonex.s3.ap-southeast-1.amazonaws.com/admin/unknown/bdc09f6a-0891-4e42-98ff-8704d7ac4c09.png"
                      )
                    : String(
                        logos?.logo ||
                          "https://vzonex.s3.ap-southeast-1.amazonaws.com/admin/unknown/65790c90-c046-4a40-8307-161e29ed3070.png"
                      )
                }
                alt="Vzonex.com"
              />
            ) : (
              <img
                className="max-w-full max-h-[62px] max-[1100px]:max-h-[40px]"
                hidden={listFiles || !urlLogo}
                src={urlLogo}
                alt="logo"
              />
            )}
          </div>
          <div className="hidden items-center justify-around w-[750px] max-[1230px]:w-[600px] ">
            {listMenu.map((item: any, index: number) => (
              <div
                className="flex items-center h-[20px] cursor-pointer overflow-hidden max-[1100px]:p-[10px]"
                style={{ color: color?.textColor }}
                key={index}
                onClick={() => goToApp(item.link || item.path)}
              >
                {item.id ? (
                  <img className="h-full" src={item.icon} alt="" />
                ) : (
                  item.icon
                )}
                <span
                  className="text-[16px] mx-[3px]"
                  style={{ color: color?.textColor }}
                >
                  {item.title}
                </span>
              </div>
            ))}
          </div>
          <div className="hidden md:flex justify-end items-center gap-x-2">
            <VButton
              radius="full"
              onClick={handleSwitchTheme}
              className="border-[1px] bg-transparent w-9 h-9 mr-3 !border-[#ff9900]"
            >
              {theme === "light" ? (
                <Icons iconName="light" />
              ) : (
                <Icons iconName="dark" />
              )}
            </VButton>
            <VButton
              radius="full"
              className="px-4 h-9 "
              onClick={() => goToApp("/auth/login")}
            >
              Login
            </VButton>
            <VButton
              type="secondary"
              radius="full"
              className="px-3 h-9"
              onClick={() => goToApp("/auth/register")}
            >
              Register
            </VButton>
          </div>
        </div>
        <div
          className="absolute lg:top-[20px] lg:left-[15px] lg:hidden top-[10px] left-[10px]"
          onClick={handleMenu}
        >
          <img src="/assets/images/menu/1.svg" alt="" />
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed top-0 left-0 max-w-[500px] h-[100vh] p-[20px] z-50"
          style={{
            backgroundColor: color?.bgColorHeader,
            color: color?.textColor,
          }}
        >
          <div
            className="absolute top-0 right-0 cursor-pointer rounded-md z-50"
            onClick={() => setIsOpen(false)}
          >
            <AiOutlineCloseSquare className="rounded-md" size={30} />
          </div>
          <div className="flex items-center cursor-pointer overflow-hidden p-[10px] lg:p-0">
            <div
              className="flex border-2 cursor-pointer rounded-md border-slate-700 w-9 h-7 items-center justify-center mr-3"
              onClick={handleSwitchTheme}
            >
              <img
                src={`/assets/icons/header/${
                  theme === "dark" ? "sun" : "moon"
                }.svg`}
                className="W-full"
                alt=""
              />
            </div>

            {/* <VButton onClick={() => goToApp("/auth/login")}>Login</VButton> */}
            <VButton
              onClick={() => goToApp("/auth/register")}
              className="h-[36px] border"
              radius="full"
              transparent={true}
            >
              Register
            </VButton>
          </div>
          {listMenu.map((item: any, index: number) => (
            <div
              className="flex items-center h-[40px] cursor-pointer overflow-hidden max-[p-[10px] lg:p-0"
              key={index}
              onClick={() => goToApp(item.link || item.path)}
            >
              {item.id ? (
                <img className="h-full" src={item.icon} alt="" />
              ) : (
                item.icon
              )}
              &ensp;<span className="mx-[3px] text-[16px]">{item.title}</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default View;
