import { useRef, useState } from "react";
import {
  BankDepositWrapper,
  CopyBtn,
  BankDepositUploadConfirm,
  BankDepositUploadDragger,
  SubmitButton,
} from "./styles";
import { copyText, formatCurrency } from "utils/common";
import { toast } from "react-toastify";

import APIs from "api";
import Input from "components/v-core/v-input";
import VButton from "components/v-core/v-button";

const Step4 = ({
  setDataByKey,
  canclePayment,
  dataPayment,
  handleSubmit,
  loading,
  backStep,
}) => {
  const { bank, currency, hash } = dataPayment;
  const [file, setFile] = useState();

  const fileRef = useRef<any>();
  const imgRef = useRef<any>();

  const [errUpload, setErrorUpload] = useState<boolean>(false);

  const onUpload = async () => {
    const [f] = fileRef.current.files;
    setFile(f);
    imgRef.current.src = URL.createObjectURL(f);
    try {
      const formData = new FormData();
      formData.append("files", f);

      const res: any = await APIs.UPLOAD.uploadFile({
        body: formData,
      });
      if (res) {
        setErrorUpload(false);
        setDataByKey("proof", res.filePath);
      } else {
        setErrorUpload(true);
      }
    } catch (error) {
      // console.log(error);
      setErrorUpload(true);
    }
  };

  const handleCopy = (tx: string) => () => {
    copyText(tx);
    toast.success("Copied to clipboard!");
  };

  return (
    <div>
      <BankDepositWrapper>
        <p>
          Transfer to {currency === "vnd" ? "account number" : "address"}{" "}
          <span> {bank.account_number}</span>
          <CopyBtn onClick={handleCopy(bank.account_number)}> [copy]</CopyBtn>
        </p>
        <p>
          Network : <span>{bank.network}</span>{" "}
        </p>
        <p>
          {currency === "vnd" ? "Bank account name" : "Wallet"} :{" "}
          <span>{bank.account_name}</span>{" "}
        </p>
        <p>
          Deposit amount{" "}
          <span>
            {" "}
            {formatCurrency(dataPayment.amount)}{" "}
            {String(currency).toUpperCase()}
          </span>
          <CopyBtn onClick={handleCopy(formatCurrency(dataPayment.amount))}>
            {" "}
            [copy]
          </CopyBtn>
        </p>
        <p>***The amount must be exact, including the odd part***</p>
        {currency === "vnd" && (
          <p>
            Transfer content <span>{dataPayment.content}</span>
            <CopyBtn onClick={handleCopy(dataPayment.content)}> [copy]</CopyBtn>
          </p>
        )}
        <p>
          ***The content must be exactly as instructed for the system to
          automatically match***{" "}
        </p>
      </BankDepositWrapper>

      <BankDepositUploadConfirm>
        <p>
          * After you have send the payment, please confirm it was sent and
          update the payment proof so we can process.
        </p>

        {currency !== "vnd" && (
          <Input
            transparent={true}
            label="Trasaction hash"
            placeholder="0x41f5919e4c007450d4eb26e773745f7d916809793dc66b35a9a9cff31e1d8cf6"
            onChange={(e) => setDataByKey("hash", e.target.value)}
          />
        )}

        {currency === "vnd" && (
          <BankDepositUploadDragger htmlFor="proofFile" errUpload={errUpload}>
            {!Boolean(file) ? "Upload" : ""}
            <img hidden={!Boolean(file)} ref={imgRef} alt="Proof" />
          </BankDepositUploadDragger>
        )}

        <input
          hidden={true}
          id="proofFile"
          ref={fileRef}
          type="file"
          className="custom-file-input"
          required
          onChange={onUpload}
        />

        <SubmitButton>
          <VButton
            loading={loading}
            onClick={canclePayment}
            type="outline"
            radius="full"
          >
            Cancel
          </VButton>
          <VButton
            radius="full"
            disabled={
              currency === "vnd" ? !Boolean(file) || errUpload : !Boolean(hash)
            }
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </VButton>
        </SubmitButton>
      </BankDepositUploadConfirm>
    </div>
  );
};

export default Step4;
