import jwt_decode from 'jwt-decode'

const decode = (token: any) => {
  try {
    return jwt_decode(token)
  } catch (error) {
    return null
  }
}

export default decode
