import { request, parseErrorResponse } from "./request";
import queryString from "query-string";

const getAllSettings = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/settings`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSettingByName = (name: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/settings/${name}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCountries = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/locations?type=country`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocations = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/locations/getAll?${queryString.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SettingService = {
  getSettingByName,
  getLocations,
  getAllSettings,
  getCountries,
};

export default SettingService;
