import React, { useContext } from "react";
import Slider from "../v-slider";
import ReactPlayer from "react-player";
import { ThemeContext } from "context/ThemeContext";
import VCountdown from "components/v-countdown";
import { compareDateWithCur } from "utils/times";

type IProps = {
  data?: any;
  isWidthFull?: boolean;
  isFull?: boolean;
  classVideoName?: string;
  countdown?: any;
};

const VBanner = (props: IProps) => {
  const { isWidthFull, data, isFull, classVideoName, countdown } = props;
  const { themesSetting } = useContext(ThemeContext);

  return (
    <div className="relative w-auto h-full justify-center items-center ">
      {countdown && compareDateWithCur(countdown?.time) > 0 && (
        <div
          className="w-full h-[100px] flex flex-col justify-center items-center gap-y-2 !bg-cover !bg-no-repeat"
          style={
            countdown.background
              ? {
                  background: `url(${countdown.background})`,
                }
              : { ...themesSetting.card }
          }
        >
          <span className="text-base font-bold">Project is opening in</span>
          <VCountdown
            date={new Date().getTime() + compareDateWithCur(countdown.time)}
          />
        </div>
      )}
      {data.type === "slide" ? (
        <Slider
          isWidthFull={isWidthFull}
          isFull={isFull}
          data={data.images || data.slide}
        />
      ) : data.type === "image" ? (
        <img className="h-full w-full object-cover" src={data.src} alt="" />
      ) : data.type === "video" ? (
        <div className={classVideoName}>
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            playing={true}
            muted={true}
            loop={true}
            url={data.link}
          />
        </div>
      ) : data.type === "facebook" ? (
        <iframe src={data.link} className="w-full" />
      ) : (
        <img
          className="w-full h-full object-cover rounded-t"
          src={"/assets/images/slide/slide-1.jpg"}
          alt=""
        />
      )}
    </div>
  );
};

export default VBanner;
