/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { UploadKYC, ContainerVisit, NoteUploadInvoice } from "../styles";
import { useContext, useState } from "react";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import Dropdown from "components/v-core/v-dropdown";
import UploadFile from "components/v-upload";
import { Upload } from "pages/home/landing-page/styles";
import Button from "components/v-core/v-button";
import Input from "components/v-core/v-input";
import { MasterContext } from "context/MasterContext";
import KycService from "api/kycs";
import UploadService from "api/uploads";

const kycType = [
  { label: "ID card", value: "id_card" },
  { label: "Passport", value: "passport" },
  { label: "Driving license", value: "driving_license" },
];

const KYC = ({ setStepCurrent }) => {
  const { settings, auth, getUser } = useContext(MasterContext);
  const { objects } = settings;

  console.log({
    auth,
  });

  const [KYC, setKYC] = useState<any>({
    type: "id_card",
    object: null,
  });

  const [isSavingKYC, setIsSavingKYC] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});
  const [idKYC, setIdKYC] = useState();
  const [labelId, setLabelId] = useState(null);

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const onChangeTypeKYC = (value, object) => {
    setKYC((prev) => ({
      ...prev,
      type: value,
    }));
    setLabelId(object.label);
  };

  const onChangeObject = (object) => {
    setKYC((prev) => ({
      ...prev,
      object: object,
    }));
  };

  const changeHandle = (event) => {
    setIdKYC(event.target.value);
  };

  const handleSaveKYC = async () => {
    setIsSavingKYC(true);
    try {
      if (!KYC.object) {
        toast.error("Please choose object!");
        setIsSavingKYC(false);
        return;
      }
      const formData = new FormData();
      Object.keys(listFiles).map((el: any) => {
        formData.append("files", listFiles[el]);
      });
      const listImg: any = await UploadService.uploadMultiFile({
        body: formData,
      });
      if (listImg && listImg?.length > 0) {
        let data = {
          type: KYC?.type || "id_card",
          object: KYC?.object,
        };
        listImg?.map((el: any) => {
          Object.keys(listFiles).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              data = {
                ...data,
                [item]: el?.filePath,
              };
            }
          });
        });
        try {
          const kyc: any = await KycService.createKyc({
            body: {
              ...data,
              method: "kyc",
              idKyc: idKYC,
            },
          });
          if (kyc) {
            toast.success("successfull!");
            setListFiles({});
            setStepCurrent(null);
            getUser();
          }
        } catch (err) {
          toast.error("ID KYC Is Exists");
        }
      }
    } catch (err) {
      toast.error("Something went wrong!");
    }
    setIsSavingKYC(false);
  };

  return (
    <UploadKYC>
      {auth?.status_kyc === "pending_kyc" ? (
        <h5 style={{ color: "orange", textAlign: "center" }}>
          We are verifying your KYC submition. Please stay tuned!
        </h5>
      ) : auth?.status_kyc === "kyc" ? (
        <h5 style={{ color: "#00ad00", textAlign: "center" }}>
          Your account was verified!
        </h5>
      ) : (
        <>
          <div className="grid col-span-2">
            <div className="">
              <Dropdown
                label="Type"
                options={kycType?.map((el: any) => {
                  return {
                    label: el?.label,
                    value: el?.value,
                  };
                })}
                onChange={onChangeTypeKYC}
              />
            </div>
            <div className="mt-2">
              <Dropdown
                label="Object"
                options={(objects || []).map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.id,
                  };
                })}
                onChange={onChangeObject}
              />
            </div>
          </div>
          <Row>
            <div className="mb-3 mt-2">
              <Input
                label={labelId || kycType[0].label}
                wrapperClassName="w-full"
                value={idKYC}
                onChange={(e) => changeHandle(e)}
              />
            </div>
            <Col md={2}></Col>
            <Col md={4}>
              <span>Front</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="front"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["front"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
            <Col md={4}>
              <span>Back</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="back"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["back"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
            <Col md={2}></Col>
          </Row>
          <br />
          {/* <Row>
            <Col md={6}>
              <span>Vdiarybook profile</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="profile_VDB"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["profile_VDB"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
            <Col md={6}>
              <span>Review Vdiarybook on CH-PLAY/APPSTORE</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="appStore_CHPlay"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["appStore_CHPlay"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
          </Row> */}
          <div className="flex justify-center w-full my-4">
            <Button
              className="min-w-40"
              onClick={handleSaveKYC}
              loading={isSavingKYC}
            >
              Save
            </Button>
          </div>
          <NoteUploadInvoice>
            <h6>Note : </h6>
            <p>
              {" "}
              - FRONT : Please upload the front of your ID card or driver's
              license or passport or student card
            </p>
            <p>
              {" "}
              - BACK : Please upload the back of your ID card or driver's
              license or passport or student card
            </p>
            {/* <p>
              {" "}
              - VDIARYBOOK PROFILE : Please upload your account screen of social
              network Vdiarybook
            </p>
            <p>
              {" "}
              - REVIEWS ON THE APP : Access the appstore Apple Store, CH Play
              evaluate and feel about the social network vdiarybook. Upload
              review screen
            </p> */}
          </NoteUploadInvoice>
        </>
      )}
    </UploadKYC>
  );
};

export default KYC;
