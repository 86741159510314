import useQuery from "hooks/useQuery";
import AuthAPI from "api/auth";
import { ROUTES } from "config/routes";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const useSSO = (props, setLoading) => {
  const query = useQuery();
  const history = useHistory();

  const checkLogin = async () => {
    try {
      if (
        props.match.params["callback_type"] === "token-callback" &&
        query.token
      ) {
        setLoading(true);

        // const showError = () => {
        //   toast.error(
        //     "Your account  have not completed KYC yet. Please complete your KYC!"
        //   );
        // };

        if (query.provider === "vzonex") {
          const res: any = await AuthAPI.login(
            {},
            {
              provider: "vzonex",
              providerToken: query.token,
            }
          );
          localStorage.setItem("auth", res.token);
          history.push(ROUTES.DASHBOARD);
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.errors || "Register failed!");
    }
  };

  return {
    checkLogin,
  };
};

export default useSSO;
