import { Helmet } from "react-helmet";

export default function View() {
  //   const testID = '105475828881866'; // Bong da
  // const mainID = "105143515219728"; // Vzonex
  const mainID = "101194846036816"; // Vzonex Project
  const isEnable = false;

  if (!isEnable) {
    return <></>;
  }

  return (
    <Helmet>
      <script>
        {`
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "${mainID}");
            chatbox.setAttribute("attribution", "biz_inbox");
          `}
      </script>
      <script>
        {`
            window.fbAsyncInit = function() {
              FB.init({
                xfbml: true,
                version: 'v14.0'
              });
            };
    
            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
          `}
      </script>
    </Helmet>
  );
}
