/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import APIs from "api";
import AvatarProfile from "../components/AvatarProfile";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";
// import VInput from "components/v-core/v-input";
// import VDropdown from "components/v-core/v-dropdown";
// import VLocation from "components/v-locations";
import Icons from "helper/icons";
import { renderStatus } from "utils/render";
import VDropdown from "components/v-core/v-dropdown";
import VLocation from "components/v-locations";
import UserService from "api/users";

const Info = () => {
  const { settings, auth, getUser } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const { objects, groups } = settings;
  const profile = auth;
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});

  const [infoUser, setInfoUser] = useState({
    fullName: profile.fullName,
    phone: profile.phone,
    email: profile.email,
    visit_card: profile.visit_card,
    object_type: profile.object_type,
    group: profile.group,
  });

  const [address, setAddress] = useState<any>({
    country: null,
    province: null,
    district: null,
    ward: null,
  });

  useEffect(() => {
    if (profile && profile.address) {
      setAddress(profile.address);
    }
  }, [profile]);

  const onClickEdit = () => {
    setIsEdit(!isEdit);
    setListFiles({});
  };

  const editProfile = (value, key: string) => {
    console.log(value, key);
    setInfoUser({
      ...infoUser,
      [key]: value,
    });
  };

  const handleEdit = async () => {
    setLoading(true);
    let data = {
      ...infoUser,
      address,
    };
    if (Object.keys(listFiles).length > 0) {
      const formData = new FormData();
      Object.keys(listFiles).map((el: any) => {
        formData.append("files", listFiles[el]);
      });
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      if (listImg?.length > 0) {
        await Promise.all(
          listImg?.map((el: any) => {
            Object.keys(listFiles).map((item: any, indexKey: number) => {
              if (el.id === indexKey) {
                data = {
                  ...data,
                  visit_card: {
                    ...data.visit_card,
                    [item]: el?.filePath,
                  },
                };
              }
            });
          })
        );
        setListFiles({});
      }
    }
    const res = await UserService.editUser({
      params: {
        id: profile._id,
      },
      body: data,
    });
    if (res) {
      setIsEdit(false);
      setLoading(false);
      getUser();
      toast.success("Update success!");
    } else {
      toast.error("Error");
      setLoading(false);
    }
  };

  const objectName = useMemo(() => {
    if (profile && objects) {
      const _object = objects.find((o) => o.id === profile.object_type);
      return _object ? _object.name : null;
    }
  }, [profile, objects]);

  const groupName = useMemo(() => {
    if (profile && groups) {
      const _object = groups.find((o) => o.index === profile.group);
      return _object ? _object.name : null;
    }
  }, [profile, groups]);

  return (
    <>
      <div
        className="relative flex !pt-14 w-full md:p-2 rounded-md mb-14"
        style={themesSetting?.card}
      >
        {!isEdit && (
          <div className="absolute top-4 right-4 bg-">
            <VButton
              className="px-4 h-8"
              onClick={onClickEdit}
              iconLeft={<Icons iconName="edit" />}
            >
              Edit
            </VButton>
          </div>
        )}

        <div className="flex flex-col w-full">
          <div className="mb-2 w-full">
            <AvatarProfile profile={profile} />
          </div>
          <hr className="w-full border-b border-dashed" />
          <div className="p-2 w-full flex flex-col gap-y-3 ">
            <div className="flex items-center py-2">
              <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                <Icons iconName="name" />
                <span className="opacity-60">Name</span>
              </div>
              <div
                contentEditable={isEdit}
                className={`${
                  isEdit ? "border-b" : ""
                } w-[70%] overflow-hidden text-ellipsis outline-none`}
                onBlur={(e) => editProfile(e, "fullName")}
              >
                {profile?.fullName}
              </div>
            </div>
            <div className="flex items-center py-2">
              <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                <Icons iconName="object" />
                <span className="opacity-60">Object</span>
              </div>
              {isEdit ? (
                <div className="w-[70%]">
                  <VDropdown
                    value={infoUser.object_type}
                    options={objects?.map((el: any) => {
                      return {
                        label: el?.name,
                        value: el?.id,
                      };
                    })}
                    onChange={(e) => editProfile(e, "object_type")}
                    customClassName="!w-full !bg-transparent rounded"
                    optionClassName="!pl-0"
                  />
                </div>
              ) : (
                <div className="w-[70%] overflow-hidden text-ellipsis">
                  {objectName}
                </div>
              )}
            </div>
            <div className="flex items-center py-2">
              <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                <Icons iconName="group" />
                <span className="opacity-60">Group</span>
              </div>
              {isEdit ? (
                <div className="w-[70%]">
                  <VDropdown
                    customClassName="!bg-transparent !w-full"
                    optionClassName="!pl-0"
                    options={groups?.map((el: any) => {
                      return {
                        label: el?.name,
                        value: el?.index,
                      };
                    })}
                    value={infoUser.group}
                    onChange={(e) => editProfile(e, "group")}
                  />
                </div>
              ) : (
                <div className="w-[70%] overflow-hidden text-ellipsis">
                  {groupName}
                </div>
              )}
            </div>
            {!isEdit && (
              <div className="flex items-center py-2">
                <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                  <Icons iconName="role" />
                  <span className="opacity-60">Role</span>
                </div>
                <div className="w-[70%] overflow-hidden text-ellipsis">
                  {profile?.role}
                </div>
              </div>
            )}
            <div className="flex items-center py-2">
              <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                <Icons iconName="phone" />
                <span className="opacity-60">Phone</span>
              </div>
              <div
                contentEditable={isEdit}
                onBlur={(e) => editProfile(e, "phone")}
                className={`${
                  isEdit ? "border-b" : ""
                } w-[70%] overflow-hidden text-ellipsis outline-none`}
              >
                {profile?.phone}
              </div>
            </div>
            <div className="flex items-center py-2">
              <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                <Icons iconName="email" />
                <span className="opacity-60">Email</span>
              </div>
              <div
                className={`${
                  isEdit && "opacity-70"
                } w-[70%] overflow-hidden text-ellipsis`}
              >
                {profile?.email}
              </div>
            </div>
            <div className="flex items-center py-2">
              <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                <Icons iconName="address" />
                <span className="opacity-60">Address</span>
              </div>
              <div className="w-[70%] overflow-hidden text-ellipsis">
                {address?.detail}
              </div>
            </div>
            {!isEdit && (
              <div className="flex items-center py-2">
                <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                  <Icons iconName="verification" />
                  <span className="opacity-60"> Email verification</span>
                </div>
                <div className="w-[70%] overflow-hidden text-ellipsis">
                  <div>{renderStatus("confirmed")}</div>
                </div>
              </div>
            )}
            {!isEdit && (
              <div className="flex items-center py-2">
                <div className="w-[150px] flex items-center gap-x-2 overflow-hidden text-ellipsis">
                  <Icons iconName="status" />
                  <span className="opacity-60">Status</span>
                </div>
                <div className="w-[70%] overflow-hidden text-ellipsis">
                  <div
                    color={
                      profile?.status === "Active" ? "green100" : "orange100"
                    }
                  >
                    {renderStatus(profile?.status)}
                  </div>
                </div>
              </div>
            )}
            {isEdit && (
              <>
                <VLocation
                  className="flex flex-col gap-y-3"
                  address={address}
                  setAddress={setAddress}
                />
                <div className="flex justify-center gap-x-2 py-5">
                  <VButton
                    type="outline"
                    className="!w-[120px]"
                    radius="full"
                    onClick={onClickEdit}
                    iconLeft={<Icons iconName="close" />}
                  >
                    Cancel
                  </VButton>
                  <VButton
                    loading={loading}
                    onClick={handleEdit}
                    radius="full"
                    className="!w-[120px]"
                    iconLeft={<Icons iconName="save" />}
                  >
                    Save
                  </VButton>
                </div>
              </>
            )}
          </div>
          {/* <div>
              <div className="flex flex-col gap-y-3 sm:mx-[30px] mx-[5px] ">
                <VInput
                  wrapperClassName="px-0"
                  transparent={true}
                  className="w-full"
                  label="Full name"
                  value={infoUser.fullName}
                  onChange={(e) => editProfile(e, "fullName")}
                />
                <VDropdown
                  label="Choose the object"
                  value={object}
                  options={objects?.map((el: any) => {
                    return {
                      label: el?.name,
                      value: el?.id,
                    };
                  })}
                  onChange={(e) => setObject(e)}
                  customClassName="!bg-transparent border rounded"
                />

                <VDropdown
                  label="Choose the group"
                  customClassName="!bg-transparent border rounded"
                  options={groups?.map((el: any) => {
                    return {
                      label: el?.name,
                      value: el?.key,
                    };
                  })}
                  value={group}
                  onChange={(e) => setGroup(e)}
                 
                />

                <div className="">
                  <VInput
                    wrapperClassName="px-0"
                    transparent={true}
                    className="!bg-transparent"
                    type="number"
                    label="Phone number"
                    placeholder="+84.."
                    value={infoUser.phone}
                    onChange={(e) => editProfile(e, "phone")}
                  />
                </div>
                <VInput
                  wrapperClassName="px-0"
                  className="!bg-transparent"
                  transparent={true}
                  label="Email"
                  value={infoUser.email}
                  disabled={true}
                />
                <VLocation
                  className="flex flex-col gap-y-3"
                  address={address}
                  setAddress={setAddress}
                />
                <div className="flex justify-center py-5">
                  <VButton
                    loading={loading}
                    onClick={handleEdit}
                    radius="full"
                    className="!w-[120px]"
                  >
                    Save
                  </VButton>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};

export default Info;
