/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import VDropdown from "components/v-core/v-dropdown";
import Icons from "helper/icons";
import { formatNumber } from "utils/common";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";
import StockService from "api/cryptos";
import { renderStock } from "utils/render";

const Exchange = () => {
  const { stocksPrice, balances, getBalances, stockDefault } =
    useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const [stocksSwap, setStocksSwap] = useState([]);
  const [active, setActive] = useState<string>("market");
  const [state, setState] = useState({
    from: null,
    to: null,
    listFrom: [],
    listTo: [],
    amountFrom: null,
    amountTo: null,
    fee: 0,
    oneWay: true,
  });
  const [loading, setLoading] = useState(false);
  const [valueProgress, setValueProgress] = useState(0);

  useEffect(() => {
    getStocksSwap();
  }, []);

  useEffect(() => {
    if (balances && state.from) {
      setState({
        ...state,
        amountFrom: ((balances[state.from.symbol] || 0) * valueProgress) / 100,
      });
    }
  }, [valueProgress]);

  useEffect(() => {
    init();
  }, [stocksSwap]);

  useEffect(() => {
    if (state.from && state.to) {
      const amountFrom = state.amountFrom || 0;
      let amountTo;
      if (active === "market") {
        amountTo =
          amountFrom *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          amountFrom *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }
      const fee_swap = state.from.fee_swap || {
        stock: stockDefault,
        percent: 1,
      };
      const fee = (amountFrom * +fee_swap.percent) / 100;
      setState({
        ...state,
        amountFrom,
        amountTo,
        fee,
      });
    }
  }, [state.amountFrom, state.amountTo]);

  useEffect(() => {
    if (state.from && state.to) {
      setValueProgress(0);
      setState({
        ...state,
        amountFrom: null,
        amountTo: null,
      });
    }
  }, [state.from, state.to]);

  const init = () => {
    if (stocksSwap) {
      const fromSelected = stocksSwap.find(
        (s) => s.symbol === stocksSwap[0]?.symbol
      );
      const listTo = stocksSwap.filter(
        (s) => (fromSelected.pairs || {})[s.symbol]
      );
      setState({
        ...state,
        from: fromSelected,
        to: listTo[0],
        listFrom: stocksSwap,
        listTo,
      });
    }
  };

  const getStocksSwap = async () => {
    try {
      await StockService.getStocksSwap().then((res: any) => {
        if (res && res.stocks)
          setStocksSwap(
            res.stocks.map((i) => ({
              ...i,
              value: i.symbol,
              label: String(i.symbol).toUpperCase(),
              icon: i.logo,
            }))
          );
      });
    } catch (error) {
      console.error("Error fetching stocks swap:", error);
    }
  };

  const handleChoose = (type, stock) => {
    let from;
    let to;
    let oneWay = true;
    let listFrom = [];
    let listTo = [];

    if (type === "from") {
      listFrom = stocksSwap;
      listTo = stocksSwap.filter((s) => (stock.pairs || {})[s.symbol]);
      from = stock;
      to = listTo[0];
    } else {
      listFrom = stocksSwap.filter((s) => (s.pairs || {})[stock.symbol]);
      listTo = state.listTo;
      from = state.from;
      to = stock;
    }
    if (
      from &&
      to &&
      (from.pairs || {})[to.symbol] &&
      (to.pairs || {})[from.symbol]
    ) {
      oneWay = false;
    }
    setActive("market");
    setState({
      ...state,
      from,
      to,
      listFrom,
      listTo,
      oneWay,
    });
  };

  const handleChange = (type, value) => {
    let amountFrom = 0;
    let amountTo = 0;

    if (type === "from") {
      amountFrom = +value;

      if (active === "market") {
        amountTo =
          +value *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          +value *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }

      setValueProgress((amountFrom / balances[state.from.symbol]) * 100);
    } else {
      amountTo = +value;

      if (active === "market") {
        amountFrom =
          +value *
          (+stocksPrice[state.to.symbol]["vnd"] /
            +stocksPrice[state.from.symbol]["vnd"]);
      } else {
        amountFrom =
          +value *
          ((+stocksPrice[state.to.stock_to_buy]["vnd"] * state.to.stock_price) /
            (+stocksPrice[state.from.stock_to_buy]["vnd"] *
              state.from.stock_price));
      }
    }

    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleSwap = async () => {
    try {
      setLoading(true);
      let res;

      if (active === "market") {
        res = await StockService.swap({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      } else {
        res = await StockService.swapStocksProjectAsync({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      }

      if (res) {
        setState({
          ...state,
          amountFrom: null,
          amountTo: null,
        });
        getBalances();
        toast.success("Swap success!");
      }
    } catch (error) {
      toast.error(error.errors);
    } finally {
      setLoading(false);
    }
  };

  const listFrom = useMemo(() => {
    if (state?.listFrom) return state?.listFrom;
    return [];
  }, [state]);

  const listTo = useMemo(() => {
    if (state?.listTo) return state?.listTo;
    return [];
  }, [state]);

  return (
    <div className="px-2 py-5 w-full" style={themesSetting?.card}>
      <div className="lg:flex lg:justify-center">
        <div className="lg:w-[700px] md:w-full">
          <div className="">
            <div className="flex flex-col gap-y-2 rounded-lg">
              <div className="w-full flex border rounded-lg border-white divide-x">
                <div className="w-full">
                  <div className="w-full relative mt-2">
                    <VDropdown
                      options={listFrom}
                      value={state.from?.value}
                      optionLabel="label"
                      optionIcon="logo"
                      onChange={(_, object) => handleChoose("from", object)}
                      buttonClassName={"!border-none !px-[14px]"}
                    />
                    <div
                      style={themesSetting?.card}
                      className="flex !m-0 absolute justify-center items-center top-[-10px] left-1 p-1 rounded-md -translate-y-1/2"
                    >
                      <Icons iconName="wallets" iconClassName="w-5 h-5" />
                      <div className="pl-1 text-xs">
                        {formatNumber(balances[state?.from?.symbol] || 0)}
                        <span className="ml-1 font-bold">
                          {renderStock(state?.from?.symbol)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <VInput
                  inputStyle={themesSetting?.card}
                  className="w-full !border-none h-[38px] mt-1 rounded-lg justify-center items-center flex px-3"
                  name="amountTo"
                  isCurrency={true}
                  value={state.amountFrom}
                  placeholder="0"
                  onValueChange={(value) => handleChange("from", value)}
                  disabled={state.from && state.to ? false : true}
                />
              </div>

              <div className="w-full cursor-pointer my-1 flex justify-center items-center"></div>
              <div className="w-full flex border rounded-lg border-white divide-x">
                <div className="w-full">
                  <div className="w-full relative mt-2">
                    <VDropdown
                      options={listTo}
                      value={state.to?.value}
                      optionLabel="label"
                      optionIcon="logo"
                      onChange={(_, object) => handleChoose("to", object)}
                      buttonClassName={"!border-none !px-[14px]"}
                    />
                    <div
                      style={themesSetting?.card}
                      className="flex !m-0 absolute justify-center items-center top-[-10px] left-1 p-1 rounded-md -translate-y-1/2"
                    >
                      <Icons iconName="wallets" iconClassName="w-5 h-5" />
                      <div className="pl-1 text-xs">
                        {formatNumber(balances[state?.to?.symbol] || 0)}
                        <span className="ml-1 font-bold">
                          {renderStock(state?.to?.symbol)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <VInput
                  inputStyle={themesSetting?.card}
                  className="w-full !border-none h-[38px] mt-1 rounded-lg justify-center items-center flex px-3"
                  name="amountTo"
                  isCurrency={true}
                  value={state.amountTo}
                  placeholder="0.0000"
                  onValueChange={(value) => handleChange("to", value)}
                  disabled={state.from && state.to ? false : true}
                />
              </div>
            </div>

            <div className="w-full justify-center items-center m-auto">
              <VButton
                className="w-full py-2 !text-[14px] rounded-lg mt-4"
                loading={loading}
                onClick={handleSwap}
                disabled={!state.from || !state.to}
              >
                Process To Wallet
              </VButton>
            </div>
            <div
              style={themesSetting?.input}
              className="grid grid-cols-9 items-center justify-center h-[90px] rounded-lg"
            >
              <div className="col-span-4 flex justify-center items-center gap-2">
                <img className="w-6 h-6" src={state?.from?.logo} alt="" />
                {state.from && state.to && (
                  <p className="m-0">
                    <span>1</span>
                  </p>
                )}
              </div>
              <div className="col-span-1 text-center">=</div>
              <div className="col-span-4 flex justify-center items-center gap-2">
                <img className="w-6 h-6" src={state?.to?.logo} alt="" />
                <p className="m-0">
                  {active === "market" &&
                    formatNumber(
                      +stocksPrice[state.from?.symbol]?.["vnd"] /
                        stocksPrice[state.to?.symbol]?.["vnd"]
                    )}
                </p>
              </div>
            </div>
            <div className="flex justify-center">
              <span>Fee : {formatNumber(state.fee)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exchange;
