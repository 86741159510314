import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
import React from "react";
import { formatDate, formatNumber } from "utils/common";
import { useHistory } from "react-router-dom";

const ITEM_DEFAULT = {
  name: "Pulses Of Imagination",
  image: "/assets/images/nft_1.png",
  created_by: {
    avatar: "/assets/images/avatar.png",
    name: "@louisnguyen",
  },
  collection: {
    icon: "",
    name: "Vzonex",
  },
  owner: {
    avatar: "/assets/images/avatar.png",
    name: "@louisnguyen",
  },
  createAt: "12/04/1994",
  description:
    "Fisherian Runaways, Child of #48 Bat Veil & #42 Screw Nose. This mushroom is the result of the cross-breeding of two original one of a kind generative mushrooms donated back to the Fisherian Runaways project their generous owner.",
  stocks: [
    {
      icon: "",
      name: "VZX",
    },
    {
      icon: "",
      name: "Bitcoin",
    },
    {
      icon: "",
      name: "Ethereum",
    },
  ],
  price: {
    amount: 1.01,
    stock: "CVX",
  },
};

const NftDetails = () => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/my/shop");
  };
  return (
    <div className="relative flex max-xl:flex-col w-full gap-2">
      <div className="absolute -top-8 left-4">
        <VButton type="outline" className="!border-none" onClick={handleBack}>
          <div className="flex items-center justify-center gap-x-1">
            <span className="text-xl font-medium">{`<`}</span>
            <span>Back</span>
          </div>
        </VButton>
      </div>
      <div className="w-1/2 max-xl:w-full flex justify-center items-center">
        <img
          className="w-full rounded object-cover"
          src={ITEM_DEFAULT.image || "/assets/images/nft_1.png"}
          alt="NFT_image"
        />
      </div>
      <div className="w-1/2 max-xl:w-full flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-3">
          <span className="font-semibold text-lg">{ITEM_DEFAULT.name}</span>
          <span className="text-xs opacity-60">
            {formatDate(ITEM_DEFAULT.createAt)}
          </span>
        </div>
        <hr className="w-full border-b border-dashed" />
        <div className="flex flex-wrap gap-5">
          <div className="flex flex-col gap-y-2">
            <span className="opacity-60">CREATED BY</span>
            <div className="flex items-center gap-x-1">
              <img
                className="w-[20px] h-[20px] rounded-full object-cover"
                src={ITEM_DEFAULT.created_by.avatar}
                alt="create by"
              />
              <span className="text-sm">{`${ITEM_DEFAULT.created_by.name}`}</span>
            </div>
          </div>
          <hr className="h-full border-r border-dashed" />
          <div className="flex flex-col gap-y-2">
            <span className="opacity-60">COLLECTION</span>
            <div className="flex items-center gap-x-1">
              <img
                className="w-[20px] h-[20px] rounded-full object-cover"
                src={ITEM_DEFAULT.collection.icon || "/logo32.png"}
                alt="create by"
              />
              <span className="text-sm">{`${ITEM_DEFAULT.collection.name}`}</span>
            </div>
          </div>
        </div>
        <hr className="w-full border-b border-dashed" />
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-col gap-y-1">
            <span className="opacity-60">DESCRIPTION</span>
            <span className="text-sm">{ITEM_DEFAULT.description}</span>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="opacity-60">OWNER</span>
            <div className="flex items-center gap-x-1">
              <img
                className="w-[20px] h-[20px] rounded-full object-cover"
                src={ITEM_DEFAULT.owner.avatar}
                alt="create by"
              />
              <span className="text-sm">{`${ITEM_DEFAULT.owner.name}`}</span>
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="opacity-60">DESCRIPTION</span>
            <div className="flex flex-wrap gap-x-3">
              {ITEM_DEFAULT.stocks.map((el) => (
                <div className="flex items-center gap-x-1">
                  <img
                    className="w-[20px] h-[20px] rounded-full object-cover"
                    src={el.icon || "/logo32.png"}
                    alt="create by"
                  />
                  <span className="text-sm">{`${el.name}`}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr className="w-full border-b border-dashed" />
        <div className="flex flex-col gap-y-2">
          <span className="opacity-60">PRICE</span>
          <div className="flex gap-x-1">
            <span>{formatNumber(ITEM_DEFAULT.price.amount)}</span>
            <span>{ITEM_DEFAULT.price.stock}</span>
          </div>
          <div className="flex items-center gap-x-1">
            <img
              className="w-[20px] h-[20px] rounded-full object-cover"
              src={ITEM_DEFAULT.owner.avatar}
              alt="create by"
            />
            <span className="text-sm">{`${ITEM_DEFAULT.owner.name}`}</span>
          </div>
        </div>
        <div className="w-full flex gap-x-2 mt-2">
          <div className="w-1/2">
            <VButton
              type="outline"
              radius="full"
              className="w-full"
              iconLeft={<Icons iconName="share" />}
            >
              Share
            </VButton>
          </div>
          <div className="w-1/2">
            <VButton
              className="w-full"
              radius="full"
              iconLeft={<Icons iconName="auction" />}
            >
              Place a BID
            </VButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftDetails;
