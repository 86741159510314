import { ThemeContext } from "context/ThemeContext";
import React, { useContext, useEffect, useState } from "react";
import { formatNumber } from "utils/common";
import { useHistory } from "react-router-dom";
import ProductApi from "api/products";
import useQuery from "hooks/useQuery";
import { MasterContext } from "context/MasterContext";
import VPagination from "components/v-core/v-pagination";

type IProps = {
  itemSelected?: any;
  setItemSelected?: (item: any) => any;
};

const ListNfts = ({ itemSelected, setItemSelected }: IProps) => {
  const { themesSetting } = useContext(ThemeContext);
  const { categories } = useContext(MasterContext);
  const [products, setProducts] = useState(null);
  const history = useHistory();
  const query = useQuery();
  const categoryId = query.category;

  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const [filterValue, setFilterValue] = useState({
    category_id: categories?.map((el) => el._id),
  });

  useEffect(() => {
    if (categoryId) {
      setFilterValue({
        ...filterValue,
        category_id: categoryId,
      });
    }
  }, [categoryId]);

  useEffect(() => {
    // if (categoryId) {
    //   getProductsByCategory(categoryId);
    // } else {
    getProducts();
    // }
  }, [filterValue]);

  const getProducts = async () => {
    try {
      const res: any = await ProductApi.getProducts({
        query: {
          ...lazyParams,
          page: lazyParams.page + 1,
          categoryId: filterValue.category_id,
        },
      });
      if (res.products) {
        setProducts(res);
      }
    } catch (error) {}
  };

  const onFilter = (type: string) => {
    if (type === "next") {
      setLazyParams((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    } else {
      setLazyParams((prev) => ({
        ...prev,
        page: prev.page - 1,
      }));
    }
  };

  // const getProductsByCategory = async (categoryId: string) => {
  //   try {
  //     const res: any = await ProductApi.getProducts({
  //       query: {
  //         ...lazyParams,
  //         page: lazyParams.page + 1,
  //         category: categoryId,
  //       },
  //     });
  //     if (res.products) {
  //       setProducts(res.products);
  //     }
  //   } catch (error) {}
  // };

  const onSelectItem = (item: any) => {
    setItemSelected(item);
    history.push(`/my/shop/nfts/${item.id}`);
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="w-full flex flex-wrap gap-4">
        {products &&
          products?.products.map((el, index) => (
            <div
              key={el.id}
              className={`relative border-1 w-[190px] h-[280px] flex flex-col rounded cursor-pointer hover:!bg-opacity-70`}
              style={{
                ...themesSetting.card,
                borderColor: themesSetting.layout.textContent,
              }}
              onClick={() => onSelectItem(el)}
            >
              <div className="absolute top-0 right-0">
                <span className="text-white bg-red-500 text-xs p-1 rounded-lg">
                  -20%
                </span>
              </div>
              <div
                className="w-full h-[60%] flex justify-center items-center mt-1 mb-2"
                style={{
                  backgroundImage: "url(/assets/images/slide/slide-3.jpg)",
                }}
              >
                <img
                  src={el.thumbnail || "/assets/images/nft_1.png"}
                  alt="nft"
                  className="max-w-[60%] max-h-[60%] object-cover"
                />
              </div>
              <div className="flex flex-col mt-2">
                <span className="max-w-full text-base font-bold text-ellipsis text-center overflow-hidden whitespace-nowrap px-3">
                  {el.name}
                </span>
              </div>
              <div className="flex flex-col mt-3 px-2">
                <span className="text-xs opacity-60 line-through">
                  {formatNumber(el.price_token + (el.price_token * 20) / 100)}{" "}
                  {el.token}
                </span>
              </div>
              <div className="flex items-center justify-between px-3">
                <div className="relative flex gap-x-1 font-semibold">
                  <span>{formatNumber(el.price_token)}</span>
                  <span className=" uppercase">{el.token}</span>
                </div>
                <span className="text-[12px]">{`${el.amount_sold} sold`}</span>
              </div>
            </div>
          ))}
      </div>
      <VPagination
        page={products?.page}
        totalPages={products?.totalPages}
        totalDocs={products?.total}
        limit={products?.rows}
        onFilter={onFilter}
      />
    </div>
  );
};

export default ListNfts;
