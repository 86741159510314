import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const DonateWrapper = styled.div`
  display: flex;
  justify-content: center;

  /* img {
    width: ${isMobile ? "70px" : "100px"};
    transition-duration: 0.5s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
  @keyframes bounce-2 {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .bounce-2 {
    animation-name: bounce-2;
    animation-timing-function: ease;
  } */
  .system,
  .charity {
    cursor: pointer;
  }
  .charity {
    margin-right: 10px;
  }
`;

export const WrapperForm = styled.div`
  padding: 20px;
  h3 {
    text-align: center;
  }
  input {
    width: 100%;
    border: 1px solid white;
  }
`;

export const Thanks = styled.div`
  img {
    width: 100%;
  }
`;

export const Histories = styled.div`
  max-height: 250px;
  overflow: auto;
`;
