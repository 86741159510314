/* eslint-disable react-hooks/exhaustive-deps */
import { ROUTES } from "config/routes";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "components/loading";
import Mess from "components/messenger";
import { MasterContext } from "context/MasterContext";
import qs from "query-string";
import UserService from "api/users";

const withAuth = (Component: any) => {
  const AuthenticatedComponent: React.FC = (props) => {
    const history = useHistory();
    const location = useLocation();
    const query = qs.parse(location.search);

    const [isLoading, setIsLoading] = useState(false);
    const { setAuth } = useContext(MasterContext);

    useEffect(() => {
      async function getMe() {
        try {
          setIsLoading(true);
          const user: any = await UserService.getUserAuth();
          setIsLoading(false);
          if (user) {
            setAuth(user?.user);
            if (query.callback)
              window.location.replace(
                `${query.callback}?token=${localStorage.getItem(
                  "auth"
                )}&&provider=canvanex`
              );
            return;
          } else {
            localStorage.removeItem("auth");
            history.push(ROUTES.LOGIN);
          }
          setIsLoading(false);
        } catch (error) {
          history.push(ROUTES.LOGIN);
          localStorage.removeItem("auth");
          setIsLoading(false);
        }
      }
      getMe();
    }, [history]);

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Component {...props} />

        <Mess />
      </div>
    );
  };

  return AuthenticatedComponent;
};

export default withAuth;
