import React from "react";

const Buy = () => {
  return (
    <div className="flex justify-center">
      <h1 className="text-[14px]">Coming soon</h1>
    </div>
  );
};

export default Buy;
