import Input from "components/v-core/v-input";
import Modal from "components/v-core/v-modal";
import VTable from "components/v-core/v-table";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { BiDonateHeart } from "react-icons/bi";
import { FaDonate } from "react-icons/fa";
import { toast } from "react-toastify";
import { DonateWrapper, Thanks } from "./styles";
import { MasterContext } from "context/MasterContext";
import VDropdown from "components/v-core/v-dropdown";
import { ThemeContext } from "context/ThemeContext";
import TransactionService from "api/transactions";
import { formatDate } from "utils/common";

const columns = [
  {
    key: "User",
    title: "User",
    render: (item: any) => `${item.from}`,
  },
  {
    key: "Amount",
    title: "Amount",
    render: (item) => item.amount + `(${String(item.token).toUpperCase()})`,
  },
  {
    key: "Hash",
    title: "Hash",
    render: (item: any) => (
      <a
        href={`${
          process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
        }/tx/${item.hash}`}
        target={"_blank"}
        rel="noreferrer"
      >
        {item.hash?.slice(0, 20)}...
      </a>
    ),
  },
  {
    title: "CreatedAt",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (data: any) => <span>{formatDate(data?.createdAt)}</span>,
  },
];

const View = ({ setOpenMenu }) => {
  const { stocks, stockDefault, appName } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    stock: stockDefault,
    amount: 0,
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [type, setType] = useState("dev");
  const [histories, setHistories] = useState({
    docs: [],
  });

  useEffect(() => {
    getData();
  }, [type]);

  const getData = async () => {
    try {
      const res: any = await TransactionService.getTransactionsDonate({
        query: {
          type,
          limit: 20,
          page: 1,
        },
      });
      setHistories(res);
    } catch (error) {}
  };

  const handleChange = (stock) => {
    setForm({
      ...form,
      stock: stock,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await TransactionService.donate({
        body: { ...form, type },
      });
      getData();
      setIsSuccess(true);
      setLoading(false);
      setForm({
        stock: stockDefault,
        amount: 0,
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.errors);
    }
  };

  const renderHistories = (type) => {
    return <VTable columns={columns} data={histories.docs}></VTable>;
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={openModal}
        centered={true}
        onCancel={() => {
          setOpenModal(false);
          setIsSuccess(false);
        }}
        onConfirm={handleSubmit}
        loading={loading}
      >
        <div className="min-h-[400px]">
          {!isSuccess ? (
            <div className="">
              <h3>
                {type === "charity"
                  ? `${appName} Charity Found`
                  : "DONATE FOR " + type.toUpperCase()}
              </h3>
              <div style={themesSetting?.card}>
                <VDropdown
                  options={stocks}
                  optionLabel="label"
                  optionIcon="logo"
                  defaultLabel="Choose NFTs"
                  onChange={(stock) => handleChange(stock)}
                />
                <Input
                  isCurrency={true}
                  placeholder="10"
                  name="amount"
                  value={form.amount}
                  onValueChange={(e) =>
                    setForm((form) => ({ ...form, amount: e }))
                  }
                  className="w-full "
                  wrapperClassName="w-full py-2"
                />
              </div>
            </div>
          ) : (
            <Thanks>
              <img src={"/assets/images/thanks.gif"} alt="" />
            </Thanks>
          )}
          {renderHistories(type)}
        </div>
      </Modal>
    );
  };

  return (
    <DonateWrapper>
      {renderModal()}
      <div
        className="charity"
        onClick={() => {
          isMobile && setOpenMenu(false);
          setType("charity");
          setOpenModal(true);
        }}
      >
        <BiDonateHeart color="red" size={30} />
      </div>
      <div
        className="system"
        onClick={() => {
          isMobile && setOpenMenu(false);
          setType("dev");
          setOpenModal(true);
        }}
      >
        <FaDonate color="green" size={25} />
      </div>
    </DonateWrapper>
  );
};

export default View;
