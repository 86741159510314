import { ThemeContext } from "context/ThemeContext";
import { useContext } from "react";
/* eslint-disable max-len */

interface Props {
  columns: any;
  data: any;
  className?: string;
  color?: any;
  isEdit?: boolean;
  isActionTable?: boolean;
  onClick?: () => any;
}

export default function VTable({
  columns,
  data,
  className,
  color,
  isActionTable,
  onClick,
}: Props) {
  const { themesSetting } = useContext(ThemeContext);

  return (
    <div
      className={`w-full max-w-full overflow-auto h-full max-h-full no-scrollbar ${className}`}
      style={{
        ...themesSetting.table.body,
        ...(color || {}),
      }}
    >
      <div className="flow-root">
        <div>
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full">
              <thead className="border-dashed border-y-[0.2px] border-gray-4">
                <tr
                  className=" w-fit text-center text-ellipsis "
                  style={{
                    ...themesSetting.table.body,
                    ...(color || {}),
                  }}
                >
                  {columns?.map((item, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold sm:pl-0 w-fit text-ellipsis whitespace-nowrap"
                      style={{
                        ...themesSetting.table.border,
                        ...(color || {}),
                      }}
                    >
                      <div className="flex items-center gap-x-2 whitespace-nowrap h-12">
                        {item?.icon && item.icon}
                        {item?.customHeader ? item?.customHeader : item?.name}
                        {item?.title && item?.title}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                className="h-full max-h-full overflow-auto"
                onClick={() => isActionTable && onClick()}
              >
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      isActionTable &&
                      "cursor-pointer opacity-70 hover:opacity-100"
                    }`}
                  >
                    {columns?.map((col, i) => (
                      <td
                        key={i}
                        className="whitespace-nowrap py-3 px-3 text-sm sm:pl-0"
                      >
                        {col?.render ? col?.render(item) : item[col?.field]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
