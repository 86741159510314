/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "./styles";
import Tabs from "components/v-core/v-tabs";
import {
  BsClockHistory,
  BsCloudDownloadFill,
  BsListTask,
} from "react-icons/bs";
import Stages from "./components/Stages";
import Rounds from "./components/Rounds";
import Pixels from "./components/Pixel";
import Histories from "./components/Histories";
import { useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";
import useProjects from "hooks/useProjects";

const View = (props) => {
  const { projectId } = useParams();
  const { projectSelected, getProjectById } = useProjects();

  const [tab, setTab] = useState<string>("stages");
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    getProjectById(projectId);
  }, [projectId]);

  useEffect(() => {
    if (query && query.tab) {
      setTab(query.tab);
    } else if (projectSelected) {
      if (projectSelected?.type_sell === "round") setTab("stages");
      else setTab("pixels");
    }
  }, [projectSelected, query]);

  const handleChangeTab = (value) => {
    setTab(value);
    history.push(`?tab=${value}`);
  };

  const tab1 = [
    {
      name: "stages",
      render: (
        <>
          <BsListTask size={17} /> <span>Stages</span>
        </>
      ),
    },
  ];
  const tab2 = [
    {
      name: "pixels",
      render: (
        <>
          <BsListTask size={17} /> <span>Pixels</span>
        </>
      ),
    },
    {
      name: "rounds",
      render: (
        <>
          <BsCloudDownloadFill size={17} /> <span>Rounds</span>
        </>
      ),
    },
  ];

  return (
    <Container>
      <Tabs
        tabActive={tab}
        tabs={[
          ...(projectSelected?.type_sell !== "round" ? tab2 : tab1),
          {
            name: "histories",
            render: (
              <>
                <BsClockHistory size={17} /> <span>History</span>
              </>
            ),
          },
        ]}
        onChangeTabs={handleChangeTab}
      />

      {tab === "stages" && <Stages />}
      {tab === "rounds" && <Rounds />}
      {tab === "pixels" && <Pixels />}
      {tab === "histories" && <Histories />}
    </Container>
  );
};

export default View;
