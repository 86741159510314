/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable indent */
import { createContext, useState } from "react";
import { getRateUSDTToVND } from "utils/currency";
import APIs from "api";
import MenuService from "api/menu";
import { getMenuItems } from "utils/common";
import { renderStock } from "utils/render";
import CategoriesService from "api/categories";

const defaultSetting = {
  adPostition: [],
  channel: [],
  objects: [],
  logos: {},
  web_config: [],
  groups: [],
};

const defaultNotification = {
  client: [],
  owner: [],
  system: [],
  access: true,
};

const defaultAds = {
  advertisements: [],
  advertisement: null,
  access: false,
};

type MasterContextConfig = {
  auth: any;
  stockDefault: string;
  stocks: any;
  stocksPrice: any;
  balances: any;
  lockedBalances: any;
  settings: {
    adPostition: any;
    channel: any;
    objects: any;
    logos: any;
    web_config: any;
    groups: any;
    [key: string]: any;
  };
  rate: any;
  notifications: {
    client: any[];
    owner: any[];
    system: any[];
    access: boolean;
  };
  advertisements: any;
  reward: any;
  countries: any;
  menus: any;
  setMenus: any;
  appName: string;
  categories: any;
  allMenus: any;
  getStocksPrice: () => void;
  getBalances: () => void;
  getSettings: () => void;
  setAuth: (auth: any) => any;
  getStocks: () => void;
  getRate: () => void;
  getCryptosByObject: () => void;
  getNotificationByType: (type: "owner" | "system" | "client") => void;
  getUser: () => void;
  getAdvertisements: () => void;
  setAdvertisements: (ads: any) => any;
  setNotifications: (ntf: any) => void;
  setReward: (reward: any) => void;
  getMenus: () => void;
  getCountries: () => void;
  getLocationsByParent: (parent: string) => void;
  getCategories: (group: any) => void;
  getSettingInWebConfig: (name: string) => any;
};

export const MasterContext = createContext<MasterContextConfig>(
  {} as MasterContextConfig
);

export const MasterProvider = (props: any) => {
  const [auth, setAuth] = useState(null);

  const [stockDefault, setStockDefault] = useState("vndb");
  const [appName, setAppName] = useState("canvanex");
  const [stocks, setStocks] = useState([]);
  const [stocksPrice, setStocksPrice] = useState({});
  const [balances, setBalances] = useState({});
  const [settings, setSettings] = useState(defaultSetting);
  const [lockedBalances, setLockedBalances] = useState({});
  const [rate, setRate] = useState(null);
  const [notifications, setNotifications] = useState(defaultNotification);
  const [advertisements, setAdvertisements] = useState(defaultAds);
  const [reward, setReward] = useState(null);
  const [countries, setCountries] = useState([]);
  const [menus, setMenus] = useState([]);
  const [allMenus, setAllMenus] = useState([]);
  const [categories, setCategories] = useState([]);

  const getCategories = (req: any) => {
    try {
      CategoriesService.getByGroup(req).then((res: any) => {
        if (res) {
          setCategories(res);
        }
      });
    } catch (error) {}
  };

  const getMenus = async () => {
    try {
      MenuService.getAll().then((resp: any) => {
        if (resp) {
          setAllMenus(resp);
          const mapAll = resp.map((e: any) => ({
            ...e,
            key: e._id != null ? String(e._id) : null,
            keyVal: e.key,
            parent_id: e.parent_id ? e.parent_id : null,
          }));

          const _mapAll = resp.map((e: any) => ({
            ...e,
            key: e._id != null ? String(e._id) : null,
            keyVal: e.key,
            parent_id: e.parent_id ? e.parent_id : null,
          }));
          const tree = _mapAll.filter((e: any) => e.parent_id == null);
          tree.forEach((el: any) => {
            const items = getMenuItems(mapAll, el._id);
            if (items && items != null && items.length > 0) {
              el.children = items;
              el.leaf = false;
            }
          });

          if (tree.length === 0 && _mapAll) {
            const treeChild = _mapAll.map((el: any) => {
              const items = getMenuItems(mapAll, el._id);
              if (items && items != null && items.length > 0) {
                el.children = items;
                el.leaf = false;
              }
              return el;
            });
            setMenus([...treeChild]);
          } else {
            setMenus([...tree]);
          }
        }
      });
    } catch (error) {}
  };

  const getStocks = async () => {
    try {
      await APIs.CRYPTO.getCryptos().then((res: any) => {
        if (res && res.cryptos) {
          setStocks(
            res.cryptos.map((c) => ({
              ...c,
              label: renderStock(c.symbol),
              value: c.symbol,
            }))
          );
        }
      });
    } catch (error) {}
  };

  const getStocksPrice = async () => {
    try {
      await APIs.CRYPTO.getStocksPrice().then((res: any) => {
        if (res && res.prices) setStocksPrice(res.prices);
      });
    } catch (error) {}
  };

  const getBalances = async () => {
    try {
      await APIs.USER.getBalances().then((res: any) => {
        if (res) {
          res?.balances && setBalances(res.balances);
          res?.locked_balances && setLockedBalances(res.locked_balances);
        }
      });
    } catch (error) {}
  };

  const getSettings = async () => {
    try {
      const res: any = await APIs.SETTING.getAllSettings();
      if (res) {
        const logos: any = res.find((a) => a.name === "logos")?.value || {};
        const objects = res.find((a) => a.name === "objects")?.value || [];
        const groups = res.find((a) => a.name === "groups")?.value || [];
        const web_config =
          res.find((a) => a.name === "web_config")?.value || [];
        const stock = web_config?.find((a) => a.name === "stock_default");
        const appname =
          web_config?.find((a) => a.name === "app_name")?.value || "Blightex";
        if (appname) {
          setAppName(appname);
        }
        if (stock) {
          setStockDefault(stock.value);
        }
        setSettings((prev) => ({
          ...prev,
          logos,
          web_config,
          objects,
          groups,
        }));
      }
    } catch (error) {}
  };

  const getCountries = async () => {
    try {
      const res: any = await APIs.SETTING.getCountries();
      if (res) {
        setCountries(res);
      }
    } catch (error) {}
  };

  const getLocationsByParent = async (parent: string) => {
    try {
      const locations = await APIs.SETTING.getLocations({
        query: {
          parent: parent,
        },
      });
      return locations;
    } catch (error) {
      return [];
    }
  };

  const getRate = async () => {
    const rate = await getRateUSDTToVND();
    setRate(rate);
  };

  const getCryptosByObject = () => {
    const result = stocks?.reduce(
      (obj, cur) => ({ ...obj, [cur.symbol]: cur }),
      {}
    );
    return result;
  };

  const getNotificationByType = async (type: "owner" | "system" | "client") => {
    try {
      const res: any = await APIs.NOTIFICATION.getNotificationsByUser();
      if (res && res?.notifications) {
        setNotifications((prev) => ({
          ...prev,
          [type]: res?.notifications,
          access: true,
        }));
      }
    } catch (error) {}
  };

  const getUser = async () => {
    const user: any = await APIs.USER.getUserAuth();
    setAuth(user?.user);
    return user?.user;
  };

  const getAdvertisements = async () => {
    try {
      const res: any = await APIs.ADVERTISEMENT.getAdvertisement();
      if (res && res?.advertisements) {
        const { advertisements, ...rest } = res;
        setAdvertisements((prev) => ({
          ...prev,
          advertisements,
          access: true,
          ...rest,
        }));
      }
    } catch (error) {
      return error;
    }
  };

  const getSettingInWebConfig = (name: string) => {
    try {
      return settings.web_config?.find((a) => a.name === name)?.value;
    } catch (error) {
      return null;
    }
  };

  return (
    <MasterContext.Provider
      value={{
        auth,
        stocks,
        stocksPrice,
        balances,
        settings,
        lockedBalances,
        rate,
        notifications,
        advertisements,
        reward,
        countries,
        menus,
        stockDefault,
        appName,
        categories,
        allMenus,

        setMenus,
        setAuth,
        getSettings,
        getStocksPrice,
        getBalances,
        getStocks,
        getRate,
        getCryptosByObject,
        getNotificationByType,
        getUser,
        getAdvertisements,
        setAdvertisements,
        setNotifications,
        setReward,
        getMenus,
        getCountries,
        getLocationsByParent,
        getCategories,
        getSettingInWebConfig,
      }}
    >
      {props.children}
    </MasterContext.Provider>
  );
};
