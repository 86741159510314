import { request, parseErrorResponse } from "./s3-request";

/**
 *
 * return data: {
 *    filePath,
 *    Key
 * }
 */

const getUploadVzonexS3AccessToken = (force = false) =>
  new Promise((resolve, reject) => {
    try {
      const cToken = localStorage.getItem("s3-auth-service");
      if (!cToken || force === true) {
        request()
          .get(`/pl/vzonex/request-access-token`)
          .then((res) => {
            const { token } = res.data;
            if (token) {
              localStorage.setItem("s3-auth-service", token);
            }
          })
          // .catch(parseErrorResponse)
          // .then(reject);
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });

getUploadVzonexS3AccessToken(true);

const uploadFilesVzonexS3 = (formData) =>
  new Promise((resolve, reject) => {
    request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .post(`/pl/vzonex/upload-files`, formData)
      .then((res) => {
        const { files } = res.data;

        const data = files.map((file, idx) => ({
          filePath: file.Location,
          Key: file.Key,
          id: idx,
        }));

        resolve(data);
      })
      .catch(parseErrorResponse)
      .then((err) => {
        getUploadVzonexS3AccessToken(true);
        reject(err);
      });
  });

const uploadFile = (data) =>
  new Promise((resolve, reject) => {
    if (!data.body.get("folderPath")) {
      data.body.append("folderPath", "unknown");
    }

    uploadFilesVzonexS3(data.body)
      .then((files) => {
        resolve(files[0]);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

/**
 *
 * return data: [{
 *    filePath,
 *    Key
 *    id
 * }]
 */

const uploadMultiFile = (data) =>
  new Promise((resolve, reject) => {
    if (!data.body.get("folderPath")) {
      data.body.append("folderPath", "unknown");
    }
    uploadFilesVzonexS3(data.body)
      .then((files) => {
        resolve(files);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UploadService = {
  getUploadVzonexS3AccessToken,
  uploadFile,
  uploadMultiFile,
};

export default UploadService;
