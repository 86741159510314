import { ThemeContext } from "context/ThemeContext";
import React, { useContext } from "react";

type IProps = {
  className?: string;
};

const ComingSoon = (props: IProps) => {
  const { className } = props;

  const { themesSetting } = useContext(ThemeContext);
  return (
    <div
      className={`p-10 rounded-xl w-full flex justify-center items-center h-full lg:py-[300px] py-[150px]`}
      style={themesSetting?.card}
    >
      <div className={className || "text-3xl font-medium"}>Coming Soon</div>
    </div>
  );
};

export default ComingSoon;
