const IconSwaps = ({ iconName, iconClassName }) => {
  return (
    <>
      {iconName === "stock_swap" && (
        <svg
          className={iconClassName || ""}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.32829 20.0083C3.32829 10.8033 10.79 3.34167 19.995 3.34167C29.2 3.34167 36.6616 10.8033 36.6616 20.0083C36.6616 29.2133 29.2 36.675 19.995 36.675C10.79 36.675 3.32829 29.2133 3.32829 20.0083ZM24.995 11.675L24.995 15.0083L18.3283 15.0083L18.3283 18.3417L24.995 18.3417L24.995 21.675L30.8283 16.675L24.995 11.675ZM14.995 28.3417L14.995 25.0083L21.6616 25.0083L21.6616 21.675L14.995 21.675L14.995 18.3417L9.16162 23.3417L14.995 28.3417Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};

export default IconSwaps;
