import { Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";

import { AiOutlineWallet, AiOutlineScan } from "react-icons/ai";

import { ROUTES } from "config/routes";
import { useContext } from "react";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";

const tabs = [
  {
    route: ROUTES.MY_WALLETS,
    icon: <AiOutlineWallet size={24} />,
    label: "Wallet",
  },
  {
    route: ROUTES.MY_SWAP,
    icon: <Icons iconName="swap" iconClassName="w-[24px] h-[24px]" />,
    label: "Swap",
  },
  {
    route: "/scan",
    icon: <AiOutlineScan size={40} />,
  },

  {
    route: ROUTES.MY_PROFILE,
    icon: <Icons iconName="profile" iconClassName="w-[24px] h-[24px]" />,
    label: "Profile",
  },
  {
    route: ROUTES.INVEST,
    icon: <Icons iconName="trust_project" iconClassName="w-[24px] h-[24px]" />,
    label: "Trust",
  },
];

const View = () => {
  const { pathname } = useLocation();
  const { themesSetting } = useContext(ThemeContext);

  return (
    <div className="flex items-center">
      {/* Bottom Tab Navigator*/}
      <nav className="text-xs z-[5] fixed right-0 left-0 bottom-0">
        <Nav className="w-100">
          <div
            className="flex justify-around w-100"
            style={themesSetting?.layout}
          >
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  className={`flex items-center no-underline px-2 !h-[56px]`}
                >
                  <div
                    className="flex flex-col items-center justify-between"
                    style={
                      pathname.includes(tab.route)
                        ? {
                            color: themesSetting.textContent,
                          }
                        : themesSetting.layout
                    }
                  >
                    <div>{tab.icon}</div>
                    <div>{tab.label}</div>
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default View;
