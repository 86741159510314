import VModal from "components/v-core/v-modal";
import VBanner from "components/v-banner";
import VInput from "components/v-core/v-input";
import VUploadFile from "components/v-upload";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext } from "react";

type IProps = {
  data?: any;
  isEdit?: boolean;
  type?: string;
  openModal?: boolean;
  file?: any;
  setFile?: any;
  link?: string;
  title?: string;
  children?: any;
  srcImg?: string;
  linkVideo?: string;
  isLoading?: boolean;
  imgClassName?: string;
  setData?: (value: any) => void;
  setLink?: (value: string) => void;
  setOpenModal?: (e: any) => any;
  onSubmit?: () => any;
  onCancel?: () => any;
};

const UploadDocs = (props: IProps) => {
  const {
    data,
    isEdit,
    type = "image",
    openModal,
    file,
    setFile,
    link,
    children,
    srcImg,
    linkVideo,
    isLoading,
    imgClassName,
    setOpenModal,
    setLink,
    onSubmit,
    onCancel,
  } = props;
  const { themesSetting } = useContext(ThemeContext);

  const onChangeLink = (value: string) => {
    setLink(value);
  };

  const renderUploadImage = () => {
    return (
      <div className="relative w-full h-full rounded-md">
        <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
          <Icons iconName="add_image" />
          <span className="text-sm">Upload image</span>
        </div>
      </div>
    );
  };

  const renderUploadVideo = () => {
    return (
      <div
        className="relative w-full h-full rounded border-1 border-dashed"
        style={themesSetting.container}
      >
        <div className="absolute-center flex flex-col justify-center items-center gap-y-2">
          <Icons iconName="add_video" />
          <span className="text-sm">Upload video</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`${
          isEdit && "border-1 border-dashed cursor-pointer"
        } w-full h-full rounded-md`}
        style={isEdit ? themesSetting.container : {}}
        onClick={() => isEdit && setOpenModal(true)}
      >
        {type === "image" ? (
          <>
            {srcImg ? (
              <img
                className={`w-full h-full object-cover ${imgClassName}`}
                src={srcImg}
                alt=""
              />
            ) : (
              isEdit && (children || renderUploadImage())
            )}
          </>
        ) : (
          <>
            {linkVideo ? (
              <VBanner data={data} isFull={true} />
            ) : (
              isEdit && (children || renderUploadVideo())
            )}
          </>
        )}
      </div>
      <div>
        <VModal
          loading={isLoading}
          title={"Upload image"}
          isOpen={openModal}
          onConfirm={onSubmit}
          onCancel={() => (onCancel ? onCancel() : setOpenModal(false))}
        >
          {type === "image" ? (
            <div className="w-full flex justify-center">
              <div className="w-48 h-48">
                <VUploadFile
                  children={renderUploadImage()}
                  radius="md"
                  style={themesSetting.container}
                  id="file"
                  file={file}
                  setFile={setFile}
                  src={srcImg}
                />
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-center">
              <div className="w-48 flex flex-col gap-y-3">
                <div className="h-48 w-full">
                  <VUploadFile
                    id={"video"}
                    src={link}
                    file={file}
                    setFile={setFile}
                    children={renderUploadVideo()}
                    radius="md"
                  />
                </div>
                <div className="w-full">
                  <VInput
                    value={linkVideo}
                    onChange={(e) => onChangeLink(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
        </VModal>
      </div>
    </>
  );
};

export default UploadDocs;
