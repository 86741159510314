/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable indent */
import { useContext, useEffect, useMemo, useState } from "react";
import { Container, ListVideo, Item, Play } from "./styles";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { Col, Row } from "reactstrap";
import { FaPlay } from "react-icons/fa";
import axios from "axios";
import { MasterContext } from "context/MasterContext";

let listTotal = [];
const View = () => {
  const { auth } = useContext(MasterContext);
  const [clientHeight, setClientHeight] = useState(600);
  const [videos, setVideos] = useState([]);
  const [clientWidth, setClientWidth] = useState(500);
  const [frameWidth, setFrameWidth] = useState(0);
  const [id, setId] = useState();

  const channels = useMemo(() => {
    return auth?.channels || [];
  }, [auth]);

  useEffect(() => {
    const doc = document.getElementById("root");
    setClientHeight(doc.clientHeight);
  }, []);

  const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
      <iframe
        width={clientWidth - 50}
        height={clientWidth / 2}
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );

  const onPlay = (item) => {
    setId(item.id);
    setVideos(listTotal.filter((index) => index.id !== item.id));
  };

  useEffect(() => {
    const getVideos = async () => {
      const _videos = await Promise.all(
        channels.map(async (item) => {
          const res = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${item.id}&key=AIzaSyBIcdVOywmTMvcJihZPZmaCdRsIsaKlnFo`
          );
          return res.data.items[0]
            ? {
                ...res.data.items[0]["snippet"],
                prioritized: item.prioritized,
                id: item.id,
              }
            : null;
        })
      );
      const listSort = _videos.filter((_) => _);
      listSort.sort((a, b) => (!a.prioritized && b.prioritized ? 1 : -1));
      let idStart = channels[0].id;
      setVideos(listSort.filter((item) => item.id !== idStart));
      listTotal = listSort;
    };
    if (channels && channels.length > 0) {
      getVideos();
      setId(channels[0].id);
    }
  }, [channels]);

  useEffect(() => {
    const width = document.getElementsByClassName("clientWidth")[0].clientWidth;
    setClientWidth(width);
    const fwidth = document.getElementsByClassName("frameWidth")[0].clientWidth;
    setFrameWidth(fwidth);
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} md={8} className="clientWidth">
          <YoutubeEmbed embedId={id} />
          <hr />
          <ListVideo>
            {videos.map((item) => (
              <Item onClick={() => onPlay(item)}>
                <img src={item.thumbnails.high.url} alt="" />
                <span>{item.title.substr(0, 55) + "..."}</span>
                <Play>
                  <FaPlay size={27} />
                </Play>
              </Item>
            ))}
          </ListVideo>
        </Col>
        <Col xs={12} md={4} className="frameWidth">
          {/* <Logo>
            <img src="/assets/images/VDB-64.png" alt="" />
            <h4>Coming soon</h4>
          </Logo> */}
          {frameWidth > 0 && (
            <iframe
              src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FVzonex-Project-101194846036816%2F&tabs=timeline&width=${frameWidth}&height=600&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
              height="600"
              width={frameWidth}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          )}
          <div>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="vzonex"
              theme="dark"
              options={{ height: clientHeight }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default View;
