/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Label, FormGroup, Input as InputB } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import VTable from "components/v-core/v-table";

const View = (props) => {
  const {
    page,
    data,
    dataManagersWithSection,
    optionSection,
    loading,
    onSubmit,
  } = props;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const columnsTableManagers = [
    {
      key: "refer_code",
      title: "ID",
      field: "refer_code",
    },
    {
      key: "name",
      title: "Name",
      render: (item: any) => <span>{item?.fullName || item?.username}</span>,
    },
    {
      key: "email",
      title: "Email",
      field: "email",
    },
    {
      key: "action",
      title: "Action",
      field: "action",
      render: (item: any) => (
        <>
          <VButton
            className="h-[30px]"
            iconLeft={<MdModeEditOutline size={20} />}
            onClick={() => handleAction("edit", item)}
          ></VButton>
          <VButton
            className="h-[30px]"
            loading={loading}
            onClick={() => handleAction("delete", item)}
            iconLeft={<MdDeleteOutline size={20} />}
          ></VButton>
        </>
      ),
    },
  ];

  const [sectionManagers, setSectionManagers] = useState(null);
  const [dataManagers, setDataManagers] = useState<any>([]);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    setSectionManagers(optionSection);
  }, []);

  const onChangeChecked = (value: boolean, key: string) => {
    if (value) {
      const newData = dataManagers.concat(key);
      setDataManagers(newData);
    } else {
      setDataManagers((prev) => prev.filter((el) => el !== key));
    }
  };

  const handleAction = (type: string, data: any) => {
    if (type === "edit") {
      setIsEdit(true);
      setValue("refer_code", data.refer_code);
      setDataManagers(dataManagersWithSection[data._id]);
    } else if (type === "delete") {
      onSubmit({ refer_code: data.refer_code }, dataManagers, type);
      handelCancelEdit();
    } else {
    }
  };

  const handelCancelEdit = () => {
    setIsEdit(false);
    setValue("refer_code", "");
    setDataManagers([]);
  };

  return (
    <>
      <Form>
        <Controller
          name="refer_code"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <VInput
              transparent={true}
              color={"orange"}
              label="ID User"
              placeholder="Nhập ID..."
              hasError={Boolean(errors.refer_code)}
              disabled={isEdit}
              {...field}
            />
          )}
        />
        <div className="flex flex-wrap max-w-[500px] mb-[15px]">
          <FormGroup className="mx-[10px] my-[5px]" check>
            <Label check>
              <InputB
                type="checkbox"
                checked={dataManagers?.includes("all")}
                onChange={(e) => onChangeChecked(e.target.checked, "all")}
              />
              {page === "event" ? "Tất cả" : "All"}
            </Label>
          </FormGroup>
          {sectionManagers &&
            Object.keys(sectionManagers).map((st) => (
              <FormGroup className="mx-[10px] my-[5px]" check>
                <Label check>
                  <InputB
                    type="checkbox"
                    checked={dataManagers?.includes(st)}
                    onChange={(e) => onChangeChecked(e.target.checked, st)}
                  />
                  {sectionManagers[st].title}
                </Label>
              </FormGroup>
            ))}
        </div>
        {isEdit ? (
          <>
            <VButton
              className="h-[30px]"
              loading={loading}
              onClick={handleSubmit((e) => onSubmit(e, dataManagers, "edit"))}
            >
              Edit
            </VButton>
            <VButton
              className="h-[30px]"
              loading={loading}
              onClick={handelCancelEdit}
            >
              Cancel
            </VButton>
          </>
        ) : (
          <VButton
            className="h-[30px]"
            loading={loading}
            onClick={handleSubmit((e) => onSubmit(e, dataManagers, "add"))}
          >
            Add
          </VButton>
        )}
      </Form>
      <br />
      <br />
      <VTable columns={columnsTableManagers} data={data} />
    </>
  );
};

export default View;
