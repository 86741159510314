import styled from "styled-components";

const ContainerScan = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: calc(var(--vh, 1vh) * 100 - 106px);

  video {
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
  }

  @media screen and (max-width: 1100px) {
    height: calc(var(--vh, 1vh) * 100 - 142px);
  }
`;

export const ScanerImg = styled.div`
  position: fixed;
  top: calc(var(--vh, 1vh) * 50 - 150px);
  left: calc(50% - 150px);
`;

export const Scan = styled.div`
  position: relative;
`;

export const Line = styled.div`
  display: block;
  width: 270px;
  height: 2px;
  background: #4CAF50;
  position: absolute;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-animation: scanner 3s infinite linear;
  -moz-animation: scanner 3s infinite linear;
  -o-animation: scanner 3s infinite linear;
  animation: scanner 3s infinite linear;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 40%);
  left: 0;
  right: 0;
  margin: auto;

  @keyframes scanner {
    0% {
      bottom: 90%;
    }
    50% {
        bottom: 10%;
    }
    100% {
        bottom: 90%;
    }
  }
`;

export const ContentQr = styled.div`
  width: 100%;
  position: absolute;
  top: 7%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const Detail = styled.div<{isLink?: any}>`
  width: 100%;
  padding: 10px 20px;
  background-color: grey;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;

  span {
    color: ${props => props.isLink ? "blue" : "white"};
    font-size: 18px;
    width: 83%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button {
    margin-left: 15px;
    height: 30px;
    padding: 2px 6px;
    font-size: 14px;
  }
`;

export default ContainerScan;