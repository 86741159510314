import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { ReactNode } from "react";

type IProps = {
  button?: ReactNode;
  children?: ReactNode;
  className?: string;
};

const VPopover = (props: IProps) => {
  const { button, children, className } = props;
  return (
    <Popover className="relative">
      <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6">
        {button && button}
      </PopoverButton>

      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          className={`absolute right-0 z-50 mt-2 flex w-screen max-w-max px-4 ${className}`}
        >
          {children && children}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default VPopover;
