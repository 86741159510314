import React from "react";

const IconCampaign = ({ iconName, iconClassName }) => {
  return (
    <>
      {iconName === "levelKyc" && (
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.57161" cy="8.00033" r="5.33333" fill={iconClassName} />
          <path
            d="M6.90527 8.00033L8.13254 9.22759C8.19112 9.28617 8.28609 9.28617 8.34467 9.22759L10.9053 6.66699"
            stroke="currentColor"
            stroke-width="1.2"
          />
        </svg>
      )}
      {iconName === "level_congratulation" && (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.5 9H12.5V10.5H9.5V9Z" fill="#DB4437" />
          <path
            d="M13.25 4.5L15.125 6.375V8.25H12.875H9.125H6.875V6.375L8.75 4.5H13.25Z"
            fill="#DB4437"
          />
          <path
            d="M10.25 7.125H9.875C9.37772 7.125 8.90081 6.92746 8.54917 6.57583C8.19754 6.22419 8 5.74728 8 5.25L6.875 6.375V8.25H11V7.125H10.25Z"
            fill="#C62E26"
          />
          <path
            d="M11.75 7.125H12.125C12.6223 7.125 13.0992 6.92746 13.4508 6.57583C13.8025 6.22419 14 5.74728 14 5.25L15.125 6.375V8.25H11V7.125H11.75Z"
            fill="#C62E26"
          />
          <path d="M2.75 15.75H19.25V23.625H2.75V15.75Z" fill="#F4B400" />
          <path d="M2.75 13.875H19.25V23.625H2.75V13.875Z" fill="#F4B400" />
          <path d="M11 13.875H19.25V23.625H11V13.875Z" fill="#F29500" />
          <path
            d="M11.7987 13.875L10.025 18.75H2.75V13.875H11.7987Z"
            fill="#F29500"
          />
          <path
            d="M19.25 13.875V18.75H11.975L11 16.0725V13.875H19.25Z"
            fill="#D88207"
          />
          <path d="M9.5 18H0.125L2 13.875H11L9.5 18Z" fill="#FFC32C" />
          <path d="M12.5 18H21.875L20 13.875H11L12.5 18Z" fill="#FFC32C" />
          <path
            d="M13.25 5.25C13.25 2.5575 11 0.375 11 0.375C11 0.375 8.75 2.5575 8.75 5.25C8.75694 6.52144 8.95076 7.78495 9.32525 9H12.6747C13.0492 7.78495 13.2431 6.52144 13.25 5.25Z"
            fill="#F4B400"
          />
          <path
            d="M13.0392 7.5C12.3715 7.50268 11.7261 7.25954 11.226 6.81692C10.726 6.3743 10.4064 5.76316 10.328 5.1C10.2771 4.65179 10.251 4.2011 10.25 3.75C10.2833 2.63054 10.6118 1.53975 11.2021 0.588C11.0773 0.45 11 0.375 11 0.375C11 0.375 8.75 2.5575 8.75 5.25C8.75694 6.52144 8.95076 7.78495 9.32525 9H12.6747C12.828 8.50826 12.9498 8.00724 13.0392 7.5Z"
            fill="#F29500"
          />
          <path
            d="M11 0.375C10.1672 1.23488 9.5187 2.25572 9.09424 3.375H12.9057C12.4813 2.25572 11.8328 1.23488 11 0.375Z"
            fill="#DB4437"
          />
          <path
            d="M11.2027 0.58875C10.6851 1.43538 10.3647 2.38766 10.2652 3.375H9.09521C9.13646 3.2625 9.17771 3.15375 9.21896 3.04875C9.26396 2.94375 9.31272 2.83875 9.35772 2.74125C9.72159 1.98201 10.1885 1.2766 10.7452 0.645C10.7865 0.6 10.8202 0.5625 10.854 0.52875C10.944 0.4275 11.0002 0.375 11.0002 0.375C11.0002 0.375 11.0752 0.45 11.2027 0.58875Z"
            fill="#C62E26"
          />
          <path
            d="M9.875 9H9.5V10.5H12.5V9.75H10.625C10.4261 9.75 10.2353 9.67098 10.0947 9.53033C9.95402 9.38968 9.875 9.19891 9.875 9Z"
            fill="#C62E26"
          />
          <path
            d="M11 7.125C11.6213 7.125 12.125 6.62132 12.125 6C12.125 5.37868 11.6213 4.875 11 4.875C10.3787 4.875 9.875 5.37868 9.875 6C9.875 6.62132 10.3787 7.125 11 7.125Z"
            fill="#4285F4"
          />
          <path
            d="M11.4838 7.01211C11.3331 7.08583 11.1677 7.1243 11 7.12461C10.7017 7.12461 10.4155 7.00608 10.2045 6.7951C9.99355 6.58413 9.87502 6.29798 9.87502 5.99961C9.87397 5.82438 9.91455 5.6514 9.99344 5.49493C10.0723 5.33846 10.1873 5.20296 10.3288 5.09961C10.3718 5.48289 10.4973 5.85231 10.6966 6.18246C10.896 6.51261 11.1646 6.79565 11.4838 7.01211Z"
            fill="#2B69B2"
          />
          <path d="M10.625 11.25H11.375V13.125H10.625V11.25Z" fill="#DB4437" />
          <path d="M11.75 11.25H12.5V12.375H11.75V11.25Z" fill="#DB4437" />
          <path d="M9.5 11.25H10.25V12.375H9.5V11.25Z" fill="#DB4437" />
          <path d="M9.5 11.25H9.875V12.375H9.5V11.25Z" fill="#C62E26" />
          <path d="M11.75 11.25H12.125V12.375H11.75V11.25Z" fill="#C62E26" />
          <path d="M10.625 11.25H11V13.125H10.625V11.25Z" fill="#C62E26" />
          <path d="M7.625 9H8.375V9.75H7.625V9Z" fill="#4285F4" />
          <path d="M13.625 9H14.375V9.75H13.625V9Z" fill="#4285F4" />
          <path
            d="M16.259 13.2903L15.584 12.9606C15.6785 12.7671 17.9465 8.22738 21.5394 8.6275L21.4569 9.373C18.3916 9.03775 16.2804 13.2479 16.259 13.2903Z"
            fill="#DB4437"
          />
          <path
            d="M21.1273 8.60449L21.0838 8.99749C18.0167 8.65999 15.9054 12.8724 15.8841 12.9147L15.6643 12.8071C15.6212 12.8885 15.5942 12.9425 15.5859 12.9601L16.2609 13.2897C16.2823 13.2474 18.3936 9.03574 21.4607 9.37249L21.5432 8.62699C21.405 8.61225 21.2662 8.60474 21.1273 8.60449Z"
            fill="#C62E26"
          />
          <path
            d="M5.78762 13.2901C5.76662 13.2474 3.6625 9.02262 0.588246 9.37287L0.505371 8.62737C4.10012 8.22837 6.36737 12.767 6.46262 12.9605L5.78762 13.2901Z"
            fill="#DB4437"
          />
          <path
            d="M6.16262 12.9153C6.14162 12.8726 4.0375 8.64784 0.963246 8.99809L0.919746 8.60547C0.781331 8.60565 0.643018 8.61303 0.505371 8.62759L0.588246 9.37309C3.66325 9.02284 5.76662 13.2476 5.78762 13.2903L6.46262 12.9607C6.454 12.9431 6.42737 12.8891 6.38387 12.8077L6.16262 12.9153Z"
            fill="#C62E26"
          />
          <path
            d="M15.8866 10.9341L15.146 10.8167C15.3223 9.94306 15.7409 9.13667 16.3539 8.48971C16.9669 7.84275 17.7496 7.38133 18.6125 7.1582L18.8124 7.8812C18.0884 8.06638 17.4307 8.45045 16.9137 8.98996C16.3966 9.52947 16.0409 10.2029 15.8866 10.9341Z"
            fill="#F4B400"
          />
          <path
            d="M18.6127 7.1582C18.5253 7.1822 18.4473 7.21333 18.3641 7.24033L18.4391 7.5062C17.7152 7.6915 17.0577 8.07562 16.5408 8.61513C16.0239 9.15463 15.6682 9.82799 15.5141 10.5591L15.2178 10.5122C15.1893 10.6124 15.1662 10.7141 15.1484 10.8167L15.8891 10.9341C16.0432 10.203 16.3989 9.52963 16.9158 8.99013C17.4327 8.45062 18.0902 8.0665 18.8141 7.8812L18.6127 7.1582Z"
            fill="#F29500"
          />
          <path
            d="M5.96602 10.9341C5.81192 10.203 5.4563 9.52955 4.93938 8.99003C4.42246 8.45052 3.76488 8.06642 3.04102 7.8812L3.24052 7.1582C4.10318 7.38151 4.88563 7.84301 5.49842 8.48996C6.11122 9.13691 6.52965 9.9432 6.70589 10.8167L5.96527 10.933L6.33614 10.8748L5.96602 10.9341Z"
            fill="#F4B400"
          />
          <path
            d="M6.34109 10.5591C6.187 9.82796 5.83138 9.15455 5.31446 8.61503C4.79754 8.07552 4.13996 7.69142 3.41609 7.5062L3.49109 7.24033C3.40822 7.21333 3.32984 7.1822 3.24247 7.1582L3.04297 7.8812C3.76683 8.06642 4.42441 8.45052 4.94133 8.99003C5.45825 9.52955 5.81387 10.203 5.96797 10.9341L6.33622 10.8748L6.70672 10.8167C6.68897 10.714 6.66594 10.6122 6.63772 10.5118L6.34109 10.5591Z"
            fill="#F29500"
          />
          <path
            d="M1.625 12.375C2.03921 12.375 2.375 12.0392 2.375 11.625C2.375 11.2108 2.03921 10.875 1.625 10.875C1.21079 10.875 0.875 11.2108 0.875 11.625C0.875 12.0392 1.21079 12.375 1.625 12.375Z"
            fill="#DB4437"
          />
          <path
            d="M1.62501 10.875C1.59194 10.8761 1.55898 10.8795 1.52638 10.8851C1.59031 10.9962 1.62429 11.1219 1.62501 11.25C1.62469 11.4314 1.55843 11.6064 1.43859 11.7426C1.31875 11.8787 1.15349 11.9666 0.973633 11.9899C1.038 12.1055 1.13181 12.202 1.24553 12.2696C1.35925 12.3372 1.48883 12.3736 1.62114 12.375C1.75344 12.3763 1.88375 12.3427 1.99884 12.2774C2.11394 12.2121 2.20971 12.1176 2.27646 12.0034C2.34321 11.8891 2.37854 11.7592 2.37889 11.6269C2.37923 11.4946 2.34456 11.3646 2.27841 11.25C2.21225 11.1354 2.11696 11.0404 2.00221 10.9745C1.88745 10.9087 1.75732 10.8743 1.62501 10.875Z"
            fill="#C62E26"
          />
          <path
            d="M20 12.375C20.4142 12.375 20.75 12.0392 20.75 11.625C20.75 11.2108 20.4142 10.875 20 10.875C19.5858 10.875 19.25 11.2108 19.25 11.625C19.25 12.0392 19.5858 12.375 20 12.375Z"
            fill="#4285F4"
          />
          <path
            d="M20 11.25C20.0007 11.1219 20.0347 10.9962 20.0986 10.8851C20.066 10.8795 20.033 10.8761 20 10.875C19.8677 10.8743 19.7375 10.9087 19.6228 10.9745C19.508 11.0404 19.4127 11.1354 19.3466 11.25C19.2804 11.3646 19.2458 11.4946 19.2461 11.6269C19.2464 11.7592 19.2818 11.8891 19.3485 12.0034C19.4153 12.1176 19.511 12.2121 19.6261 12.2774C19.7412 12.3427 19.8715 12.3763 20.0038 12.375C20.1361 12.3736 20.2657 12.3372 20.3795 12.2696C20.4932 12.202 20.587 12.1055 20.6513 11.9899C20.4715 11.9666 20.3062 11.8787 20.1864 11.7426C20.0665 11.6064 20.0003 11.4314 20 11.25Z"
            fill="#2B69B2"
          />
          <path
            d="M20 7.5C20.4142 7.5 20.75 7.16421 20.75 6.75C20.75 6.33579 20.4142 6 20 6C19.5858 6 19.25 6.33579 19.25 6.75C19.25 7.16421 19.5858 7.5 20 7.5Z"
            fill="#DB4437"
          />
          <path
            d="M20 6.37501C20.0007 6.2469 20.0347 6.12116 20.0986 6.01014C20.066 6.00448 20.033 6.0011 20 6.00001C19.8677 5.99933 19.7375 6.03365 19.6228 6.09951C19.508 6.16537 19.4127 6.26041 19.3466 6.375C19.2804 6.48958 19.2458 6.61963 19.2461 6.75194C19.2464 6.88424 19.2818 7.01411 19.3485 7.12835C19.4153 7.24259 19.511 7.33714 19.6261 7.40241C19.7412 7.46767 19.8715 7.50133 20.0038 7.49996C20.1361 7.49859 20.2657 7.46225 20.3795 7.39462C20.4932 7.32699 20.587 7.23048 20.6513 7.11488C20.4715 7.09159 20.3062 7.00369 20.1864 6.86756C20.0665 6.73143 20.0003 6.55637 20 6.37501Z"
            fill="#C62E26"
          />
          <path
            d="M1.625 7.5C2.03921 7.5 2.375 7.16421 2.375 6.75C2.375 6.33579 2.03921 6 1.625 6C1.21079 6 0.875 6.33579 0.875 6.75C0.875 7.16421 1.21079 7.5 1.625 7.5Z"
            fill="#4285F4"
          />
          <path
            d="M1.62501 6.00001C1.59194 6.0011 1.55898 6.00448 1.52638 6.01014C1.59031 6.12116 1.62429 6.2469 1.62501 6.37501C1.62469 6.55637 1.55843 6.73143 1.43859 6.86756C1.31875 7.00369 1.15349 7.09159 0.973633 7.11488C1.038 7.23048 1.13181 7.32699 1.24553 7.39462C1.35925 7.46225 1.48883 7.49859 1.62114 7.49996C1.75344 7.50133 1.88375 7.46767 1.99884 7.40241C2.11394 7.33714 2.20971 7.24259 2.27646 7.12835C2.34321 7.01411 2.37854 6.88424 2.37889 6.75194C2.37923 6.61963 2.34456 6.48958 2.27841 6.375C2.21225 6.26041 2.11696 6.16537 2.00221 6.09951C1.88745 6.03365 1.75732 5.99933 1.62501 6.00001Z"
            fill="#2B69B2"
          />
          <path
            d="M17.375 1.125L17.9544 2.3595L19.25 2.5575L18.3125 3.51825L18.5338 4.875L17.375 4.2345L16.2162 4.875L16.4375 3.51825L15.5 2.5575L16.7956 2.3595L17.375 1.125Z"
            fill="#F4B400"
          />
          <path
            d="M16.577 2.39258L15.5 2.5572L16.4375 3.51795L16.2162 4.8747L17.375 4.2342L18.5338 4.8747L18.3414 3.69533C17.9708 3.61811 17.6213 3.46172 17.3167 3.23687C17.0122 3.01202 16.7599 2.72401 16.577 2.39258Z"
            fill="#F29500"
          />
          <path
            d="M5 1.125L5.57938 2.3595L6.875 2.5575L5.9375 3.51825L6.15875 4.875L5 4.2345L3.84125 4.875L4.0625 3.51825L3.125 2.5575L4.42062 2.3595L5 1.125Z"
            fill="#F4B400"
          />
          <path
            d="M4.202 2.39258L3.125 2.5572L4.0625 3.51795L3.84125 4.8747L5 4.2342L6.15875 4.8747L5.96637 3.69533C5.59579 3.61811 5.24627 3.46172 4.94175 3.23687C4.63722 3.01202 4.38488 2.72401 4.202 2.39258Z"
            fill="#F29500"
          />
          <path d="M7.625 9H8V9.75H7.625V9Z" fill="#2B69B2" />
          <path d="M13.625 9H14V9.75H13.625V9Z" fill="#2B69B2" />
        </svg>
      )}
    </>
  );
};

export default IconCampaign;
