import "./styles.scss";

const Progress = ({ value, handleChange }) => {
  return (
    <>
      <div className="input-range ">
        <div className="dots">
          {Array(5)
            .fill(0)
            .map((_, i) => (
              <div key={i} onClick={() => handleChange(i * 25)}>
                <span>{25 * i}%</span>
              </div>
            ))}
        </div>
        <input
          className="slider"
          type={"range"}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </>
  );
};

export default Progress;
