import { useContext, useMemo, useState } from "react";
import Modal from "components/v-core/v-modal";
import UploadFiles from "./UploadFiles";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";
import Icons from "helper/icons";
import VButton from "components/v-core/v-button";
import { RiImageAddLine } from "react-icons/ri";
import { formatNumber } from "utils/common";
import ToolTip from "components/v-tooltip";
import VReward from "components/v-reward";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type IProps = {
  campaignId: string;
  levels?: any;
  level?: any;
};

const ProgressCircles = ({ levels, level, campaignId }: IProps) => {
  const { auth } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const [isModal, setIsModal] = useState(false);
  const [isShowModalUpload, setIsShowModalUpload] = useState(false);

  const levelCurrent = (levels || []).find((l) => l.id === level?.level_id);

  const LEVEL_DEFAULT = {
    images: [],
    attachments: [],
    videos: [],
    percent_address: 0,
    percent_leader: 0,
    total_f1: 0,
    total_my_invest: 0,
    total_user: 0,
    total_user_invest: 0,
    user_revenue: 0,
  };

  const myLevel = useMemo(
    () => ({
      ...LEVEL_DEFAULT,
      ...level,
    }),
    [level]
  );

  const openModalUpload = (data: any) => {
    setIsShowModalUpload(true);
  };

  const renderItem = (
    name: string,
    enable: boolean,
    amount: number,
    target: number
  ) => {
    try {
      if (!enable) return "";
      return (
        <div className="flex flex-col ">
          <div className="flex items-center gap-x-1">
            <Icons
              iconName="levelKyc"
              iconClassName={(amount || 0) >= target ? "green" : "gray"}
            />
            <span className="text-[12px] "> {name}:</span>
          </div>
          <span className="text-[12px] text-center">
            {`${amount || 0}/${target || 0}`}
          </span>
        </div>
      );
    } catch (error) {
      return "";
    }
  };

  const renderReward = (p: any) => {
    return (
      <div>
        <VReward reward={p.reward} />
      </div>
    );
  };

  const renderAffilate = (p: any) => {
    const _affiliates = Object.keys(p.affiliates || {})?.splice(0, 3);
    return (
      <div>
        {_affiliates.map((key) => {
          const reward = p.affiliates[key];
          return <VReward key={key} reward={reward} />;
        })}
      </div>
    );
  };

  return (
    <div className="mt-1">
      <nav aria-label="Progress" className="w-full">
        <ol
          role="list"
          className={`w-full flex items-center z-10 pl-20 ml-0 overflow-y-auto`}
        >
          {levels.map((level, stepIdx) => (
            <div
              key={level.name}
              className={classNames(
                stepIdx !== levels.length - 1 ? "w-[200px]" : "!w-10"
              )}
            >
              {/* level current */}
              <div className="h-16">
                {levelCurrent?.id === level.id && (
                  <>
                    <div className="relative top-0 -left-1 w-12 h-12 rounded-full">
                      <img
                        className="w-full h-full rounded-full object-cover"
                        src={auth.avatar}
                        alt=""
                      />
                    </div>
                    <div
                      className="relative top-0 left-5 h-4 border-l-2 border-dashed"
                      style={{
                        borderColor: themesSetting.layout.textContent,
                      }}
                    />
                  </>
                )}
              </div>
              <li
                className="relative"
                // style={{ width: `${100 / levels.length + 3}%` }}
              >
                <div
                  className="absolute inset-0 flex items-center z-0"
                  aria-hidden="true"
                >
                  <div
                    className="h-0.5 w-full"
                    style={{
                      backgroundColor: themesSetting.layout.textContent,
                    }}
                  />
                </div>
                <div
                  className="relative group flex flex-col h-10 w-10 items-center justify-center border-1 rounded-full p-2 z-10"
                  style={
                    levelCurrent?.id === level.id
                      ? { backgroundColor: themesSetting.layout.textContent }
                      : {
                          borderColor: themesSetting.layout.textContent,
                          backgroundColor: themesSetting.layout.backgroundColor,
                        }
                  }
                >
                  {/* logo level */}

                  <img
                    className="w-full h-full rounded-full object-cover"
                    src={level.logoLevel}
                    alt=""
                  />
                </div>
              </li>

              {/* detail */}
              <div className="relative w-[200px] -left-14 flex flex-col justify-center">
                <div
                  className="relative left-[75px] h-6 border-l-2 border-dashed"
                  style={{ borderColor: themesSetting.layout.textContent }}
                />
                <div
                  className={`relative w-[150px] h-[220px] flex flex-col gap-y-1 font-bold ${
                    level.backgroundLevel && "border-1 text-white"
                  } rounded p-2`}
                  style={
                    level.backgroundLevel
                      ? {
                          borderColor: themesSetting.layout.textContent,
                          background: `url('${level.backgroundLevel}')`,
                        }
                      : themesSetting.progress
                  }
                >
                  <span className="font-bold text-center">{level.name}</span>

                  {/* render progress */}
                  <div
                    className="relative w-full border-1 rounded-full"
                    style={{ borderColor: themesSetting.layout.textContent }}
                  >
                    <div
                      className="py-2 rounded-full"
                      style={{
                        width: `${
                          ((myLevel?.images?.length +
                            myLevel?.attachments?.length +
                            myLevel?.videos?.length +
                            (myLevel?.data?.total_f1 || 0) +
                            (myLevel?.data?.total_my_invest || 0) +
                            (myLevel?.data?.total_user || 0) +
                            (myLevel?.data?.total_user_invest || 0)) /
                            (level.amount_image +
                              level.amount_attachment +
                              level.amount_video +
                              level.total_f1 +
                              level.total_my_invest +
                              level.total_user +
                              level.total_user_invest)) *
                            100 || 0
                        }%`,
                        backgroundColor: themesSetting.layout.textContent,
                      }}
                    ></div>
                    <span className="absolute-center text-xs font-medium leading-none">
                      {formatNumber(
                        ((myLevel?.images?.length +
                          myLevel?.attachments?.length +
                          myLevel?.videos?.length +
                          (myLevel?.data?.total_f1 || 0) +
                          (myLevel?.data?.total_my_invest || 0) +
                          (myLevel?.data?.total_user || 0) +
                          (myLevel?.data?.total_user_invest || 0)) /
                          (level.amount_image +
                            level.amount_attachment +
                            level.amount_video +
                            level.total_f1 +
                            level.total_my_invest +
                            level.total_user +
                            level.total_user_invest)) *
                          100 || 0
                      )}
                      %
                    </span>
                  </div>
                  <div className="max-h-[120px] overflow-y-auto">
                    {renderItem(
                      "Amount image",
                      level.enable_amount_image,
                      myLevel?.images?.length,
                      level.amount_image
                    )}
                    {renderItem(
                      "Amount attachment",
                      level.enable_amount_attachment,
                      myLevel?.attachments?.length,
                      level.amount_attachment
                    )}
                    {renderItem(
                      "Amount video",
                      level.enable_amount_video,
                      myLevel?.videos?.length,
                      level.amount_video
                    )}
                    {renderItem(
                      "Total f1",
                      level.enable_total_f1,
                      myLevel?.data?.total_f1,
                      level.total_f1
                    )}
                    {renderItem(
                      "Revenue myself",
                      level.enable_total_my_invest,
                      myLevel?.data?.total_my_invest,
                      level.total_my_invest
                    )}
                    {renderItem(
                      "Total user",
                      level.enable_total_user,
                      myLevel?.data?.total_user,
                      level.total_user
                    )}
                    {renderItem(
                      "Revenue my branch",
                      level.enable_total_user_invest,
                      myLevel?.data?.total_user_invest,
                      level.total_user_invest
                    )}

                    {level.enable_user_revenue && (
                      <div className="flex flex-col ">
                        <div className="flex items-center gap-x-1">
                          <Icons
                            iconName="levelKyc"
                            iconClassName={
                              myLevel.data.qty_user_revenue >=
                              (level.qty_user_revenue || 0)
                                ? "green"
                                : "gray"
                            }
                          />
                          <span className="text-[12px] "> Require branch:</span>
                        </div>
                        <span className="text-[12px] text-center">
                          {level.qty_user_revenue} /{" "}
                          {formatNumber(level.amount_user_revenue)}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* render icon */}
                  <div className="absolute bottom-2 w-full">
                    <hr
                      className="border-dashed my-1"
                      style={{
                        borderColor: themesSetting.layout.textContent,
                      }}
                    />
                    <div className="flex justify-center items-center gap-2">
                      {(level.enable_amount_image ||
                        level.enable_amount_video ||
                        level.enable_amount_attachment) && (
                        <span onClick={openModalUpload}>
                          <RiImageAddLine size={22} />
                        </span>
                      )}
                      <ToolTip
                        id={`${campaignId}_gift`}
                        content={renderReward(level)}
                      >
                        <span
                          id={`tooltip-` + `${campaignId}_gift`}
                          className="gift"
                        >
                          <Icons iconName="gift" />
                        </span>
                      </ToolTip>
                      <ToolTip
                        id={`${campaignId}_aff`}
                        content={renderAffilate(level)}
                      >
                        <span
                          id={`tooltip-` + `${campaignId}_aff`}
                          className="gift"
                        >
                          <Icons iconName="connect" />
                        </span>
                      </ToolTip>
                    </div>
                  </div>
                </div>
                <div className="relative left-[20px] h-[56px] mt-2">
                  {levelCurrent?.id === level.id && (
                    <div className=" w-[110px] pb-3">
                      <VButton
                        radius="full"
                        className="w-full text-ellipsis overflow-hidden"
                        iconLeft={<Icons iconName="level_congratulation" />}
                        onClick={() => setIsModal(true)}
                      ></VButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </ol>
      </nav>

      {isModal && (
        <Modal
          isOpen={isModal}
          onCancel={() => setIsModal(false)}
          title="Congratulation !"
        />
      )}

      <Modal
        isOpen={isShowModalUpload}
        onCancel={() => setIsShowModalUpload(false)}
      >
        <UploadFiles
          campaignId={campaignId}
          setOpenModalUpload={setIsShowModalUpload}
        />
      </Modal>
    </div>
  );
};

export default ProgressCircles;
