import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext } from "react";

const LoanEstimate = () => {
  const { themesSetting } = useContext(ThemeContext);
  return (
    <>
      <div className="flex items-center">
        <div className="w-1/2 flex flex-col gap-y-2 mr-2">
          <div className="flex w-full justify-between items-center">
            <div className="flex-1">
              <VInput label={"Monthly income (VND)"} type="number" />
            </div>
            <div className="flex-none">
              <VButton
                type="outline"
                className="!border-none"
                iconLeft={<Icons iconName="stock_swap" />}
              />
            </div>
            <div className="flex-1">
              <VInput
                isBorder={false}
                label={"Maximum"}
                value="10,0000,000"
                readOnly={true}
              />
            </div>
          </div>
          <div className="w-full flex gap-x-2">
            <label
              className="w-[100px]"
              htmlFor="loan"
            >{`You need a loan (VND)`}</label>
            <input value={100} type="range" />
          </div>
          <div className="w-full flex gap-x-2">
            <label
              className="w-[100px]"
              htmlFor="loan"
            >{`During that time`}</label>
            <input value={100} type="range" />
          </div>
          <div className="w-full flex flex-col items-start">
            <span>{`Minimum illustrated interest rate (%/year)`}</span>
            <span className="text-base font-semibold">10,39%</span>
          </div>
        </div>
        <div
          className="w-1/2 rounded h-full flex flex-col items-center justify-center gap-y-2 p-3"
          style={themesSetting.container}
        >
          <span>{`Estimated monthly payments (VND)`}</span>
          <span>{`9,215,779`}</span>
          <span className="text-sm underline">Full display</span>
          <VButton className="w-full" radius="full">
            Loan
          </VButton>
          <span className="text-[12px] opacity-70">
            * Information and results are for reference only.
          </span>
        </div>
      </div>
    </>
  );
};

export default LoanEstimate;
