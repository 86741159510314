import { formatNumber } from "utils/common";

const VReward = ({ reward }) => {
  return (
    <div>
      {reward["stocks"] && reward["stocks"].length > 0 && (
        <>
          <label>Stocks :</label>
          {reward["stocks"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} {s.is_percent ? "%" : ""} (
                {String(s.symbol).toUpperCase()})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}
      {reward["vouchers"] && reward["vouchers"].length > 0 && (
        <>
          <label>Vouchers :</label>
          {reward["vouchers"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} ({s.id})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}

      {reward["tickets"] && reward["tickets"].length > 0 && (
        <>
          <label>Tickets :</label>
          {reward["tickets"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} ({s.game_name})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}

      {reward["codes"] && reward["codes"].length > 0 && (
        <>
          <label>Codes :</label>
          {reward["codes"].map((s) => (
            <div>
              <span>
                +{s.codes} ({s.symbol})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}
    </div>
  );
};

export default VReward;
