import { useEffect, useState } from "react";
import ModalViewRequest from "components/modal-view-request";

import VTable from "components/v-core/v-table";
import SupportService from "api/supports";
import VButton from "components/v-core/v-button";
import { renderStatus } from "utils/render";

const History = () => {
  const columnsMyProjects = [
    {
      title: "Title",
      field: "title",
      key: "title",
      // render: (data: any) => <span>{data?.projectId?.name}</span>,
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString("en-GB")}</span>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (data: any) => renderStatus(data?.status),
    },
    {
      title: "Action",
      key: "action",
      render: (data: any) => (
        <VButton
          onClick={() => {
            handleAction(data);
            setOpenModal(true);
          }}
        >
          Detail
        </VButton>
      ),
    },
  ];

  const [dataTable, setDataTable] = useState([]);
  const [selectData, setSelectData] = useState(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    getSupports();
  }, []);

  const handleAction = (data: any) => {
    setSelectData(data);
  };

  const getSupports = async () => {
    try {
      const mySupports: any = await SupportService.getSupportsByUser();
      setDataTable(mySupports.support);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="p-4">
      <VTable columns={columnsMyProjects} data={dataTable} />
      <ModalViewRequest
        type={"support"}
        openModal={openModal}
        selectData={selectData}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default History;
