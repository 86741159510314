import { ThemeContext } from "context/ThemeContext";
import React, { useContext } from "react";
import { formatNumber } from "utils/common";

type IPrps = {
  item?: any;
};

const Profits = ({ item }: IPrps) => {
  const { themesSetting } = useContext(ThemeContext);
  return (
    <div className="flex snap-x w-full text-[12px] overflow-auto no-scrollbar">
      {(item?.profits || []).map((el: any, index: number) => (
        <div
          key={index}
          className="min-w-full flex flex-col justify-center snap-center gap-y-2"
        >
          <div className="flex flex-col items-center gap-y-1">
            <span className="text-base font-bold">Trust times</span>
            <div className="flex items-center gap-x-1">
              <span>{el?.time_lock}</span>
              <span>{item.type_lock}s</span>
            </div>
            <div className="flex items-center gap-x-1">
              <span className="opacity-70">Min claim reward:</span>
              <span>{formatNumber(item.require.min_withdraw_profit)}</span>
              <span>{String(el?.stock).toUpperCase()}</span>
            </div>
          </div>
          <hr
            className="w-full border-b border-dashed m-0"
            style={{ borderColor: themesSetting.layout.textContent }}
          />
          <div className="flex flex-col items-center justify-center gap-y-1">
            <span className="text-base font-bold">Trust reward</span>
            <div className="flex items-center gap-x-1">
              <span>{el?.time_lock}</span>
              <span>{item.type_lock}s</span>
              <span>{`(${String(el?.stock).toUpperCase()})`}</span>
            </div>
            <div className="flex items-center gap-x-1">
              <span className="opacity-70">Total:</span>
              <span>{`${formatNumber(
                (+el?.percent * +item?.invest.from) / 100
              )} => ${formatNumber(
                (+el?.percent * +item?.invest.to) / 100
              )}`}</span>
            </div>
            <div className="flex items-center gap-x-1">
              <span className="opacity-70">
                {item.type_lock === "month"
                  ? "Monthly"
                  : item.type_lock === "day"
                  ? "Bonus daily"
                  : "Weekly"}
                :
              </span>
              <span>
                {" "}
                {`${formatNumber(
                  (el?.percent * item.invest?.from) / +el?.time_lock / 100
                )} => ${formatNumber(
                  (el?.percent * item.invest?.to) / +el?.time_lock / 100
                )} `}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Profits;
