import styled from "styled-components";

export const Container = styled.div`
  margin-top: 70px;
  background-color: ${(props) => props.theme.colors.black100};
  padding: 14px;
  overflow: auto;
  min-height: 500px;
`;

export const WrapperForm = styled.div``;

export const StageWrapper = styled.div`
  .btn-delete {
    margin: 5px 0;
    padding: 0 15px;
  }
`;

export const UpdateWrapper = styled.div`
  label {
    height: 200px;
    max-width: 300px;
  }
`;

export const UploadWrapper = styled.div`
  label {
    height: 200px;
    max-width: 300px;
  }
`;

export const PixelWrapper = styled.div``;

export const UploadData = styled.div``;

export const PixelItems = styled.div``;

export const PixelItem = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid;
  margin: 1px;
  text-align: center;
  line-height: 25px;
  img {
    width: 25px;
    height: 25px;
  }
  svg {
    width: 100%;
  }
`;

export const ModalWrapper = styled.div`
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
`;

export const EditWrapper = styled.div`
  .upload-wrapper {
    height: 155px !important;
    display: flex;
    justify-content: center;
    label {
      width: 150px;
      height: 150px;
    }
  }
  .action {
    margin-top: 20px;
  }
`;

export const SeletedPixel = styled.div`
  div {
    color: black;
  }
`;
