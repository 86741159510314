import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const getPurchase = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/purchases/${req.params.type}/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getOrderByCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/purchases/code/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const buyProduct = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/purchases/buy/product`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const buyIBO = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/purchases/buy/project`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const investPackage = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/purchases/buy/package`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const claim = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/purchases/claim`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInvoice = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/purchases/invoice/${req.params.id}/${req.params.type}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      });
  });

const getPurchaseByPixelId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/purchases/by-pixel/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPurchaseByUserId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/purchases/packages/${req.params.user_id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getHistoriesProfit = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `/purchases/getHistoriesProfit/${req.params.purchaseId}?${qs.stringify(
          req.query
        )}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PurchaseService = {
  getPurchase,
  buyProduct,
  getOrderByCode,
  buyIBO,
  getInvoice,
  claim,
  investPackage,
  getPurchaseByPixelId,
  getPurchaseByUserId,
  getHistoriesProfit,
};

export default PurchaseService;
