/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import VTable from "components/v-core/v-table";
import { useEffect, useState } from "react";

const LevelHistory = ({ campaignId }) => {
  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (item: any) => (
        <span>{item.user.fullName || item.user.username}</span>
      ),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      render: (item: any) => <span>{item.user.email}</span>,
    },
    {
      key: "levelId",
      title: "Level",
      dataIndex: "levelId",
      render: (item: any) => {
        const level = item?.campaign?.levels.find(
          (el) => el.id === item.level_id
        );
        return <span>{level.name}</span>;
      },
    },
  ];

  const [levels, setLevels] = useState({
    docs: [],
  });

  const getLevels = async () => {
    try {
      const res: any = await APIs.TASK.getLevels({
        params: {
          campaignId: campaignId,
        },
      });
      if (res && res.docs) {
        setLevels(res);
      }
    } catch (error) {
      // console.log();
    }
  };

  useEffect(() => {
    if (campaignId) {
      getLevels();
    }
  }, []);

  return (
    <>
      <div>
        <VTable columns={columns} data={levels.docs} />
      </div>
    </>
  );
};

export default LevelHistory;
