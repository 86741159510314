import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "context/ThemeContext";
import SettingService from "api/settings";
import Icons from "helper/icons";
import VTable from "components/v-core/v-table";
import {
  renderAmount,
  renderStatus,
  renderStock,
  renderTransactionType,
} from "utils/render";
import TransactionService from "api/transactions";
import { MasterContext } from "context/MasterContext";
import { formatNumber } from "utils/common";
import VBanner from "components/v-banner";

const columns = [
  {
    title: "From",
    dataIndex: "from",
    key: "from",
    render: (data: any) => <span>{data.from}</span>,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (data: any) => <span>{renderTransactionType(data.type)}</span>,
  },
  {
    title: "Asset",
    dataIndex: "asset",
    key: "asset",
    render: (data: any) => <span>{data.token}</span>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (data: any) => (
      <span color="orange100">{renderAmount(data.amount)}</span>
    ),
  },
  {
    title: "Amount Received",
    dataIndex: "amountReceived",
    key: "amountReceived",
    render: (data: any) => (
      <span color="orange100">
        {renderAmount(data.amountReceived || data.amount)}
      </span>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (data: any) => renderStatus(data.status),
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (data: any) => (
      <span>{new Date(data.createdAt).toLocaleString()}</span>
    ),
  },
];

const Dashboard = () => {
  const { balances, stocks, stocksPrice } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const [banner, setBanner] = useState<any>({});
  const [histories, setHistories] = useState({
    docs: [],
  });

  useEffect(() => {
    getHistory();
    onGetBanner();
  }, []);

  const getHistory = async () => {
    try {
      const res: any = await TransactionService.getTransactions({
        query: {
          limit: 10,
          page: 1,
        },
      });
      setHistories(res);
    } catch (error) {}
  };

  const onGetBanner = async () => {
    try {
      const res: any = await SettingService.getSettingByName("banner");
      if (res && res?.setting) {
        setBanner(res?.setting?.value);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full flex flex-col gap-y-4 mt-6 px-3 mx-auto">
      <div className="w-full grid gap-4 grid-cols-4">
        {stocks.map(
          (stock) =>
            stock.allow_show?.includes("dashboard") && (
              <div
                className="flex gap-x-4 rounded-lg p-3 col-span-2 max-sm:col-span-4 xl:col-span-1"
                style={themesSetting?.card}
              >
                <div className="w-1/2 flex flex-col gap-y-2">
                  <div className="flex items-center gap-x-2">
                    <img
                      className="w-4 h-4 rounded-full object-cover"
                      src={stock.logo}
                      alt="#"
                    />
                    <span className="text-sm">{stock.name}</span>
                  </div>
                  <div className="flex items-center gap-x-1">
                    <span> {formatNumber(balances[stock.symbol] || 0)}</span>
                    <span>{renderStock(stock.symbol)}</span>
                  </div>
                  <div className="flex items-center gap-x-1">
                    <span className="text-xs font-thin">
                      {formatNumber(stocksPrice[stock.symbol]?.vnd)}
                    </span>
                    <span className="text-xs font-thin">VND</span>
                  </div>
                  <div className="flex items-center gap-x-1">
                    <Icons iconName="arrow-up" />
                    <span className="text-xs text-green-500">+0%</span>
                  </div>
                </div>
                <div className="w-1/2 flex justify-center">
                  <Icons iconName="chart-up" />
                </div>
              </div>
            )
        )}
      </div>
      {banner && (
        <div className="w-full h-auto">
          <VBanner
            data={banner}
            classVideoName={"h-[200px] md:h-[400px] lg:h-[70vh]"}
          />
        </div>
      )}
      <div className="flex flex-col gap-y-2 mt-2">
        <span className="text-base font-semibold">Transactions</span>
        <VTable columns={columns} data={histories.docs} />
      </div>
    </div>
  );
};

export default Dashboard;
