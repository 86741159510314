import axios from "axios";

const getRateUSDTToVND = async (): Promise<Number> => {
  try {
    const res: any = await axios.get(
      "https://api.exchangerate-api.com/v4/latest/USD",
      {}
    );
    if (res.data) {
      return res.data["rates"]["VND"] || 23500;
    }
    return 23500;
  } catch (error) {
    return 23500;
  }
};

export { getRateUSDTToVND };
