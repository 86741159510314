import {
  HeadProfit,
  ItemProfit,
  ContainerInput,
  Action,
  ItemShare,
  Title,
  Detail,
  Arrow,
  TotalAmountShare,
  ContainerReferCode,
  ItemTopShare,
} from "../styles";
import { AddField } from "pages/my/my-project/styles";

import Input from "components/v-core/v-input";
import Dropdown from "components/v-core/v-dropdown";

import ReferCodeSection from "../refer_code_section";

import { formatCurrency } from "utils/common";
import { useContext } from "react";
import { MasterContext } from "context/MasterContext";

const View = (props: any) => {
  const {
    isCreate,
    isWebview,
    dataProfit,
    dataListFriends,
    summary,
    symbol,
    // onChangeNote,
    onChangeAmount,
    onChangePercent,
    onChangeStockName,
    handleAddField,
    handleRemoveField,
    onChangeAffilate,
  } = props;

  const { stocks } = useContext(MasterContext);

  return (
    <>
      {!isCreate && (
        <>
          {" "}
          <HeadProfit>
            Total profit sharing
            <Input
              type="text"
              transparent={!isWebview}
              color={isWebview ? "black" : "white"}
              placeholder="$10,000,000"
              isCurrency={true}
              allowNegativeValue={false}
              value={dataProfit?.totalProfitSharingSetting?.total}
              onValueChange={(e) => onChangeAmount(e, "total")}
            />
            &nbsp; VNEX
          </HeadProfit>
          <ItemProfit isWebview={isWebview}>
            <Title>Admin profit received</Title>
            <Detail>
              <ItemTopShare>
                <ContainerInput>
                  <Input
                    transparent={!isWebview}
                    color={isWebview ? "black" : "white"}
                    type="number"
                    label="% profit received"
                    placeholder="0"
                    suffixIcon="%"
                    value={
                      dataProfit?.totalProfitSharingSetting?.admin?.percent
                    }
                    onChange={(e) =>
                      onChangePercent(e, "admin", "totalProfitSharingSetting")
                    }
                  />
                </ContainerInput>
                {/* <ContainerInput>
              <Input
                transparent={!isWebview}
                color={isWebview ? "black" : "white"}
                label="Note"
                placeholder="note"
                value={
                  dataProfit?.totalProfitSharingSetting?.admin?.note
                }
                onChange={(e) =>
                  onChangeNote(e, "admin")
                }
              />
            </ContainerInput> */}
              </ItemTopShare>
            </Detail>
            <TotalAmountShare>
              <span>
                Total percent: {summary.admin.percent}% &ensp;{" "}
                <Arrow isTotal={true}>⟵</Arrow> &ensp;
                {formatCurrency(summary.admin.vnex)} VNEX
              </span>
            </TotalAmountShare>
          </ItemProfit>
        </>
      )}
      <ItemProfit isWebview={isWebview}>
        <Title>Profit shareholder received (Airdrop)</Title>
        <Detail>
          <ItemTopShare>
            <ContainerInput>
              <Dropdown
                key={dataProfit?.totalProfitSharingSetting?.shareholder?.estock}
                // hasError={errorField['stock_to_buy']}
                label="e.NFT stock received"
                options={stocks?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.symbol,
                  };
                })}
                value={
                  dataProfit?.totalProfitSharingSetting?.shareholder?.estock ||
                  "Choose the stock"
                }
                onChange={(stock) =>
                  onChangeStockName(
                    stock,
                    "shareholder",
                    "totalProfitSharingSetting"
                  )
                }
              />
            </ContainerInput>
            {/* <ContainerInput>
              <Input
                transparent={!isWebview}
                color={isWebview ? "black" : "white"}
                label="Note"
                placeholder="note"
                value={
                  dataProfit?.totalProfitSharingSetting?.shareholder?.note
                }
                onChange={(e) =>
                  onChangeNote(e, "shareholder")
                }
              />
            </ContainerInput> */}
          </ItemTopShare>
          {(isCreate || isWebview) && (
            <Action>
              <AddField
                bgColor="green100"
                onClick={() =>
                  handleAddField("shareholder", "totalProfitSharingSetting")
                }
              >
                +
              </AddField>
              <AddField
                bgColor="red100"
                onClick={() =>
                  handleRemoveField("shareholder", "totalProfitSharingSetting")
                }
              >
                -
              </AddField>
            </Action>
          )}
          {dataProfit.totalProfitSharingSetting?.shareholder?.option?.length >
            0 &&
            dataProfit.totalProfitSharingSetting?.shareholder?.option?.map(
              (el: any, index: number) => (
                <ItemShare>
                  <ContainerInput width="200px">
                    <Input
                      transparent={!isWebview}
                      color={isWebview ? "black" : "white"}
                      type="text"
                      label={`From`}
                      placeholder="0"
                      isCurrency={true}
                      allowNegativeValue={false}
                      suffixIcon={`${symbol}`}
                      disabled={isWebview ? false : !isCreate}
                      value={el?.from}
                      // hasError={errors[`tokenomics${index}${index}${active}`]}
                      onValueChange={(e) =>
                        onChangeAffilate(
                          index,
                          "from",
                          e,
                          "shareholder",
                          "totalProfitSharingSetting",
                          "currency"
                        )
                      }
                    />
                  </ContainerInput>
                  <Arrow>⟵</Arrow>
                  <ContainerInput width="200px">
                    <Input
                      transparent={!isWebview}
                      color={isWebview ? "black" : "white"}
                      type="text"
                      label={`To`}
                      isCurrency={true}
                      allowNegativeValue={false}
                      // placeholder="0"
                      suffixIcon={`${symbol}`}
                      disabled={isWebview ? false : !isCreate}
                      value={el?.to}
                      // hasError={errors[`tokenomics${index}${index}${active}`]}
                      onValueChange={(e) =>
                        onChangeAffilate(
                          index,
                          "to",
                          e,
                          "shareholder",
                          "totalProfitSharingSetting",
                          "currency"
                        )
                      }
                    />
                  </ContainerInput>
                  <ContainerInput width="120px">
                    <Input
                      transparent={!isWebview}
                      color={isWebview ? "black" : "white"}
                      type="number"
                      label="% profit received"
                      placeholder="0"
                      suffixIcon="%"
                      disabled={isWebview ? false : !isCreate}
                      value={el?.percent}
                      // hasError={errors[`tokenomics${index}${index}${active}`]}
                      onChange={(e) =>
                        onChangeAffilate(
                          index,
                          "percent",
                          e,
                          "shareholder",
                          "totalProfitSharingSetting"
                        )
                      }
                    />
                  </ContainerInput>
                </ItemShare>
              )
            )}
        </Detail>
        {
          <TotalAmountShare>
            <span>
              Total percent: {summary.shareholder.percent}% &ensp;
              {!isCreate && (
                <>
                  <Arrow isTotal={true}>⟵</Arrow>&ensp;
                  {formatCurrency(summary.shareholder.vnex)} VNEX
                </>
              )}
            </span>
          </TotalAmountShare>
        }
      </ItemProfit>
      {!isCreate && (
        <ItemProfit isWebview={isWebview} noBottom={true}>
          <Title>Marketing specialist</Title>
          <Detail>
            <ContainerInput>
              <Dropdown
                key={dataProfit?.totalProfitSharingSetting?.presenter?.estock}
                label="e.NFT stock received"
                options={stocks?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.symbol,
                  };
                })}
                value={
                  dataProfit?.totalProfitSharingSetting?.presenter?.estock ||
                  "Choose the stock"
                }
                onChange={(stock) =>
                  onChangeStockName(
                    stock,
                    "presenter",
                    "totalProfitSharingSetting"
                  )
                }
              />
            </ContainerInput>
            <Action>
              <AddField
                bgColor="green100"
                onClick={() =>
                  handleAddField("presenter", "totalProfitSharingSetting")
                }
              >
                +
              </AddField>
              <AddField
                bgColor="red100"
                onClick={() =>
                  handleRemoveField("presenter", "totalProfitSharingSetting")
                }
              >
                -
              </AddField>
            </Action>
            <ContainerReferCode>
              {dataProfit.totalProfitSharingSetting?.presenter?.option?.length >
                0 && (
                <ReferCodeSection
                  dataProfit={
                    dataProfit.totalProfitSharingSetting?.presenter?.option
                  }
                  dataListFriends={dataListFriends}
                  field={"presenter"}
                  type={"totalProfitSharingSetting"}
                  isWebview={isWebview}
                  onChangeAffilate={onChangeAffilate}
                />
              )}
            </ContainerReferCode>
          </Detail>
          <TotalAmountShare>
            <span>
              Total percent: {summary.presenter.percent}% &ensp;
              <Arrow isTotal={true}>⟵</Arrow> &ensp;
              {formatCurrency(summary.presenter.vnex)} VNEX
            </span>
          </TotalAmountShare>
        </ItemProfit>
      )}
    </>
  );
};

export default View;
