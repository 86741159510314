import styled from "styled-components";

export const TransferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0px;
`;

export const StockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Stock = styled.div`
  position: relative;
  padding: 0 10px;
  margin-bottom: 8px;
  background: ${(props) => props.theme.colors["white100"]};
  color: ${(props) => props.theme.colors["black100"]};
  height: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  img:first-child {
    width: 25px;
  }
  p {
    margin: 0;
    margin-left: 6px;
    margin-right: 8px;
    font-weight: bold;
  }
`;

export const Available = styled.div`
  display: flex;
  align-items: center;
  gap: 0px 12px;

  p:last-child {
    font-weight: bold;
  }
`;
export const Submit = styled.div`
  text-align: center;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  button {
    width: 350px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  @media screen and (max-width: 470px) {
    button {
      max-width: 100%;
    }
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
  }
`;

export const HistoryTransfer = styled.div`
  overflow: auto;
  max-height: calc(var(--vh, 1vh) * 100);
  div {
    min-width: 700px;
  }
`;

export const SpanColor = styled.span<{ color: any }>`
  color: ${(props) => props.theme.colors[props.color]};
`;

export const HistoryTitle = styled.div<{ bgColor; just?; direction?: any }>`
  display: flex;
  padding: 15px;
  background-color: ${(props) => props.theme.colors[props.bgColor]};
  justify-content: ${(props) => props.just};
  align-items: center;
  flex-direction: ${(props) => (props.direction ? props.direction : "")};
  border-bottom: 1px solid black;
`;
