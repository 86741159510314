import Countdown from "components/v-countdown";
import { MasterContext } from "context/MasterContext";
import useQuery from "hooks/useQuery";
import { useContext } from "react";
import { formatDate } from "utils/common";

const View = () => {
  const { key } = useQuery();
  const { allMenus } = useContext(MasterContext);
  const menu = allMenus?.find((m) => m._id === key);
  const isShowCountdown =
    Number(new Date(menu?.coming_soon?.countdown).getTime()) >
    Number(new Date().getTime());

  return (
    <div className="relative w-full flex flex-col items-center">
      {menu?.coming_soon?.countdown && isShowCountdown && (
        <div className="absolute-center flex flex-col items-center">
          <h4 className="font-bold">{menu?.coming_soon?.title} </h4>
          <div>
            <Countdown date={formatDate(menu?.coming_soon?.countdown)} />
          </div>
        </div>
      )}
      <img
        className="h-auto w-full object-cover"
        src={`${menu?.coming_soon?.background}`}
        alt="Coming soon"
      />
    </div>
  );
};

export default View;
