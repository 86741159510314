import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  type?: string;
  isEdit?: boolean;
  data?: any;
  setData?: (e: any) => void;
};

const FundamentalItems = (props: IProps) => {
  const { type, isEdit, data, setData } = props;
  const { theme } = useContext(ThemeContext);

  const handleAddItems = (type: string) => {
    setData((prev) => ({
      ...prev,
      fundamentals: {
        ...prev.fundamentals,
        [type]: prev.fundamentals[type]?.concat({
          id: uuidv4(),
          title: "Title",
          desc: "Desc",
        }),
      },
    }));
  };

  const handleSubItems = (type: string, item: any) => {
    setData((prev) => ({
      ...prev,
      fundamentals: {
        ...prev.fundamentals,
        [type]: prev.fundamentals[type]?.filter((el) => el.id !== item.id),
      },
    }));
  };

  const onBlurDocument = (
    e: any,
    name: string,
    child?: string,
    id?: string
  ) => {
    if (child) {
      setData((prev) => ({
        ...prev,
        fundamentals: {
          ...prev.fundamentals,
          [child]: prev.fundamentals[child]?.map((el) =>
            el.id === id ? { ...el, [name]: e.target.innerText } : { ...el }
          ),
        },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        fundamentals: {
          ...prev.fundamentals,
          [name]: e.target.innerText,
        },
      }));
    }
  };

  return (
    <>
      {data.fundamentals[type]?.map((item) => (
        <div
          key={item.id}
          className="relative w-full h-1/3 flex flex-col gap-y-1 p-3 rounded"
          style={
            data?.settingMenu?.colors[theme]?.content?.via ||
            data?.settingMenu?.colors[theme]?.content?.to
              ? {
                  backgroundImage: `linear-gradient(to right, ${data?.settingMenu?.colors[theme]?.content?.from},${data?.settingMenu?.colors[theme]?.content?.via}, ${data?.settingMenu?.colors[theme]?.content?.to})`,
                  color: data?.settingMenu?.colors[theme]?.content?.text,
                }
              : {
                  backgroundColor:
                    data?.settingMenu?.colors[theme]?.content?.from,
                  color: data?.settingMenu?.colors[theme]?.content?.text,
                }
          }
        >
          {isEdit && (
            <div className="absolute top-0 right-0">
              <VButton
                className="w-6 h-6"
                radius="md"
                onClick={() => handleSubItems(type, item)}
              >
                -
              </VButton>
            </div>
          )}
          <div className="flex items-center gap-x-1">
            <Icons iconName="check" />
            <span
              className="font-semibold"
              contentEditable={isEdit}
              onBlur={(e) => onBlurDocument(e, "title", type, item.id)}
            >
              {item.title}
            </span>
          </div>
          <span
            className="text-sm h-full w-full overflow-hidden"
            contentEditable={isEdit}
            onBlur={(e) => onBlurDocument(e, "desc", type, item.id)}
          >
            {item.desc}
          </span>
        </div>
      ))}
      {data.fundamentals[type]?.length < 3 && isEdit && (
        <VButton
          className="w-8 h-8"
          radius="full"
          onClick={() => handleAddItems(type)}
        >
          +
        </VButton>
      )}
    </>
  );
};

export default FundamentalItems;
