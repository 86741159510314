/* eslint-disable no-template-curly-in-string */
import { formatNumber } from "./common";

export const renderId = (_id: String) => {
  return `${_id.slice(0, 15)}...`;
};

export const renderTextInDay = () => {
  const hour = new Date().getHours();
  if (0 <= hour && hour <= 12) return "Good Morning";
  if (13 <= hour && hour <= 18) return "Good Afternoon";
  return "Good Evening";
};

export const renderAmount = (amount: number) => {
  if (amount >= 0)
    return <span style={{ color: "#00AC11" }}> +{formatNumber(+amount)} </span>;
  else
    return (
      <span style={{ color: "#dc3545" }}>
        {" "}
        -{formatNumber(Math.abs(+amount))}{" "}
      </span>
    );
};

export const renderStock = (stock: string) => {
  return stock?.toUpperCase();
};

export const renderStatus = (status: string) => {
  const color = {
    init: "bg-gray-500",
    success: "bg-green-500",
    approved: "bg-green-500",
    happening: "bg-green-500",
    confirmed: "bg-green-500",
    upcoming: "bg-orange-500",
    pending: "bg-orange-400",
    error: "bg-red-500",
    opening: "bg-green-500",
    closed: "bg-red-500",
    enable: "bg-green-500",
    disable: "bg-red-500",
    replied: "bg-green-500",
  };
  return (
    <span className={`rounded-[8px] p-[4px] ${color[status]} capitalize`}>
      {status}
    </span>
  );
};

export const renderProjectStatus = (status: string) => {
  const color = {
    upcoming: "text-orange-500",
    opening: "text-green-500",
    closed: "text-red-500",
  };
  return (
    <span className={`rounded-[8px] p-[4px] ${color[status]} capitalize`}>
      {status}
    </span>
  );
};

export const renderKycStatus = (status: string) => {
  const color = {
    verified: {
      img: "/assets/images/kyc/veriried.png",
      bg: "bg-blue-500",
      text: "Verified",
    },
    kyc: {
      bg: "bg-orange-500",
      img: "/assets/images/kyc/kyc.png",
      text: "KYC",
    },
    declined_verified: {
      bg: "bg-orange-400",
      img: "/assets/images/kyc/kyc.png",
      text: "Declined Verfify",
    },
    pending_verified: {
      bg: "bg-orange-400",
      img: "/assets/images/kyc/kyc.png",
      text: "Pending Verfify",
    },
    pending_kyc: {
      bg: "bg-yellow-400",
      img: "/assets/images/kyc/no.png",
      text: "Pending KYC",
    },
    declined: {
      bg: "bg-red-500",
      img: "/assets/images/kyc/no.png",
      text: "Declined",
    },
  };

  if (!color[status]) return "Not Verify";

  return (
    <span
      className={`flex items-center gap-x-1 rounded-[8px] p-[4px] ${color[status]?.bg} capitalize`}
    >
      <img src={color[status]?.img} alt="" className="w-5 h-5" />
      <span>{color[status].text}</span>
    </span>
  );
};

export const renderTransactionType = (type: string) => {
  return (
    <span className={" bg-yellow-500 rounded-[8px] p-[4px] capitalize"}>
      {type}
    </span>
  );
};

export const renderHash = (hash: string) => {
  return (
    <a
      href={`${
        process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
      }/tx/${hash}`}
      target={"_blank"}
      rel="noreferrer"
    >
      {hash?.slice(0, 5)}...
      {hash?.slice(-5)}
    </a>
  );
};
