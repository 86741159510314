/* eslint-disable indent */
/* eslint-disable max-len */
import { Flex } from "./styles";

import { Link, useParams } from "react-router-dom";

import UploadFile from "components/v-upload";

import { Col, Container } from "reactstrap";

import GoogleMap from "components/google-map";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "context/ThemeContext";
import { isMobile } from "react-device-detect";
import APIs from "api";
import { MasterContext } from "context/MasterContext";

type IProps = {
  isEdit?: boolean;
  main?: any;
  dataUser?: any;
  dataEstock?: any;
  dataProject?: any;
  listFiles?: any;
  setListFiles?: any;
  setDataEstock?: any;
  handleOpenPopup?: any;
};

const Footer = ({
  isEdit,
  main,
  dataUser,
  dataEstock,
  dataProject,
  listFiles,
  setDataEstock,
  setListFiles,
  handleOpenPopup,
}: IProps) => {
  const prams: any = useParams();
  const { logos } = useContext(ThemeContext);
  const { settings, appName } = useContext(MasterContext);
  const [state, setState] = useState<any>();

  const onBlurText = (e: any, type: string) => {
    setDataEstock((prev) => ({
      ...prev,
      [type]: e.target.innerText,
    }));
  };
  const setFile = (f: any, id: string) => {
    setListFiles((pre) => ({
      ...pre,
      [id]: f,
    }));
  };

  const getInformation = async () => {
    try {
      const res: any = await APIs.SETTING.getSettingByName("landing_page");
      if (res && res?.setting) {
        setState(res.setting?.value);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getInformation();
  }, []);

  return (
    <>
      <Container>
        <Flex>
          <Col md={5}>
            <div className="h-full">
              <div className="mt-[40px] w-full max-w-[430px]">
                <div className="pr-[50px">
                  <div className="relative my-[30px] w-auto h-full min-h-[50px]">
                    {isEdit && (
                      <div
                        className={`absolute max-w-full h-full ${
                          Boolean(!listFiles && dataEstock?.logoFooter)
                            ? "w-[50%]"
                            : "w-auto"
                        } ${
                          Boolean(!listFiles && dataEstock?.logoFooter)
                            ? "opacity-0"
                            : "opacity-100"
                        }`}
                      >
                        <UploadFile
                          id="logoFooter"
                          ratio="213x40"
                          file={listFiles}
                          setFile={setFile}
                        />
                      </div>
                    )}
                    {main ? (
                      <img
                        className="w-auto max-w-full h-[50px] cursor-pointer"
                        src={
                          isMobile
                            ? String(
                                logos?.logoResponsive ||
                                  "https://vzonex.s3.ap-southeast-1.amazonaws.com/admin/unknown/bdc09f6a-0891-4e42-98ff-8704d7ac4c09.png"
                              )
                            : String(
                                logos?.logo ||
                                  "https://vzonex.s3.ap-southeast-1.amazonaws.com/admin/unknown/65790c90-c046-4a40-8307-161e29ed3070.png"
                              )
                        }
                        alt="Vzonex.com"
                      />
                    ) : (
                      <img
                        className="w-auto max-w-full h-[50px] cursor-pointer"
                        hidden={listFiles || !dataEstock?.logoFooter}
                        src={dataEstock?.logoFooter}
                        alt="logoFooter"
                      />
                    )}
                  </div>
                  <span
                    className="flex items-center text-[14px] leading-4 mb-[20px]"
                    data-key={"footer_desc"}
                    contentEditable={isEdit}
                    onBlur={(e) => onBlurText(e, "footer_desc")}
                  >
                    {state && <div>{state?.decriptions}</div>}
                  </span>
                  <div className="flex my-[40px] gap-x-2">
                    <img
                      className="w-[30px] h-[30px] mr-[30-px] cursor-pointer"
                      src="/assets/images/social/vdiarybook.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? settings?.web_config?.vdiarybook
                            : dataEstock?.vdiarybook
                        )
                      }
                    />
                    <img
                      className="w-[30px] h-[30px] mr-[30-px] cursor-pointer"
                      src="/assets/images/social/facebook.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? settings?.web_config?.facebook
                            : dataEstock?.facebook
                        )
                      }
                    />
                    <img
                      className="w-[30px] h-[30px] mr-[30-px] cursor-pointer"
                      src="/assets/images/social/youtube.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? settings?.web_config?.youtube
                            : dataEstock?.youtube
                        )
                      }
                    />
                    <img
                      className="w-[30px] h-[30px] mr-[30-px] cursor-pointer"
                      src="/assets/images/social/twitter.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? settings?.web_config?.twitter
                            : dataEstock?.twitter
                        )
                      }
                    />
                    <img
                      className="w-[30px] h-[30px] mr-[30-px] cursor-pointer"
                      src="/assets/images/social/telegramColor.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? settings?.web_config?.telegram
                            : dataEstock?.telegram
                        )
                      }
                    />
                  </div>
                  {isEdit && (
                    <div
                      className="-mt-[20px] cursor-pointer"
                      onClick={() => handleOpenPopup("footer")}
                    >
                      Click here to add link
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="h-full">
              <div className="mt-[40px] w-full max-w-[430px]">
                <h6 className="w-fit border-b font-bold cursor-pointer mb-[20px] hover:text-[#a0a0a0]">
                  Important Link
                </h6>
                <p className="text-[14px] cursor-pointer hover:text-[#a0a0a0]">
                  Home
                </p>
                <p className="text-[14px] cursor-pointer hover:text-[#a0a0a0]">
                  Feature
                </p>
                <p className="text-[14px] cursor-pointer hover:text-[#a0a0a0]">
                  Integrations
                </p>
                <p className="text-[14px] cursor-pointer hover:text-[#a0a0a0]">
                  About
                </p>
                <Link
                  to={
                    main ? `/terms` : `/${dataUser?.role}/${prams.symbol}/terms`
                  }
                  target="_blank"
                >
                  <p>User Agreement and Privacy Policy.</p>
                </Link>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="h-full">
              <div className="mt-[40px] w-full max-w-[430px]">
                <h6 className="w-fit border-b font-bold cursor-pointer mb-[20px] hover:text-[#a0a0a0]">
                  Informations
                </h6>
                {/* {dataUser?.role !== "personal" &&
                  dataUser?.role !== "student" && (
                    <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                      <img
                        className="w-[25px] mr-[5px]"
                        src="/assets/images/icons/company.svg"
                        alt=""
                      />
                      <div>
                        Name: &nbsp;
                        <b>
                          {main
                            ? "BLOCKCHAIN EDUCATION AND TECHNOLOGY CO .,JSC"
                            : dataUser?.fullname}
                        </b>
                      </div>
                    </span>
                  )} */}
                <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                  <img
                    className="w-[25px] mr-[5px]"
                    src="/assets/images/icons/map.svg"
                    alt=""
                  />
                  <div className="">
                    <b>{state && <div> Address:&nbsp;{state?.name}</div>}</b>
                  </div>
                </span>
                {!main && (
                  <>
                    <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                      <img
                        className="w-[25px] mr-[5px]"
                        src="/assets/images/icons/business.svg"
                        alt=""
                      />
                      <div>
                        Phone: &nbsp;<b>{dataUser?.phone}</b>
                      </div>
                    </span>
                    <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                      <img
                        className="w-[25px] mr-[5px]"
                        src="/assets/images/icons/business.svg"
                        alt=""
                      />
                      <div>
                        Email: &nbsp;<b>{dataUser?.email}</b>
                      </div>
                    </span>
                  </>
                )}
                {dataUser?.role === "personal" ||
                dataUser?.role === "student" ? (
                  <>
                    <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                      <img
                        className="w-[25px] mr-[5px]"
                        src="/assets/images/icons/user.svg"
                        alt=""
                      />
                      <div>
                        Full Name: &nbsp;<b>{dataUser?.fullname}</b>
                      </div>
                    </span>
                    {dataUser?.role === "student" && (
                      <>
                        <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                          <img
                            className="w-[25px] mr-[5px]"
                            src="/assets/images/icons/company.svg"
                            alt=""
                          />
                          <div>
                            School: &nbsp;
                            <b>{dataUser?.studentData?.schoolName}</b>
                          </div>
                        </span>
                        <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                          <img
                            className="w-[25px] mr-[5px]"
                            src="/assets/images/icons/business.svg"
                            alt=""
                          />
                          <div>
                            School code : &nbsp;
                            <b>{dataUser?.studentData?.schoolCode}</b>
                          </div>
                        </span>
                        <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                          <img
                            className="w-[25px] mr-[5px]"
                            src="/assets/images/icons/world.svg"
                            alt=""
                          />
                          <div>
                            Specialized: &nbsp;
                            <b>{dataUser?.studentData?.specialized}</b>
                          </div>
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!main && (
                      <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                        <img
                          className="w-[25px] mr-[5px]"
                          src="/assets/images/icons/business.svg"
                          alt=""
                        />
                        <div>
                          {dataUser?.organizationsData
                            ? "Organization"
                            : "Business"}{" "}
                          Name: &nbsp;
                          <b>
                            {dataUser?.organizationsData?.organization ||
                              dataUser?.businessData?.business}
                          </b>
                        </div>
                      </span>
                    )}

                    <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                      <img
                        className="w-[25px] mr-[5px]"
                        src="/assets/images/icons/business.svg"
                        alt=""
                      />
                      <div className="flex">
                        Business Code: &nbsp;
                        <b>{state && <div>{state?.business_code}</div>}</b>
                      </div>
                    </span>
                    <span className="flex items-center text-[14px] leading-4 mb-[20px]">
                      <img
                        className="w-[25px] mr-[5px]"
                        src="/assets/images/icons/user.svg"
                        alt=""
                      />
                      <div className="flex">
                        Agent Name: &nbsp;
                        <b>{state && <div>{state?.agent_name}</div>}</b>
                      </div>
                    </span>
                  </>
                )}
              </div>
              {isEdit && <GoogleMap />}
            </div>
          </Col>
        </Flex>
        <Flex main={main}>
          <Col md={7}>
            {state && (
              <p dangerouslySetInnerHTML={{ __html: state.introduce }}></p>
            )}
          </Col>
          <Col md={5}></Col>
        </Flex>
        <br />
        <span className="flex">
          <img src="/assets/images/icons/copyright.svg" alt="" /> 2024 | All
          Right Reserved By {dataEstock?.name || dataProject?.name || appName}
        </span>
      </Container>
    </>
  );
};

export default Footer;
