// import { Warning, ContentRightView, Continue } from "../../styles";
import VButton from "components/v-core/v-button";
import { toast } from "react-toastify";
import Network from "../networks";
import VInput from "components/v-core/v-input";
import { useContext } from "react";
import { ThemeContext } from "context/ThemeContext";

const Step1 = ({ dataPayment, nextStep, setDataByKey, backStep }) => {
  const { themesSetting } = useContext(ThemeContext);

  const handleChange = (e) => {
    setDataByKey("amount", e);
  };

  const handleClick = () => {
    if (dataPayment.currency === "vnd" && +dataPayment.amount < 10000)
      return toast.error("Minimum deposit is 10000vnd!");
    if (dataPayment.currency === "usdt" && +dataPayment.amount <= 0)
      return toast.error("Amount must be greater 0!");
    nextStep();
  };

  return (
    <div style={themesSetting?.card} className="p-[15px]">
      {dataPayment.currency === "usdt" && (
        <Network
          network={dataPayment.network}
          setNetwork={(data) => {
            setDataByKey("network", data);
          }}
        />
      )}
      <VInput
        className="w-full !bg-transparent"
        isCurrency={true}
        transparent={true}
        label={`Amount (${String(dataPayment.currency || "").toUpperCase()}) *`}
        value={dataPayment.amount}
        onValueChange={(e) => handleChange(e)}
        placeholder="10,000"
      />
      <div className="flex items-start pt-[6px]">
        <img
          src="/assets/images/icons/warning.svg"
          alt="warning"
          className="mr-[5px]"
        />
        <p className=" text-[10px]">
          {dataPayment.currency.toUpperCase()} deposit is free. Vzonex does not
          collect {dataPayment.currency.toUpperCase()} deposit fee, however, in
          some instances, you may have to bear a small transaction fee charged
          directly by the processing bank or network.
        </p>
      </div>
      <div className="pt-[25px] flex flex-col w-full  items-center justify-center gap-y-3">
        <VButton className="!w-[300px]" onClick={handleClick}>
          Continue
        </VButton>
        <VButton
          type="outline"
          className="!w-[300px]"
          onClick={() => backStep()}
        >
          Back
        </VButton>
      </div>
    </div>
  );
};

export default Step1;
