export const defaultTheme = {
  dark: {
    layout: {
      fontSize: 14,
      color: "#FFF",
      backgroundColor: "#0D1321",
      textContent: "#F6D558",
    },
    container: {
      fontSize: 14,
      color: "#FFF",
      backgroundColor: "#171E2E",
    },
    card: {
      fontSize: 14,
      color: "#FFF",
      backgroundColor: "#1F2937",
    },
    menus: {
      color: "#6B7280",
      borderColor: "#2F3137",
      backgroundColor: "#0D1321",
      subMenuActive: {
        color: "#000",
        backgroundColor: "#F6D558",
      },
      menuActive: {
        color: "#FFF",
      },
    },
    header: {
      color: "#FFF",
      backgroundColor: "#0D1321",
      borderColor: "#2F3137",
    },
    range: {
      color: "#4284F3",
    },
    button: {
      primary: {
        fontSize: 14,
        color: "#000",
        backgroundColor: "#F6D558",
        fontWeight: 500,
      },
      secondary: {
        fontSize: 14,
        color: "#000",
        backgroundColor: "#F6D558",
        fontWeight: 400,
      },
      outline: {
        fontSize: 14,
        borderWidth: 1,
        fontWeight: 400,
        active: {
          color: "#000",
          backgroundColor: "#F6D558",
        },
      },
    },
    dropdown: {
      select: {
        fontSize: 14,
        color: "#6B7280",
        backgroundColor: "transparent",
      },
      list: {
        color: "#6B7280",
        backgroundColor: "transparent",
      },
      active: {
        color: "#F6D558",
      },
      icon: "text-black",
    },
    input: {
      fontSize: 14,
      color: "#FFF",
      backgroundColor: "#121F33",
    },
    pagination: {
      color: "#FFF",
      fontSize: 14,
      backgroundColor: "#0D1321",
    },
    tabs: {
      default: {
        color: "#6B7280",
        borderColor: "#6B7280",
        active: {
          color: "#FFF",
          borderColor: "#FFF",
        },
      },
      button: {
        color: "#6B7280",
        borderColor: "transparent",
        active: {
          color: "#000",
          backgroundColor: "#F6D558",
        },
      },
    },
    table: {
      header: {
        color: "#6B7280",
        backgroundColor: "#0D1321",
      },
      body: { backgroundColor: "#0D1321", color: "#FFF" },
    },
    market: {
      backgroundColor: "#062D52",
      color: "#FFF",
    },
    modal: {
      fontSize: 14,
      color: "#FFF",
      backgroundColor: "#1F2937",
      borderColor: "#F6D558",
    },
  },
  light: {
    layout: {
      fontSize: 14,
      color: "#000",
      backgroundColor: "#FCFCFC",
      textContent: "#F6D558",
    },
    container: {
      fontSize: 14,
      color: "#000",
      backgroundColor: "#FCFCFC",
    },
    card: {
      fontSize: 14,
      color: "#000",
      backgroundColor: "#FCFCFC",
      boxShadow:
        "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    },
    menus: {
      color: "#6B7280",
      borderColor: "#E6E7EB",
      backgroundColor: "#FCFCFC",
      hoverColor: "v-yellow",
      subMenuActive: {
        color: "#000",
        backgroundColor: "#F6D558",
      },
      menuActive: {
        color: "#F6D558",
      },
    },
    header: {
      color: "#000",
      backgroundColor: "#FCFCFC",
      borderColor: "#E6E7EB",
    },
    range: {
      color: "#4284F3",
    },
    button: {
      primary: {
        fontSize: 14,
        color: "#000",
        backgroundColor: "#F6D558",
        fontWeight: 500,
      },
      secondary: {
        fontSize: 14,
        color: "#000",
        backgroundColor: "#F6D558",
        fontWeight: 400,
      },
      outline: {
        fontSize: 14,
        borderWidth: 1,
        fontWeight: 400,
        active: {
          color: "#000",
          backgroundColor: "#F6D558",
        },
      },
    },
    dropdown: {
      select: {
        fontSize: 14,
        color: "#6B7280",
        backgroundColor: "transparent",
      },
      list: {
        color: "#6B7280",
        backgroundColor: "transparent",
      },
      active: {
        color: "#F6D558",
      },
      icon: "text-black",
    },
    input: {
      fontSize: 14,
      color: "#000",
      backgroundColor: "#FCFCFC",
      borderColor: "#6B7280",
    },
    pagination: {
      color: "#000",
      fontSize: 14,
      backgroundColor: "#FCFCFC",
    },
    tabs: {
      default: {
        color: "#6B7280",
        borderColor: "#E6E7EB",
        active: {
          color: "#000",
          borderColor: "#F6D558",
        },
      },
      button: {
        color: "#6B7280",
        borderColor: "transparent",
        active: {
          color: "#000",
          backgroundColor: "#F6D558",
        },
      },
    },
    table: {
      header: {
        color: "#6B7280",
        backgroundColor: "#FCFCFC",
      },
      body: { backgroundColor: "#FCFCFC", color: "#000" },
    },
    market: {
      backgroundColor: "#062D52",
      color: "#FFF",
    },
    modal: {
      fontSize: 14,
      color: "#000",
      backgroundColor: "#FCFCFC",
      boxShadow:
        "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      borderColor: "#F6D558",
    },
  },
};
