/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Image,
  Tutorial,
  TopMain,
  BottomMain,
  Step,
  Item,
  ContainButton,
  ContainInput,
  Flex,
  Description,
  Title,
} from "./styles";
import { Col, Row } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { signMessage } from "utils/ethers";
import APIs from "api";
import Verify from "pages/verify";
import { MasterContext } from "context/MasterContext";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import VInput from "components/v-core/v-input";

const View = () => {
  const { auth, getUser } = useContext(MasterContext);

  const [twoFa, setTwoFa] = useState("");
  const [errMessage, setErrMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPin, setLoadingPin] = useState(false);
  const [emailSecure, setEmailSecure] = useState(false);
  const [loginByAddress, setLoginByAddress] = useState(false);
  const [publicAddress, setPublicAddress] = useState(null);
  const [pinExist, setPinExist] = useState(false);
  const [pinCode, setPinCode] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [security, setSecurity] = useState(null);
  const { themesSetting } = useContext(ThemeContext);

  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const [openSecure, setOpenSecure] = useState(false);

  const getSecurity = async () => {
    try {
      const res: any = await APIs.USER.getSecurity();
      setSecurity(res);
    } catch (error) {}
  };

  const onEditUser = async (req: any) => {
    const res = await APIs.USER.editUser(req);
    return res;
  };

  const onEnable2FA = async (req: any) => {
    try {
      const res: any = await APIs.USER.enable2FA(req);
      setSecurity((prev) => ({ ...prev, is_enable: res?.is_enable }));
    } catch (error) {
      setErrMessage({ twoFa: error?.errors });
    }
  };

  const onDisable2FA = async (req: any) => {
    try {
      const res: any = await APIs.USER.disable2FA(req);
      setSecurity(res);
    } catch (error) {
      setErrMessage({ twoFa: error.errors });
    }
  };

  const handleClick = async () => {
    setLoading(true);
    if (!twoFa) setErrMessage({ otp: "2Fa incorrect!" });
    if (!security?.is_enable) {
      await onEnable2FA({
        body: {
          otp: twoFa,
        },
      });
    } else {
      await onDisable2FA({
        body: {
          otp: twoFa,
        },
      });
    }
    setTwoFa("");
    setLoading(false);
  };

  const createSignature = async () => {
    try {
      const res: any = await signMessage("Create Signature Login");
      setPublicAddress(res.address);
      await onEditUser({
        params: {
          id: auth?._id,
        },
        body: {
          public_address: res.address.toLowerCase(),
        },
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangeEmailSecure = async (checked) => {
    setEmailSecure(checked);
    await onEditUser({
      params: {
        id: auth?._id,
      },
      body: {
        email_secure: checked,
      },
    });
  };

  const handleChangePinSecure = async (checked) => {
    setOpenSecure(true);
  };

  const handleLoginByAddress = async (checked) => {
    setLoginByAddress(checked);
    await onEditUser({
      params: {
        id: auth?._id,
      },
      body: {
        login_by_address: checked,
      },
    });
  };

  const handleSubmit = async () => {
    try {
      if (isReset || !pinExist) {
        setLoadingPin(true);
        if (Number.isInteger(+pinCode) && String(pinCode).length === 6) {
          if (!isReset) {
            await APIs.USER.createPinCode({
              body: {
                verify,
                code: pinCode,
              },
            });
          } else
            await APIs.USER.resetPinCode({
              body: {
                verify,
                code: pinCode,
              },
            });
        } else {
          setLoadingPin(false);
          return toast.error("Code is not in the correct format!");
        }
      }
      !isReset &&
        (await APIs.USER.editUserSecure({
          body: {
            verify,
            pin_secure: !auth?.pin_secure,
          },
        }));
      await getUser();
      await getData();
      setIsReset(false);
      setOpenSecure(false);
      setLoadingPin(false);
    } catch (error) {
      setLoadingPin(false);
      toast.error(error.errors);
    }
  };

  useEffect(() => {
    getSecurity();
  }, []);

  useEffect(() => {
    if (auth) {
      setEmailSecure(auth.email_secure);
      setLoginByAddress(auth.login_by_address);
      setPublicAddress(auth.public_address);
    }
  }, [auth]);

  const getData = async () => {
    try {
      const res = await APIs.USER.checkPinExist({});
      if (res) setPinExist(true);
      else setPinExist(false);
    } catch (error) {
      setPinExist(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container color={"black100"} style={themesSetting?.card} className="!mt-0">
      <TopMain>
        <Title>Signature</Title>

        {!publicAddress ? (
          <VButton onClick={createSignature} loading={false}>
            Create
          </VButton>
        ) : (
          <>
            {" "}
            <Switch onChange={handleLoginByAddress} checked={loginByAddress} />
            <p className=" overflow-hidden text-ellipsis">
              Address : {publicAddress}
            </p>
          </>
        )}
      </TopMain>

      <TopMain>
        <Title>Email Secure</Title>
        <Switch onChange={handleChangeEmailSecure} checked={emailSecure} />
      </TopMain>

      <TopMain>
        <Title>Set up pin code</Title>
        <Switch onChange={handleChangePinSecure} checked={auth?.pin_secure} />
        {openSecure && (
          <div className="pin-secure">
            <Verify verify={verify} setVerify={setVerify} />
            {(isReset || !pinExist) && (
              <div className="input-code">
                <label className="details">Pin code</label>
                <VInput
                  transparent={true}
                  className="!bg-transparent"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                  type="number"
                  placeHolder={"Please input pin code"}
                />
                <span className="details">Enter the 6 digit code</span>
              </div>
            )}
            <VButton onClick={() => handleSubmit()} loading={loadingPin}>
              Submit
            </VButton>
            <VButton
              onClick={() => {
                setOpenSecure(false);
                setIsReset(false);
              }}
            >
              Cancel
            </VButton>
          </div>
        )}
        <br />
        {!openSecure && auth?.pin_secure && (
          <a
            className="reset"
            onClick={() => {
              setOpenSecure(true);
              setIsReset(true);
            }}
          >
            Reset pin code
          </a>
        )}
      </TopMain>

      <TopMain>
        <h4>Two-Factor Authentication</h4>
        <Row>
          {!security?.is_enable && (
            <Col col md={12}>
              <Flex>
                <Image>
                  <img
                    alt=""
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=250*250&data=${security?.uri}`}
                  />
                </Image>
                <Description>
                  <h5>Google Authenticator</h5>
                  <p>
                    Download and install Google Authenticator. Scan the QR code
                    or copy the key.
                  </p>
                </Description>
              </Flex>
              <VInput
                transparent={true}
                className="!bg-transparent"
                value={security?.secret_key}
              />
            </Col>
          )}
          <Col col md={12}>
            <span>Two-Factor Authentication</span>
            <ContainInput>
              <VInput
                className="!bg-transparent"
                transparent={true}
                value={twoFa}
                onChange={(e) => setTwoFa(e.target.value)}
                errMessage={errMessage["twoFa"]}
                hasError={errMessage["twoFa"]}
                type="number"
              />
            </ContainInput>
            <ContainButton className="!mt-9 w-full flex justify-center">
              <VButton
                className="w-1/2"
                radius="full"
                onClick={handleClick}
                loading={loading}
              >
                {!security?.is_enable ? "Enable" : "Disable"}
              </VButton>
            </ContainButton>
          </Col>
        </Row>
      </TopMain>
      <BottomMain>
        <Row>
          <Col col md={12}>
            <Tutorial>
              <h4>How to Anable Authenticator App</h4>
              <p>
                2-step verification uses your mobile phone device to provide an
                extra layer of security for your Hootsuite account. Powered by
                Google Authenticator.
              </p>
              <a
                href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=1"
                target={"_blank"}
                rel="noreferrer"
              >
                <img alt="" src="/assets/images/icons/authenticator.svg" />
              </a>
            </Tutorial>
          </Col>
          <Col col md={12}>
            <Step>
              <Item>
                <span style={{ color: themesSetting.layout.textContent }}>
                  1
                </span>
                <p>
                  Install the Google Authenticator App. For your mobile device.
                  <span
                    className="cursor-pointer"
                    style={{ color: themesSetting.layout.textContent }}
                  >
                    {" "}
                    Learn more
                  </span>
                </p>
              </Item>
              <Item>
                <span style={{ color: themesSetting.layout.textContent }}>
                  2
                </span>
                <p>
                  Scan the QR code or manually input your secret code into the
                  Google Authenticator App.
                </p>
              </Item>
              <Item>
                <span style={{ color: themesSetting.layout.textContent }}>
                  3
                </span>
                <p>
                  Input the 6-degit code that the Google Authenticator App
                  provider.
                </p>
              </Item>
            </Step>
          </Col>
        </Row>
      </BottomMain>
    </Container>
  );
};

export default View;
