/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { Link } from "react-router-dom";
import { FormGroup, Label, Input as InputB } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { formatNumber } from "utils/common";
import { MasterContext } from "context/MasterContext";
import useProjects from "hooks/useProjects";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import { useHistory } from "react-router-dom";

const Wallet = (props) => {
  const history = useHistory();

  const { balances, stocks, lockedBalances, stockDefault } =
    useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);

  // const address = query.address;
  // const amountStock = query.amountStock;
  // const [isQrScanWithdraw, setIsQrScanWithdraw] = useState<boolean>(
  //   Boolean(amountStock && address)
  // );

  const { projects, getProjects } = useProjects();
  const [subWallets, setSubWallets] = useState([]);
  const [wallets, setListWallets] = useState([]);
  const [isShowAll, setIsShowAll] = useState(true);
  const [isShowAllSub, setIsShowAllSub] = useState(false);

  useEffect(() => {
    if (stocks && stocks.length > 0 && balances) {
      const _wallets = stocks.map((token) => ({
        ...token,
        balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
        locked_balances: lockedBalances[token.symbol]
          ? Number(lockedBalances[token.symbol])
          : 0,
      }));

      const _walletVnex = _wallets.find((w) => w.symbol === stockDefault);
      const _walletsNormal = _wallets.filter(
        (w) => w.symbol !== stockDefault && (isShowAll ? true : +w.balance > 0)
      );
      setListWallets([_walletVnex, ..._walletsNormal]);
    }
  }, [stocks, balances, lockedBalances, isShowAll]);

  useEffect(() => {
    if (projects?.length > 0 && balances) {
      const _wallets = projects
        .filter(
          (p) =>
            ["closed", "opening"].includes(p.status) &&
            !stocks.some((c) => c.symbol === p.symbol)
        )
        .map((token) => ({
          ...token,
          balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
          locked_balances: lockedBalances[token.symbol]
            ? Number(lockedBalances[token.symbol])
            : 0,
        }));
      const _walletsNormal = _wallets.filter((w) =>
        isShowAllSub ? true : +w.balance > 0
      );
      setSubWallets(_walletsNormal);
    }
  }, [projects, stocks, balances, lockedBalances, isShowAllSub]);

  useEffect(() => {
    getProjects();
  }, []);

  const handleClickFunctionButton = (type?: string, stock?: any) => {
    history.push(`/my/wallets/${stock.symbol}/${type}`);
  };

  return (
    <div>
      <div className="lg:flex justify-between block items-center py-3 px-3 w-full">
        <p className="text-lg sm:text-[22px]">NFTs Overview</p>
      </div>

      <div className="w-full flex max-xl:flex-col xl:justify-between gap-4">
        <div
          className="w-1/2 max-xl:!w-full flex justify-between rounded-lg p-3"
          style={themesSetting?.card}
        >
          <div className="w-1/2 flex flex-col gap-y-3">
            <div className="flex items-center gap-x-1">
              <Icons className="w-6 h-6 rounded-full" iconName="wallets" />
              <span className="text-xs">Estimated assets</span>
            </div>
            <div className="flex gap-x-1">
              <span>{formatNumber(balances.total_usd || 0, 2)} $</span>
              <span className="text-sm">{}</span>
            </div>
            <div className="flex max-2xl:flex-col max-2xl:justify-center 2xl:items-center gap-x-2">
              <div className="flex gap-x-1">
                <span className="text-xs font-thin">
                  {formatNumber(balances.total_usdt || 0, 2)}
                </span>
                <span className="text-xs font-thin">USDT</span>
              </div>
              <div className="flex items-center gap-x-1">
                <Icons iconName="arrow-up" />
                <span className="text-xs text-green-500">+0%</span>
              </div>
            </div>
            <div>
              <VButton
                disabled
                radius="full"
                iconLeft={<Icons iconName="analysis" />}
              >
                Asset analysis
              </VButton>
            </div>
          </div>
          <div className="w-1/2 flex justify-center">
            <Icons iconName="chart-up" />
          </div>
        </div>
        <div
          className="w-1/2 max-xl:!w-full flex justify-between rounded-lg p-3"
          style={themesSetting?.card}
        >
          <div className="w-1/2 flex flex-col gap-y-3">
            <div className="flex items-center gap-x-1">
              <Icons className="w-6 h-6 rounded-full" iconName="wallets" />
              <span className="text-xs">Today's income</span>
            </div>
            <div className="flex gap-x-1">
              <span>Coming soon</span>
            </div>
            <div className="flex max-2xl:flex-col max-2xl:justify-center 2xl:items-center gap-x-2">
              <div className="flex gap-x-1">
                <span className="text-xs font-thin">Coming soon</span>
                <span className="text-xs font-thin">USDT</span>
              </div>
              <div className="flex items-center gap-x-1">
                <Icons iconName="arrow-down" />
                <span className="text-xs text-red-500">+0%</span>
              </div>
            </div>
            <div>
              <VButton
                disabled
                radius="full"
                iconLeft={<Icons iconName="share" />}
              >
                Share
              </VButton>
            </div>
          </div>
          <div className="w-1/2 flex justify-center">
            <Icons iconName="chart-down" />
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-md gap-x-3 py-3">
        <div className="w-full">
          <div className="mb-10">
            <div className="flex justify-between">
              <p>e.NFTs</p>
              <FormGroup check>
                <Label check>
                  <InputB
                    type="checkbox"
                    checked={isShowAll}
                    onChange={(e) => setIsShowAll(e.target.checked)}
                  />{" "}
                  Show all NFTs
                </Label>
              </FormGroup>
            </div>
            <div className="grid grid-cols-1 2xl:grid-cols-2 gap-x-4 gap-y-1">
              {wallets.map((item: any, index: number) => (
                <div
                  className="w-full rounded-md mt-2 p-3 sm:p-4 transition-all duration-500 ease-linear"
                  key={index}
                  style={themesSetting?.card}
                >
                  <div className="flex justify-between">
                    <div className="flex gap-x-3">
                      <div className="flex justify-center items-center">
                        {" "}
                        <img
                          className="w-10 h-10 rounded-full"
                          src={item?.logo || "/assets/images/coins/btc.svg"}
                          alt={item?.symbol}
                        />{" "}
                      </div>
                      <div className="flex flex-col ">
                        <p className="mb-1">
                          {" "}
                          {item?.name.length > 10
                            ? `${item?.name.substring(0, 10)}...`
                            : item?.name}{" "}
                        </p>
                        <div className="">
                          <span className="">
                            {formatNumber(+item?.balance)}{" "}
                          </span>{" "}
                          {item?.symbol.toUpperCase()}
                        </div>
                      </div>
                      {+item?.locked_balances > 0 && (
                        <div className="text-xs mb-2">
                          Hold:{" "}
                          <span className="text-xs">
                            {formatNumber(+item?.locked_balances)}{" "}
                          </span>{" "}
                          {item?.symbol.toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="flex text-sm xl:text-xs  max-w-full xl:max-w-60 justify-end gap-x-3 xl:gap-x-2 max-lg:hidden">
                      <VButton
                        type="outline"
                        radius="full"
                        px="3"
                        disabled={!item?.feature?.is_withdraw}
                        iconLeft={<Icons iconName="withdraw" />}
                        className="text-sm xl:text-xs"
                        onClick={() =>
                          handleClickFunctionButton("withdraw", item)
                        }
                      >
                        Withdraw
                      </VButton>
                      <VButton
                        radius="full"
                        px="3"
                        disabled={!item?.feature?.is_deposit}
                        iconLeft={<Icons iconName="deposit" />}
                        onClick={() =>
                          handleClickFunctionButton("deposit", item)
                        }
                      >
                        Deposit
                      </VButton>
                    </div>
                    <div className="flex justify-end gap-x-3 lg:hidden">
                      <VButton
                        type="outline"
                        radius="full"
                        disabled={!item?.feature?.is_withdraw}
                        iconLeft={<Icons iconName="withdraw" />}
                        onClick={() =>
                          handleClickFunctionButton("withdraw", item)
                        }
                      ></VButton>

                      <VButton
                        radius="full"
                        disabled={!item?.feature?.is_deposit}
                        iconLeft={<Icons iconName="deposit" />}
                        onClick={() =>
                          handleClickFunctionButton("deposit", item)
                        }
                      ></VButton>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-x-4 mt-1.5">
                    <div
                      className="flex justify-center items-center gap-x-1 cursor-pointer"
                      onClick={() => handleClickFunctionButton("history", item)}
                    >
                      <img
                        className=""
                        src={`/assets/images/icons/${
                          props?.location?.pathname?.includes(
                            `${item?.symbol}/history`
                          )
                            ? "historyActive.svg"
                            : "history.svg"
                        }`}
                        alt="BTC"
                      />{" "}
                      History
                    </div>

                    <Link
                      className="no-underline"
                      to={`/my/wallets/${item?.symbol}/sell`}
                      style={{ color: themesSetting?.container?.color }}
                    >
                      <div className="flex justify-center items-center gap-x-1">
                        <img
                          src="/assets/images/icons/sellCard.svg"
                          alt="BTC"
                        />{" "}
                        Sell to cash
                      </div>
                    </Link>
                    <div className="flex justify-center items-center gap-x-1">
                      <img src="/assets/images/icons/contract.svg" alt="BTC" />{" "}
                      Contract
                    </div>
                    <div className="flex justify-center items-center gap-x-1">
                      <img src="/assets/images/icons/website.svg" alt="BTC" />{" "}
                      Website
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between">
              {/* <p>Projects are implemented</p> */}
              <FormGroup check>
                <Label check>
                  <InputB
                    hidden
                    type="checkbox"
                    checked={isShowAllSub}
                    onChange={(e) => setIsShowAllSub(e.target.checked)}
                  />{" "}
                  {/* Show all NFTs */}
                </Label>
              </FormGroup>
            </div>
            {subWallets.map((item: any, index: number) => (
              // <div
              //   className="flex flex-col w-full rounded-md mt-2 p-3 sm:p-4 border-[0.5px] shadow-lg"
              //   key={index}
              //   style={themesSetting?.container}
              // >
              //   <div className="flex justify-between gap-x-5">
              //     <Col xs={8} lg={6}>
              //       <div className="flex gap-x-3">
              //         <div className="flex justify-center items-center">
              //           {" "}
              //           <img
              //             className="w-10 h-10 rounded-full"
              //             src={item.logo || "/assets/images/coins/btc.svg"}
              //             alt={item.symbol}
              //           />{" "}
              //         </div>
              //         <div className="flex flex-col ">
              //           <p className="mb-1">
              //             {" "}
              //             {item.name.length > 10
              //               ? `${item.name.substring(0, 10)}...`
              //               : item.name}{" "}
              //           </p>
              //           <div className="">
              //             <span className="">
              //               {formatNumber(+item.balance)}{" "}
              //             </span>{" "}
              //             {item.symbol.toUpperCase()}
              //           </div>
              //         </div>
              //         {+item.locked_balances > 0 && (
              //           <div className="text-xs mb-2">
              //             Hold:{" "}
              //             <span className="text-xs">
              //               {formatNumber(+item.locked_balances)}{" "}
              //             </span>{" "}
              //             {item.symbol.toUpperCase()}
              //           </div>
              //         )}
              //       </div>
              //     </Col>
              //     <Col xs={4} lg={6}>
              //       <div className="flex justify-end gap-x-3 max-lg:hidden">
              //         <Link
              //           className="no-underline text-current"
              //           to={
              //             item.is_withdraw &&
              //             `/my/wallets/${item.symbol}/withdraw`
              //           }
              //         >
              //           <VButton
              //             type="outline"
              //             radius="full"
              //             px="3"
              //             className=""
              //             disabled={!item.is_withdraw}
              //           >
              //             Withdraw
              //           </VButton>
              //         </Link>
              //         <Link
              //           className="no-underline text-current"
              //           to={
              //             item.is_deposit &&
              //             `/my/wallets/${item.symbol}/deposit`
              //           }
              //         >
              //           <VButton
              //             radius="full"
              //             className=""
              //             px="3"
              //             disabled={!item.is_deposit}
              //           >
              //             Deposit
              //           </VButton>
              //         </Link>
              //       </div>
              //     </Col>
              //   </div>
              //   <div>
              //     <div className="flex flex-wrap gap-x-4 mt-1.5">
              //       <Link
              //         className="no-underline"
              //         to={`/my/wallets/${item.symbol}/history`}
              //         style={{ color: themesSetting?.container?.color }}
              //       >
              //         <div className="flex justify-center items-center gap-x-1">
              //           <img
              //             className=""
              //             src={`/assets/images/icons/${
              //               props?.location?.pathname?.includes(
              //                 `${item.symbol}/history`
              //               )
              //                 ? "historyActive.svg"
              //                 : "history.svg"
              //             }`}
              //             alt="BTC"
              //           />{" "}
              //           History
              //         </div>
              //       </Link>

              //       <Link
              //         className="no-underline"
              //         to={`/my/wallets/${item.symbol}/sell`}
              //         style={{ color: themesSetting?.container?.color }}
              //       >
              //         <div className="flex justify-center items-center gap-x-1">
              //           <img
              //             src="/assets/images/icons/sellCard.svg"
              //             alt="BTC"
              //           />{" "}
              //           Sell to cash
              //         </div>
              //       </Link>
              //       <div className="flex justify-center items-center gap-x-1">
              //         <img src="/assets/images/icons/contract.svg" alt="BTC" />{" "}
              //         Contract
              //       </div>
              //       <div className="flex justify-center items-center gap-x-1">
              //         <img src="/assets/images/icons/website.svg" alt="BTC" />{" "}
              //         Website
              //       </div>
              //     </div>
              //   </div>
              // </div>
              <div
                className="flex flex-col w-full rounded-md mt-2 p-3 sm:p-4 transition-all duration-500 ease-linear"
                key={index}
                style={themesSetting?.card}
              >
                <div className="flex justify-between">
                  <div className="flex gap-x-3">
                    <div className="flex justify-center items-center">
                      {" "}
                      <img
                        className="w-10 h-10 rounded-full"
                        src={item?.logo || "/assets/images/coins/btc.svg"}
                        alt={item?.symbol}
                      />{" "}
                    </div>
                    <div className="flex flex-col ">
                      <p className="mb-1">
                        {" "}
                        {item?.name.length > 10
                          ? `${item?.name.substring(0, 10)}...`
                          : item?.name}{" "}
                      </p>
                      <div className="">
                        <span className="">
                          {formatNumber(+item?.balance)}{" "}
                        </span>{" "}
                        {item?.symbol.toUpperCase()}
                      </div>
                    </div>
                    {+item?.locked_balances > 0 && (
                      <div className="text-xs mb-2">
                        Hold:{" "}
                        <span className="text-xs">
                          {formatNumber(+item?.locked_balances)}{" "}
                        </span>{" "}
                        {item?.symbol.toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end gap-x-3 max-lg:hidden">
                    <VButton
                      type="outline"
                      radius="full"
                      px="3"
                      disabled={!item?.is_deposit}
                      iconLeft={<Icons iconName="withdraw" />}
                      onClick={() =>
                        handleClickFunctionButton("withdraw", item)
                      }
                    >
                      Withdraw
                    </VButton>

                    <VButton
                      radius="full"
                      px="3"
                      disabled={!item?.is_withdraw}
                      iconLeft={<Icons iconName="deposit" />}
                      onClick={() => handleClickFunctionButton("deposit", item)}
                    >
                      Deposit
                    </VButton>
                  </div>
                  <div className="flex justify-end gap-x-3 lg:hidden">
                    <VButton
                      type="outline"
                      radius="full"
                      disabled={!item?.is_deposit}
                      iconLeft={<Icons iconName="withdraw" />}
                      onClick={() =>
                        handleClickFunctionButton("withdraw", item)
                      }
                    ></VButton>

                    <VButton
                      radius="full"
                      disabled={!item?.is_withdraw}
                      iconLeft={<Icons iconName="deposit" />}
                      onClick={() => handleClickFunctionButton("deposit", item)}
                    ></VButton>
                  </div>
                </div>
                <div className="flex flex-wrap gap-x-4 mt-1.5">
                  <div
                    className="flex justify-center items-center gap-x-1 cursor-pointer"
                    onClick={() => handleClickFunctionButton("history", item)}
                  >
                    <img
                      className=""
                      src={`/assets/images/icons/${
                        props?.location?.pathname?.includes(
                          `${item?.symbol}/history`
                        )
                          ? "historyActive.svg"
                          : "history.svg"
                      }`}
                      alt="BTC"
                    />{" "}
                    History
                  </div>

                  <Link
                    className="no-underline"
                    to={`/my/wallets/${item?.symbol}/sell`}
                    style={{ color: themesSetting?.container?.color }}
                  >
                    <div className="flex justify-center items-center gap-x-1">
                      <img src="/assets/images/icons/sellCard.svg" alt="BTC" />{" "}
                      Sell to cash
                    </div>
                  </Link>
                  <div className="flex justify-center items-center gap-x-1">
                    <img src="/assets/images/icons/contract.svg" alt="BTC" />{" "}
                    Contract
                  </div>
                  <div className="flex justify-center items-center gap-x-1">
                    <img src="/assets/images/icons/website.svg" alt="BTC" />{" "}
                    Website
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
