/* eslint-disable indent */
import { ReactNode, createContext, useContext } from "react";
import io from "socket.io-client";
import { MasterContext } from "./MasterContext";

interface SocketContextConfig {
  connectSocket?: () => void;
  connectSocketAd?: () => void;
}

export const SocketContext = createContext<SocketContextConfig>({});

const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { getBalances, setNotifications, setAdvertisements, setReward } =
    useContext(MasterContext);

  const connectSocket = () => {
    const token = localStorage.getItem("auth");
    if (token) {
      const socket = io(`${process.env.REACT_APP_SOCKET_URL}/users`, {
        query: {
          token,
        },
        transports: ["websocket"],
      });
      socket.on("balances", () => {
        getBalances();
      });
      socket.on("rewards", (data) => {
        setReward(data);
      });
      socket.on("notifications", (data) => {
        setNotifications((prev) => ({ ...prev, client: data }));
      });
      socket.on("notifications-system", (data) => {
        setNotifications((prev) => ({ ...prev, system: data }));
      });
    }
  };

  const connectSocketAd = () => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}/advertisements`, {
      transports: ["websocket"],
    });
    socket.on("advertisement", (data) => {
      setAdvertisements((prev) => ({
        ...prev,
        advertisement: { ...prev.advertisement, ...data },
      }));
    });
  };

  const socketValue = {
    connectSocket,
    connectSocketAd,
  };

  return (
    <SocketContext.Provider value={socketValue}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
