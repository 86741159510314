/* eslint-disable max-len */
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useForm, Controller } from "react-hook-form";
import useCountdown from "hooks/useCountdown";
import { Spinner } from "reactstrap";
import { isMobile } from "react-device-detect";

import { Content, Form, FormItem, FormTitle, FormDescription } from "../styles";
import AuthAPI from "api/auth";
import { ROUTES } from "config/routes";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { appName } = useContext(MasterContext);
  const { theme, getThemeByKey } = useContext(ThemeContext);
  const location = useLocation();
  const { time, isCountdown, loadingResend, resendCode } = useCountdown();

  const inputTheme = getThemeByKey("auth.input");
  const query = qs.parse(location.search);
  const email = String(query.email).replace(" ", "+");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [hoverField, setHoverField] = useState(null);

  const { callback } = query;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const confirmLogin = async (data) => {
    try {
      setErrMessage("");
      setLoading(true);
      const res: any = await AuthAPI.postVerify({
        body: { ...data, email: email },
      });
      if (res) {
        localStorage.setItem("auth", res.token);
        setLoading(false);
        history.push(
          `${ROUTES.DASHBOARD}${callback ? `?callback=${callback}` : ""}`
        );
      }
    } catch (error) {
      setErrMessage(error?.errors?.code || "Invalid code!");
      setLoading(false);
    }
  };

  return (
    <Content>
      <div className="flex flex-col md:flex-row-reverse md:gap-x-[100px] items-center justify-center mx-auto h-full max-h-full overflow-auto md:mt-[-80px]">
        {!isMobile && (
          <div className="flex flex-col items-center gap-y-6">
            <svg
              width="121"
              height="120"
              viewBox="0 0 121 120"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                // eslint-disable-next-line max-len
                d="M38 7.5H8V37.5H38V7.5ZM45.5 0V45H0.5V0H45.5ZM15.5 15H30.5V30H15.5V15ZM113 7.5H83V37.5H113V7.5ZM120.5 0V45H75.5V0H120.5ZM90.5 15H105.5V30H90.5V15ZM38 82.5H8V112.5H38V82.5ZM45.5 75V120H0.5V75H45.5ZM15.5 90H30.5V105H15.5V90ZM53 0H60.5V7.5H53V0ZM60.5 7.5H68V15H60.5V7.5ZM53 15H60.5V22.5H53V15ZM60.5 22.5H68V30H60.5V22.5ZM53 30H60.5V37.5H53V30ZM60.5 37.5H68V45H60.5V37.5ZM53 45H60.5V52.5H53V45ZM53 60H60.5V67.5H53V60ZM60.5 67.5H68V75H60.5V67.5ZM53 75H60.5V82.5H53V75ZM60.5 82.5H68V90H60.5V82.5ZM53 90H60.5V97.5H53V90ZM60.5 97.5H68V105H60.5V97.5ZM53 105H60.5V112.5H53V105ZM60.5 112.5H68V120H60.5V112.5ZM113 60H120.5V67.5H113V60ZM8 60H15.5V67.5H8V60ZM15.5 52.5H23V60H15.5V52.5ZM0.5 52.5H8V60H0.5V52.5ZM30.5 52.5H38V60H30.5V52.5ZM38 60H45.5V67.5H38V60ZM45.5 52.5H53V60H45.5V52.5ZM68 60H75.5V67.5H68V60ZM75.5 52.5H83V60H75.5V52.5ZM83 60H90.5V67.5H83V60ZM90.5 52.5H98V60H90.5V52.5ZM98 60H105.5V67.5H98V60ZM105.5 52.5H113V60H105.5V52.5ZM113 75H120.5V82.5H113V75ZM68 75H75.5V82.5H68V75ZM75.5 67.5H83V75H75.5V67.5ZM83 75H90.5V82.5H83V75ZM98 75H105.5V82.5H98V75ZM105.5 67.5H113V75H105.5V67.5ZM113 90H120.5V97.5H113V90ZM68 90H75.5V97.5H68V90ZM75.5 82.5H83V90H75.5V82.5ZM90.5 82.5H98V90H90.5V82.5ZM98 90H105.5V97.5H98V90ZM105.5 82.5H113V90H105.5V82.5ZM113 105H120.5V112.5H113V105ZM75.5 97.5H83V105H75.5V97.5ZM83 105H90.5V112.5H83V105ZM90.5 97.5H98V105H90.5V97.5ZM98 105H105.5V112.5H98V105ZM75.5 112.5H83V120H75.5V112.5ZM90.5 112.5H98V120H90.5V112.5ZM105.5 112.5H113V120H105.5V112.5Z"
                fill="currentColor"
              />
            </svg>
            <h5>Sign in with QR Code</h5>
            <p className="m-0">
              Scan this code with the mobile app to sign in instantly.
            </p>
          </div>
        )}
        <div className="flex flex-col items-center overflow-auto">
          <Form onSubmit={handleSubmit(confirmLogin)}>
            <FormTitle>{appName} Account Verify</FormTitle>
            <FormDescription>
              Welcome back! We sent a code to your email
            </FormDescription>
            <FormItem>
              <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                  <VInput
                    required
                    label="Code"
                    placeholder="Code"
                    hasError={Boolean(errors.code)}
                    errMessage={errMessage}
                    wrapperClassName={"bg-transparent gap-y-1 px-0"}
                    labelClassName={`text-xs font-bold ${
                      theme === "dark" ? "text-white" : "text-gray-6"
                    }`}
                    inputWrapperClassName={`w-full border-[1px] rounded`}
                    className={`w-full bg-transparent p-2`}
                    onMouseEnter={() => setHoverField("code")}
                    onMouseLeave={() => setHoverField(null)}
                    inputStyle={{
                      ...inputTheme,
                      borderColor:
                        hoverField === "code"
                          ? inputTheme?.hover?.borderColor
                          : inputTheme?.borderColor,
                    }}
                    iconRight={
                      <div className="flex items-center justify-center font-bold pr-2 cursor-pointer">
                        {loadingResend ? (
                          <Spinner className="!w-4 !h-4" animation="border" />
                        ) : (
                          <span
                            onClick={() => !isCountdown && resendCode(email)}
                          >
                            {isCountdown ? time : "Resend"}
                          </span>
                        )}
                      </div>
                    }
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <VButton
                className="hover:!opacity-80 cursor-pointer !rounded-lg !border-none py-2"
                loading={loading}
              >
                Verify Code
              </VButton>
            </FormItem>
            <br />

            <div className="flex items-center">
              <a href={ROUTES.LOGIN} className="no-underline">
                <span className="text-gray-4 font-medium">
                  You already have account?{" "}
                </span>
                <span className="font-medium">Sign in</span>
              </a>
            </div>
          </Form>
        </div>
      </div>
    </Content>
  );
}
