// import { Continue } from "../../../styles";
import VButton from "components/v-core/v-button";
// import { Step0Wrapper } from "./styles";

const Step2 = ({
  setDataByKey,
  nextStep,
  depositBanks = [],
  dataPayment,
  backStep,
}) => {
  const onclickBank = (b) => {
    setDataByKey("bank", b);
  };

  return (
    <div className="p-4">
      <div className="text-center flex flex-col items-center justify-center w-full">
        {depositBanks
          .filter((b) => dataPayment.network === b.network)
          .map((b) => {
            return (
              <VButton
                className={`m-1 opacity-60 !font-semibold !w-[350px] ${
                  dataPayment.bank &&
                  dataPayment.bank.account_name === b.account_name
                    ? "!opacity-100"
                    : ""
                }`}
                key={b.name}
                onClick={onclickBank.bind(null, b)}
              >
                Transfer to <b>{b.account_name}</b> ({b.bank_name})
              </VButton>
            );
          })}
        <br />
        <div className="flex flex-col gap-y-3">
          <VButton
            radius="full"
            className="!w-[350px]"
            onClick={() => nextStep()}
            disabled={!dataPayment.bank}
          >
            Continue
          </VButton>
          <VButton
            type="outline"
            radius="full"
            className="!w-[350px]"
            transparent={true}
            onClick={() => backStep()}
          >
            Back
          </VButton>
        </div>
      </div>
    </div>
  );
};

export default Step2;
