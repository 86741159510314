/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import VButton from "components/v-core/v-button";
// import { Continue } from "../../styles";
import { useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MasterContext } from "context/MasterContext";
import DepositVND from "../deposit-vnd";
import { ThemeContext } from "context/ThemeContext";

const DepositStock = () => {
  const { stocks, stockDefault } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const history = useHistory();
  const [method, setMethod] = useState("");
  const { stock } = useParams();

  const handleClick = () => {
    history.push(`/my/wallets/${stock}/deposit/${method}`);
  };

  const currencies = [
    {
      name: "p2p",
      icon: "/assets/images/coins/p2p.png",
      disable: false,
      open: "is_deposit_p2p",
    },
    {
      name: "spot",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_deposit_spot",
    },
  ];

  const _currencies = useMemo(() => {
    if (stocks) {
      const _stock = stocks.find((s) => s.symbol === stock);
      if (_stock) return currencies.filter((c) => _stock?.feature[c.open]);
      return [];
    }
    return [];
  }, [stocks, stock]);

  if (stock === stockDefault) return <DepositVND />;
  return (
    <div className="p-5 flex flex-col w-full justify-center items-center ">
      <h5 className="text-center mb-3">
        {String(stock || "").toUpperCase()} - Please select the method you want
        to deposit{" "}
      </h5>
      <div style={themesSetting.card} className="flex gap-x-2 justify-center my-4">
        {_currencies.map((c) => (
          <VButton
            className={`p-2 rounded-full font-semibold opacity-60 ${
              method === c.name ? "opacity-100" : ""
            }`}
            onClick={() => setMethod(c.name)}
            loading={c.disable}
          >
            <img className="mr-[5px] h-5" src={c.icon} />
            {c.name.toUpperCase()}
          </VButton>
        ))}
      </div>

      <VButton className="!w-[350px]" onClick={handleClick} disabled={!method}>
        Continue
      </VButton>
    </div>
  );
};
export default DepositStock;
