import styled from "styled-components";

export const ItemContent = styled.div`
  height: 100%;

  img {
    width: 100%;
  }
`;

export const Flex = styled.div<{ main?: boolean }>`
  display: flex;

  &:nth-child(2) {
    pointer-events: none;
    margin-top: ${(props) => (props.main ? "-20px" : "0px")};
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    &:nth-child(2) {
      margin-top: 0px;
    }
  }
`;

export const ItemFooter = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 430px;
  {mt-[40px] w-full max-w-[430px]}

  a {
    color: #fff;
    text-decoration: none;
  }

  h6 {
    width: fit-content;
    border-bottom: 1px solid;
    margin-bottom: 20px;
    font-weight: bold;
    cursor: pointer;
    {w-fit border-b font-bold cursor-pointer mb-[20px] hover:text-[#a0a0a0]}

    &:hover {
      color: #a0a0a0;
    }
  }

  p {
    cursor: pointer;
    &:hover {
      color: #a0a0a0;
    }
    font-size: 14px;
    {text-[14px] cursor-pointer hover:text-[#a0a0a0]}
  }

  span {
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 16px;
    margin-bottom: 20px;
    {flex items-center text-[14px] leading-4 mb-[20px]}
    img {
      width: 25px;
      margin-right: 5px;
    }
  }
`;

export const LogoFooter = styled.div`
  margin: 30px 0;
  position: relative;
  /* max-width: 50%; */
  width: auto;
  height: 100%;
  min-height: 50px;
  {relative my-[30px] w-auto h-full min-h-[50px]}
  img {
    width: auto !important;
    max-width: 100% !important;
    cursor: pointer;
    height: 50px !important;
    {w-auto max-w-full h-[50px] cursor-pointer}
  }
`;

export const ListIcons = styled.div`
  display: flex;
  margin: 40px 0;

  img {
    margin-right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    {w-[30px] h-[30px] mr-[30-px] cursor-pointer}
  }
`;

export const AddLinkFooter = styled.div<{ mt?: string }>`
  margin-top: ${(props) => props.mt || "0px"};
  cursor: pointer;
`;

export const ItemContainer = styled.div`
  padding-right: 50px;
`;

export const Upload = styled.div`
  position: absolute;
  width: auto;
  max-width: 100%;
  height: 100%;
`;
