import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.black100};
  min-height: 100%;
  padding: 20px;
`;

export const TextColor = styled.div<{ color: any }>`
  color: ${(props) => props.theme.colors[props.color]};
`;

export const Profile = styled.div`
  position: relative;
  padding-top: 40px;
  margin-top: 40px;
  width: 100%;
`;

export const VisitCard = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerVisitCard = styled.div`
  margin-bottom: 30px;
  p {
    color: #8c8b8b;
  }
`;

export const VisitCardDetailFront = styled.div`
  position: absolute;
  padding: 0 10px;
  width: 100%;
  height: 44%;
  top: 29%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
`;

export const VisitCardDetailBack = styled.div`
  padding: 7.5% 10%;
  display: flex;
  justify-content: space-between;
  color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const TitleVisitCard = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
`;

export const Content = styled.div<{ marginTop?: string }>`
  text-align: center;
  height: 40%;
  margin-top: ${(props) => props.marginTop || "3%"};
  width: 100%;

  @media screen and (max-width: 1350px) {
    height: 30%;
  }
`;

export const ImageVisit = styled.div`
  width: 500px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
  }

  @media screen and (max-width: 1350px) {
    width: 325px;
  }
`;

export const LogoVisit = styled.div`
  position: relative;
  width: auto;
  min-width: 40px;
  max-width: 100%;
  height: 40px;

  img {
    height: 100%;
    width: auto;
  }

  @media screen and (max-width: 1350px) {
    height: 30px;
    min-width: 30px;
  }
`;

export const NameVisit = styled.h1`
  font-size: 24px;
  margin-bottom: 0;
  line-height: 0.8;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: 1350px) {
    font-size: 16px;
  }
`;

export const DescVisit = styled.div`
  font-size: 13px;
  color: #8c8b8b;
  line-height: 120%;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: 1350px) {
    font-size: 9px;
    color: #8c8b8b;
    line-height: 135%;
  }
`;

export const QrCodeWrapper = styled.div`
  padding: 4px;
  background-color: #fff;
  display: inline-block;
  margin-top: -15px;
`;

export const LinkVisit = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 228px;

  @media screen and (max-width: 1350px) {
    font-size: 11px;
    margin-top: 6px;
    max-width: 150px;
  }
`;

export const LeftDetail = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const RightDetail = styled.div`
  width: 58%;
  text-align: right;
  position: relative;
`;

export const HeaderRight = styled.div`
  position: absolute;
  right: 0;
  max-width: 100%;
  bottom: 75%;

  h5 {
    margin-bottom: 0;
    font-size: 18px;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
  p {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1350px) {
    h5 {
      font-size: 14px;
      max-height: 34px;
    }
    p {
      font-size: 9px;
      margin-bottom: 2px;
    }
  }
`;

export const ContentRigth = styled.div`
  position: absolute;
  width: 100%;
  right: 8%;
  bottom: 26%;
  height: 34%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (max-width: 1350px) {
    margin-top: 20.1%;
    height: 32%;
    p {
      font-size: 8px;
    }
  }
`;

export const FooterRight = styled.div`
  position: absolute;
  width: 93%;
  right: 10%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;

  p {
    overflow-wrap: break-word;
    overflow: hidden;
    margin-bottom: 0;
    text-align: right;
    line-height: 100%;
    font-size: 14px;
    color: white;
    text-align: right;
    max-height: 100%;
  }

  @media screen and (max-width: 1350px) {
    height: 20px;
    p {
      font-size: 10px;
    }
  }
`;

export const MessageKyc = styled.div`
  text-align: center;
  margin-top: 20px;
  p {
    color: ${(props) => props.theme.colors.orange100};
    display: inline;
    cursor: pointer;
  }
`;

export const ButtonEdit = styled.div`
  position: absolute;
  top: 0;
  right: 30px;

  @media screen and (max-width: 460px) {
    right: 5px;
  }
`;

export const UserProfile = styled.div`
  padding: 0px 10px;
  margin-bottom: 20px;

  p {
    margin-top: 10px;
    text-align: center;
  }
`;

export const Tabs = styled.div`
  display: flex;
  margin: 10px 0;
  p {
    margin: 15px;
  }

  @media screen and (max-width: 660px) {
    flex-direction: column;
  }
`;

export const Avatar = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }
`;

export const ChangeAvt = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Info = styled.div`
  margin: 0 30px;

  @media screen and (max-width: 460px) {
    margin: 0 5px;
  }
`;

export const RowInfo = styled.div`
  background-color: ${(props) => props.theme.colors.grey200};
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.black100};
`;

export const ItemEdit = styled.div`
  margin: 25px 0;
`;

export const ItemRow = styled.div`
  width: 50%;
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Edit = styled.div`
  padding: 0px 20px;
  margin-top: 50px;
`;

export const SubmitEdit = styled.div`
  padding-top: 20px;
  text-align: center;
`;

export const ChangePassword = styled.div`
  margin-top: 50px;
  padding: 0px 20px;
`;

export const Activity = styled.div`
  margin-top: 50px;
  padding: 0px 20px;
  overflow: auto;
`;

export const UploadKYC = styled.div`
  width: 100%;
  padding: 20px 10px;

  .dropdown {
    min-width: 200px;
    text-align: start;
  }

  .row {
    text-align: center;
  }
  .type-object {
    display: flex;
    .object {
      margin-left: 15px;
    }
  }
`;

export const SubmitKYC = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button {
    margin: 0 10px;
    height: 32px;
  }
`;

export const ContainerVisit = styled.div`
  position: relative;
  height: 220px;
  max-width: 350px;
  margin: auto;
`;

export const VerificationWrapper = styled.div`
  padding: 0 30px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .CircularProgressbar {
    max-width: 80px;
  }
  h4 {
    margin: 0;
    font-size: 20px;
    margin-left: 20px;
    max-width: 300px;
  }
`;

export const StepWrapper = styled.div`
  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 5px 0;
      img {
        width: 20px;
      }
      span {
        color: yellow;
        font-size: 12px;
      }
    }
  }
  .note {
    margin-top: 7px;
    svg {
      margin-right: 3px;
    }
  }
`;

export const NoteUploadInvoice = styled.div`
  p {
    margin: 2px 0;
    font-size: 13px;
  }
`;

export const ContaineIdKYC = styled.div`
  input {
    width: 100%;
    background: inherit !important;
    color: white !important;
  }
`;

export const VerifyWrapper = styled.div``;
