import VDropdown from "components/v-core/v-dropdown";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext, useEffect, useState } from "react";
import { formatNumber } from "utils/common";
import { useHistory } from "react-router-dom";

const DROPDOWN_VALUE = {
  label: "Newest",
  value: "newest",
};

const FiltersArea = () => {
  const { categories, getCategories } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const [categorySelected, setCategorySelected] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getCategories({
      params: { group: "category_product" },
    });
  }, []);

  const choseCategory = (item: any) => {
    history.push(`/my/shop?category=${item._id}`);
    setCategorySelected(item);
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-1">
          <span>{formatNumber("5782238")}</span>
          <span className="text-xs opacity-60">items</span>
        </div>
        <div className="flex items-center">
          <span className="text-xs opacity-60">Date listed:</span>
          <VDropdown
            buttonClassName="border-none !bg-transparent pointer-events-none !p-0"
            customClassName="w-[135px]"
            value={DROPDOWN_VALUE}
            options={[]}
          />
          <Icons iconName="filter" />
        </div>
      </div>
      <div className="w-full grid grid-cols-6 gap-2">
        {categories?.map((el) => (
          <div
            key={el._id}
            className={`col-span-1 flex flex-col gap-y-2 justify-center items-center rounded p-2 cursor-pointer hover:opacity-80`}
            onClick={() => choseCategory(el)}
            style={categorySelected?.id === el.id ? themesSetting.card : {}}
          >
            <img
              className="max-w-[35px] w-full object-cover"
              src={el.icon || "/logo32.png"}
              alt="filter-icon"
            />
            <span className="max-w-full overflow-hidden text-ellipsis text-nowrap">
              {el.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FiltersArea;
