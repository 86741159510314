/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useMemo, useState } from "react";
// import {  DepositStockWrapper } from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { MasterContext } from "context/MasterContext";
import VButton from "components/v-core/v-button";
import WithdrawVND from "../withdraw-vnd";

const View = () => {
  const { stocks, stockDefault } = useContext(MasterContext);
  const history = useHistory();
  const [method, setMethod] = useState("");
  const { stock } = useParams();

  const handleClick = () => {
    history.push(`/my/wallets/${stock}/withdraw/${method}`);
  };

  const currencies = [
    {
      name: "p2p",
      icon: "/assets/images/coins/p2p.png",
      disable: false,
      open: "is_withdraw_p2p",
    },
    {
      name: "spot",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_withdraw_spot",
    },
    {
      name: "qr-mart",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_qrmart",
    },
    {
      name: "cv",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_cv",
    },
    {
      name: "vdiarybook",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_vdiarybook",
    },
  ];

  const _currencies = useMemo(() => {
    if (stocks) {
      const _stock = stocks.find((s) => s.symbol === stock);
      if (_stock) return currencies.filter((c) => _stock?.feature[c.open]);
      return [];
    }
    return [];
  }, [stocks, stock]);

  if (stock === stockDefault) return <WithdrawVND />;

  return (
    <div className="p-5 flex flex-col w-full justify-center items-center">
      <h5 className="text-center">
        {String(stock || "").toUpperCase()} - Please select the method you want
        to withdraw{" "}
      </h5>
      <div className="flex justify-center mt-2">
        {_currencies.map((c) => (
          <VButton
            className={`m-1 opacity-60 !font-semibold  ${
              method === c.name ? "!opacity-100" : ""
            }`}
            onClick={() => setMethod(c.name)}
            disabled={c.disable}
          >
            <img src={c.icon} className="mr-[5px] h-5" />
            {c.name.toUpperCase()}
          </VButton>
        ))}
      </div>
      <div className="pt-[25px] flex flex-col w-full  items-center justify-center gap-y-3">
        <VButton
          className="!w-[300px]"
          radius="full"
          onClick={handleClick}
          disabled={!method}
        >
          Continue
        </VButton>
      </div>
    </div>
  );
};
export default View;
