/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse, IRequestData } from "./request";

export const getAdvertisement = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/advertisements")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const createAdvertisement = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post("/advertisements", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export default {
  getAdvertisement,
  createAdvertisement,
};
