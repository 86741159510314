import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  border-radius: 8px;
  width: 100%;
  padding: 30px;
  overflow: auto;
  min-height: 500px;
  button {
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const ListVideo = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  width: 250px;
  margin: 10px;
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
  }
`;

export const Play = styled.div`
  position: absolute;
  top: 35%;
  right: 50%;
  :hover {
    color: red;
  }
`;

export const Logo = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #6d6b6b;
  border-radius: 8px;
  margin-bottom: 20px;
  h4 {
    margin-top: 10px;
  }
`;