import { ThemeContext } from "context/ThemeContext";
import { useContext } from "react";
import IconWallets from "./wallets";
import IconMenus from "./menus";
import IconTrusts from "./trusts";
import IconSwaps from "./swaps";
import IconCommons from "./common";
import IconCavaners from "./cavaner";
import IconProfile from "./profile";
import IconCampaign from "./campaign";
import IconRankings from "./rankings";
import IconLandingPage from "./landing-page";

type IProps = {
  iconName?:
    | "dark"
    | "dropdown"
    | "light"
    | "copy"
    | "bell"
    | "menu_mobile"
    | "arrow_down_icon"
    | "loading"
    | "close"
    | "chevronleft"
    | "chevronright"
    | "back"
    | "edit"
    | "save"
    | "send"
    | "auction"
    | "tag"
    | "hour_glass"
    | "filter"
    | "signOut"
    | "badge-check"
    | "dashboard"
    | "exchanges"
    | "swap"
    | "wallets"
    | "products"
    | "tasks"
    | "trust_project"
    | "b_commerce"
    | "ibo_crowdfunding_project"
    | "personal"
    | "profile"
    | "my_ibo_project"
    | "campaign"
    | "sales_statistics"
    | "channel"
    | "q&a"
    | "supports"
    | "arrow-up"
    | "arrow-down"
    | "chart-up"
    | "chart-down"
    | "analysis"
    | "share"
    | "withdraw"
    | "deposit"
    | "del"
    | "view"
    | "more"
    | "down"
    | "receipt"
    | "congratulation"
    | "histories"
    | "claim_profit"
    | "stock_swap"
    | "gift"
    | "connect"
    | "trendup"
    | "image"
    | "video"
    | "devide"
    | "web"
    | "money"
    | "name"
    | "object"
    | "group"
    | "role"
    | "phone"
    | "email"
    | "address"
    | "verification"
    | "status"
    | "levelKyc"
    | "level_congratulation"
    | "profit"
    | "affiliate"
    | "group_sales"
    | "customer"
    | "top-1"
    | "arrow_drop_up"
    | "arrow_drop_down"
    | "left_arrow_alt"
    | "right_arrow_alt"
    | "play_button"
    | "upload"
    | "check"
    | "add_image"
    | "add_video"
    | "copy_right";
  isActive?: boolean;
  isHover?: boolean;
  className?: string;
  onClick?: any;
  iconClassName?: string;
  style?: any;
};

const Icons = ({
  iconName,
  isActive = false,
  isHover = false,
  className,
  onClick,
  iconClassName,
  style,
}: IProps) => {
  const { themesSetting } = useContext(ThemeContext);

  return (
    <div className={`${className}`} onClick={onClick}>
      <IconCommons
        iconClassName={iconClassName}
        themesSetting={themesSetting}
        iconName={iconName}
        isActive={isActive}
        isHover={isHover}
        style={style}
      />
      <IconMenus
        iconClassName={iconClassName}
        themesSetting={themesSetting}
        iconName={iconName}
        isActive={isActive}
        isHover={isHover}
      />
      <IconWallets iconClassName={iconClassName} iconName={iconName} />
      <IconTrusts iconClassName={iconClassName} iconName={iconName} />
      <IconSwaps iconClassName={iconClassName} iconName={iconName} />
      <IconCavaners iconClassName={iconClassName} iconName={iconName} />
      <IconProfile iconClassName={iconClassName} iconName={iconName} />
      <IconCampaign iconClassName={iconClassName} iconName={iconName} />
      <IconRankings iconClassName={iconClassName} iconName={iconName} />
      <IconLandingPage iconClassName={iconClassName} iconName={iconName} />
    </div>
  );
};

export default Icons;
