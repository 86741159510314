// import { ContentRightView} from "../../styles";
import Network from "../networks";
import VInput from "components/v-core/v-input";
import VButton from "components/v-core/v-button";

const Step1 = ({
  dataTrading,
  handleClick,
  nextStep,
  setDataByKey,
  backStep,
}) => {
  const handleChange = (e) => {
    setDataByKey("amount", Number(e.target.value));
  };

  return (
    <div className="p-[15px]">
      {dataTrading.currency === "usdt" && (
        <Network
          network={dataTrading.network}
          setNetwork={(data) => {
            setDataByKey("network", data);
          }}
        />
      )}
      <VInput
        isCurrency={false}
        transparent={true}
        className="!bg-transparent"
        label={`Amount stock (${String(
          dataTrading.stock || ""
        ).toUpperCase()}) *`}
        value={dataTrading.amount}
        onChange={(e) => handleChange(e)}
        placeholder="10"
      />
      <div className="flex pt-[6px]">
        <img
          className="mr-[5px] mb-4"
          src="/assets/images/icons/warning.svg"
          alt="warning"
        />
        <p className="text-[10px]">
          {dataTrading.stock.toUpperCase()} sell is free. Vzonex does not
          collect {dataTrading.stock.toUpperCase()} sell fee, however, in some
          instances, you may have to bear a small transaction fee charged
          directly by the processing bank or network.
        </p>
      </div>
      <div className="pt-[25px] flex flex-col w-full items-center justify-center gap-y-4">
        <VButton radius="full" className="!w-[350px]" onClick={handleClick}>
          Sell
        </VButton>
        <VButton
          type="outline"
          radius="full"
          className="!w-[350px]"
          onClick={() => backStep()}
        >
          Back
        </VButton>
      </div>
    </div>
  );
};

export default Step1;
