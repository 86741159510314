/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "context/ThemeContext";
import SettingService from "api/settings";

const View = () => {
  const [state, setState] = useState([]);
  const [qas, setQAs] = useState([]);
  const [open, setOpen] = useState(null);
  const { themesSetting, theme } = useContext(ThemeContext);

  const onGetQAs = async () => {
    try {
      const res: any = await SettingService.getSettingByName("q&a");
      if (res && res?.setting) {
        setQAs(res.setting?.value);
      }
    } catch (error) {}
  };

  useEffect(() => {
    onGetQAs();
  }, []);

  useEffect(() => {
    if (qas)
      setState(
        (qas || []).map((q) => ({
          ...q,
          isOpen: false,
        }))
      );
  }, [qas]);

  const onClick = (index) => {
    setOpen((open) => (open === index ? null : index));
  };

  return (
    <div className="p-3">
      <h3 className={`${theme === "dark" ? "!text-white" : "!text-gray-3"}`}>
        Q&A
      </h3>
      <div className="p-4">
        {state.map((item) => (
          <div>
            <button
              onClick={(_) => onClick(item.index)}
              style={themesSetting?.card}
              id={`toggler_${item.index}`}
              className="rounded-md w-full p-3 my-2 text-left"
            >
              {item.question}
            </button>
            <div
              className={`transition-all duration-1000  ease-in-out  ${
                open === item.index ? "block" : "hidden"
              }`}
            >
              <div className={"px-2 "}>
                <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default View;