import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Wrapper = styled.div<{ isWebview?, isCreate?: boolean }>`
    margin-top: ${props => ( props.isWebview || props.isCreate ) ? '0px' : "70px"};
    background-color: ${(props) =>( props.isWebview || props.isCreate ) ? "transparent" : props.theme.colors.black100};
    padding: 14px;
    overflow: auto;
    height: 100%;

    .dropdown-item {
            &:hover {
                color: ${props => props.isWebview ? "white" : "black"} !important;
            }
        }

    .dropdown-item, .dropdown-item-text {
        color: white !important;
    }

    th,td,tr {
        text-align: center;
    }
`;

export const Container = styled.div`
    padding: ${isMobile ? "0px" : "10px"};
    max-width: 840px;
    margin: auto;

    @media screen and (max-width: 600px) {
        .form-label {
            font-size: 10px !important;
        }
    }
`;

export const HeadProfit = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    font-size: 20px;
    color: ${props => props.theme.colors.green100};
    font-weight: bold;

    input {
        margin: 0;
        margin-left: 10px;
    }
`;

export const ItemProfit = styled.div<{ isWebview?, noBottom?: boolean, }>`
    border-bottom: ${props => props.noBottom ? "none" : '1px dashed #585656'} ;
    padding: 20px 0;
    position: relative;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
`;

export const Detail = styled.div`
    padding-left: 20px;
`;

export const ItemTopShare = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const ContainerInput = styled.div<{ width?: string }>`
    width: ${props => props.width || "100%"};
    position: relative;
    max-width: 200px;
    min-width: 82px;
    margin-right: 10px;

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    }
`;

export const Arrow = styled.div<{ isTotal?: boolean }>`
    margin-top: ${props => !props.isTotal ? '15px' : '0'};
    margin-right:  ${props => props.isTotal ? '10px' : '5px'};
    transform: rotate(180deg);
`;

export const Action = styled.div`
    margin-top: 30px;
    display: flex;
    padding: 10px 0;
`;

export const ItemShare = styled.div<{ isWrap?: boolean }>`
    display: flex;
    align-items: center;
    flex-wrap: ${props => props.isWrap ? 'wrap' : 'nowrap'};

    input {
        display: block;
        width: 100%;
    }
`;

export const SubmmitShare = styled.div`
    display: flex;
    justify-content: center;
    padding: 100px 0;

    button {
        width: 300px;
        margin: 0 5px;
    }
`;

export const HistotyProfit = styled.div`
    font-size: 18px;
`;

export const PaginationHistoty = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 30px;

    button {
        padding: 0 15px !important;
        margin: 3px;
    }
`;

export const FlexColumn = styled.div<{ fisrtBold?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;

    span:first-child {
        font-weight: ${props => props.fisrtBold ? "bold" : ''};
        font-size: ${props => props.fisrtBold ? "16px" : ''};
    }
`;

export const TotalAmountShare = styled.div`
    span {
        display: flex;
    }
`;

export const ListFriends = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
    
    p {
        margin-bottom: 8px;
        font-size: 14px;
    }

    @media screen and (max-width: 876px) {
       position: initial;
       max-height: 300px;
       height: 100%;
       margin-bottom: 15px;
    }
`;

export const TextSwap = styled.div`
    display: block;
    margin-top: 10px;

    b {
        color: ${props => props.theme.colors.green100};
    }
    span {
        cursor: pointer;
        color: ${props => props.theme.colors.orange100};
    }
`;

export const ContainerReferCode = styled.div<{minHeight? : string}>`
    position: relative;
    min-height: ${props => props.minHeight};
`;

export const TableDetail = styled.div`
  overflow: auto;
  max-height: 90%;
  min-height: 180px;
  height: auto;

  @media screen and (max-width: 700px) {
       max-height: 270px;
    }
`;

export default Wrapper;