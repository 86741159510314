import VButton from "components/v-core/v-button";
import { MasterContext } from "context/MasterContext";
import React, { useContext } from "react";
import { formatNumber } from "utils/common";

const Balances = () => {
  const { auth, balances } = useContext(MasterContext);

  return (
    <div className="flex flex-col items-center justify-center gap-y-2">
      <img
        className="size-24 rounded-full object-cover"
        src={auth?.avatar || "/assets/images/backvisitcard.png"}
        alt="avatar"
      />
      <div className="flex items-center gap-x-2">
        <span className="font-semibold">
          {formatNumber(+balances?.total_usd, 2)} $
        </span>
      </div>
      <VButton
        disabled={true}
        className="w-full"
        py="[10px]"
        radius="full"
        iconLeft={""}
      >
        Top up balances
      </VButton>
    </div>
  );
};

export default Balances;
