import axios from "axios";

const baseUrl = "https://s3.vzonex.com" //"http://192.168.1.4:3003";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  headers: { "X-Custom-Header": "xxx", "Access-Control-Allow-Origin": "*" },
});

export const request = (options: any = {}) => {
  const AUTH_TOKEN = localStorage.getItem("s3-auth-service");
  axiosInstance.defaults.headers.common["x-access-token"] = AUTH_TOKEN;
  axiosInstance.defaults.headers.common["x-token"] = "xzrRdde#@298192!!999099928" //"xiiooz1dm2#@!112";

  if (options.headers) {
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...options.headers,
    };
  }
  return axiosInstance;
};

export const convertS3Link = (s3Url) => `${baseUrl}/pl/vzonex/get-file-base64?fileUrl=${s3Url}`

export const parseErrorResponse = (err) => {
  if (err && err.response) {
    if (err.response.data.code === 401) {
      //   cookies.remove('authorization');
    }
    return err.response.data;
  }
  return new Error("Bad request");
};

export interface IRequestData {
  body? :any;
  params? :any;
  query?:any
}