import FormProjectICO from "pages/my/my-project/components/form-project";

const Step3 = (props) => {
  const { projectInfo, handleChangeProject } = props;

  return (
    <>
      <p className="text-[14px] md:text-[16px] text-center">
        Project information
      </p>
      <div className="w-full flex flex-col gap-y-3">
        <FormProjectICO
          isShowDetail={false}
          projectInfo={projectInfo}
          handleChangeProject={handleChangeProject}
        />
      </div>
    </>
  );
};

export default Step3;
