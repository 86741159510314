import styled from "styled-components";

export const ObjectWrapper = styled.div`
  .form-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .upload {
      width: 200px;
      label {
        height: 200px;
        max-width: 300px;
      }
    }
  }
  .logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonEdit = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;
