import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import NavBarBottom from "../MainLayout/navbar-bottom";
import { ThemeContext } from "context/ThemeContext";
import BackgroundVideoComponent from "pages/create-landing-page/iframeVideo";
// import { getLinkYoutubeIframe } from "utils/common";
// import { convertIdVideo } from "utils/convertIdVideo";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { theme, handleSwitchTheme, logos, themesSetting } =
    useContext(ThemeContext);
  const history = useHistory();

  console.log('123123',{
    logos
  });
  

  return (
    <div
      style={{ color: themesSetting.layout.textLoginPage }}
      className="relative bg-black h-screen"
    >
      <BackgroundVideoComponent link={logos?.backgroundLogin} />
      <div className="z-1 relative h-full">
        <div className="flex items-start justify-center md:justify-between p-[14px] md:p-6 cursor-pointer">
          <img
            onClick={() => history.push("/")}
            src={logos?.logo}
            className={` ${
              props?.page === "event" && "w-[150px]"
            } md:w-[250px] w-[200px]`}
          />
          <div
            className="flex items-center justify-center"
            onClick={handleSwitchTheme}
          >
            <img
              src={`/assets/icons/header/${
                theme === "dark" ? "sun" : "moon"
              }.svg`}
              className="w-5"
              alt=""
            />
          </div>
        </div>

        <div className="flex justify-center h-[calc(100vh-150px)] lg:h-[calc(100vh-115px)] items-center">
          {props.children}
        </div>
        {isMobile && <NavBarBottom />}
      </div>
    </div>
  );
}
