import Icons from "helper/icons";
import React from "react";

const Details = () => {
  return (
    <div className="flex gap-x-3">
      <div className="flex flex-col items-center gap-y-1">
        <div className="w-6 h-6">
          <Icons iconName="top-1" />
        </div>
        <div className="w-20 h-20 rounded-full">
          <img
            className="w-full h-full rounded-full object-cover"
            src="/assets/images/avatar.png"
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col items-start gap-y-2">
        <div className="flex flex-col items-start">
          <span className="text-xs opacity-70">Total sales</span>
          <span>$150.992.175</span>
        </div>
        <div className="flex flex-col items-start">
          <span className="text-xs opacity-70">Affiliates</span>
          <span>$92.770.128</span>
        </div>
        <div className="flex flex-col items-start">
          <span className="text-xs opacity-70">Group sales</span>
          <span>$375.996.084</span>
        </div>
        <div className="flex flex-col items-start">
          <span className="text-xs opacity-70">Custom</span>
          <span>914</span>
        </div>
      </div>
    </div>
  );
};

export default Details;
