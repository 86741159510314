/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable array-callback-return */
import Wrapper, { Container, SubmmitShare, TextSwap } from "./styles";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import APIs from "api";
import { Tab, Tabs } from "../../styles";
import Button from "components/v-core/v-button";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import TableProfit from "./tableShareProfit";

import ShareProfitTotal from "./shareProfitTotal";
import ShareProfitSell from "./shareProfitSell";
import HistoryProfit from "./historyProfit";
import { MasterContext } from "context/MasterContext";

const initValueProfit = {
  totalProfitSharingSetting: {
    total: 0,
    admin: {
      percent: 0,
    },
    shareholder: {
      estock: "",
      option: [
        {
          from: 0,
          to: 0,
          percent: 0,
        },
      ],
    },
    presenter: {
      estock: "",
      option: [
        {
          refer_code: "",
          percent: 0,
        },
      ],
    },
  },
  affilateProfitSetting: {
    enable: false,
    admin: {
      percent: 0,
    },
    affiliate_marketing: {
      estock: "",
      percent: 0,
    },
    connector: {
      estock: "",
      option: [
        {
          refer_code: "",
          percent: 0,
        },
      ],
    },
    commission_for_buyer: {
      option: [
        {
          level: "",
          estock: "",
          percent: 0,
        },
      ],
    },
  },
};

const Profit = (props) => {
  const { stocksPrice } = useContext(MasterContext);
  const { role, projectId, token } = useParams();

  const location: any = useLocation();
  const { newAccount } = queryString.parse(location.search);
  const { isWebview, isCreate, disable, dataProject } = props;
  const history = useHistory();

  const [saving, setSaving] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [symbol, setSymbol] = useState<string>("");
  const [isShowdetail, setIsShowdetail] = useState<boolean>(false);
  const [active, setActive] = useState<string>("profitbusiness");
  const [dataProfit, setDataProfit] = useState<any>(initValueProfit);
  const [dataListFriends, setDataListFriends] = useState<any>(null);
  const [dataTables, setDataTables] = useState<any>();
  const [dataHistory, setDatahistory] = useState<any>(null);
  const [indexHistory, setIndexHistory] = useState<number>(0);
  const [totalSharedAfterCheck, setTotalSharedAfterCheck] = useState<number>(0);
  const [missingStock, setMissingStock] = useState<any>([]);

  const blockHandling = saving || checking || processing;

  const { handleSubmit } = useForm();

  useEffect(() => {
    getProject();
  }, [projectId]);

  useEffect(() => {
    getProject();
    getListFriends();
  }, []);

  const handelTabs = (tab: string) => {
    if (tab === "history") {
      getProject();
    }
    if (tab !== active) {
      setIsShowdetail(false);
    }
    setActive(tab);
  };

  const onChangeStockName = (
    stock: any,
    key: string,
    type: string,
    index?: number,
    name?: string
  ) => {
    if (key !== "commission_for_buyer") {
      setDataProfit((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [key]: {
            ...prev[type][key],
            estock: stock.value,
          },
        },
      }));
    } else {
      const newArr: any = dataProfit[type][key].option;
      newArr[index] = {
        ...dataProfit[type][key].option[index],
        [name]: stock.value,
      };
      setDataProfit((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          option: newArr,
        },
      }));
    }
  };

  const onChangeAmount = (e, key) => {
    setDataProfit((prev) => ({
      ...prev,
      totalProfitSharingSetting: {
        ...prev.totalProfitSharingSetting,
        [key]: e,
      },
    }));
  };

  const onChangeNote = (e, key) => {
    setDataProfit((prev) => ({
      ...prev,
      totalProfitSharingSetting: {
        ...prev.totalProfitSharingSetting,
        [key]: {
          ...prev.totalProfitSharingSetting[key],
          note: e.target.value,
        },
      },
    }));
  };

  const onChangeAffilate = (index, type, e, key, title, currency?) => {
    const newArr: any = dataProfit[title][key]?.option;
    newArr[index] = {
      ...dataProfit[title][key]?.option[index],
      [type]: currency ? e : e.target.value,
    };
    setDataProfit((prev) => ({
      ...prev,
      [title]: {
        ...prev[title],
        [key]: {
          ...prev[title][key],
          option: newArr,
        },
      },
    }));
  };

  const onChangePercent = (e, key, type) => {
    setDataProfit((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [key]: {
          ...prev[type][key],
          percent: e.target.value,
        },
      },
    }));
  };

  const handleAddField = (key: string, type: string) => {
    const option =
      key === "shareholder"
        ? {
            from: 0,
            to: 0,
            percent: 0,
          }
        : key === "commission_for_buyer"
        ? {
            level: "",
            estock: "",
            percent: 0,
          }
        : {
            refer_code: "",
            percent: 0,
          };

    setDataProfit((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [key]: {
          ...prev[type][key],
          option: prev?.[type]?.[key]?.option?.concat(option),
        },
      },
    }));
  };

  const handleRemoveField = (key: string, type: string) => {
    const newArr = dataProfit[type][key].option.slice(
      0,
      dataProfit[type][key].option.length - 1
    );
    setDataProfit((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [key]: {
          ...prev[type][key],
          option: newArr,
        },
      },
    }));
  };

  const showHistotyTable = (index: number) => {
    setIndexHistory(index);
  };

  const checkTotalShared = async (data) => {
    let total: number = 0;
    await Promise.all(
      data.map((dt: any) => {
        total = total + dt.amountVnex;
      })
    );
    setTotalSharedAfterCheck(total);
  };

  const checkShare = async () => {
    setChecking(true);
    try {
      const res: any = await APIs.PROJECT.checkShareProfitByType({
        params: {
          id: projectId,
          type: "totalProfitSharingSetting",
        },
      });
      if (res) {
        setMissingStock(
          res.dataBalanceNotEnough.filter(
            (el: any, index: number) =>
              el.symbol !== res.dataBalanceNotEnough[index - 1]?.symbol
          )
        );
        setDataTables(res.dataProfitDetail);
        checkTotalShared(res.dataProfitDetail);
        setIsShowdetail(true);
        toast.success("Check profit successful!");
      }
      setChecking(false);
    } catch (error) {
      toast.error(error.errors || "Some thing went wrong");
      setIsShowdetail(false);
      setChecking(false);
    }
  };

  const handleSaveProfit = async (value) => {
    setSaving(true);
    try {
      const res: any = await APIs.PROJECT.updateShareProfit({
        params: {
          id: projectId,
        },
        body: {
          ...dataProfit,
        },
      });
      if (res) {
        if (isCreate) {
          if (newAccount) {
            history.push(
              `/${role}/${res?.symbol}?token=${token}&isEdit=true&newAccount=true`
            );
          } else {
            history.push(`/${role}/${res?.symbol}?token=${token}&isEdit=true`);
          }
        }
        toast.success("Share profit successful!");
      }
      setSaving(false);
    } catch (error) {
      toast.error(error.errors || "Some thing went wrong");
      setSaving(false);
    }
  };

  const handleTransfer = async () => {
    setProcessing(true);
    try {
      const res: any = await APIs.PROJECT.transferShareProfit({
        params: {
          id: projectId,
        },
        body: dataTables,
      });
      if (res) {
        setDataTables(res);
        toast.success("Transfer successful!");
      }
      setProcessing(false);
    } catch (error) {
      toast.error(error.errors || "Some thing went wrong");
      setProcessing(false);
    }
  };

  const getProject = async () => {
    try {
      const project: any = await APIs.PROJECT.getProjectById({
        params: {
          id: projectId,
        },
      });
      if (project) {
        setSymbol(project?.project.symbol);
      }
      if (project && project.project?.sharedProfit) {
        setDataProfit({
          totalProfitSharingSetting: {
            ...initValueProfit.totalProfitSharingSetting,
            ...project.project?.sharedProfit?.totalProfitSharingSetting,
          },
          affilateProfitSetting: {
            ...initValueProfit.affilateProfitSetting,
            ...project.project?.sharedProfit?.affilateProfitSetting,
          },
        });
      }

      if (project && project.project?.sharedProfitHistory) {
        setDatahistory(project.project?.sharedProfitHistory);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const getListFriends = async () => {
    const list = await APIs.PROJECT.getProjectOwnerFriends({
      params: {
        id: projectId,
      },
    });
    if (list) {
      setDataListFriends(list);
    }
  };

  const summary: any = useMemo(() => {
    const value = dataProfit?.totalProfitSharingSetting;
    const totalPercentPresenter = value.presenter.option.reduce(function (
      acc,
      obj
    ) {
      return acc + Number(obj.percent);
    },
    0);
    const totalPercentShareholder = value.shareholder.option.reduce(function (
      acc,
      obj
    ) {
      return acc + Number(obj.percent);
    },
    0);
    return {
      admin: {
        percent: Number(value.admin.percent),
        total: (value.total * value.admin.percent) / 100,
        estock: "VNEX",
        vnex: (value.total * value.admin.percent) / 100,
      },
      presenter: {
        percent: totalPercentPresenter,
        total:
          (((value.total * stocksPrice?.vnex?.vnd) /
            stocksPrice?.[value.presenter.estock.toLowerCase()]?.vnd) *
            totalPercentPresenter) /
          100,
        estock: value.presenter.estock,
        vnex: (value.total * totalPercentPresenter) / 100,
      },
      shareholder: {
        percent: totalPercentShareholder,
        total:
          (((value.total * stocksPrice?.vnex?.vnd) /
            stocksPrice?.[value.shareholder.estock.toLowerCase()]?.vnd) *
            totalPercentShareholder) /
          100,
        estock: value.shareholder.estock,
        vnex: (value.total * totalPercentShareholder) / 100,
      },
    };
  }, [dataProfit]);

  return (
    <Wrapper isWebview={isWebview} isCreate={isCreate}>
      {!isCreate && (
        <Tabs>
          <Tab
            color={isWebview ? "black" : ""}
            active={active === "profitbusiness"}
            onClick={() => handelTabs("profitbusiness")}
          >
            Total Profit Sharing
          </Tab>
          <Tab
            color={isWebview ? "black" : ""}
            active={active === "profitSell"}
            onClick={() => handelTabs("profitSell")}
          >
            Affilate marketing profit
          </Tab>
          <Tab
            color={isWebview ? "black" : ""}
            active={active === "history"}
            onClick={() => handelTabs("history")}
          >
            History
          </Tab>
        </Tabs>
      )}
      <Container>
        <form>
          {active === "profitbusiness" && (
            <ShareProfitTotal
              isCreate={isCreate}
              isWebview={isWebview}
              dataProfit={dataProject?.sharedProfit || dataProfit}
              dataListFriends={dataListFriends}
              summary={summary}
              symbol={symbol}
              onChangeNote={onChangeNote}
              onChangeAmount={onChangeAmount}
              onChangePercent={onChangePercent}
              onChangeStockName={onChangeStockName}
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              onChangeAffilate={onChangeAffilate}
            />
          )}
          {active === "profitSell" && (
            <ShareProfitSell
              isWebview={isWebview}
              dataProfit={dataProfit}
              dataListFriends={dataListFriends}
              onChangePercent={onChangePercent}
              onChangeStockName={onChangeStockName}
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              onChangeAffilate={onChangeAffilate}
            />
          )}
        </form>
      </Container>
      {active === "history" ? (
        <HistoryProfit
          dataHistory={dataHistory}
          indexHistory={indexHistory}
          showHistotyTable={showHistotyTable}
        />
      ) : (
        <>
          {!disable && (
            <SubmmitShare>
              <Button
                loading={saving}
                disabled={blockHandling}
                onClick={handleSubmit(handleSaveProfit)}
              >
                Save
              </Button>
              {active === "profitbusiness" && !isCreate && (
                <Button
                  loading={checking}
                  disabled={
                    blockHandling ||
                    summary.admin.percent +
                      summary.shareholder.percent +
                      summary.presenter.percent !==
                      100
                  }
                  onClick={checkShare}
                >
                  Check
                </Button>
              )}
            </SubmmitShare>
          )}
          {isShowdetail && (
            <>
              <TableProfit
                total={totalSharedAfterCheck}
                dataSource={dataTables}
              />
              {missingStock && missingStock.length > 0 && (
                <>
                  {missingStock.map((el: any) => (
                    <TextSwap>
                      Your <b>{el?.symbol.toUpperCase()}</b> stock do not have
                      enough balance:{" "}
                      <b>
                        {el?.amountNeed} {el?.symbol.toUpperCase()}
                      </b>
                      .
                      <span onClick={() => history.push("/my/swap")}>
                        {" "}
                        Click here to swap!
                      </span>
                    </TextSwap>
                  ))}
                </>
              )}
              <SubmmitShare>
                <Button
                  loading={processing}
                  disabled={blockHandling}
                  onClick={handleTransfer}
                >
                  Process transactions
                </Button>
              </SubmmitShare>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Profit;
