import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import // ContentRightView,
// Continue,
// Confirm,
// QrCodeWrapper,
"../../../styles";

import { copyText, formatCurrency, randString } from "utils/common";
import { toast } from "react-toastify";
// import { CopyBtn } from "./styles";
import QRCode from "react-qr-code";
import VInput from "components/v-core/v-input";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
// import { Col, Row } from "reactstrap";

const Step3 = ({ nextStep, dataPayment, setDataByKey, backStep }) => {
  const [confirmAmount] = useState(formatCurrency(dataPayment.amount));
  const [confirmString] = useState(randString(false, 10));
  // const [globalError, setGlobalError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const confirmSubmit = (data) => {
    if (data.cfAmount !== confirmAmount) {
      return setError("cfAmount", new Error("Invalid amount!"));
    }

    if (dataPayment.currency === "vnd" && data.cfString !== confirmString) {
      return setError("cfString", new Error("Invalid amount!"));
    }

    setDataByKey("amount", dataPayment.amount);
    setDataByKey("content", confirmString);

    nextStep();
  };

  const handleCopy = (tx: string) => () => {
    copyText(tx);
    toast.success("Copied to clipboard!");
  };

  return (
    <div className="p-[15px]">
      <div>
        <div className="flex gap-x-2">
          <div className="w-3/4">
            {" "}
            <VInput
              transparent={true}
              className="!bg-transparent"
              label="Network"
              value={String(dataPayment.network).toUpperCase()}
              disabled={true}
            />
            <VInput
              transparent={true}
              className="!bg-transparent"
              label={`${dataPayment.bank && dataPayment.bank.bank_name} -  ${
                dataPayment.bank && dataPayment.bank.account_name
              }`}
              value={dataPayment.bank && dataPayment.bank.account_number}
              suffixIcon={
                <div className=" cursor-pointer ml-1">
                  <Icons
                    iconName="copy"
                    onClick={handleCopy(
                      dataPayment.bank && dataPayment.bank.account_number
                    )}
                  />
                </div>
              }
              disabled={true}
            />
            <VInput
              transparent={true}
              className="!bg-transparent"
              label="Amount to be paid"
              value={
                confirmAmount + " " + String(dataPayment.currency).toUpperCase()
              }
              suffixIcon={
                <div className="cursor-pointer ml-1">
                  <Icons iconName="copy" onClick={handleCopy(confirmAmount)} />
                </div>
              }
              disabled={true}
            />
            {dataPayment.currency === "vnd" && (
              <VInput
                transparent={true}
                className="!bg-transparent"
                disabled={true}
                label="Bank transfer content"
                placeholder="LDC9DI038K"
                value={confirmString}
                suffixIcon={
                  <Icons iconName="copy" onClick={handleCopy(confirmString)} />
                }
              />
            )}
          </div>
          {dataPayment.currency !== "vnd" && (
            <div className="w-1/4">
              <div className=" text-white inline-block">
                <QRCode value={dataPayment.bank.account_number} size={90} />
              </div>
              <br />
              <small>
                {" "}
                <b>Network:</b>{" "}
                {String(dataPayment.network || "").toUpperCase()}{" "}
              </small>
              <br />
              <small>
                <b>Note:</b> Please direct to the selected network. We are not
                responsible for this issue.
              </small>
            </div>
          )}
        </div>
        <div className="pt-[10px]">
          <p className="text-orange-400 mb-4">Please confirm again.</p>
          <Controller
            name="cfAmount"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <VInput
                transparent={true}
                className="!bg-transparent"
                label={`The ${String(
                  dataPayment.currency
                ).toUpperCase()} amount you will transfer`}
                hasError={Boolean(errors.cfAmount)}
                placeholder={`10,000 ${String(
                  dataPayment.currency
                ).toUpperCase()}`}
                {...field}
              />
            )}
          />

          {dataPayment.currency === "vnd" && (
            <Controller
              name="cfString"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <VInput
                  transparent={true}
                  className="!bg-transparent"
                  hasError={Boolean(errors.cfString)}
                  label="The bank transfer content you will use"
                  placeholder="LDC9DI038K"
                  {...field}
                />
              )}
            />
          )}
          <div className="text-center flex flex-col items-center justify-center w-full gap-y-3 mt-4">
            <VButton
              radius="full"
              className="!w-[350px]"
              onClick={handleSubmit(confirmSubmit)}
            >
              I’m ready to transfer 
            </VButton>
            <VButton
              type="outline"
              radius="full"
              className="!w-[350px]"
              transparent={true}
              onClick={() => backStep()}
            >
              Back
            </VButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
