import { ThemeContext } from "context/ThemeContext";
import { useContext } from "react";

/* eslint-disable indent */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  tabActive?: any;
  tabs: any;
  type?: "default" | "button";
  className?: string;
  onChangeTabs: (tab?: string) => void;
}

export default function VTabs({
  tabActive,
  tabs,
  type = "default",
  className,
  onChangeTabs,
}: Props) {
  const { themesSetting } = useContext(ThemeContext);

  return (
    <div
      className={`flex items-center w-full overflow-y-hidden overflow-x-auto no-scrollbar ${className}`}
    >
      <div
        className="border-b overflow-x-auto overflow-y-hidden no-scrollbar"
        style={themesSetting.tabs[type]}
      >
        <div className="flex overflow-x-auto overflow-y-hidden no-scrollbar">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`group inline-flex items-center py-1 px-3 text-sm font-medium whitespace-nowrap cursor-pointer transition-all duration-500 ease-linear ${
                type === "button" && "rounded-md"
              } ${
                tab?.value === tabActive &&
                type === "default" &&
                "border-b-[2.5px]"
              }
              ${tab.disable && "opacity-40"}
              `}
              onClick={() => !tab.disable && onChangeTabs(tab?.value)}
              style={
                tab.value === tabActive
                  ? themesSetting?.tabs[type]?.active
                  : themesSetting?.tabs[type]
              }
            >
              {tab?.icon && (
                <div
                  style={
                    tab.value === tabActive
                      ? themesSetting.tabs[type]?.active
                      : themesSetting.tabs[type]
                  }
                  className={classNames(
                    "-ml-0.5 mr-2 h-5 w-5",
                    "hover:opacity-70"
                  )}
                >
                  {tab.icon}
                </div>
              )}
              <span>{tab.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
