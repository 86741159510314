/* eslint-disable no-useless-concat */
/* eslint-disable indent */
// import { Col, Row } from "reactstrap";
import { renderAmount, renderStatus, renderStock } from "utils/render";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ToolTip from "components/v-tooltip";
import { useContext } from "react";
import { ThemeContext } from "context/ThemeContext";

const HistoryVND = (props: any) => {
  const renderNote = (el) => {
    return <span>{el.note || ""}</span>;
  };

  const { themesSetting } = useContext(ThemeContext);

  return (
    <div className="pt-[20px]">
      <div className="flex justify-between items-center p-[15px]">
        <p> {props.type === "deposit" ? "Deposit" : "Withdraw"} History</p>
      </div>
      {props.lists.length &&
        props.lists.map((el: any, index: number) => (
          <div
            key={index}
            className="px-[20px] py-[15px] mt-[2px] rounded-md"
            style={themesSetting?.card}
          >
            <div className="flex w-full">
              <div className=" w-7/12">
                <div className="flex gap-x-1">
                  <div className="w-[180px]">
                    <h6>Reference: {el.content}</h6>
                    <h6>{new Date(el.updatedAt).toLocaleString()}</h6>
                  </div>
                  <BsFillInfoCircleFill color="yellow" />
                </div>
              </div>
              <div className=" w-5/12">
                <div className="text-end">
                  {props.type === "withdraw" ? (
                    <h6 className="text-ellipsis overflow-hidden flex-nowrap">
                      {renderAmount(-el.amount)} {renderStock(el.stock)}
                    </h6>
                  ) : (
                    <h6 className="text-ellipsis overflow-hidden flex-nowrap">
                      {" "}
                      {renderAmount(el.amount_received)} {renderStock(el.stock)}
                    </h6>
                  )}
                  <div>
                    {el.status === "error" && (
                      <ToolTip id={`${el._id}_statis`} content={renderNote(el)}>
                        <span
                          id={`tooltip-` + `${el._id}_statis`}
                          className="gift"
                        >
                          {/* <BsFillInfoCircleFill color="yellow" /> */}
                        </span>
                      </ToolTip>
                    )}
                    {renderStatus(el.status)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default HistoryVND;
