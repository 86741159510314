import Button from "../../components/v-core/v-button";
import Input from "../../components/v-core/v-input";
import Dropdown from "../../components/v-core/v-dropdown";
import Modal from "../../components/v-core/v-modal";
import { useState } from "react";

const View = () => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const onChangeValue = (value: any) => {
    console.log(value);
  };
  return (
    <>
      <div>
        <div>
          <Button>Sign In</Button>
          <Button transparent={true}>Sign Up</Button>

          <Button>Sign In</Button>
          <Button transparent={true}>Sign Up</Button>
        </div>
        <br />

        <div>
          <Input color={"orange100"} placeholder="Email" />
          <Input transparent={true} placeholder="Password" />
          <Input transparent={true} color={"green100"} placeholder="Confirm" />
        </div>

        <div>
          <Dropdown
            options={[
              {
                label: "value1",
                value: "val1",
              },
              {
                label: "value2",
                value: "val2",
              },
              {
                label: "value3",
                value: "val3",
              },
            ]}
            onChange={onChangeValue}
          />
        </div>
        <br />
        <div>
          <Button onClick={() => setIsModal(true)}>Open Modal</Button>
          <Modal
            isOpen={isModal}
            title="Modal"
            onConfirm={() => setIsModal(false)}
            onCancel={() => setIsModal(false)}
          >
            Content Modal
          </Modal>
        </div>
      </div>
    </>
  );
};

export default View;
