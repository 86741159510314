import { useContext } from "react";
import { formatNumber } from "utils/common";
import { renderAmount, renderStatus, renderStock } from "utils/render";
import VTable from "components/v-core/v-table";
import { ThemeContext } from "context/ThemeContext";
import VPagination from "components/v-core/v-pagination";

const History = ({ history, filter, onFilter }) => {
  const { themesSetting } = useContext(ThemeContext);

  const columns = [
    {
      name: "Period",
      field: "id",
      dataIndex: "_id",
    },
    {
      name: "User",
      field: "user",
      render: (item: any) => item.user?.email,
    },
    {
      name: "From",
      field: "from",
      render: (item) => renderStock(item.from),
    },

    {
      name: "To",
      field: "to",
      render: (item) => renderStock(item.to),
    },
    {
      name: "Amount Send",
      field: "amount_send",
      render: (item) => renderAmount(-item.amountSend),
    },
    {
      name: "Amount Locked",
      field: "amount_locked",
      render: (item) => renderAmount(item.amountLocked),
    },
    {
      name: "Amount Received",
      field: "amount_received",
      render: (item) => renderAmount(item.amountReceived),
    },
    {
      name: "Fee",
      field: "fee",
      render: (item) =>
        item.fee
          ? formatNumber(item.fee.amount) +
            `(${String(item.fee.stock || "").toUpperCase()})`
          : "N/A",
    },
    {
      name: "Status",
      field: "Status",
      dataIndex: "status",
      render: (item: any) => <span>{renderStatus(item.status)}</span>,
    },
  ];

  return (
    <div className="rounded-md h-full relative" style={themesSetting?.card}>
      <div className="h-[calc(100%-70px)] max-h-[calc(100%-70px)]">
        <VTable columns={columns} data={history?.docs || []}></VTable>
      </div>
      <VPagination
        page={filter?.page}
        totalPages={history?.totalPages}
        limit={filter?.limit}
        totalDocs={history?.totalDocs}
        onFilter={onFilter}
      />
    </div>
  );
};

export default History;
